import { livechatConversationNoteFragment } from '../@types/livechatConversationNoteFragment';
import Maybe from 'graphql/tsutils/Maybe';

interface NotEmptyNode extends livechatConversationNoteFragment {
  text: string;
}

export function isNoteNotEmpty(
  note: Maybe<livechatConversationNoteFragment>,
): note is NotEmptyNode {
  return !!note?.text?.trim().length;
}
