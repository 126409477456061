import { useSubscriptionWithAuth } from '@utils/Data/hocks/useSubscriptionWithAuth';
import {
  NewDialogsMessagesSubscription,
  NewDialogsMessagesSubscriptionVariables,
  NewDialogsMessagesSubscription_livechatDialogsNewMessage,
} from '../../../common/@types/NewDialogsMessagesSubscription';
import { NEW_DIALOGS_MESSAGES_SUBSCRIPTION } from '../../../common/subscriptions';
import { useEffect } from 'react';
import {
  Folder,
  ParameterFilterValueOperator,
  ParametersOperator,
  SegmentationInput,
} from '@globals';
import { useCurrentBotId } from '@utils/Routing';
import { ObservableQuery } from 'apollo-client';
import { DialogsQuery } from '../../../common/@types/DialogsQuery';

const isInSegment = (
  segmetationConfig: SegmentationInput | null,
  updatedDialog: NewDialogsMessagesSubscription_livechatDialogsNewMessage,
): boolean => {
  if (!segmetationConfig || !updatedDialog) return true;

  /* ToDo:
    Пока сделан самый простой вариант, т.к. логику с бэка дублировать не хочется
    В случае учащенных запросов от премиумов надо думать
    */
  const assigneeAttribute = segmetationConfig.parameters?.find(
    (a) =>
      a?.name === 'assignee' &&
      a.operation === ParameterFilterValueOperator.is &&
      a.values?.length,
  );
  if (
    segmetationConfig.operation === ParametersOperator.and &&
    assigneeAttribute
  ) {
    const paramsSet = new Set(assigneeAttribute.values);
    const dialogAttribute = updatedDialog.subscriber.attributes.find(
      (a) => a.name === 'assignee',
    );
    return !!dialogAttribute?.values.find((attributeValue) =>
      paramsSet.has(attributeValue),
    );
  }

  return true;
};

export const useSubscribeNewDialogsMessages = (
  observableQuery: ObservableQuery<DialogsQuery>,
) => {
  const botId = useCurrentBotId();

  const { data } = useSubscriptionWithAuth<
    NewDialogsMessagesSubscription,
    NewDialogsMessagesSubscriptionVariables
  >(NEW_DIALOGS_MESSAGES_SUBSCRIPTION, {
    variables: {
      botId: botId || '',
    },
    skip: !botId,
    fetchPolicy: 'no-cache',
  });

  const updatedDialog = data?.livechatDialogsNewMessage;

  useEffect(() => {
    if (
      !updatedDialog ||
      !isInSegment(observableQuery.variables.segmentation, updatedDialog) ||
      (observableQuery.variables.folder !== updatedDialog.folder &&
        observableQuery.variables.folder !== Folder.all)
    ) {
      return;
    }

    try {
      observableQuery.updateQuery((data) => {
        if (!data) {
          return data;
        }

        const {
          livechatConversations: { items: currentItems },
        } = data;

        const currentDialogIndex = currentItems.findIndex(
          ({ id }) => id === updatedDialog.id,
        );

        const isNewDialog = currentDialogIndex === -1;

        if (!isNewDialog && currentDialogIndex === 0) {
          return data;
        }

        const mergedItems = currentItems.slice();
        if (!isNewDialog && currentDialogIndex !== 0) {
          mergedItems.splice(currentDialogIndex, 1);
        }

        mergedItems.unshift(updatedDialog);

        return {
          ...data,
          livechatConversations: {
            ...data.livechatConversations,
            items: mergedItems,
          },
        };
      });
    } catch (error) {
      console.error(error);
    }
  }, [botId, observableQuery, updatedDialog]);
};
