import { useMutation } from 'react-apollo';
import { BaseMutationOptions } from '@apollo/react-common';
import gql from 'graphql-tag';
import {
  MoveConversation,
  MoveConversationVariables,
} from './@types/MoveConversation';

const MOVE_CONVERSATION_MUTATION = gql`
  mutation MoveConversation(
    $botId: String!
    $conversationId: String!
    $folder: Folder!
    $platform: Platform
  ) {
    moveConversation(
      botId: $botId
      conversationId: $conversationId
      folder: $folder
      platform: $platform
    ) {
      id
      folder
    }
  }
`;

export const useMoveConversation = (
  options: BaseMutationOptions<MoveConversation, MoveConversationVariables>,
) => {
  const [moveConversation, { loading }] = useMutation<
    MoveConversation,
    MoveConversationVariables
  >(MOVE_CONVERSATION_MUTATION, options);

  return { moveConversation, loading };
};
