import React from 'react';
import cn from 'classnames';
import * as css from './FacebookAdCampaignsTable.css';
import { Spacer } from '../../../../modern-ui/Spacer';
import { Flex } from '../../../../modern-ui/Flex';
import { Type } from '../../../../modern-ui/Type';
import {
  Button,
  ButtonColorWay,
  ButtonIntent,
} from '../../../../modern-ui/_deprecated/Button';
import { ReactComponent as InfoIcon } from '../../../../modern-ui/_deprecated/Icon/icons/error-info.svg';
import { goToGetMarketingPermissions } from '../../../../utils/FacebookPages/goToGetPermissions';
import { sendEvent } from '../../../../utils/Analytics';

export const FacebookAdCampaignsListNeedRequest: React.FC = () => (
  <tbody className={css.disabled}>
    <tr>
      <td colSpan={12}>
        <Flex
          alignItems="center"
          justifyContent="space-between"
          className={css.permissionsRequestBox}
        >
          <Flex alignItems="flex-start">
            <InfoIcon className={cn(css.infoIcon, css.grey)} />
            <div>
              <Type weight="medium" as="p" size="15px_DEPRECATED">
                {window.i18next.t(
                  'FacebookAdCampaignsListNeedRequest-JSXText--179-chatfuel-needs-permission-to-list-your-facebook-ads',
                )}
              </Type>
              <Spacer factor={0.5} />
              <Type color="greyDark" as="p" size="15px_DEPRECATED">
                {window.i18next.t(
                  'FacebookAdCampaignsListNeedRequest-JSXText-8199-you-always-remain-in-control-we-will-not-do-anything-without-your-explicit-direction',
                )}
              </Type>
            </div>
          </Flex>
          <Button
            intent={ButtonIntent.primary}
            colorWay={ButtonColorWay.blue}
            wide
            style={{ pointerEvents: 'auto' }}
            onClick={() => {
              sendEvent({
                category: 'ads manager',
                action: 'give permissions click',
              });
              goToGetMarketingPermissions();
            }}
          >
            {window.i18next.t(
              'FacebookAdCampaignsListNeedRequest-JSXText-9467-give-permission',
            )}
          </Button>
        </Flex>
      </td>
    </tr>
  </tbody>
);
