import React from 'react';
import cx from 'classnames';
import { Type } from '@ui/Type';
import { usePageConnected } from '@utils/FacebookPages/usePageConnected';
import { useCurrentBotId } from '@utils/Routing';
import { Flex } from '@ui/Flex';
import { Avatar } from '@ui/Avatar';
import { Spacer } from '@ui/Spacer';
import productSrc from './images/product.svg';
import * as s from './ProductGalleryMessage.css';
import { MessageContentShape, MessageExtraShape } from '../MessageContentShape';
import {
  MessageFooter,
  MessageFooterProps,
} from '../../MessageTimestamp/MessageTimestamp';
import { useSafeTranslation } from '@utils/useSafeTranslation';

interface ProductItem {
  product_id: string | null;
}

interface ProductGalleryMessageProps extends MessageFooterProps {
  type: 'incoming' | 'outgoing';
  testing?: boolean;
  gallery: {
    items: ProductItem[];
  };
}

export const ProductGalleryMessage: React.FC<ProductGalleryMessageProps> = ({
  date,
  type,
  gallery,
  testing,
  error,
  status,
}) => {
  const botId = useCurrentBotId();
  const { page } = usePageConnected(botId);
  const { t } = useSafeTranslation();

  return (
    <div className={cx(s.root, { [s.outgoing]: type === 'outgoing' })}>
      {gallery.items.map((item, index) => {
        const link = `https://www.facebook.com/commerce/products/${item.product_id}/`;
        return (
          // eslint-disable-next-line react/no-array-index-key
          <div key={index}>
            <MessageContentShape
              type={type}
              leading
              trailing={false}
              capped
              className={s.card}
            >
              <Flex className={s.page} alignItems="center">
                <Avatar src={page?.picture || ''} customSize={20} />
                <Spacer factor={1} horizontalFactor={2} />
                <Type size="11px" weight="semibold" as="div">
                  <div className={s.ellipsis} style={{ width: 136 }}>
                    {page?.title}
                  </div>
                </Type>
              </Flex>
              <img src={productSrc} alt="" className={s.image} />
            </MessageContentShape>
            <MessageExtraShape type={type} className={s.card} trailing>
              <MessageFooter
                date={date}
                error={error}
                type={type}
                testing={testing}
                status={status}
              >
                <div className={s.padding}>
                  <Type as="div" size="11px">
                    {t('ProductGalleryMessage-JSXText--613-product')}
                    {index + 1}
                  </Type>
                  <a
                    href={link}
                    target="_blank"
                    className={s.link}
                    rel="noopener noreferrer"
                  >
                    <Type size="11px" as="div">
                      <div className={s.ellipsis} style={{ width: 170 }}>
                        {link}
                      </div>
                    </Type>
                  </a>
                </div>
              </MessageFooter>
            </MessageExtraShape>
          </div>
        );
      })}
    </div>
  );
};
