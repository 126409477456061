import i18next from 'i18next';
import memoize from 'lodash-es/memoize';
import { IconNames } from '@ui/Icon/icons';

export interface Placeholder {
  text: string;
  expired: string;
  iconName: IconNames;
  /**
   * Поле для конфигурирования способа отображения на ui
   */
  big: boolean;
}

export const getMediaMessagePlaceholders = memoize(
  () =>
    ({
      image: {
        text: i18next.t('pages.LiveChat.Message.MediaMessage.Image'),
        expired: i18next.t('pages.LiveChat.Message.MediaMessage.expired'),
        iconName: 'imagePlaceholder',
        big: true,
      },
      audio: {
        text: i18next.t('pages.LiveChat.Message.MediaMessage.Audio'),
        expired: i18next.t('pages.LiveChat.Message.MediaMessage.expired'),
        iconName: 'audioPlaceholder',
        big: false,
      },
      video: {
        text: i18next.t('pages.LiveChat.Message.MediaMessage.Video'),
        expired: i18next.t('pages.LiveChat.Message.MediaMessage.expired'),
        iconName: 'videoPlaceholder',
        big: true,
      },
      document: {
        text: i18next.t('pages.LiveChat.Message.MediaMessage.Document'),
        expired: i18next.t('pages.LiveChat.Message.MediaMessage.expired'),
        iconName: 'documentPlaceholder',
        big: false,
      },
    } as Record<string, Placeholder>),
);
