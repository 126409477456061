import React, { useEffect, useMemo, useRef, useState } from 'react';
import * as css from './LiveChatNoteItem.css';
import { IconButton } from '@ui/IconButton';
import Maybe from 'graphql/tsutils/Maybe';
import { Flex } from '@ui/Flex';
import { Spacer } from '@ui/Spacer';
import TextareaAutosize from 'react-textarea-autosize';
import { Type } from '@ui/Type';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import cn from 'classnames';
import { TextEllipsis } from '@ui/TextEllipsis';
import { DateFormat } from '@utils/DateTime';
import { sendEvent } from '@utils/Analytics';

interface LiveChatNoteItemProps {
  defaultText: Maybe<string>;
  adminName: string;
  editable: boolean;
  lastEdit: number;
  onDone: (value: string) => void;
  onChange: (value: string) => void;
  onDeleteRequest: () => void;
}

const MAX_LENGTH = 640;

export const LiveChatNoteItem: React.FC<LiveChatNoteItemProps> = ({
  defaultText,
  adminName,
  editable,
  onDone,
  onChange,
  lastEdit,
  onDeleteRequest,
}) => {
  const { t } = useSafeTranslation();
  const inputRef = useRef<HTMLTextAreaElement>();
  const [text, setText] = useState<string>(defaultText || '');
  const subText = useMemo(
    () =>
      t('pages.LiveChat.rightPanel.notes.sign', {
        name: adminName,
        date: DateFormat.MMMMDD_ifNeedYYYY(lastEdit),
      }),
    [adminName, lastEdit, t],
  );

  useEffect(() => {
    setText(defaultText || '');
  }, [defaultText]);

  useEffect(() => {
    if (!defaultText?.trim().length) {
      inputRef.current?.focus();
    }
  }, [defaultText]);

  return (
    <>
      <div
        data-testid="livechat_notes_item"
        className={css.box}
        onMouseDown={(event) => {
          if (!editable) {
            return;
          }
          event.preventDefault();
          event.stopPropagation();
          inputRef.current?.focus();
        }}
      >
        {editable ? (
          <TextareaAutosize
            onMouseDown={(event) => {
              event.stopPropagation();
            }}
            className={cn(css.text, css.input)}
            value={text}
            maxLength={MAX_LENGTH}
            onBlur={({ currentTarget: { value: rawValue } }) => {
              const value = rawValue.trim();
              if (value.length) {
                onDone(value);
              } else {
                onDeleteRequest();
              }
            }}
            onChange={({ currentTarget: { value } }) => {
              setText(value);
              onChange(value.trim());
            }}
            inputRef={(el) => {
              inputRef.current = el;
            }}
          />
        ) : (
          <div className={css.text}>{defaultText}</div>
        )}

        <Spacer factor={1} />
        <Flex justifyContent="space-between" alignItems="center">
          <Type size="12px" weight="semibold" color="yellowDark" as="div">
            <TextEllipsis width={280} title={subText}>
              {subText}
            </TextEllipsis>
          </Type>

          {editable && (
            <IconButton
              icon="delete"
              color="yellowDark"
              data-testid="livechat_notes_delete-button"
              onClick={() => {
                sendEvent({
                  category: 'live chat',
                  action: 'delete note click',
                  label: 'notes',
                });
                onDeleteRequest();
              }}
              onMouseDown={(event) => {
                event.preventDefault();
                event.stopPropagation();
              }}
            />
          )}
        </Flex>
      </div>
      <Spacer factor={1} />
    </>
  );
};
