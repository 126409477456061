import { Emoji } from '@ui/Emoji';
import { Flex } from '@ui/Flex';
import cn from 'classnames';
import React from 'react';
import { MESSAGE_REACTIONS } from '../../../consts';
import { MessagesQuery_livechatMessages_items_message_reactions } from '../@types/MessagesQuery';
import { MessageSourceType } from '../InstagramMessage/types';
import * as css from './MessageReaction.css';

interface MessageReactionProps {
  sourceType: MessageSourceType;
  reactions: MessagesQuery_livechatMessages_items_message_reactions[];
}

export const MessageReaction: React.FC<MessageReactionProps> = ({
  sourceType,
  reactions,
}) => {
  if (reactions.length === 0) {
    return null;
  }
  return (
    <Flex
      className={cn(css.messageEmoji, {
        [css.incoming]: sourceType === MessageSourceType.incoming,
        [css.outgoing]: sourceType === MessageSourceType.outgoing,
      })}
      justifyContent="center"
      alignItems="center"
      data-testid="livechat_message_reactions"
    >
      {reactions.map((reaction) =>
        reaction.type === MESSAGE_REACTIONS.love ? (
          <Emoji
            key={reaction.sender_id + reaction.type}
            emoji="❤️"
            size={12}
          />
        ) : null,
      )}
    </Flex>
  );
};
