import gql from 'graphql-tag';

export const SET_MESSAGE_REACTION_MUTATION = gql`
  mutation SetMessageReactionMutation(
    $botId: String!
    $conversationId: String!
    $messageId: String!
    $unreact: Boolean
  ) {
    setMessageReaction(
      botId: $botId
      conversationId: $conversationId
      messageId: $messageId
      unreact: $unreact
    )
  }
`;
