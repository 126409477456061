import React from 'react';
import cn from 'classnames';
import { TextMessage } from '../TextMessage';
import { MessagesQuery_livechatMessages_items_message_whatsapp_location } from '../../@types/MessagesQuery';
import {
  MessageFooter,
  MessageFooterProps,
} from '../../MessageTimestamp/MessageTimestamp';
import { TextMessageProps } from '../TextMessage/TextMessage';
import { Type } from '@ui/Type';
import { Spacer } from '@ui/Spacer';
import { Icon } from '@ui/Icon';
import { Flex } from '@ui/Flex';
import * as css from './WhatsappLocationMessage.css';
import { TextEllipsis } from '@ui/TextEllipsis';
import { ButtonUnstyled } from '@ui/Button';
import { openGoogleMapsAtLocation } from '@components/FlowBuilder/views/plugins/WhatsappLocationPlugin/utils';
import { sendEvent } from '@utils/Analytics';

export interface WhatsappLocationMessageProps
  extends Omit<TextMessageProps, 'color'>,
    MessageFooterProps {
  config: MessagesQuery_livechatMessages_items_message_whatsapp_location;
}

export const WhatsappLocationMessage: React.FC<WhatsappLocationMessageProps> =
  ({ leading, trailing, config, error, type, date, status }) => {
    return (
      <TextMessage type={type} leading={leading} trailing={trailing}>
        <MessageFooter error={error} date={date} type={type} status={status}>
          <div
            className={cn(css.wrapper, {
              [css.incoming]: type === 'incoming',
              [css.outgoing]: type === 'outgoing',
            })}
          >
            <ButtonUnstyled
              className={css.location}
              onClick={() => {
                sendEvent({
                  category: 'live chat',
                  action: 'open google maps',
                });

                openGoogleMapsAtLocation(config);
              }}
              data-testid="location-message__open-google-maps"
            >
              <Flex justifyContent="center" alignItems="center">
                <Icon icon="location" className={css.icon} />
              </Flex>
            </ButtonUnstyled>

            <Spacer factor={1} />

            <TextEllipsis title={config.name || ''}>
              <Type
                size="15px"
                className={css.name}
                data-testid="location-message__address-name"
              >
                {config.name}
              </Type>
            </TextEllipsis>

            <Spacer factor={1} />

            <Type
              size="12px"
              className={css.address}
              data-testid="location-message__address"
            >
              {config.address}
            </Type>
          </div>
        </MessageFooter>
      </TextMessage>
    );
  };
