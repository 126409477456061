import gql from 'graphql-tag';

export const LIVECHAT_CONVERSATION_NOTE_FRAGMENT = gql`
  fragment livechatConversationNoteFragment on LivechatConversationNote {
    id
    adminId
    text
    updatedAt
  }
`;

export const CONVERSATION_NOTES_QUERY = gql`
  query ConversationNotesQuery(
    $botId: String!
    $conversationId: String!
    $platform: Platform!
  ) {
    livechatConversationNotes(
      botId: $botId
      conversationId: $conversationId
      platform: $platform
    ) {
      ...livechatConversationNoteFragment
      createdAt
    }
  }
  ${LIVECHAT_CONVERSATION_NOTE_FRAGMENT}
`;

export const CREATE_CONVERSATION_NOTE_MUTATION = gql`
  mutation CreateConversationNoteMutation(
    $botId: String!
    $conversationId: String!
    $text: String!
    $platform: Platform!
  ) {
    createConversationNote(
      botId: $botId
      conversationId: $conversationId
      text: $text
      platform: $platform
    ) {
      ...livechatConversationNoteFragment
      createdAt
    }
  }
  ${LIVECHAT_CONVERSATION_NOTE_FRAGMENT}
`;

export const UPDATE_CONVERSATION_NOTE_MUTATION = gql`
  mutation UpdateConversationNoteMutation(
    $noteId: String!
    $botId: String!
    $conversationId: String!
    $text: String!
    $platform: Platform!
  ) {
    updateConversationNote(
      noteId: $noteId
      botId: $botId
      conversationId: $conversationId
      text: $text
      platform: $platform
    ) {
      ...livechatConversationNoteFragment
    }
  }
  ${LIVECHAT_CONVERSATION_NOTE_FRAGMENT}
`;

export const DELETE_CONVERSATION_NOTE_MUTATION = gql`
  mutation DeleteConversationNoteMutation(
    $noteId: String!
    $botId: String!
    $conversationId: String!
    $platform: Platform!
  ) {
    deleteConversationNote(
      noteId: $noteId
      botId: $botId
      conversationId: $conversationId
      platform: $platform
    )
  }
`;
