import { useMutation } from '@apollo/react-hooks';
import { usePageConnected } from '@utils/FacebookPages/usePageConnected';
import { useCallback } from 'react';
import { clone } from 'ramda';
import { Platform } from '@globals';
import { MESSAGE_REACTIONS } from '../../../consts';
import { MessagesQuery, MessagesQueryVariables } from '../@types/MessagesQuery';
import { MESSAGES_QUERY } from '../queries';
import {
  SetMessageReactionMutation,
  SetMessageReactionMutationVariables,
} from './@types/SetMessageReactionMutation';
import { SET_MESSAGE_REACTION_MUTATION } from './query';
import { log } from 'cf-common/src/logger';

interface useMessageReactionProps {
  botId: string;
  conversationId: string;
  messageId: string;
  platform: Platform;
}

interface reactionProps {
  reaction: string;
  unreact?: boolean;
}

export const useMessageReaction = ({
  botId,
  conversationId,
  messageId,
  platform,
}: useMessageReactionProps) => {
  const [setMessageReactionMutation, { loading }] = useMutation<
    SetMessageReactionMutation,
    SetMessageReactionMutationVariables
  >(SET_MESSAGE_REACTION_MUTATION);
  const { pageId } = usePageConnected(botId);

  const setMessageReaction = useCallback(
    ({ reaction, unreact }: reactionProps) => {
      if (reaction !== MESSAGE_REACTIONS.love) {
        return;
      }
      setMessageReactionMutation({
        variables: { botId, conversationId, messageId, unreact },
        update: (cache, { data }) => {
          if (data?.setMessageReaction) {
            // update message data with new reaction data

            let currentData;
            try {
              currentData = cache.readQuery<
                MessagesQuery,
                MessagesQueryVariables
              >({
                query: MESSAGES_QUERY,
                variables: { botId, conversationId, platform },
              });
            } catch (error) {
              log.error({
                msg: 'setMessageReactionMutation read messages query failed',
                data: { error },
              });
            }

            if (!currentData || !pageId) {
              return;
            }

            const modifiedMessage = currentData?.livechatMessages.items.find(
              (v) => v.mid === messageId,
            );

            if (modifiedMessage) {
              const { message } = modifiedMessage;
              if (unreact) {
                const reactionToRemove = message.reactions?.find(
                  (v) => v.sender_id === pageId,
                );
                if (reactionToRemove) {
                  const reactionToRemoveIndex =
                    message.reactions?.indexOf(reactionToRemove);
                  if (
                    reactionToRemoveIndex !== -1 &&
                    typeof reactionToRemoveIndex !== 'undefined'
                  ) {
                    message.reactions?.splice(reactionToRemoveIndex, 1);
                  }
                }
              } else {
                const newReaction = {
                  __typename: 'MessageReaction' as const,
                  type: reaction,
                  emoji: '❤️',
                  sender_id: pageId,
                };
                if (message.reactions) {
                  message.reactions.push(newReaction);
                } else {
                  // eslint-disable-next-line no-param-reassign
                  message.reactions = [newReaction];
                }
              }
            }

            try {
              const newData = clone(currentData);

              cache.writeQuery<MessagesQuery, MessagesQueryVariables>({
                query: MESSAGES_QUERY,
                variables: { botId, conversationId, platform },
                data: newData,
              });
            } catch (error) {
              log.error({
                msg: 'setMessageReactionMutation write messages query failed',
                data: { error },
              });
            }
          }
        },
      });
    },
    [
      setMessageReactionMutation,
      pageId,
      botId,
      conversationId,
      messageId,
      platform,
    ],
  );
  return {
    setMessageReaction,
    loading,
  };
};
