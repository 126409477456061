import React from 'react';
import isEqual from 'lodash-es/isEqual';
import { DEFAULT_SEGMENTATION } from '@utils/Data/LiveChat';
import { SegmentationInput } from '@globals';
import { useFilterableSearch } from '@components/FilterableSearchField';
import { LivechatFilter } from '../FilterableSearch';
import { AttributesFilter } from '../FilterableSearch/components/AttributesFilter/AttributesFilter';

export interface AttributesFilterViewProps {
  onClose: VoidFunction;
}

export const AttributesFilterView: React.FC<AttributesFilterViewProps> = ({
  onClose,
}) => {
  const { parameters, setFilter, resetFilter } = useFilterableSearch<
    LivechatFilter,
    SegmentationInput
  >();

  return (
    <AttributesFilter
      initialValues={parameters}
      onClose={() => {
        onClose();
        if (!parameters?.parameters?.length) {
          resetFilter();
        }
      }}
      onSubmit={(_, segmentation) => {
        onClose();

        if (
          !isEqual(DEFAULT_SEGMENTATION, segmentation) &&
          segmentation.parameters?.length
        ) {
          setFilter({
            value: null,
            type: LivechatFilter.attribute,
            parameters: segmentation,
          });
        } else {
          resetFilter();
        }
      }}
    />
  );
};
