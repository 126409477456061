import React from 'react';
import * as css from './AudioPlayer.css';

interface AudioPlayerProps {
  url: string;
}

export const AudioPlayer: React.FC<AudioPlayerProps> = ({ url }) => {
  return (
    // eslint-disable-next-line jsx-a11y/media-has-caption
    <audio controls src={url} className={css.audio} />
  );
};
