import { useQuery } from '@apollo/react-hooks';
import { Platform } from '@globals';
import { LIVECHAT_SUBSCRIBER_QUERY } from '../common/queries';
import {
  LivechatSubscriberQuery,
  LivechatSubscriberQueryVariables,
} from '../common/@types/LivechatSubscriberQuery';
import Maybe from 'graphql/tsutils/Maybe';

interface UseLiveChatSubscriberProps {
  botId: Maybe<string>;
  conversationId?: string | null;
  platform?: Platform | null;
}

export const useLiveChatSubscriber = ({
  botId,
  conversationId,
  platform,
}: UseLiveChatSubscriberProps) => {
  const { data, loading, error } = useQuery<
    LivechatSubscriberQuery,
    LivechatSubscriberQueryVariables
  >(LIVECHAT_SUBSCRIBER_QUERY, {
    variables: {
      botId: botId || '',
      conversationId: conversationId!,
      platform: platform!,
    },
    skip: !conversationId || !platform || !botId,
  });

  return {
    subscriber: data?.livechatConversation.subscriber,
    loading,
    error,
  };
};
