import { useDeviceMedia } from '@utils/DOM/useDeviceMedia';
import { usePrevious } from 'cf-common/src/utils/hooks';
import React, { Fragment } from 'react';
import { CSSTransition } from 'react-transition-group';
import cn from 'classnames';
import css from './MobileTransition.css';

const slideLeftClasses = {
  enter: css.slideLeftEnter,
  enterActive: css.slideLeftEnterActive,
  exit: css.slideLeftExit,
  exitActive: css.slideLeftExitActive,
};

const slideRightClasses = {
  enter: css.slideRightEnter,
  enterActive: css.slideRightEnterActive,
  exit: css.slideRightExit,
  exitActive: css.slideRightExitActive,
};

const getClasses = (
  index: number,
  activePageIndex: number,
  prevActivePageIndex: number,
) => {
  if (prevActivePageIndex > activePageIndex) {
    return activePageIndex === index ? slideLeftClasses : slideRightClasses;
  }
  return activePageIndex === index ? slideRightClasses : slideLeftClasses;
};

interface MobileTransitionPage {
  id: string | number;
  page: React.ReactNode;
  active?: boolean;
}

interface MobileTransitionProps {
  pages: MobileTransitionPage[];
  mobileContainerClassName?: string;
  mobilePageClassName?: string;
}

export const MobileTransition: React.FC<MobileTransitionProps> = ({
  pages,
  mobileContainerClassName,
  mobilePageClassName,
}) => {
  const { isSmallScreenSize } = useDeviceMedia();

  const activePageIndex = pages.findIndex(({ active }) => active);

  const prevActivePageIndex = usePrevious(activePageIndex) ?? activePageIndex;

  return isSmallScreenSize ? (
    <div className={cn(css.container, mobileContainerClassName)}>
      {pages.map(({ page, id, active }, i) => (
        <CSSTransition
          key={id}
          in={active}
          timeout={250}
          classNames={getClasses(i, activePageIndex, prevActivePageIndex)}
          unmountOnExit
        >
          <div className={cn(css.slide, mobilePageClassName)}>{page}</div>
        </CSSTransition>
      ))}
    </div>
  ) : (
    <>
      {pages.map(({ page, id }) => (
        <Fragment key={id}>{page}</Fragment>
      ))}
    </>
  );
};
