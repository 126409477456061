import { Button } from '@ui/Button';
import { Emoji } from '@ui/Emoji';
import { Icon } from '@ui/Icon';
import { Tooltip2 } from '@ui/Tooltip2';
import React from 'react';
import { MESSAGE_REACTIONS } from '../../../../consts';
import { sendMessageProps } from '../../../SendMessage';

interface HeartStickerProps {
  onSubmit: (message: sendMessageProps) => void;
  disabled?: boolean;
}

export const HeartSticker: React.FC<HeartStickerProps> = ({
  onSubmit,
  disabled,
}) => {
  return (
    <Tooltip2
      content={
        <>
          {window.i18next.t('HeartSticker-JSXText-1281-send')}
          <Emoji emoji="❤️" size={15} />
        </>
      }
      placement="top"
      type="small"
    >
      {(refSticker, bindSticker) => (
        <Button
          {...bindSticker}
          ref={refSticker}
          intent="text"
          size="s"
          icon={<Icon icon="heart" />}
          data-testid="livechat_message-input_love-sticker"
          onClick={() => {
            onSubmit({ sticker_id: MESSAGE_REACTIONS.love });
          }}
          disabled={disabled}
        />
      )}
    </Tooltip2>
  );
};
