import { BotTabPlaceholder } from '@components/BotTab';
import { Button } from '@ui/Button';
import { Emoji } from '@ui/Emoji';
import React from 'react';

export const MessagesErrorPlaceholder: React.FC = () => {
  return (
    <BotTabPlaceholder
      icon={<Emoji emoji="💬" size={130} />}
      title={window.i18next.t(
        'MessagesErrorPlaceholder-string-1655-couldnt-load-conversation',
      )}
      subtitle={window.i18next.t(
        'MessagesErrorPlaceholder-string--156-there-was-an-error-loading-this-thread',
      )}
      renderFooter={() => (
        <Button onClick={() => window.location.reload()}>
          {window.i18next.t('MessagesErrorPlaceholder-JSXText-7455-reload-now')}
        </Button>
      )}
    />
  );
};
