import React from 'react';
import { ButtonUnstyled } from '../../../../../modern-ui/Button';
import { ReactComponent as CloseIcon } from '../../../../../modern-ui/_deprecated/Icon/icons/ic_close_2.svg';
import * as css from './AbandonedCartSetupHeader.css';
import { Flex } from '../../../../../modern-ui/Flex';
import { ReactComponent as ShopifyIcon } from './shopify_icon.svg';
import { sendEvent } from '../../../../../utils/Analytics';
import { SetCallbackType } from '../../../../../utils/ServerStorage';

interface AbandonedCartSetupHeaderProps {
  closedHeader?: boolean;
  setClosedHeader: SetCallbackType<boolean>;
}

export const AbandonedCartSetupHeader: React.FC<AbandonedCartSetupHeaderProps> = ({
  closedHeader,
  setClosedHeader,
}) => {
  return !closedHeader ? (
    <Flex alignItems="center" className={css.header}>
      <ShopifyIcon className={css.icon} />
      <span className={css.text}>
        {window.i18next.t(
          'AbandonedCartSetupHeader-JSXText-1110-finish-setting-up-cart-reminders-here',
        )}
      </span>
      <ButtonUnstyled
        className={css.button}
        onClick={() => {
          sendEvent({
            category: 'grow tools',
            action: 'close setting up cart reminders',
          });
          setClosedHeader(true);
        }}
      >
        <CloseIcon />
      </ButtonUnstyled>
    </Flex>
  ) : null;
};
