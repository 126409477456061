import React from 'react';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import cn from 'classnames';
import { Flex } from '@ui/Flex';
import { Icon } from '@ui/Icon';
import { Spacer } from '@ui/Spacer';
import { Type } from '@ui/Type';
import { Button } from '@ui/Button';
import css from './NotConnected.css';
import { sendEvent } from '@utils/Analytics';

interface NotConnectedProps extends TestLocator {
  icon: React.ReactNode;
  title: string;
  description: string;
  buttonTitle?: string;
  onConnect: () => void;
  isSmallScreenSize: boolean;
}

const NotConnected: React.FC<NotConnectedProps> = ({
  icon,
  title,
  description,
  buttonTitle,
  onConnect,
  isSmallScreenSize,
  ...props
}) => {
  const { t } = useSafeTranslation();
  return (
    <Flex
      flexDirection="column"
      fullHeight
      alignItems="center"
      justifyContent="center"
      style={{
        maxWidth: '80%',
        marginLeft: 'auto',
        marginRight: 'auto',
      }}
    >
      <>
        {icon}
        <Spacer factor={4} />
        <Type size="18px" weight="medium" align="center">
          {title}
        </Type>
        <Spacer factor={2} />
        <Type size="15px" align="center">
          {description}
        </Type>
        <Spacer factor={4} />
        <Button
          className={cn(isSmallScreenSize && css.connectButtton)}
          intent="primary"
          onClick={onConnect}
          data-testid={props['data-testid']}
        >
          {buttonTitle ?? t('pages.LiveChat.Disabled.connectButton')}
        </Button>
      </>
    </Flex>
  );
};

type SpecifiedProps = 'icon' | 'title' | 'description' | 'data-testid';

export interface PlatformNotConnectedProps
  extends Omit<NotConnectedProps, SpecifiedProps> {}

export const FacebookNotConnected: React.FC<PlatformNotConnectedProps> = (
  props,
) => {
  const { t } = useSafeTranslation();

  return (
    <NotConnected
      icon={<Icon icon="facebook" size="32px" />}
      title={t('pages.LiveChat.Disabled.connectFbHeader')}
      description={t('pages.LiveChat.Disabled.connectFbDescription')}
      data-testid="livechat__dialogs__connect-facebook"
      {...props}
    />
  );
};

export const InstagramNotConnected: React.FC<PlatformNotConnectedProps> = (
  props,
) => {
  const { t } = useSafeTranslation();

  return (
    <NotConnected
      icon={<Icon icon="instagramFilled" size="32px" />}
      title={t('pages.LiveChat.Disabled.connectIgHeader')}
      description={t('pages.LiveChat.Disabled.connectIgDescription')}
      data-testid="livechat__dialogs__connect-instagram"
      {...props}
    />
  );
};

export const WhatsappNotConnected: React.FC<PlatformNotConnectedProps> = (
  props,
) => {
  const { t } = useSafeTranslation();

  return (
    <NotConnected
      icon={<Icon icon="whatsappLogo" size="32px" />}
      title={t('pages.LiveChat.Disabled.connectWhatsappHeader')}
      description={t('pages.LiveChat.Disabled.connectWhatsappDescription')}
      buttonTitle={`${t('pages.LiveChat.Disabled.connectButton')} ↗︎`}
      data-testid="livechat__dialogs__connect-whatsapp"
      {...props}
      onConnect={() => {
        sendEvent({
          category: 'whatsapp livechat',
          action: 'connect whatsapp button click',
        });
        props.onConnect();
      }}
    />
  );
};
