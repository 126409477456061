import {
  FilterableBaseInput,
  RenderFilterableInputConfig,
  SearchType,
  useFilterableSearch,
} from '@components/FilterableSearchField';
import React, { useMemo, useRef } from 'react';
import { LivechatFilter } from '../../types';
import { AssigneeInput } from '../AssineeFilter/AssigneeFilterableInput';
import { Spacer } from '@ui/Spacer';
import { Type } from '@ui/Type';
import { SegmentationInput } from '@globals';
import { ButtonUnstyled } from '@ui/Button';
import { Flex } from '@ui/Flex';
import { useSafeTranslation } from '@utils/useSafeTranslation';

export interface AttributesFilterableInputProps
  extends RenderFilterableInputConfig<LivechatFilter> {
  preparedFilter: LivechatFilter;
  onItemClick?: VoidFunction;
}

export const AttributesFilterableInput = ({
  preparedFilterRef,
  searchValue,
  setPreparedFilter,
  inputWidth,
  preparedFilter,
  onItemClick,
}: AttributesFilterableInputProps) => {
  const { t } = useSafeTranslation();
  const { parameters, setFilter, resetFilter } = useFilterableSearch();
  const clearSelectionRef = useRef<HTMLButtonElement>(null);

  const attributesAmount = useMemo(() => {
    if (!parameters) {
      return 0;
    }

    const segmentation = parameters as SegmentationInput;

    return segmentation.parameters?.length || 0;
  }, [parameters]);

  return (
    <FilterableBaseInput
      width={inputWidth}
      defaultValue={searchValue}
      showPreparedFilterInput={!!preparedFilter}
      preparedFilterRef={preparedFilterRef}
      onPreparedFilterChange={(event: any) => {
        const { value } = event.target;
        setPreparedFilter(null);
        setFilter({ type: SearchType.search, value, parameters: null });
      }}
      onControlClick={() => {
        if (!preparedFilter) {
          clearSelectionRef.current?.focus();
        }
      }}
      renderFilter={() => (
        <AssigneeInput
          ref={clearSelectionRef}
          onCloseClick={() => resetFilter()}
        >
          <ButtonUnstyled onClick={onItemClick}>
            <Flex>
              <Spacer horizontalFactor={2} factor={0} />
              <Type size="15px_DEPRECATED">
                {t('pages.LiveChat.FilterableSearch.attribute.attributes', {
                  amount: attributesAmount,
                })}
              </Type>
            </Flex>
          </ButtonUnstyled>
        </AssigneeInput>
      )}
    />
  );
};
