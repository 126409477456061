import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { BlocksStatsType } from './FacebookAdUtils';
import { LoadingPlaceholder } from '../../../../modern-ui/LoadingPlaceholder';
import { ButtonUnstyled } from '../../../../modern-ui/Button';
import * as css from './FacebookAdCampaignsListBlockStat.css';
import {
  FieldNameType,
  prepareBlockFilterConfig,
} from '../../../../utils/PeopleTabUtils';
import { useCurrentBotId } from '../../../../utils/Routing';

interface FacebookAdCampaignsListBlockStatProps {
  blockId: string | undefined;
  blocksStats: BlocksStatsType | undefined;
}

const formatter = new Intl.NumberFormat('en-EN', {
  maximumFractionDigits: 2,
  minimumFractionDigits: 0,
});

const prepareRate = (value: number, basis: number) =>
  basis > 0 ? `${formatter.format(((value || 0) / basis) * 100)}%` : '-';

export const FacebookAdCampaignsListBlockStat: React.FC<FacebookAdCampaignsListBlockStatProps> = ({
  blockId,
  blocksStats,
}) => {
  const botId = useCurrentBotId();
  const history = useHistory();

  const blockStatsClickHandler = useCallback(
    (fieldName: FieldNameType, blockId: string) => {
      if (!botId) {
        return;
      }
      history.push(`/bot/${botId}/users`, {
        filter: prepareBlockFilterConfig(fieldName, blockId),
      });
    },
    [botId, history],
  );

  if (!blockId) {
    return (
      <>
        <td />
        <td />
        <td />
      </>
    );
  }

  const blockStat = blocksStats?.[blockId];

  if (!blockStat) {
    return (
      <>
        <td>
          <LoadingPlaceholder />
        </td>
        <td>
          <LoadingPlaceholder />
        </td>
        <td>
          <LoadingPlaceholder />
        </td>
      </>
    );
  }

  const { seen, clicked, sent } = blockStat;

  return (
    <>
      <td>
        <ButtonUnstyled
          className={css.blockStatLink}
          disabled={!sent}
          onClick={(e) => {
            e.stopPropagation();
            blockStatsClickHandler('sent', blockId);
          }}
        >
          {sent.toLocaleString('en-EN')}
        </ButtonUnstyled>
      </td>
      <td>
        <ButtonUnstyled
          className={css.blockStatLink}
          disabled={!seen}
          onClick={(e) => {
            e.stopPropagation();
            blockStatsClickHandler('seen', blockId);
          }}
        >
          {prepareRate(seen || 0, sent)}
        </ButtonUnstyled>
      </td>
      <td>
        <ButtonUnstyled
          className={css.blockStatLink}
          disabled={!clicked}
          onClick={(e) => {
            e.stopPropagation();
            blockStatsClickHandler('clicked', blockId);
          }}
        >
          {prepareRate(clicked || 0, sent)}
        </ButtonUnstyled>
      </td>
    </>
  );
};
