import { Translation } from '@translations';
import { Folder } from '../../../@types/globalTypes';

interface FolderItem {
  id: Folder;
  title: Translation;
  icon: string;
}

export const FOLDER_ITEMS: Array<FolderItem> = [
  {
    id: Folder.inbox,
    title: 'pages.LiveChat.open',
    icon: 'inboxActive',
  },
  {
    id: Folder.closed,
    title: 'pages.LiveChat.closed',
    icon: 'inboxDone',
  },
  {
    id: Folder.all,
    title: 'pages.LiveChat.allConversations',
    icon: 'inboxAll',
  },
];

export const MESSAGE_REACTIONS = {
  love: 'love',
};

export const DISPLAYED_FOLDERS = FOLDER_ITEMS.map((v) => v.id);

export const CONVERSATION_ID = 'conversationId';
