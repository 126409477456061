import React from 'react';
import cn from 'classnames';
import { sendEvent } from '@utils/Analytics';
import { Anchor } from '@ui/Links';
import * as css from './MessageContentShape.css';
import { useIsLiveChatWebView } from '@utils/useIsLiveChatWebView';
import { sendMessageToWebView } from '@utils/sendMessageToWebView';

interface MessageImageShapeProps
  extends Omit<React.HTMLProps<HTMLImageElement>, 'crossOrigin'> {
  url?: string | null;
}

export const MessageImageShape: React.FC<MessageImageShapeProps> = ({
  url,
  className,
  ...rest
}) => {
  const { isLiveChatWebView } = useIsLiveChatWebView();

  if (!url) {
    return null;
  }

  return (
    <Anchor
      href={url}
      target="_blank"
      hideArrow
      onClick={(event) => {
        if (isLiveChatWebView) {
          event.preventDefault();
          sendMessageToWebView({ type: 'openURL', payload: { url } });
        }

        sendEvent({
          category: 'live chat',
          action: 'open image in new tab',
          propertyBag: {
            url,
          },
        });
      }}
    >
      <img
        src={url}
        alt="message"
        className={cn(className, css.image)}
        {...rest}
      />
    </Anchor>
  );
};
