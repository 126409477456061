import React from 'react';
import { MessageImageShape } from '../../../MessageContentShape/MessageImageShape';
import { getMediaMessagePlaceholders } from '../../helpers/getMediaMessagePlaceholders';
import { AttachmentProps } from './types';
import { CommonAttachment } from './CommonAttachment';
import * as css from './ImageAttachment.css';

export const ImageAttachment: React.FC<AttachmentProps> = (props) => {
  const placeholder = getMediaMessagePlaceholders().image;

  return (
    <CommonAttachment {...props} placeholder={placeholder}>
      {(url) => <MessageImageShape url={url} className={css.image} />}
    </CommonAttachment>
  );
};
