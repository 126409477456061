import React, { useEffect, useState } from 'react';
import gql from 'graphql-tag';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { removeGlobalLoader } from 'cf-common/src/utils/removeGlobalLoader';
import { acceptGdpr } from 'cf-common/src/analytics';
import { log } from 'cf-common/src/logger';
import { PermissionGroup } from '@utils/Permissions';
import { getRedirectUrl, InitAuthProps } from '@utils/AuthFlow';
import { toaster } from '@services/MessageService';
import { ServiceMessageType } from '@ui/ServiceMessage2';
import { AuthFlowPage } from '@ui/Page';
import { Loader } from '@ui/Loader';
import { sendEvent } from '@utils/Analytics';
import { SET_AUTH_MUTATION } from '@utils/AuthFlow/queries';
import { redirect, safeRedirect } from '../../utils/UrlUtils';
import { Oops } from '../Oops';
import {
  GenerateTokenViaSdk,
  GenerateTokenViaSdkVariables,
} from './@types/GenerateTokenViaSdk';
import { LoginQuery, LoginQueryVariables } from './@types/LoginQuery';
import { getQueryObjectStringified } from '../MultiSystemAuth/utils/query';
import { sendInitialEventsAndLogs } from '../MultiSystemAuth/utils/sendInitialEventsAndLogs';

export const PERMISSION_GROUP_SEARCH_PARAM = 'permission_group';

const LOGIN_QUERY = gql`
  query LoginQuery(
    $interruptedHref: String
    $queryObjectStringified: String
    $permissionGroup: String
  ) {
    redirectAuthLink(
      interruptedHref: $interruptedHref
      queryObjectStringified: $queryObjectStringified
      permissionGroup: $permissionGroup
    ) {
      link
      tmp_google_user_id
      token
    }
    fbLoginStatus @client {
      status
      authResponse {
        accessToken
      }
    }
  }
`;

const GENERATE_TOKEN_VIA_SDK_MUTATION = gql`
  mutation GenerateTokenViaSdk(
    $sdkToken: String!
    $queryObjectStringified: String
    $permissionGroup: String
  ) {
    generateTokenFromSdk(
      sdkToken: $sdkToken
      queryObjectStringified: $queryObjectStringified
      permissionGroup: $permissionGroup
    ) {
      token
    }
  }
`;

export interface LoginProps extends InitAuthProps {
  useFbSdkToken?: boolean;
  isAutoLogin?: boolean;
  interruptedHref: string;
  reason: string;
  permissionGroup?: PermissionGroup;
}

export interface LoginCommonProps extends LoginProps {
  render: (params: {
    getTokenFromSdkLoading: boolean;
    goFbAuth: () => void;
    searchParams?: URLSearchParams;
  }) => React.ReactNode;
  onSuccessLogin?: () => Promise<void>;
  hideHeader?: boolean;
}

const redirectToAuth = (redirectAuthLink: String) => {
  acceptGdpr(true);
  const redirectUrl = encodeURIComponent(getRedirectUrl());
  redirect(redirectAuthLink.replace('REDIRECT_URL', redirectUrl));
};

export const LoginCommon: React.FC<LoginCommonProps> = (props) => {
  const {
    useFbSdkToken = true,
    client,
    isAutoLogin,
    interruptedHref,
    location,
    permissionGroup,
    render: pageUiRender,
    onSuccessLogin,
    hideHeader,
  } = props;

  const [storedRedirectAuthLink, setStoreRedirectAuthLink] = useState('');

  useEffect(() => {
    sendInitialEventsAndLogs(props);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const searchParams = new URLSearchParams(location.search);

  const permissionGroupSearchParam = searchParams.get(
    PERMISSION_GROUP_SEARCH_PARAM,
  );
  const permissionGroupState = location.state?.permissionGroup;
  const permissionGroupForLogin =
    permissionGroupSearchParam ||
    permissionGroupState ||
    permissionGroup ||
    PermissionGroup.minimal;

  const [getTokenFromSdk, { loading: getTokenFromSdkLoading }] = useMutation<
    GenerateTokenViaSdk,
    GenerateTokenViaSdkVariables
  >(GENERATE_TOKEN_VIA_SDK_MUTATION, {
    onError: (error) => {
      log.warn({ error, msg: 'Error while generating token on login page' });

      if (storedRedirectAuthLink) {
        redirectToAuth(storedRedirectAuthLink);
      } else {
        log.warn({
          msg: 'Unexpected error while generating token',
        });

        toaster.show({
          type: ServiceMessageType.error,
          payload: {
            message: window.i18next.t(
              'LoginCommon-string-3944-something-went-wrong-please-try-again-later',
            ),
          },
        });
      }
    },
    onCompleted: async (data) => {
      const { token } = data && data.generateTokenFromSdk;

      try {
        await client.mutate({
          mutation: SET_AUTH_MUTATION,
          variables: { token },
        });
        await onSuccessLogin?.();
        safeRedirect(interruptedHref || '/');
      } catch (error) {
        log.warn({ error, msg: 'Error while setting token on login page' });

        toaster.show({
          type: ServiceMessageType.error,
          payload: {
            message: window.i18next.t(
              'LoginCommon-string-3944-something-went-wrong-please-try-again-later',
            ),
          },
        });
      }
    },
  });

  const {
    data: loginQueryData,
    loading: loginQueryLoading,
    error: loginQueryError,
  } = useQuery<LoginQuery, LoginQueryVariables>(LOGIN_QUERY, {
    variables: {
      interruptedHref,
      permissionGroup: permissionGroupForLogin,
      queryObjectStringified: getQueryObjectStringified(
        location.search,
        interruptedHref,
      ),
    },
    onCompleted: async (data) => {
      const {
        redirectAuthLink: { link, tmp_google_user_id, token },
        fbLoginStatus,
      } = data;
      const accessToken = fbLoginStatus?.authResponse?.accessToken;

      setStoreRedirectAuthLink(link);

      if (token) {
        try {
          await client.mutate({
            mutation: SET_AUTH_MUTATION,
            variables: { token },
          });
          safeRedirect(interruptedHref || '/');
        } catch (error) {
          log.warn({ error, msg: 'Error while setting token on login page' });

          toaster.show({
            type: ServiceMessageType.error,
            payload: {
              message: window.i18next.t(
                'LoginCommon-string-3944-something-went-wrong-please-try-again-later',
              ),
            },
          });
        }
      }

      if (accessToken && isAutoLogin && useFbSdkToken) {
        getTokenFromSdk({
          variables: {
            sdkToken: accessToken,
            queryObjectStringified: getQueryObjectStringified(
              location.search,
              interruptedHref,
              ['id_token'],
              tmp_google_user_id ? { tmp_google_user_id } : {},
            ),
            permissionGroup: permissionGroupForLogin,
          },
        });
      } else if (isAutoLogin) {
        redirectToAuth(link);
      } else {
        removeGlobalLoader();
      }
    },
  });

  if (loginQueryError) {
    return (
      <Oops
        reason="Error in LOGIN_QUERY"
        errorText={loginQueryError.toString()}
      />
    );
  }

  return (
    <AuthFlowPage
      hideHeader={hideHeader}
      render={() => {
        if (loginQueryLoading || isAutoLogin) {
          return <Loader />;
        }

        if (!pageUiRender) {
          return null;
        }

        return pageUiRender({
          searchParams: new URL(window.location.href).searchParams,
          getTokenFromSdkLoading,
          goFbAuth: () => {
            if (!loginQueryData) {
              return;
            }
            sendEvent({
              category: 'welcome',
              action: 'click',
              propertyBag: {
                referrer: document.referrer,
              },
            });
            const {
              redirectAuthLink: { link, tmp_google_user_id },
              fbLoginStatus,
            } = loginQueryData;
            const accessToken = fbLoginStatus?.authResponse?.accessToken;
            if (accessToken && useFbSdkToken) {
              getTokenFromSdk({
                variables: {
                  sdkToken: accessToken,
                  queryObjectStringified: getQueryObjectStringified(
                    location.search,
                    interruptedHref,
                    ['id_token'],
                    tmp_google_user_id ? { tmp_google_user_id } : {},
                  ),
                  permissionGroup: permissionGroupForLogin,
                },
              });
            } else {
              redirectToAuth(link);
            }
          },
        });
      }}
    />
  );
};
