import { Flex } from '@ui/Flex';
import { LoadingPlaceholder } from '@ui/LoadingPlaceholder';
import React from 'react';
import { MessageRow, MessageTypeKey } from './Message/MessageRow';
import { TimeTitle } from './Message/TimeTitle';
import { sizes } from '../../common/sizes';
import { useDeviceMedia } from '@utils/DOM/useDeviceMedia';

const PLACEHOLDER_BORDER_RADIUS = '18px';

export const MessagesLoader = () => {
  const { isSmallScreenSize } = useDeviceMedia();

  return (
    <Flex
      flexDirection="column"
      style={{
        width: '100%',
        height: '100%',
        backgroundColor: 'var(--white)',
        paddingTop: 10,
      }}
    >
      <TimeTitle>
        <LoadingPlaceholder
          color="grey"
          style={{
            margin: 'auto',
            width: 57,
            height: 10,
            borderRadius: PLACEHOLDER_BORDER_RADIUS,
          }}
        />
      </TimeTitle>
      {/* eslint-disable-next-line @typescript-eslint/no-use-before-define */}
      <MessagePlaceholder
        type="incoming"
        width={280}
        height={76}
        isSmallScreenSize={isSmallScreenSize}
      />
      {/* eslint-disable-next-line @typescript-eslint/no-use-before-define */}
      <MessagePlaceholder
        type="outgoing"
        width={131}
        height={36}
        isSmallScreenSize={isSmallScreenSize}
      />
      {/* eslint-disable-next-line @typescript-eslint/no-use-before-define */}
      <MessagePlaceholder
        type="incoming"
        width={233}
        height={56}
        isSmallScreenSize={isSmallScreenSize}
      />
      {/* eslint-disable-next-line @typescript-eslint/no-use-before-define */}
      <MessagePlaceholder
        type="outgoing"
        width={238}
        height={76}
        isSmallScreenSize={isSmallScreenSize}
      />
      {/* eslint-disable-next-line @typescript-eslint/no-use-before-define */}
      <MessagePlaceholder
        type="incoming"
        width={233}
        height={56}
        isSmallScreenSize={isSmallScreenSize}
      />
    </Flex>
  );
};

interface MessagePlaceholderProps {
  type: MessageTypeKey;
  width: number;
  height: number;
  isSmallScreenSize: boolean;
}

const MessagePlaceholder: React.FC<MessagePlaceholderProps> = ({
  type,
  width,
  height,
  isSmallScreenSize,
}) => (
  <MessageRow
    isSmallScreenSize={isSmallScreenSize}
    type={type}
    renderMedia={() => (
      <LoadingPlaceholder
        color="grey"
        style={
          isSmallScreenSize
            ? {
                width: sizes.mobile.avatarSize,
                height: sizes.mobile.avatarSize,
                marginLeft: sizes.mobile.avatarMargin,
                marginRight: sizes.mobile.avatarMargin,
                borderRadius: '50%',
              }
            : {
                width: sizes[type].avatarSize,
                height: sizes[type].avatarSize,
                marginLeft: sizes[type].avatarMargin,
                marginRight: sizes[type].avatarMargin,
                borderRadius: '50%',
              }
        }
      />
    )}
  >
    <LoadingPlaceholder
      color="grey"
      style={{
        width,
        height,
        borderRadius: PLACEHOLDER_BORDER_RADIUS,
      }}
    />
  </MessageRow>
);
