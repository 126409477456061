import React from 'react';
import ng from 'angular';
import { ApolloProvider } from '@apollo/react-common';
import { react2angular } from 'react2angular';
import { Router } from 'react-router-dom';
import { Type } from '@ui/Type';
import { Anchor } from '@ui/Links';
import { Button } from '@ui/Button';
import { DefaultDialog } from '@ui/Dialog';
import { Modal } from '../../../modern-ui/Modal';
import client from '../../../common/services/ApolloService';
import { globalHistory } from '../../../utils/Routing';

const CommentsAutoreplySwitchToFlowsPopup = (props: {
  onRequestClose(): void;
}) => (
  <ApolloProvider client={client}>
    <Router history={globalHistory}>
      <Modal onDismiss={props.onRequestClose}>
        <DefaultDialog
          header={window.i18next.t(
            'ngCommentsAutoreplyPopup-string--640-acquire-users-from-comments-feature-is-gone',
          )}
          footer={
            <Button onClick={props.onRequestClose}>
              {window.i18next.t('ngCommentsAutoreplyPopup-JSXText-1874-got-it')}
            </Button>
          }
          onDismiss={props.onRequestClose}
        >
          <Type size="15px_DEPRECATED">
            {window.i18next.t(
              'ngCommentsAutoreplyPopup-JSXText-1114-acquire-users-from-comments-feature-isnt-available-anymore-but-no-worries-go-to-flows-in-the-automation-tab-and-set-up-comments-autoreply-entry-point-instead-it-functions-in-the-same-way',
            )}{' '}
            <Anchor
              intent="external"
              href="https://docs.chatfuel.com/en/articles/4603539-comments-autoreply-entry-point-automatically-respond-to-facebook-post-comments"
              target="_blank"
              rel="noopener noreferrer"
            >
              {window.i18next.t(
                'ngCommentsAutoreplyPopup-JSXText-2022-read-more',
              )}
            </Anchor>{' '}
            {window.i18next.t('ngCommentsAutoreplyPopup-JSXText-7817-about-it')}
          </Type>
        </DefaultDialog>
      </Modal>
    </Router>
  </ApolloProvider>
);

export const ngCommentsAutoreplySwitchToFlowsPopup = ng
  .module('app.modernComponents.commentsAutoreplySwitchToFlowsPopup', [])
  .component(
    'commentsAutoreplySwitchToFlowsPopup',
    react2angular(CommentsAutoreplySwitchToFlowsPopup, ['onRequestClose'], []),
  ).name;
