import React, { useCallback, useEffect, useState } from 'react';
import gql from 'graphql-tag';
import { Query } from '@apollo/react-components';
import { pathOr } from 'ramda';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { Level, log } from 'cf-common/src/logger';
import { AdminFeatures, useAdminFeatures } from '@utils/Data/Admin';
import cn from 'classnames';
import { Input } from '../../modern-ui/Input';
import { Button, ButtonIntent } from '../../modern-ui/_deprecated/Button';
import {
  ServerStorageItemKeys,
  serverStorageItemSet,
} from '../../utils/ServerStorage';
import * as css from './LeadsQualification.css';
import { InitAuthProps } from '../../utils/AuthFlow';
import { AuthFlowPage } from '../../modern-ui/Page';
import { RadioButton } from '../../modern-ui/Radio';
import {
  LeadsQualification,
  LeadsQualificationVariables,
} from './@types/LeadsQualification';
import { Modal } from '../../modern-ui/Modal';
import { Dialog } from '../../modern-ui/Dialog';
import { ReactComponent as DropdownIcon } from '../../modern-ui/_deprecated/Icon/icons/ic_dropdown_arr.svg';
import { SimpleCombobox } from '@ui/SimpleCombobox';
import { isWhiteLabelDomain } from '../../utils/WhiteLabelUtils';
import {
  sendEvent,
  sendEventOnceByCategoryAndAction,
} from '../../utils/Analytics';
import { safeRedirect } from '../../utils/UrlUtils';
import { LogoSize } from '../../modern-components/PageHeader/WhiteLabelLogo';
import { CenteredLoader } from '../../modern-ui/Loader';

interface LeadsQualificationProps extends InitAuthProps {
  interruptedHref: string;
}

interface FormValues {
  businessSize?: string;
  agency?: string;
  workEmail?: string;
  industry?: string;
  skipped: boolean;
}

interface LeadsQualificationFormProps {
  interruptedHref?: string;
  onSubmit?: () => void;
}

const SMALL_BUSINESS_SIZE = '1-10';
const OPTION_REQUIRED_TEXT_ERROR = 'Pick an option';

const SKIPPED_FIELD = 'skipped';

const INDUSTRIES = () => [
  window.i18next.t('LeadsQualification-string--100-auto-dealer'),
  window.i18next.t('LeadsQualification-string-1985-beauty'),
  window.i18next.t('LeadsQualification-string--858-celebrity'),
  window.i18next.t('LeadsQualification-string--100-e-commerce'),
  window.i18next.t('LeadsQualification-string-1713-education'),
  window.i18next.t('LeadsQualification-string--173-financial-services'),
  window.i18next.t('LeadsQualification-string-2125-gaming'),
  window.i18next.t('LeadsQualification-string--200-global-brand'),
  window.i18next.t('LeadsQualification-string--182-government'),
  window.i18next.t('LeadsQualification-string--139-healthcare'),
  window.i18next.t('LeadsQualification-string-6350-internet'),
  window.i18next.t('LeadsQualification-string--202-marketing-advertising'),
  window.i18next.t('LeadsQualification-string--200-media-publisher'),
  window.i18next.t('LeadsQualification-string-1826-non-profit'),
  window.i18next.t('LeadsQualification-string--188-political-campaign'),
  window.i18next.t(
    'LeadsQualification-string-8615-professional-lawyer-doctor-etc',
  ),
  window.i18next.t('LeadsQualification-string-7418-real-estate'),
  window.i18next.t('LeadsQualification-string-3595-small-retail'),
  window.i18next.t('LeadsQualification-string-1383-software'),
  window.i18next.t('LeadsQualification-string-1890-sports-team'),
  window.i18next.t('LeadsQualification-string--178-travel'),
  window.i18next.t('LeadsQualification-string-7651-other'),
];
const INDUSTRY_ITEMS = () =>
  INDUSTRIES().map((industry) => ({
    id: industry,
    title: industry,
  }));

const SELECTOR_MARGINS = 8;
const SELECTOR_ITEM_HEIGHT = 32;
const SELECTOR_HEIGHT = 6 * SELECTOR_ITEM_HEIGHT;

const ENTER_YOUR_WORK_EMAIL = 'Enter your work email';

const LeadsQualificationForm: React.FC<LeadsQualificationFormProps> = ({
  interruptedHref,
  onSubmit,
}) => {
  const { adminFeatures, adminFeaturesLoading } = useAdminFeatures();
  const needValidateEmailAsWork =
    adminFeatures?.work_email_validation_in_lids_qualification_form;
  const [domains, setDomains] = useState<string[] | undefined>();

  useEffect(() => {
    if (needValidateEmailAsWork) {
      import('./publicMailDomains.json').then((data) => {
        setDomains((data as any).publicMailDomains);
      });
    }
  }, [needValidateEmailAsWork]);

  const validateWorkEmail = useCallback(
    (val: string | undefined) => {
      if (!needValidateEmailAsWork || !domains || !val) {
        return true;
      }
      const domain = val.toLowerCase().split('@')[1];
      return !!domain && !domains.includes(domain);
    },
    [domains, needValidateEmailAsWork],
  );

  if (adminFeaturesLoading || (needValidateEmailAsWork && !domains)) {
    return <CenteredLoader />;
  }

  sendEventOnceByCategoryAndAction({
    category: 'onboarding form',
    action: 'seen',
  });

  const formInitialValues: FormValues = {
    businessSize: '',
    agency: '',
    workEmail: '',
    industry: '',
    skipped: false,
  };

  return (
    <Formik
      initialValues={formInitialValues}
      validationSchema={Yup.object().shape({
        businessSize: Yup.string().when(SKIPPED_FIELD, {
          is: false,
          then: Yup.string().required(OPTION_REQUIRED_TEXT_ERROR),
        }),
        agency: Yup.string().when(SKIPPED_FIELD, {
          is: false,
          then: Yup.string().required(OPTION_REQUIRED_TEXT_ERROR),
        }),
        workEmail: Yup.string().when(SKIPPED_FIELD, {
          is: false,
          then: Yup.string()
            .email(
              window.i18next.t(
                'LeadsQualification-string--357-enter-valid-email',
              ),
            )
            .test('work_email', ENTER_YOUR_WORK_EMAIL, validateWorkEmail)
            .required(ENTER_YOUR_WORK_EMAIL),
        }),
        industry: Yup.string().when(SKIPPED_FIELD, {
          is: false,
          then: Yup.string().required(OPTION_REQUIRED_TEXT_ERROR),
        }),
      })}
      onSubmit={async (values, actions) => {
        sendEvent(
          {
            category: 'onboarding form',
            action: 'submit',
          },
          true,
        );
        sendEvent(
          {
            category: 'onboarding form',
            action: 'email',
            label: values.workEmail,
            propertyBag: {
              validate_email_as_work: needValidateEmailAsWork,
              validate_email_as_work_status: validateWorkEmail(
                values.workEmail,
              ),
            },
          },
          true,
        );
        sendEvent(
          {
            category: 'onboarding form',
            action: 'business_size',
            label: values.businessSize,
          },
          true,
        );
        sendEvent(
          {
            category: 'onboarding form',
            action: 'agency',
            label: values.agency,
          },
          true,
        );
        sendEvent(
          {
            category: 'onboarding form',
            action: 'industry',
            label: values.industry,
          },
          true,
        );
        await serverStorageItemSet(
          ServerStorageItemKeys.leadsQualificationData,
          values,
        );

        actions.setSubmitting(false);

        if (interruptedHref) {
          safeRedirect(interruptedHref);
        }

        if (onSubmit) {
          onSubmit();
        }
      }}
    >
      {({
        handleSubmit,
        values,
        errors,
        touched,
        isSubmitting,
        handleChange,
        handleBlur,
        setFieldValue,
        submitForm,
      }) => {
        return (
          <div className={css.boxLeadsQualification}>
            <form onSubmit={handleSubmit}>
              <div className={css.title}>
                {window.i18next.t(
                  'LeadsQualification-JSXText-1646-lets-get-to-know-each-other',
                )}
              </div>
              <div className={css.info}>
                <span>
                  {window.i18next.t(
                    'LeadsQualification-JSXText--519-please-help-us-tailor-your-experience-specifically-for-your-needs',
                  )}
                </span>
              </div>
              <div className={css.formSection}>
                <span className={cn(css.formSectionHeader, css.required)}>
                  {window.i18next.t(
                    'LeadsQualification-JSXText--111-how-many-people-work-at-your-company',
                  )}
                </span>
                <div className={css.row}>
                  <RadioButton
                    name="businessSize"
                    id={SMALL_BUSINESS_SIZE}
                    label={SMALL_BUSINESS_SIZE}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.businessSize}
                  />
                  <RadioButton
                    name="businessSize"
                    id="11-200"
                    label="11-200"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.businessSize}
                  />
                  <RadioButton
                    name="businessSize"
                    id="200-1000"
                    label="200-1000"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.businessSize}
                  />
                  <RadioButton
                    name="businessSize"
                    id="1000+"
                    label="1000+"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.businessSize}
                  />
                </div>
                {touched.businessSize && (
                  <span className={css.error}>{errors.businessSize}</span>
                )}
              </div>
              {values.businessSize && (
                <>
                  <div className={css.formSection}>
                    <span className={cn(css.formSectionHeader, css.required)}>
                      {window.i18next.t(
                        'LeadsQualification-JSXText-9839-are-you-a-marketing-or-social-media-agency',
                      )}
                    </span>
                    <div className={css.row}>
                      <RadioButton
                        name="agency"
                        id="Yes"
                        label={window.i18next.t(
                          'LeadsQualification-string-8877-yes',
                        )}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.agency}
                      />
                      <RadioButton
                        name="agency"
                        id="No"
                        label={window.i18next.t(
                          'LeadsQualification-string-2529-no',
                        )}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.agency}
                      />
                    </div>
                    {touched.agency && (
                      <span className={css.error}>{errors.agency}</span>
                    )}
                  </div>
                  <div className={css.formSection}>
                    <span className={cn(css.formSectionHeader, css.required)}>
                      {values.agency === 'Yes'
                        ? window.i18next.t(
                            'LeadsQualification-string-1166-what-describes-most-of-your-clients-best',
                          )
                        : window.i18next.t(
                            'LeadsQualification-string-4071-what-describes-your-business-best',
                          )}
                    </span>
                    <SimpleCombobox
                      items={INDUSTRY_ITEMS()}
                      onChange={(item) => {
                        setFieldValue('industry', item && item.id);
                      }}
                      menuboxStyle={{
                        height: SELECTOR_HEIGHT + SELECTOR_MARGINS,
                      }}
                      scrollboxStyle={{ height: SELECTOR_HEIGHT }}
                      renderInput={({ getToggleButtonProps, selectedItem }) => (
                        <Button
                          type="button"
                          intent={ButtonIntent.secondary}
                          {...getToggleButtonProps()}
                          renderIconRight={() => <DropdownIcon />}
                        >
                          {selectedItem
                            ? selectedItem.title
                            : OPTION_REQUIRED_TEXT_ERROR}
                        </Button>
                      )}
                    />
                    {touched.industry && (
                      <span className={css.error}>{errors.industry}</span>
                    )}
                  </div>
                  <div className={css.formSection}>
                    <span className={cn(css.formSectionHeader, css.required)}>
                      {window.i18next.t(
                        'LeadsQualification-JSXText--910-whats-your-work-email-address',
                      )}
                    </span>
                    <Input
                      onChange={handleChange}
                      onBlur={handleBlur}
                      type="email"
                      name="workEmail"
                      value={values.workEmail}
                      error={!!(touched.workEmail && errors.workEmail)}
                      placeholder={
                        touched.workEmail && errors.workEmail
                          ? errors.workEmail
                          : window.i18next.t(
                              'LeadsQualification-string-2020-e-mail',
                            )
                      }
                    />
                    {touched.workEmail && values.workEmail && (
                      <span className={css.error}>{errors.workEmail}</span>
                    )}
                  </div>
                </>
              )}
              <div className={css.buttonBox}>
                <Button
                  data-testid="leads-qualification__skip-button"
                  type="button"
                  intent={ButtonIntent.secondary}
                  disabled={isSubmitting}
                  onClick={() => {
                    sendEvent(
                      {
                        category: 'onboarding form',
                        action: 'skip',
                      },
                      true,
                    );
                    setFieldValue(SKIPPED_FIELD, true);
                    setTimeout(() => {
                      submitForm(); // submit form after skip field update
                    });
                  }}
                >
                  {window.i18next.t('LeadsQualification-JSXText-1061-skip')}
                </Button>
                <Button
                  data-testid="leads-qualification__next-button"
                  type="submit"
                  intent={ButtonIntent.primary}
                  disabled={isSubmitting}
                >
                  {interruptedHref
                    ? window.i18next.t(
                        'LeadsQualification-string--911-get-started',
                      )
                    : window.i18next.t('LeadsQualification-string--180-submit')}
                </Button>
              </div>
            </form>
          </div>
        );
      }}
    </Formik>
  );
};

export const LeadsQualificationPage: React.FC<LeadsQualificationProps> = ({
  interruptedHref = '/',
}) => {
  return (
    <AuthFlowPage
      hideNav
      logoSize={LogoSize.big}
      disabledHeader
      render={() => (
        <LeadsQualificationForm interruptedHref={interruptedHref} />
      )}
    />
  );
};

const LEADS_QUALIFICATION_DIALOG_QUERY = gql`
  query LeadsQualification($serverStorageKey: ID!) {
    serverStorageItemGet(id: $serverStorageKey) {
      id
      data
    }
  }
`;

export const LeadsQualificationDialog: React.FC = () => {
  const [showDialog, setShowDialog] = useState(true);

  if (!showDialog) {
    return null;
  }

  return (
    <AdminFeatures>
      {({ adminFeatures, adminFeaturesLoading, adminFeaturesError }) => {
        if (adminFeaturesLoading || adminFeaturesError || !adminFeatures) {
          return null;
        }

        const useWhiteLabelDomain = isWhiteLabelDomain();
        if (useWhiteLabelDomain) {
          log({
            msg: 'Skip leads qualification dialog',
            level: Level.verbose,
            data: { useWhiteLabelDomain },
          });
          return null;
        }

        return (
          <Query<LeadsQualification, LeadsQualificationVariables>
            query={LEADS_QUALIFICATION_DIALOG_QUERY}
            variables={{
              serverStorageKey: ServerStorageItemKeys.leadsQualificationData,
            }}
          >
            {({ data, loading, error }) => {
              if (loading || error || !data || !data.serverStorageItemGet) {
                return null;
              }

              const qualificationData =
                data.serverStorageItemGet.data &&
                JSON.parse(data.serverStorageItemGet.data);
              const businessSize = pathOr<string>(
                '',
                ['businessSize'],
                qualificationData,
              );
              const skipped = pathOr<boolean>(
                false,
                [SKIPPED_FIELD],
                qualificationData,
              );

              if (businessSize || skipped) {
                log({
                  msg: 'Skip leads qualification dialog',
                  level: Level.verbose,
                  data: { businessSize, skipped },
                });
                return null;
              }

              return (
                <Modal>
                  <Dialog>
                    <LeadsQualificationForm
                      onSubmit={() => setShowDialog(false)}
                    />
                  </Dialog>
                </Modal>
              );
            }}
          </Query>
        );
      }}
    </AdminFeatures>
  );
};
