import gql from 'graphql-tag';

export const ATTACHMENT_FRAGMENT = gql`
  fragment attachmentFragment on MessageAttachment {
    id
    attachment_id
    type
    url
    # These fields are actual for whatsapp media attachments
    mime_type
    filename
    caption
    file_size
    expired
  }
`;

const MESSAGE_FRAGMENT = gql`
  fragment messageFragment on LivechatMessageV3 {
    id
    date
    mid
    seen
    conversationId
    status
    outpacingId
    from {
      type
      admin_id
      persona {
        name
        picture
      }
    }
    message {
      text
      buttons
      sticker_id
      attachment {
        ...attachmentFragment
      }
      gallery {
        type
        items {
          title
          description
          buttons
          image_url
          url
          product_id
        }
      }
      reactions {
        type
        emoji
        sender_id
      }
      receipt {
        order_number
        recipient_name
        currency
        total_cost
        subtotal_cost
        tax_cost
        payment_method
        order_status_url
        order_date
        shipping_address {
          address_1
          address_2
          country
          state
          city
          zip_code
        }
        line_items {
          title
          quantity
          price
          currency
          image_url
        }
      }
      send_to {
        title
        bot_id
        block_id
        flow_id
      }
      whatsapp_list {
        header_text
        body
        footer
        button
      }
      whatsapp_list_reply {
        row_title
        row_description
      }
      whatsapp_template {
        name
        body
        footer
        header_text
        header_document {
          name
          link
        }
        header_image
        header_video
        buttons {
          type
          text
          url
        }
      }
      whatsapp_location {
        longitude
        latitude
        name
        address
      }
      whatsapp_context {
        forwarded
        frequently_forwarded
        originalMessageType
        msgId
      }
    }
    from {
      type
      admin_id
      persona {
        name
        picture
      }
    }
    error {
      message
      code
      error_subcode
    }
  }
  ${ATTACHMENT_FRAGMENT}
`;

export const MESSAGES_QUERY = gql`
  query MessagesQuery(
    $botId: String!
    $conversationId: String!
    $to: String
    $from: String
    $platform: Platform
  ) {
    livechatMessages(
      botId: $botId
      conversationId: $conversationId
      to: $to
      from: $from
      platform: $platform
    ) {
      cursors {
        before
        after
      }
      items {
        ...messageFragment
      }
    }
  }
  ${MESSAGE_FRAGMENT}
`;

export const CONVERSATION_DATA_QUERY = gql`
  query ConversationDataQuery($botId: String!) {
    bot(id: $botId) {
      id
      admins {
        id
        picture
      }
      status {
        page_info {
          id
          title
          picture
          username
        }
      }
    }
  }
`;

export const NEW_MESSAGE_SUBSCRIPTION = gql`
  subscription NewMessageSubscription(
    $botId: String!
    $conversationId: String!
  ) {
    livechatNewMessage(botId: $botId, conversationId: $conversationId) {
      ...messageFragment
    }
  }
  ${MESSAGE_FRAGMENT}
`;

export const MESSAGE_STATUS_SUBSCRIPTION = gql`
  subscription MessageStatusSubscription(
    $botId: String!
    $conversationId: String!
  ) {
    livechatMessageStatusUpdate(
      botId: $botId
      conversationId: $conversationId
    ) {
      id
      status
    }
  }
`;

export const MESSAGE_REACTION_SUBSCRIPTION = gql`
  subscription MessageReactionSubscription(
    $botId: String!
    $conversationId: String!
  ) {
    livechatMessageReactionUpdate(
      botId: $botId
      conversationId: $conversationId
    ) {
      id
      message {
        reactions {
          type
          emoji
          sender_id
        }
      }
    }
  }
`;
