import React, { useCallback } from 'react';
import { sendEvent } from '@utils/Analytics';
import { useFacebookPages } from '@utils/FacebookPages/useFacebookPages';
import { useCurrentBotId } from '@utils/Routing';
import { useInstagramAccount } from '@utils/Data/Instagram/BotAccount';
import { SectionBox } from '@components/SectionBox';
import { useRefetchAdminData } from '@utils/Admin/useRefetchAdminData';
import { useLocation } from 'react-router-dom';
import { FacebookPageConnected } from './components/FacebookPageConnected';
import { FacebookPageDisconnected } from './components/FacebookPageDisconnected';
import { useFacebookLogin } from '../../MultiSystemAuth/hooks/useFacebookLogin';
import { PlatformConnectionLoading } from '../PlatformConnection/PlatformConnectionLoading/PlatformConnectionLoading';

export const FacebookPage: React.FC = () => {
  const botId = useCurrentBotId()!;
  const { facebookPagesData, facebookPagesRefetch, facebookPagesLoading } =
    useFacebookPages(botId, { fetchPolicy: 'cache-first' });
  const { refetchInstagramAccount } = useInstagramAccount(botId);
  const location = useLocation();
  const { facebookLogin } = useFacebookLogin({
    interruptedHref: window.location.href,
    location,
    reRequestPermissions: true,
  });
  const refetchAdminData = useRefetchAdminData();

  const bot = facebookPagesData?.bot;
  const connectedPage = bot?.status?.page_info;
  const userId = facebookPagesData?.me.id;

  const handleUpdatePermisssions = useCallback(async () => {
    sendEvent({
      category: 'connect facebook page',
      action: 'click update permissions',
    });
    await facebookLogin();
    facebookPagesRefetch();
    refetchAdminData();
  }, [facebookLogin, facebookPagesRefetch, refetchAdminData]);

  const renderBody = () => {
    if (facebookPagesLoading) {
      return <PlatformConnectionLoading data-testid="facebook-page__loading" />;
    }

    if (connectedPage) {
      return (
        <FacebookPageConnected
          page={connectedPage}
          botId={botId}
          otherAdminConnected={
            userId === connectedPage!.owner?.id ? null : connectedPage!.owner
          }
          onDisconnected={() => refetchInstagramAccount()}
          onUpdatePermissions={handleUpdatePermisssions}
        />
      );
    }

    return (
      <FacebookPageDisconnected
        botId={botId}
        refetchInstagramAccount={refetchInstagramAccount}
      />
    );
  };

  return <SectionBox data-testid="facebook-page">{renderBody()}</SectionBox>;
};
