import React, { useCallback, useRef, useState } from 'react';
import { useReverseScrollView } from './useReverseScrollView';
import { useScrollState } from './useScrollState';
import { TimeTitle } from '../Message/TimeTitle';
import { BackToMessagesWrapper } from '../BackToMessagesContext';
import { ANIMATION_MESSAGES_CONTAINER } from '../Message/Reply/hooks';
import * as css from '../MessagesColumn.css';

interface MessagesScrollListProps {
  loadMoreItems(): void;
  hasMoreBefore: boolean;
  loading: boolean;
}

export const MessagesScrollList: React.FC<MessagesScrollListProps> = ({
  loadMoreItems,
  hasMoreBefore,
  loading,
  children,
}) => {
  const [bottomScrolled, setBottomScrolled] = useState(0);
  const isNearTopEdge = useRef(false);
  const requestItemsBefore = useCallback(
    (isNear) => {
      if (isNear === isNearTopEdge.current) {
        return;
      }
      isNearTopEdge.current = isNear;
      if (isNear && loadMoreItems) {
        loadMoreItems();
      }
    },
    [loadMoreItems],
  );

  const handleScrollStateChange = useCallback(
    ({ topEdgeProximity, bottomEdgeProximity }) => {
      setBottomScrolled(bottomEdgeProximity);
      requestItemsBefore(topEdgeProximity != null && topEdgeProximity < 800);
    },
    [requestItemsBefore],
  );

  const { scrollRef, containerRef } = useReverseScrollView(loading);
  useScrollState(scrollRef, handleScrollStateChange);

  return (
    <BackToMessagesWrapper scrolled={bottomScrolled}>
      <div
        id={ANIMATION_MESSAGES_CONTAINER}
        ref={scrollRef}
        className={css.messagesScrollContainer}
      >
        <div ref={containerRef}>
          {hasMoreBefore && (
            <TimeTitle>
              {window.i18next.t('MessagesScrollList-JSXText-4081-loading')}
            </TimeTitle>
          )}
          {children}
        </div>
      </div>
    </BackToMessagesWrapper>
  );
};
