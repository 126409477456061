import React from 'react';
import cn from 'classnames';
import { Flex } from '@ui/Flex';
import { FlexProps } from '@ui/Flex/Flex';
import * as css from './PlatformConnectedHeaderWrapper.css';
import { getComponentWithRefAndName } from '@utils/withRef';

interface PlatformConnectedHeaderWrapperProps extends FlexProps, TestLocator {
  reversed?: boolean;
}

export const PlatformConnectedHeaderWrapper = getComponentWithRefAndName<
  HTMLDivElement,
  PlatformConnectedHeaderWrapperProps
>('PlatformConnectedHeaderWrapper', ({ children, reversed, ...props }, ref) => (
  <Flex
    ref={ref}
    alignItems="center"
    {...props}
    className={cn(props.className, {
      [css.reversed]: reversed,
    })}
  >
    {children}
  </Flex>
));
