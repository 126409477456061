import gql from 'graphql-tag';

export const UPDATE_USER_ATTRIBUTES = gql`
  mutation UpdateUserAttributes(
    $botId: String!
    $userId: String!
    $update: UpdateBotUserInput!
  ) {
    updateUserAttributes(botId: $botId, userId: $userId, update: $update) {
      update {
        name
        value
      }
      delete
    }
  }
`;

export const VARIABLE_SUGGEST_QUERY = gql`
  query VariableSuggest(
    $botId: String!
    $suggestType: VariableSuggestType!
    $type: VariablesType
  ) {
    bot(id: $botId) {
      id
      variableSuggest(suggestType: $suggestType, type: $type, platform: null, allPlatforms: false) {
        name
      }
    }
  }
`;
