import i18next from 'i18next';

export const SEND_TO_MESSENGER_TEXTS = () => [
  {
    id: 'GET_THIS_IN_MESSENGER',
    title: i18next.t('sendToMessengerTexts-string--100-get-this-in-messenger'),
  },
  {
    id: 'RECEIVE_THIS_IN_MESSENGER',
    title: i18next.t(
      'sendToMessengerTexts-string-1295-receive-this-in-messenger',
    ),
  },
  {
    id: 'SEND_THIS_TO_ME',
    title: i18next.t('sendToMessengerTexts-string--503-send-this-to-me'),
  },
  {
    id: 'GET_CUSTOMER_ASSISTANCE',
    title: i18next.t(
      'sendToMessengerTexts-string--119-get-customer-assistance',
    ),
  },
  {
    id: 'GET_CUSTOMER_SERVICE',
    title: i18next.t('sendToMessengerTexts-string--177-get-customer-service'),
  },
  {
    id: 'GET_SUPPORT',
    title: i18next.t('sendToMessengerTexts-string-1761-get-support'),
  },
  {
    id: 'LET_US_CHAT',
    title: i18next.t('sendToMessengerTexts-string--128-let-us-chat'),
  },
  {
    id: 'SEND_ME_MESSAGES',
    title: i18next.t('sendToMessengerTexts-string--135-send-me-messages'),
  },
  {
    id: 'ALERT_ME_IN_MESSENGER',
    title: i18next.t('sendToMessengerTexts-string-1408-alert-me-in-messenger'),
  },
  {
    id: 'SEND_ME_UPDATES',
    title: i18next.t('sendToMessengerTexts-string--916-send-me-updates'),
  },
  {
    id: 'MESSAGE_ME',
    title: i18next.t('sendToMessengerTexts-string-3026-message-me'),
  },
  {
    id: 'LET_ME_KNOW',
    title: i18next.t('sendToMessengerTexts-string--195-let-me-know'),
  },
  {
    id: 'KEEP_ME_UPDATED',
    title: i18next.t('sendToMessengerTexts-string--197-keep-me-updated'),
  },
  {
    id: 'TELL_ME_MORE',
    title: i18next.t('sendToMessengerTexts-string-2828-tell-me-more'),
  },
  {
    id: 'SUBSCRIBE_IN_MESSENGER',
    title: i18next.t('sendToMessengerTexts-string-6171-subscribe-in-messenger'),
  },
  {
    id: 'SUBSCRIBE_TO_UPDATES',
    title: i18next.t('sendToMessengerTexts-string-1418-subscribe-to-updates'),
  },
  {
    id: 'GET_MESSAGES',
    title: i18next.t('sendToMessengerTexts-string--203-get-messages'),
  },
  {
    id: 'SUBSCRIBE',
    title: i18next.t('sendToMessengerTexts-string--177-subscribe'),
  },
  {
    id: 'GET_STARTED_IN_MESSENGER',
    title: i18next.t(
      'sendToMessengerTexts-string--648-get-started-in-messenger',
    ),
  },
  {
    id: 'LEARN_MORE_IN_MESSENGER',
    title: i18next.t(
      'sendToMessengerTexts-string--616-learn-more-in-messenger',
    ),
  },
  {
    id: 'GET_STARTED',
    title: i18next.t('sendToMessengerTexts-string--911-get-started'),
  },
];

export const SEND_TO_MESSENGER_TEXTS_NORMALIZED: Record<
  string,
  ReturnType<typeof SEND_TO_MESSENGER_TEXTS>[0]
> = SEND_TO_MESSENGER_TEXTS().reduce(
  (acc, prev) => ({ ...acc, [prev.id]: prev }),
  {},
);
