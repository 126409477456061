import { useApolloClient } from 'react-apollo';
import { Platform } from '@globals';
import {
  MessagesQuery,
  MessagesQueryVariables,
} from '../components/Messages/@types/MessagesQuery';
import { MESSAGES_QUERY } from '../components/Messages/queries';
import { log } from 'cf-common/src/logger';
import { useMessagesSubscription } from './useMessagesSubscription';
import { useCallback } from 'react';
import { useMessagesQuery } from './useMessagesQuery';

export interface FetchMessageParams {
  botId: string;
  conversationId: string;
  platform: Platform;
  skip?: boolean;
  needUpdateDataOnMount?: boolean;
}

export const useFetchMessages = (params: FetchMessageParams) => {
  const client = useApolloClient();

  const { items, cursors, loading, error, fetchMore } = useMessagesQuery(
    params,
    true,
  );

  useMessagesSubscription(params);

  const fetchOlderMessages = () => {
    if (!cursors?.before) {
      return Promise.resolve();
    }
    /**
     * По какой то причине, при переключении диалогов в момент запроса этой квери
     * сообщения из одного диалога могут попасть в диалоги другого. Проблема в том, что
     * аполо вызывает updateQuery(prev, options), где prev - кеш ТЕКУЩЕГО диалога, а
     * options.fetchMoreResult - результат ответа запроса старых сообщений для предыдущего
     * диалога. Скорее всего это происходит из-за того, что где-то есть мутация или же
     * не корректно пишем в кеш.
     *
     * Перед тем как венуть код из then в updateQuery надо проверить что баг не воспроизводится
     * а так же, проверить что в логах больше нет ошибки инвалидации {@see useConversationsInvalidation}
     */
    return fetchMore({
      variables: { to: cursors.before },
      updateQuery: (prev) => prev,
    }).then((result) => {
      const fetchMoreResult = result.data;

      let prev;

      try {
        prev = client.readQuery({
          query: MESSAGES_QUERY,
          variables: params,
        });
      } catch (error) {
        log.error({
          msg: 'fetchOlderMessages read messages query failed',
          data: { error },
        });
      }

      if (!fetchMoreResult?.livechatMessages.items.length) {
        return {
          ...prev,
          livechatMessages: {
            ...prev.livechatMessages,
            cursors: {
              ...prev.livechatMessages.cursors,
              before: null,
            },
          },
        };
      }

      // eslint-disable-next-line no-param-reassign
      fetchMoreResult.livechatMessages.cursors.after =
        prev.livechatMessages.cursors.after;

      // eslint-disable-next-line no-param-reassign
      fetchMoreResult.livechatMessages.items = [
        ...fetchMoreResult.livechatMessages.items,
        ...prev.livechatMessages.items,
      ];

      try {
        client.writeQuery({
          query: MESSAGES_QUERY,
          variables: params,
          data: fetchMoreResult,
        });
      } catch (error) {
        log.error({
          msg: 'fetchOlderMessages write messages query failed',
          data: { error },
        });
      }

      return result;
    });
  };

  const fetchNewerMessages = useCallback(() => {
    if (!cursors) {
      return Promise.resolve();
    }
    /**
     * {@see fetchOlderMessages}
     */
    return fetchMore({
      variables: { from: cursors.after },
      updateQuery: (prev) => prev,
    }).then((result) => {
      const fetchMoreResult = result.data;

      let prev;

      try {
        prev = client.readQuery<MessagesQuery, MessagesQueryVariables>({
          query: MESSAGES_QUERY,
          variables: params,
        });
      } catch (error) {
        log.error({
          msg: 'fetchOlderMessages read messages query failed',
          data: { error },
        });
      }

      if (!prev) {
        return;
      }

      if (!fetchMoreResult?.livechatMessages.items.length) {
        return;
      }

      // eslint-disable-next-line no-param-reassign
      fetchMoreResult.livechatMessages.cursors.before =
        prev.livechatMessages.cursors.before;

      const oldItemsList = prev.livechatMessages.items.reduce<{
        [key: string]: number;
      }>((accum, item, index) => {
        // eslint-disable-next-line no-param-reassign
        accum[item.outpacingId || item.id] = index;
        return accum;
      }, {});

      const updatedItems = prev.livechatMessages.items.slice();

      fetchMoreResult.livechatMessages.items.forEach((updatedItem) => {
        const index = oldItemsList[updatedItem.outpacingId || updatedItem.id];
        // eslint-disable-next-line no-param-reassign
        if (index !== undefined) updatedItems[index] = updatedItem;
        else updatedItems.push(updatedItem);
      });

      // eslint-disable-next-line no-param-reassign
      fetchMoreResult.livechatMessages.items = updatedItems;

      try {
        client.writeQuery({
          query: MESSAGES_QUERY,
          variables: params,
          data: fetchMoreResult,
        });
      } catch (error) {
        log.error({
          msg: 'fetchOlderMessages write messages query failed',
          data: { error },
        });
      }
    });
  }, [cursors, fetchMore, client, params]);

  return {
    items,
    cursors,
    loading,
    error,
    fetchOlderMessages,
    fetchNewerMessages,
  };
};
