import { useEffect, useState } from 'react';
import { directFetch } from '@utils/Fetch/directFetch';
import { AudioMimeTypes } from '@utils/PlatformSupportedFiles/mimeGroups';
import { oggOpusToWavConvertor } from '@utils/Audio/oggOpusToWavConvertor';
import Maybe from 'graphql/tsutils/Maybe';

enum AudioReadyState {
  none = 'none',
  load = 'load',
  loading = 'loading',
  native = 'native',
  decode = 'decode',
  decodeReady = 'decodeReady',
}

/**
 *
 * Safari не умеет декодировать и проигрывать ogg аудио файлы которые записывает
 * ватсапп. Потому мы проверяем тип файла и если надо - перекодируем его в wav и
 * отдаем в виде dataUrl.
 *
 */

export const usePrepareAudioData = (url: Maybe<string>) => {
  const [audioReadyState, setAudioReadyState] = useState<AudioReadyState>(
    AudioReadyState.none,
  );

  const [dataUrl, setDataUrl] = useState<string | undefined>();

  useEffect(() => {
    if (audioReadyState !== AudioReadyState.none || !url) {
      return;
    }
    directFetch(url, { method: 'HEAD' }).then((result) => {
      setAudioReadyState(
        result.headers.get('content-type') === AudioMimeTypes.ogg
          ? AudioReadyState.load
          : AudioReadyState.native,
      );
    });
  }, [audioReadyState, url]);

  useEffect(() => {
    if (audioReadyState !== AudioReadyState.load || !url) {
      return;
    }
    setAudioReadyState(AudioReadyState.loading);
    directFetch(url, { method: 'GET' })
      .then((result) => {
        setAudioReadyState(AudioReadyState.decode);
        return result.arrayBuffer();
      })
      .then((buffer) => {
        return oggOpusToWavConvertor(buffer)
          .then((wavData) => wavData)
          .catch(() => new Blob());
      })
      .then((blob) => {
        if (blob.size === 0) {
          setAudioReadyState(AudioReadyState.native);
          return;
        }
        setDataUrl(URL.createObjectURL(blob));
        setAudioReadyState(AudioReadyState.decodeReady);
      });
  }, [audioReadyState, url]);

  useEffect(
    () => () => {
      if (!dataUrl) {
        return;
      }
      URL.revokeObjectURL(dataUrl);
    },
    [dataUrl],
  );

  return {
    ready: [AudioReadyState.decodeReady, AudioReadyState.native].includes(
      audioReadyState,
    ),
    src:
      audioReadyState === AudioReadyState.native
        ? url
        : audioReadyState === AudioReadyState.decodeReady
        ? dataUrl
        : undefined,
  };
};
