import React, { useCallback, useMemo, useState } from 'react';
import cx from 'classnames';
import { MessageContentShape, MessageExtraShape } from '../MessageContentShape';
import { MessageButtons } from '../MessageButtons';
import * as s from './TextMessage.css';
import { IconNames } from '@ui/Icon/icons';
import { useSafeTranslation } from '@utils/useSafeTranslation';

export interface TextMessageProps extends React.HTMLProps<HTMLDivElement> {
  type: 'incoming' | 'outgoing';
  leading?: boolean;
  trailing?: boolean;
  testing?: boolean;
  buttons?: string[] | null;
  buttonsIcons?: (IconNames | null)[];
  buttonsUrls?: (string | null)[];
  onButtonClick?(index: number, text: string): void;
  noAccent?: boolean;
}

const SHOW_BUTTONS_LIMIT = 3;

export const TextMessage: React.FC<TextMessageProps> = ({
  type,
  leading = true,
  trailing = true,
  testing,
  buttons,
  buttonsIcons,
  buttonsUrls,
  children,
  onButtonClick,
  className,
  noAccent,
  ...domAttrs
}) => {
  const { t } = useSafeTranslation();
  const [showAll, setShowAll] = useState<boolean>(false);
  const hasButtons = Boolean(buttons && buttons.length);
  const idButtonsQtyOverLimit = (buttons?.length || 0) > SHOW_BUTTONS_LIMIT;

  const buttonsItems = useMemo(() => {
    const items =
      (buttons &&
        (idButtonsQtyOverLimit && !showAll
          ? buttons?.slice(0, SHOW_BUTTONS_LIMIT - 1)
          : buttons
        ).map((text, index) => ({
          text,
          url: buttonsUrls?.[index],
          icon: buttonsIcons?.[index]!,
          isClickable: false,
        }))) ||
      [];

    if (idButtonsQtyOverLimit && !showAll) {
      items.push({
        text: t('pages.LiveChat.Message.seeAllOptions'),
        icon: 'listView',
        url: null,
        isClickable: true,
      });
    }

    return items;
  }, [buttons, buttonsIcons, buttonsUrls, idButtonsQtyOverLimit, showAll, t]);

  const buttonClickHandler = useCallback(
    (index: number, text: string) => {
      if (
        idButtonsQtyOverLimit &&
        !showAll &&
        index === SHOW_BUTTONS_LIMIT - 1
      ) {
        setShowAll(true);
      }
      onButtonClick?.(index, text);
    },
    [idButtonsQtyOverLimit, onButtonClick, showAll],
  );
  return (
    <div
      className={cx(
        s.wrapper,
        {
          [s.trailingWrapper]: trailing,
        },
        className,
      )}
    >
      <MessageContentShape
        type={type}
        leading={leading}
        trailing={!hasButtons && trailing}
        capped={hasButtons}
        className={cx(s.text, {
          [s.incoming]: type === 'incoming' || noAccent,
          [s.outgoing]: type === 'outgoing' && !noAccent,
        })}
        {...domAttrs}
      >
        {children}
      </MessageContentShape>
      {hasButtons ? (
        <MessageExtraShape type={type} testing={testing}>
          <MessageButtons
            testing={testing}
            buttons={buttonsItems}
            onButtonClick={buttonClickHandler}
          />
        </MessageExtraShape>
      ) : null}
    </div>
  );
};
