import React, { useState } from 'react';
import { useSafeTranslation } from '@utils/useSafeTranslation';

import { sendEvent } from '@utils/Analytics';
import { useFbPermissions } from '@utils/FacebookPages/useFbPermissions';
import { Button } from '@ui/Button';
import { CenteredLoader } from '@ui/Loader';
import { Spacer } from '@ui/Spacer';
import { AvatarSize } from '@ui/Avatar';
import { useDeviceMedia } from '@utils/DOM/useDeviceMedia';
import { FacebookPageTitle } from '@components/FacebookPageTitle';
import {
  FacebookPagesSetupQuery_bot_status_page_info as FacebookPageInfo,
  FacebookPagesSetupQuery_bot_status_page_info_owner as FacebookPageOwner,
} from '@utils/FacebookPages/@types/FacebookPagesSetupQuery';
import { useShowDisconnectPageDialog } from './hooks';
import { useAccountStatus } from '@pages/MultiSystemAuth/hooks/useAccountStatus';
import { PlatformUpdatePermission } from '../../../PlatformConnection/PlatformUpdatePermissionButton/PlatformUpdatePermissionButton';
import { FacebookPageRestrictedNote } from './FacebookPageRestrictedNote';
import { PlatformConnectedButtonsWrapper } from '../../../PlatformConnection';
import { PlatformConnectedWrapper } from '../../../PlatformConnection/PlatformConnected/PlatformConnectedWrapper';

interface FacebookPagesSetupStateConnectedPageProps {
  botId: string;
  page: FacebookPageInfo;
  otherAdminConnected: FacebookPageOwner | null;
  onDisconnected?(): void;
  onUpdatePermissions(): void;
}

export const FacebookPageConnected: React.FC<FacebookPagesSetupStateConnectedPageProps> =
  ({
    page,
    botId,
    otherAdminConnected,
    onDisconnected,
    onUpdatePermissions,
  }) => {
    const { t } = useSafeTranslation();
    const { isSmallScreenSize } = useDeviceMedia();
    const { status } = useAccountStatus();
    const [isDisconnecting, setIsDisconnecting] = useState(false);
    const {
      loading: permissionsLoading,
      permissions: { hasAllPermissions },
    } = useFbPermissions({ fetchPolicy: 'network-only' });

    const { showDisconnectPageDialog } = useShowDisconnectPageDialog({
      botId,
      onDisconnected() {
        setIsDisconnecting(false);
        onDisconnected?.();
      },
      onDisconnecting() {
        setIsDisconnecting(true);
      },
    });

    if (isDisconnecting) {
      return <CenteredLoader />;
    }

    const isInsufficientPermissions = !permissionsLoading && !hasAllPermissions;

    return (
      <PlatformConnectedWrapper data-testid="facebook-page-connected">
        <FacebookPageTitle
          page={page}
          avatarSize={AvatarSize.big}
          titleSize="24px"
          titleWeight="semibold"
          data-testid="facebook-connected-page__page-data"
          otherAdminConnected={otherAdminConnected}
          onClick={() => {
            sendEvent({
              category: 'connect facebook page',
              action: 'go to facebook page click',
            });
          }}
        />
        {status?.facebook_account_connected && (
          <PlatformConnectedButtonsWrapper data-testid="facebook-connected-page__published-by">
            {page.eu_restricted && (
              <>
                <FacebookPageRestrictedNote />
                {isSmallScreenSize && <Spacer factor={5} />}
              </>
            )}

            <PlatformUpdatePermission
              insufficient={isInsufficientPermissions}
              onClick={onUpdatePermissions}
              tooltipContent={t(
                'modernComponents.FacebookPagesSetup.connectedPage.updatePermissionsTooltip',
              )}
              data-testid="facebook-page-connected__update-permissions-button"
            />

            <Spacer factor={isSmallScreenSize ? 2 : 0} horizontalFactor={4} />

            <Button
              intent="secondary"
              fitToText
              data-testid="facebook-page-connected__disconnect-button"
              onClick={() => {
                sendEvent({
                  category: 'connect facebook page',
                  action: 'click disconnect',
                });
                showDisconnectPageDialog();
              }}
            >
              {t(
                'modernComponents.FacebookPagesSetup.connectedPage.disconnectButtonTitle',
              )}
            </Button>
          </PlatformConnectedButtonsWrapper>
        )}
      </PlatformConnectedWrapper>
    );
  };
