import React from 'react';
import { TextMessage } from '../TextMessage';
import { TextMessageProps } from '../TextMessage/TextMessage';
import { MessagesQuery_livechatMessages_items_message_whatsapp_list_reply } from '../../@types/MessagesQuery';
import { Type } from '@ui/Type';
import { Spacer } from '@ui/Spacer';
import {
  MessageFooter,
  MessageFooterProps,
} from '../../MessageTimestamp/MessageTimestamp';

interface WhatsappListMessageProps
  extends Omit<TextMessageProps, 'color'>,
    MessageFooterProps {
  config: MessagesQuery_livechatMessages_items_message_whatsapp_list_reply;
}

export const WhatsappListReplyMessage: React.FC<WhatsappListMessageProps> = ({
  date,
  type,
  status,
  error,
  config: { row_title: rowTitle, row_description: rowDescription },
  ...messageProps
}) => {
  const textColor = type === 'outgoing' ? 'white' : 'baseNormal';

  return (
    <TextMessage type={type} {...messageProps} buttonsIcons={['listView']}>
      <MessageFooter error={error} date={date} type={type} status={status}>
        <div style={{ padding: '8px 14px' }}>
          <Type
            color={textColor}
            as="div"
            data-testid="livechat__list-message-reply__row-title"
          >
            {rowTitle}
          </Type>
          {rowDescription && (
            <>
              <Spacer factor={2} />
              <Type
                color={textColor}
                size="12px"
                as="div"
                data-testid="livechat__list-message-reply__row-description"
              >
                {rowDescription}
              </Type>
            </>
          )}
        </div>
      </MessageFooter>
    </TextMessage>
  );
};
