import gql from 'graphql-tag';
import { DIALOG_FRAGMENT } from './queries';

export const NEW_DIALOGS_MESSAGES_SUBSCRIPTION = gql`
  subscription NewDialogsMessagesSubscription($botId: String!) {
    livechatDialogsNewMessage(botId: $botId) {
      ...dialogFragment
    }
  }
  ${DIALOG_FRAGMENT}
`;

export const DIALOGS_FOLDER_SUBSCRIPTION = gql`
  subscription DialogsFolderSubscription($botId: String!) {
    livechatDialogsFolder(botId: $botId) {
      id
      folder
    }
  }
`;

export const DIALOGS_UNSEEN_MESSAGES_COUNT_SUBSCRIPTION = gql`
  subscription DialogsUnseenMessagesCountSubscription($botId: String!) {
    livechatDialogsUnseenMessagesCount(botId: $botId) {
      id
      unseen_messages_count
    }
  }
`;

export const DIALOGS_LAST_MESSAGE_STATUS_SUBSCRIPTION = gql`
  subscription DialogsLastMessageStatusSubscription($botId: String!) {
    livechatDialogsLastMessageStatus(botId: $botId) {
      id
      last_message {
        id
        status
      }
    }
  }
`;

export const DIALOGS_ASSIGNEE_UPDATE_SUBSCRIPTION = gql`
  subscription DialogsAssigneeUpdateSubscription($botId: String!) {
    livechatDialogsAssigneeUpdate(botId: $botId) {
      ...dialogFragment
    }
  }
  ${DIALOG_FRAGMENT}
`;
