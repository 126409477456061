import { SizeMe, SizeMeOptions } from 'react-sizeme';
import React from 'react';

const placeholderDivStyle = { height: '100%' };

export const AvailableSizeDetector: React.FC<SizeMeOptions> = (props) => (
  <SizeMe {...props}>
    {(data) => {
      const {
        size: { height },
      } = data;
      return (
        <div style={placeholderDivStyle}>
          {height ? props.children(data) : null}
        </div>
      );
    }}
  </SizeMe>
);
