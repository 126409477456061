import React from 'react';
import { Type } from '@ui/Type';
import { IS_DEBUG } from 'cf-common/src/environment';
import { MessagesQuery_livechatMessages_items_error as MessageError } from './@types/MessagesQuery';
import euRestrictedAdmin from './eu-restricted-admin.png';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { Anchor, AnchorUnstyled } from '@ui/Links';
import * as css from './getMessageRejectionContent.css';

// https://developers.facebook.com/docs/whatsapp/cloud-api/support/error-codes/
enum MetaErrorCode {
  permissionDenied = 10,
  messageUndeliverable = 131026,
  businessEligibilityPaymentIssue = 131042,
  reEngagementMessage = 131047,
  spamRateLimitHit = 131048,
  mediaUploadError = 131053,
}

const EU_RESTRICTED_FOR_ADMIN = 2018336;

interface MessageRejectionContentProps {
  error: MessageError;
  isBusinessVerified?: boolean;
  billingPaymentLink?: string;
}

export const MessageRejectionContent: React.FC<MessageRejectionContentProps> =
  ({ error, isBusinessVerified, billingPaymentLink }) => {
    const { t } = useSafeTranslation();

    if (IS_DEBUG) {
      return (
        <Type size="12px" color="white">
          {error.message}
        </Type>
      );
    }

    if (error.code === MetaErrorCode.reEngagementMessage) {
      return (
        <Type size="12px" color="white">
          {t('pages.LiveChat.Message.rejected.reEngagementMessage')}
        </Type>
      );
    }

    if (error.code === MetaErrorCode.businessEligibilityPaymentIssue) {
      return (
        <Type size="12px" color="white">
          {t(
            'pages.LiveChat.Message.rejected.businessEligibilityPaymentIssue1',
          )}{' '}
          <AnchorUnstyled
            className={css.link}
            target="_blank"
            rel="noopener noreferrer"
            href={billingPaymentLink}
          >
            {t(
              'pages.LiveChat.Message.rejected.businessEligibilityPaymentIssue2',
            )}
          </AnchorUnstyled>{' '}
          {t(
            'pages.LiveChat.Message.rejected.businessEligibilityPaymentIssue3',
          )}
        </Type>
      );
    }

    if (error.code === MetaErrorCode.messageUndeliverable) {
      return (
        <Type size="12px" color="white">
          {t('pages.LiveChat.Message.rejected.messageUndeliverable')}
        </Type>
      );
    }

    if (error.code === MetaErrorCode.mediaUploadError) {
      return (
        <Type size="12px" color="white">
          {t('pages.LiveChat.Message.rejected.mediaUploadError')}
        </Type>
      );
    }

    if (
      error.code === MetaErrorCode.permissionDenied &&
      error.error_subcode === EU_RESTRICTED_FOR_ADMIN
    ) {
      return (
        <>
          <img alt="" src={euRestrictedAdmin} width="214" />
          <br />
          <br />
          <div style={{ width: 214 }}>
            <Type size="12px" color="white">
              {t('pages.LiveChat.Message.rejected.euAdminRestricted')}
            </Type>
          </div>
        </>
      );
    }

    if (error.code === MetaErrorCode.spamRateLimitHit) {
      if (!isBusinessVerified) {
        return (
          <>
            <Type size="12px" color="white">
              {t('pages.LiveChat.Message.rejected.businessNotVerified')}
            </Type>

            <br />
            <br />

            <Anchor
              className={css.link}
              externalLinkClassName={css.linkIcon}
              noUnderline
              intent="external"
              target="_blank"
              rel="noopener noreferrer"
              href="https://docs.chatfuel.com/en/articles/7185735-how-to-verify-your-meta-business-account"
            >
              {t('pages.LiveChat.Message.rejected.learnMore')}
            </Anchor>
          </>
        );
      }

      return (
        <Type size="12px" color="white">
          {t('pages.LiveChat.Message.rejected.waSpamError')}
        </Type>
      );
    }

    return (
      <Type size="12px" color="white">
        {t('pages.LiveChat.Message.rejected.unknownError')}
        <br />
        {t('pages.LiveChat.Message.rejected.tryAgainLater')}
      </Type>
    );
  };
