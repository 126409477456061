import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Trans } from 'react-i18next';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { log, Level } from 'cf-common/src/logger';
import { Flex } from '@ui/Flex';
import { Spacer } from '@ui/Spacer';
import { Type } from '@ui/Type';
import { sendEvent } from '@utils/Analytics';
import { Anchor } from '@ui/Links';
import { useToaster } from '@ui/Toaster';
import { Button } from '@ui/Button';
import { Modal } from '@services/index';
import { getRequestIdFromApolloError } from '@utils/GQL/utils';
import { DialogLocalizationContextProvider } from '@components/FlowSelectorDialog/LocalizationContext';
import { FlowSelectorDialog } from '@components/FlowSelectorDialog';

import { useFlowGroups } from '@components/FlowSelectorDialog/hooks';
import { PluginType } from '@components/Plugins/common/PluginTypes';
import { createFlowBlock } from '@components/FlowBuilder/api/createFlowBlock';
import { BotTabs, getTabLink, useCurrentBotId } from '@utils/Routing';
import { ReactComponent as HeadphonesImage } from './assets/Headphones.svg';
import { Platform } from '@globals';

export const AddLivechatPluginPlaceholder: React.FC<{}> = () => {
  const { t } = useSafeTranslation();
  const [addingPlugin, setAddingPlugin] = useState(false);
  const botId = useCurrentBotId()!;
  const { flowGroups, loading } = useFlowGroups(botId);
  const history = useHistory();
  const { addToaster } = useToaster();
  return (
    <Flex
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      style={{ backgroundColor: 'var(--white)' }}
      fullHeight
    >
      <HeadphonesImage />
      <Spacer factor={4} />
      <Type size="24px" weight="medium">
        {t('pages.LiveChat.DialogPlaceholders.addLivechatPlugin.title')}
      </Type>
      <Spacer factor={2} />
      <Type size="15px" align="center">
        <Trans i18nKey="pages.LiveChat.DialogPlaceholders.addLivechatPlugin.description" />
        <Anchor
          href="https://docs.chatfuel.com/en/articles/849315-live-chat"
          target="_blank"
          style={{ color: 'var(--black)' }}
          onMouseDown={() => {
            sendEvent({
              category: 'live chat',
              action: 'add live chat plugin tooltip',
              label: 'grow',
            });
          }}
        >
          {t('pages.LiveChat.DialogPlaceholders.addLivechatPlugin.link')}
        </Anchor>
      </Type>
      <Spacer />
      <Button
        onClick={() => {
          Modal.show(({ close }) => (
            <DialogLocalizationContextProvider
              header="pages.LiveChat.DialogPlaceholders.addLivechatPlugin.flowsModal.header"
              selectFlowButtonTitle="pages.LiveChat.DialogPlaceholders.addLivechatPlugin.flowsModal.selectButton"
            >
              <FlowSelectorDialog
                flowsGroups={flowGroups}
                loading={loading || addingPlugin}
                platform={[Platform.facebook, Platform.instagram]}
                onAcceptSelectFlow={async (flowId) => {
                  setAddingPlugin(true);
                  createFlowBlock({
                    flowId,
                    position: { x: 0, y: 0 },
                    subtype: 'action',
                    cards: [
                      {
                        plugin_id: PluginType.flowbuilder_livechat,
                        config: {},
                      },
                    ],
                    callback(res) {
                      setAddingPlugin(false);
                      close();
                      history.push(
                        getTabLink(BotTabs.flows, botId, {
                          flowId,
                          blockId: res.id,
                        }),
                      );
                    },
                    onError(error: any) {
                      setAddingPlugin(false);
                      close();
                      addToaster({
                        type: 'error',
                        content: (
                          <div
                            style={{
                              minWidth: '300px',
                              whiteSpace: 'nowrap',
                            }}
                          >
                            {t('common.ToasterMessages.somethingWentWrong')}
                          </div>
                        ),
                        timeout: 3000,
                        closeButton: true,
                      });
                      log({
                        error,
                        msg: 'Could not add livechat plugin',
                        level: Level.error,
                        data: {
                          label: 'live chat',
                          requestId: getRequestIdFromApolloError(error),
                        },
                      });
                    },
                  });
                }}
                showCreateButton={false}
                onClose={close}
              />
            </DialogLocalizationContextProvider>
          ));
        }}
      >
        {t('pages.LiveChat.DialogPlaceholders.addLivechatPlugin.addButton')}
      </Button>
    </Flex>
  );
};
