import {
  ManageSubscriptionsDialogQuery,
  ManageSubscriptionsDialogQuery_bot_livechatSubscribers,
  ManageSubscriptionsDialogQueryVariables,
} from '../@types/ManageSubscriptionsDialogQuery';
import React, { useEffect, useMemo } from 'react';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { useMutation } from 'react-apollo';
import {
  ChangeNotificationsSubscriptionMutation,
  ChangeNotificationsSubscriptionMutationVariables,
} from '../@types/ChangeNotificationsSubscriptionMutation';
import {
  CHANGE_NOTIFICATIONS_SUBSCRIPTION_MUTATION,
  MANAGE_SUBSCRIPTIONS_DIALOG_QUERY,
} from '../query';
import { Flex } from '@ui/Flex';
import * as css from '../ManageSubscriptions.css';
import cn from 'classnames';
import { Spacer } from '@ui/Spacer';
import { Type } from '@ui/Type';
import { Toggle } from '@ui/Toggle';
import { sendEvent } from '@utils/Analytics';
import { useAdminData } from '@utils/Data/Admin';
import { LinkFBUserToAdminButton } from '@components/FBPlugins/LinkFBUserToAdminButton';
import { Tooltip2 } from '@ui/Tooltip2';
import { usePageConnected } from '@utils/FacebookPages/usePageConnected';
import { useRolePermission } from '@utils/Roles';
import { Permission } from '@common/services/RoleService';
import { usePrevious } from 'cf-common/src/utils/hooks';
import { log } from 'cf-common/src/logger';

interface SubscriberProps {
  botId: string;
  ableToEditSubscriptions: boolean;
  subscriber: ManageSubscriptionsDialogQuery_bot_livechatSubscribers;
  isSmallScreenSize: boolean;
}

export const Subscriber: React.FC<SubscriberProps> = ({
  botId,
  subscriber,
  ableToEditSubscriptions,
  isSmallScreenSize,
}) => {
  const { adminData, adminDataLoading } = useAdminData();
  const { pageId: currentPageId, loading: pageLoading } = usePageConnected();
  const { t } = useSafeTranslation();
  const [
    changeNotificationSubscription,
    { loading: changeSubscriptionLoading },
  ] = useMutation<
    ChangeNotificationsSubscriptionMutation,
    ChangeNotificationsSubscriptionMutationVariables
  >(CHANGE_NOTIFICATIONS_SUBSCRIPTION_MUTATION, {
    update: (cache, { data }) => {
      const subscribersData = cache.readQuery<
        ManageSubscriptionsDialogQuery,
        ManageSubscriptionsDialogQueryVariables
      >({
        query: MANAGE_SUBSCRIPTIONS_DIALOG_QUERY,
        variables: { botId },
      });
      if (subscribersData?.bot.livechatSubscribers) {
        const newData = {
          ...subscribersData,
          bot: {
            ...subscribersData.bot,
            livechatSubscribers:
              subscribersData.bot.livechatSubscribers.map((user) =>
                user.id === subscriber.id
                  ? {
                      ...user,
                      subscribed: data?.changeNotificationsSubscription,
                    }
                  : user,
              ) || [],
          },
        };
        try {
          cache.writeQuery({
            query: MANAGE_SUBSCRIPTIONS_DIALOG_QUERY,
            variables: { botId },
            data: newData,
          });
        } catch (error) {
          log.error({
            msg: 'changeNotificationSubscription write manage query failed',
            data: { error },
          });
        }
      }
    },
  });

  const { allowed: allowedInboxPermission, loading: permissionLoading } =
    useRolePermission({
      domain: 'inbox',
      can: Permission.VIEW,
    });

  const me = adminData?.me;
  const needShowAllowMessagingButton = useMemo(
    () =>
      allowedInboxPermission &&
      me?.id === subscriber.id &&
      !me?.user_states?.some(({ page_id: pageId }) => pageId === currentPageId),
    [allowedInboxPermission, me, subscriber.id, currentPageId],
  );

  const prevNeedShowAllowMessagingButton = usePrevious(
    needShowAllowMessagingButton,
  );

  useEffect(() => {
    if (!needShowAllowMessagingButton && prevNeedShowAllowMessagingButton) {
      changeNotificationSubscription({
        variables: {
          botId,
          adminId: subscriber.id,
          subscribed: true,
        },
      });
    }
  }, [
    botId,
    changeNotificationSubscription,
    needShowAllowMessagingButton,
    prevNeedShowAllowMessagingButton,
    subscriber.id,
  ]);

  const loading =
    adminDataLoading ||
    changeSubscriptionLoading ||
    pageLoading ||
    permissionLoading;

  return (
    <Flex
      alignItems="center"
      justifyContent="space-between"
      className={css.admin}
    >
      <Flex alignItems="center">
        {subscriber.picture ? (
          <div
            className={css.subscriberImage}
            style={{
              backgroundImage: `url(${subscriber.picture})`,
            }}
          />
        ) : (
          <div
            className={cn(css.subscriberImage, css.subscriberDefaultImage)}
          />
        )}
        <Spacer horizontalFactor={4} />
        <Flex flexDirection="column" style={{ textTransform: 'capitalize' }}>
          <div>{subscriber.name}</div>
          <Type color="grey" size="15px_DEPRECATED">
            {subscriber.role?.toLowerCase()}
          </Type>
        </Flex>
      </Flex>

      {needShowAllowMessagingButton ? (
        <LinkFBUserToAdminButton />
      ) : (
        <Tooltip2
          content={t('pages.LiveChat.ManageSubscriptions.tooltip')}
          disabled={!ableToEditSubscriptions}
          placement="right"
          type="small"
          boundariesElement="viewport"
        >
          {(ref, bind) => (
            <Flex
              alignItems="center"
              className={cn(
                css.subscriberStatusContainer,
                isSmallScreenSize && css.subscriberStatusContainer_mobile,
              )}
              ref={ref}
              {...bind}
            >
              {!isSmallScreenSize && (
                <Type
                  color={
                    subscriber.subscribed && ableToEditSubscriptions
                      ? 'black'
                      : 'grey'
                  }
                  size="15px_DEPRECATED"
                >
                  {subscriber.subscribed
                    ? t('pages.LiveChat.ManageSubscriptions.subscribed')
                    : t('pages.LiveChat.ManageSubscriptions.unsubscribed')}
                </Type>
              )}
              <Spacer horizontalFactor={4} />
              <Toggle
                small
                value={!!subscriber.subscribed}
                disabled={loading || !ableToEditSubscriptions}
                onChange={() => {
                  sendEvent({
                    category: 'live chat',
                    action: subscriber.subscribed ? 'unsubscribe' : 'subscribe',
                    propertyBag: {
                      botId,
                      subscriberId: subscriber.id,
                    },
                  });
                  changeNotificationSubscription({
                    variables: {
                      botId,
                      adminId: subscriber.id,
                      subscribed: !subscriber.subscribed,
                    },
                  });
                }}
              />
            </Flex>
          )}
        </Tooltip2>
      )}
    </Flex>
  );
};
