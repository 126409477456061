import { Modal } from '@services/index';
import { Button } from '@ui/Button';
import { DefaultDialog } from '@ui/Dialog';
import { Flex } from '@ui/Flex';
import { Anchor } from '@ui/Links';
import { ScrollBoxWithShadow } from '@ui/ScrollBox/ScrollBoxWithShadow';
import { Spacer } from '@ui/Spacer';
import { Type } from '@ui/Type';
import { sendEvent } from '@utils/Analytics';
import { useDeviceMedia } from '@utils/DOM/useDeviceMedia';
import { usePreventWheelOnRef } from '@utils/Event/usePreventWheel';
import { usePageConnected } from '@utils/FacebookPages/usePageConnected';
import { BotTabs, getTabLink } from '@utils/Routing';
import cn from 'classnames';
import React, { useMemo, useRef } from 'react';
import { useQuery } from 'react-apollo';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { Link } from 'react-router-dom';
import { RoleIds } from '../../../../components/settings/admins/RolesList';
import {
  ManageSubscriptionsDialogQuery,
  ManageSubscriptionsDialogQueryVariables,
} from './@types/ManageSubscriptionsDialogQuery';
import * as css from './ManageSubscriptions.css';
import { MANAGE_SUBSCRIPTIONS_DIALOG_QUERY } from './query';
import { SubscriptionLoader } from './SubscriptionLoader';
import { Subscriber } from './components/Subscriber';

interface ManageSubscriptionsProps {
  botId: string;
  children: (props: {
    showModal: () => void;
    isConnected: boolean;
  }) => React.ReactNode;
}

export const ManageSubscriptions: React.FC<ManageSubscriptionsProps> = ({
  botId,
  children,
}) => {
  const { isConnected } = usePageConnected(botId);
  const showModal = () => {
    sendEvent({
      category: 'live chat',
      action: 'show subscriptions',
      propertyBag: {
        botId,
      },
    });
    Modal.show(
      ({ close }) => (
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        <ManageSubscriptionsDialog botId={botId} onDismiss={close} />
      ),
      { mobileAdaptive: true },
    );
  };

  return children({ showModal, isConnected }) as JSX.Element;
};

interface ManageSubscriptionsDialogProps {
  botId: string;
  onDismiss(): void;
}

export const ManageSubscriptionsDialog: React.FC<ManageSubscriptionsDialogProps> =
  ({ botId, onDismiss }) => {
    const ref = useRef<HTMLDivElement | null>(null);
    const { t } = useSafeTranslation();
    const { isSmallScreenSize } = useDeviceMedia();
    usePreventWheelOnRef(ref);

    const { data, loading } = useQuery<
      ManageSubscriptionsDialogQuery,
      ManageSubscriptionsDialogQueryVariables
    >(MANAGE_SUBSCRIPTIONS_DIALOG_QUERY, {
      variables: { botId },
      fetchPolicy: 'network-only',
    });

    const myRoleForCurrentBot = data?.me?.roles?.find(
      (r) => r.bot_id === botId,
    );

    const subscribers = useMemo(
      () => data?.bot.livechatSubscribers ?? [],
      [data],
    );

    const ableToEditSubscriptions =
      myRoleForCurrentBot?.role_id === RoleIds.admin;

    return (
      <DefaultDialog
        header={t('pages.LiveChat.ManageSubscriptions.title')}
        onDismiss={onDismiss}
        className={cn(css.dialogContainer, isSmallScreenSize && css.mobile)}
        contentClassName={css.dialogContent}
        mobileAdaptive={isSmallScreenSize}
      >
        <Type size="15px_DEPRECATED">
          {t('pages.LiveChat.ManageSubscriptions.descriptionFirstLine')}
          <br />
          {t('pages.LiveChat.ManageSubscriptions.descriptionSecondLine')}{' '}
          <Anchor
            target="_blank"
            rel="noreferrer noopener"
            intent="external"
            hideArrow
            href={t('common.helpDocLinks.message_tags')}
            onClick={() => {
              sendEvent({
                category: 'live chat',
                action: 'click on 24h docs link',
                propertyBag: {
                  botId,
                },
              });
            }}
          >
            {t('pages.LiveChat.ManageSubscriptions.link')}
          </Anchor>
        </Type>
        <Spacer factor={4} />
        <ScrollBoxWithShadow
          ref={ref}
          className={css.adminContainer}
          fullHeight={isSmallScreenSize}
        >
          {loading ? (
            <SubscriptionLoader />
          ) : (
            subscribers.map((subscriber) => {
              return (
                <Subscriber
                  key={subscriber.id}
                  subscriber={subscriber}
                  botId={botId}
                  ableToEditSubscriptions={ableToEditSubscriptions}
                  isSmallScreenSize={isSmallScreenSize}
                />
              );
            })
          )}
        </ScrollBoxWithShadow>
        {ableToEditSubscriptions && (
          <>
            <Spacer factor={4} />
            <Flex justifyContent="flex-end">
              <Link
                className={css.inviteTeammateButton_mobile}
                to={{
                  pathname: getTabLink(BotTabs.configure, botId),
                  state: {
                    openInviteUserDialog: true,
                  },
                }}
              >
                <Button
                  className={css.inviteTeammateButton_mobile}
                  intent="secondary"
                  onClick={() => {
                    sendEvent({
                      category: 'live chat',
                      action: 'invite teammate from manage subscriptions',
                    });
                    onDismiss();
                  }}
                >
                  {t('pages.LiveChat.ManageSubscriptions.inviteTeammateButton')}
                </Button>
              </Link>
            </Flex>
          </>
        )}
      </DefaultDialog>
    );
  };
