import { Anchor } from '@ui/Links';
import { sendEvent } from '@utils/Analytics';
import React from 'react';
import cn from 'classnames';
import css from './ImageMessage.css';
import { MessageViewProps } from '../types';

interface ImageMessageProps {
  message: MessageViewProps;
  positionClassName: string;
}

export const ImageMessage: React.FC<ImageMessageProps> = ({
  message,
  positionClassName,
}) => {
  if (!message.attachment?.url) {
    return null;
  }

  return (
    <Anchor
      href={message.attachment?.url}
      target="_blank"
      data-testid="image-message"
      hideArrow
      className={cn(css.imageWrapper, positionClassName)}
      onClick={() => {
        sendEvent({
          category: 'live chat',
          action: 'open image in new tab',
          propertyBag: {
            url: message.attachment?.url,
          },
        });
      }}
    >
      <img
        src={message.attachment?.url}
        alt="message preview"
        className={css.imageMessage}
      />
    </Anchor>
  );
};
