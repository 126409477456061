import React from 'react';
import scrollIntoView from 'scroll-into-view-if-needed';
import { Icon } from '@ui/Icon';
import { Flex } from '@ui/Flex';
import { ButtonUnstyled } from '@ui/Button';
import { useAnimation } from './Message/Reply/hooks';
import { BOTTOM_ID, useBackToMessages } from './BackToMessagesContext';
import * as css from './BackToMessages.css';

export const BackToMessages = () => {
  const { scrolled, giveNextMessageToScroll } = useBackToMessages();
  const { runAnimation } = useAnimation();

  if (scrolled < 100) {
    return null;
  }

  return (
    <ButtonUnstyled
      data-testid="live-chat__back-to-messages"
      className={css.button}
      onClick={() => {
        const messageId = giveNextMessageToScroll();

        if (messageId) {
          runAnimation(messageId);
        } else {
          scrollIntoView(document.getElementById(BOTTOM_ID)!, {
            block: messageId ? 'center' : 'end',
            scrollMode: 'if-needed',
            behavior: 'smooth',
          });
        }
      }}
    >
      <Flex alignItems="center" justifyContent="center">
        <Icon icon="chevronDown" size="24px" color="accent1Normal" />
      </Flex>
    </ButtonUnstyled>
  );
};
