import React from 'react';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { PlatformBody, PlatformBodyProps } from './PlatformBody';
import { PlatformButton } from '../PlatformButton/PlatformButton';
import { useRolePermission } from '@utils/Roles';
import { Permission } from '@common/services/RoleService';

export interface PlatformNotConnectedBodyProps extends PlatformBodyProps {
  buttonTextId?: string;
  buttonLoading?: boolean;
  onClick: () => void;
  buttonId?: string;
}

export const PlatformNotConnectedBody: React.FC<PlatformNotConnectedBodyProps> =
  ({ buttonTextId, buttonLoading, onClick, buttonId, ...props }) => {
    const { t } = useSafeTranslation();
    const { allowed: isEditAllowed } = useRolePermission({
      domain: 'grow',
      can: Permission.EDIT,
    });
    return (
      <PlatformBody {...props}>
        <PlatformButton
          data-testid={buttonTextId}
          loading={buttonLoading}
          onClick={onClick}
          id={buttonId}
          disabled={!isEditAllowed}
        >
          {t('modernComponents.FacebookPage.connectPage.connectButton')}
        </PlatformButton>
      </PlatformBody>
    );
  };
