import { Type } from '@ui/Type';
import React, { useState } from 'react';
import cn from 'classnames';
import * as css from './VideoMessage.css';
import { MessageViewProps } from '../types';
import { HoverDisclosure } from '@ui/HoverDisclosure';

interface VideoMessageProps {
  message: MessageViewProps;
  positionClassName: string;
}

export const VideoMessage: React.FC<VideoMessageProps> = ({
  message,
  positionClassName,
}) => {
  const [error, setError] = useState(false);

  if (!message.attachment?.url) {
    return null;
  }
  return (
    <div
      className={cn(css.videoMessage, positionClassName, {
        [css.errorVideo]: error,
      })}
    >
      {error ? (
        <Type color="grey" size="12px">
          {window.i18next.t('VideoMessage-JSXText--370-cant-load-video')}
        </Type>
      ) : (
        /* eslint-disable-next-line jsx-a11y/media-has-caption */
        <HoverDisclosure
          render={({ isVisible, bind }) => (
            // eslint-disable-next-line jsx-a11y/media-has-caption
            <video
              className={cn(css.videoMessage, positionClassName)}
              controls={isVisible}
              {...bind}
              data-testid="video-message"
              src={message.attachment!.url!}
              onError={() => setError(true)}
            />
          )}
        />
      )}
    </div>
  );
};
