import { notesCacheUpdater } from './notesCacheUpdater';
import { isOptimisticNoteId } from './isOptimisticNoteId';
import {
  ConversationNotesQuery_livechatConversationNotes,
  ConversationNotesQueryVariables,
} from '../@types/ConversationNotesQuery';

export const replaceOptimisticItem = (
  newConversation: ConversationNotesQuery_livechatConversationNotes,
  variables: ConversationNotesQueryVariables,
) => {
  notesCacheUpdater((items) => {
    const optimisticItemIndex = items.findIndex(({ id }) =>
      isOptimisticNoteId(id),
    );
    if (optimisticItemIndex !== -1) {
      // eslint-disable-next-line no-param-reassign
      items[optimisticItemIndex] = newConversation;
    }
    if (items.every(({ id }) => id !== newConversation.id)) {
      // eslint-disable-next-line no-param-reassign
      items.unshift(newConversation);
    }
    return items;
  }, variables);
};
