import React from 'react';
import { Button } from '@ui/Button';
import { Spacer } from '@ui/Spacer';
import { Tooltip2 } from '@ui/Tooltip2';
import { useDeviceMedia } from '@utils/DOM/useDeviceMedia';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { PlatformErrorLabel } from '../../PlatformConnection/PlatformErrorLabel/PlatformErrorLabel';
import {
  ConnectedInstagramWrapper,
  ConnectedInstagramWrapperProps,
} from './ConnectedInstagramContent/ConnectedInstagramContent';

export interface InstagramNotConnectedToPageProps
  extends ConnectedInstagramWrapperProps {
  onClick: React.MouseEventHandler<HTMLButtonElement>;
}

export const InstagramNotConnectedToPage: React.FC<InstagramNotConnectedToPageProps> =
  ({ onClick, ...props }) => {
    const { isSmallScreenSize } = useDeviceMedia();
    const { t } = useSafeTranslation();

    return (
      <ConnectedInstagramWrapper {...props}>
        <PlatformErrorLabel>
          {t('common.Instagram.connectionProblem')}
        </PlatformErrorLabel>

        <Spacer
          factor={isSmallScreenSize ? 2 : 0}
          horizontalFactor={isSmallScreenSize ? 0 : 4}
        />

        <Tooltip2
          boundariesElement="viewport"
          content={t('common.Instagram.instagramNotLinked')}
        >
          {(ref, bind) => (
            <Button
              ref={ref}
              {...bind}
              intent="red"
              onClick={onClick}
              style={{ width: '100%' }}
              data-testid="instagram-connected__disconnect-button"
            >
              {t(
                'modernComponents.FacebookPagesSetup.connectedPage.disconnectButtonTitle',
              )}
            </Button>
          )}
        </Tooltip2>
      </ConnectedInstagramWrapper>
    );
  };
