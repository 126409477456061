import React from 'react';
import { Type } from '@ui/Type';
import cn from 'classnames';
import { AdCampaignType } from '@globals';
import * as css from './FacebookAdTypeBage.css';

type BageProps = {
  className: string;
  label: string;
};

const Bage: React.FC<BageProps> = ({ className, label }) => (
  <div className={cn(css.bage, className)}>
    <Type color="white" size="9px">
      {label}
    </Type>
  </div>
);

type FacebookAdTypeBageProps = {
  type: AdCampaignType;
};

export const FacebookAdTypeBage: React.FC<FacebookAdTypeBageProps> = ({
  type,
}) => {
  switch (type) {
    case AdCampaignType.click_to_messenger:
      return <Bage className={css.ctm} label="CTM" />;
    case AdCampaignType.sponsored_message:
      return <Bage className={css.sm} label="SM" />;
    default:
      throw new Error(`Unknown facebook ad type ${type}`);
  }
};
