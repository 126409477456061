import React from 'react';
import * as css from './RightPanelDivider.css';
import { Spacer } from '@ui/Spacer';

export const RightPanelDivider: React.FC = () => (
  <>
    <Spacer factor={4} />
    <div className={css.divider} />
    <Spacer factor={4} />
  </>
);
