import { log } from 'cf-common/src/logger';
import { groupBy, pick, prop } from 'ramda';
import noop from 'lodash-es/noop';
import { useQuery } from '@apollo/react-hooks';
import { sendEvent } from '@utils/Analytics';
import React, { useEffect, useMemo, useRef } from 'react';
import { Folder, Platform } from '@globals';
import { Flex, FlexItem } from '@ui/Flex';
import { useFetchMessages } from '../../hooks/useFetchMessage';
import { useLiveChatConversation } from '../../hooks/useLiveChatConversation';
import { DIALOGS_QUERY } from '../../common/queries';
import { useMarkMessagesAsSeen } from '../../hooks/useMarkMessagesAsSeen';
import { useMoveConversation } from '../../hooks/useMoveConversation';
import { useSendMessage } from '../SendMessage';
import {
  ConversationDataQuery,
  ConversationDataQueryVariables,
} from './@types/ConversationDataQuery';
import { AdminsById } from './helpers';
import { MessageInput } from './MessageInput';
import { MessagesEmptyStatePlaceholder } from './MessagesEmptyStatePlaceholder';
import { MessagesErrorPlaceholder } from './MessagesErrorPlaceholder';
import { MessagesList, WhatsppMessagesList } from './MessagesList';
import { MessagesLoader } from './MessagesLoader';
import { useLiveChatSegmentation } from '../../hooks/useLiveChatSegmentation';
import { moveConversationToFolderInCacheClosure } from '../../utils/moveConversationToFolderInCacheClosure';
import { MessagesScrollList } from './MessageScrollList';
import { CONVERSATION_DATA_QUERY } from './queries';
import { MessagesQuery_livechatMessages_items as Messages } from './@types/MessagesQuery';
import * as css from './MessagesColumn.css';
import { useSequentialPolling } from '@utils/DynamicListDataSource/sequentialPolling';

export const useConversationsInvalidation = (
  messages: Messages[],
  conversationId: string,
) => {
  const logOnceRef = useRef(false);
  const conversationIdToMessagesMap = useMemo(
    () => groupBy(prop('conversationId'), messages || []),
    [messages],
  );

  useEffect(() => {
    if (logOnceRef.current) {
      return;
    }

    const conversationIds = Object.keys(conversationIdToMessagesMap);
    const moreThanOneConversation = conversationIds.length > 1;

    if (!moreThanOneConversation) {
      return;
    }

    const otherConversations = conversationIds.filter(
      (id) => conversationId !== id,
    );

    const data = pick(otherConversations, conversationIdToMessagesMap);

    logOnceRef.current = true;

    log.error({
      msg: 'List messages invalidation failed, conversations with different id was found',
      data,
    });
  }, [conversationId, conversationIdToMessagesMap]);

  return conversationIdToMessagesMap[conversationId];
};

export interface MessagesColumnProps {
  botId: string;
  platform: Platform;
  permissionsAreLoading?: boolean;
  editingAllowed: boolean;
  dialogListPlatform: Platform | null;
  conversationId: string;
  folder: Folder;
  onAfterMoveConversation?: (
    destinationFolder: Folder.inbox | Folder.closed,
  ) => void;
}

const MESSAGE_POLLING_INTERVAL = 30000;

export const MessagesColumn: React.FC<MessagesColumnProps> = ({
  botId,
  platform,
  dialogListPlatform,
  conversationId,
  permissionsAreLoading,
  editingAllowed,
  folder,
  onAfterMoveConversation = noop,
}) => {
  const segmentation = useLiveChatSegmentation(botId);

  const {
    data: botData,
    loading,
    error,
  } = useQuery<ConversationDataQuery, ConversationDataQueryVariables>(
    CONVERSATION_DATA_QUERY,
    {
      variables: { botId },
    },
  );

  const { data: conversationData } = useLiveChatConversation({
    botId,
    conversationId,
    platform,
  });

  const { moveConversation, loading: moveConversationLoading } =
    useMoveConversation({
      refetchQueries: [
        {
          query: DIALOGS_QUERY,
          variables: { botId, segmentation, folder },
        },
      ],
    });

  const { sendMessage, disabled: sendMessageDisabled } = useSendMessage({
    botId,
    conversationId,
    platform,
  });

  const {
    items: messages,
    loading: messagesLoading,
    cursors,
    error: messagesError,
    fetchOlderMessages,
    fetchNewerMessages,
  } = useFetchMessages({
    botId,
    conversationId,
    platform,
  });

  useMarkMessagesAsSeen({
    botId,
    editingAllowed,
    conversationId,
    platform,
    messages,
  });

  const items = useConversationsInvalidation(messages || [], conversationId);

  useSequentialPolling({
    pollingFn: fetchNewerMessages,
    interval: MESSAGE_POLLING_INTERVAL,
  });

  const adminsById =
    botData?.bot.admins.reduce((acc, admin) => {
      acc[admin.id] = admin;
      return acc;
    }, {} as AdminsById) ?? {};

  const conversationFolder = conversationData?.folder ?? Folder.all;
  const liveChatIsOn = conversationFolder === Folder.inbox;
  const out24HoursWindow = Boolean(conversationData?.outside_24h_window);
  const notOpen24HoursWindow = !conversationData?.last_seen;
  const outOf7DaysWindow = conversationData
    ? !!conversationData.outside_livechat_window
    : false;
  const disabled =
    !editingAllowed ||
    outOf7DaysWindow ||
    (platform === Platform.whatsapp &&
      (out24HoursWindow || notOpen24HoursWindow));

  const MessagesListView =
    platform === Platform.whatsapp ? WhatsppMessagesList : MessagesList;

  return (
    <Flex
      flexDirection="column"
      className={css.column}
      data-testid="livechat_messages_column"
    >
      {/* TODO check that internet turn off not cause error screen, just toasters */}
      {error || messagesError ? (
        <MessagesErrorPlaceholder />
      ) : !items ||
        loading ||
        (messagesLoading && !items.length) ||
        permissionsAreLoading ||
        !botData?.bot.status?.page_info ||
        !botData?.bot.admins ? (
        <MessagesLoader />
      ) : !items.length ? (
        <MessagesEmptyStatePlaceholder />
      ) : (
        <>
          <FlexItem growHeight>
            <MessagesScrollList
              loadMoreItems={fetchOlderMessages}
              hasMoreBefore={!!cursors?.before}
              loading={loading}
            >
              <MessagesListView
                items={items}
                platform={platform}
                conversationId={conversationId}
                user={conversationData?.subscriber}
                page={botData.bot.status.page_info}
                adminsById={adminsById}
              />
            </MessagesScrollList>
          </FlexItem>
          <MessageInput
            onSubmit={(message) => {
              sendEvent({
                category: 'live chat',
                action: liveChatIsOn ? 'send' : 'send & start live chat',
                propertyBag: {
                  subscriberId: conversationId,
                },
              });
              if (message.sticker_id) {
                sendEvent({
                  category: 'live chat',
                  action: 'send sticker',
                  propertyBag: {
                    sticker: message.sticker_id,
                  },
                });
              }
              if (conversationFolder !== Folder.inbox) {
                moveConversation({
                  variables: {
                    botId: botId!,
                    conversationId,
                    folder: Folder.inbox,
                    platform,
                  },
                  refetchQueries: [
                    {
                      query: DIALOGS_QUERY,
                      variables: {
                        botId,
                        folder: conversationFolder,
                        platform: dialogListPlatform,
                        segmentation,
                      },
                    },
                  ],
                  update: moveConversationToFolderInCacheClosure(
                    { source: folder, target: Folder.inbox },
                    {
                      botId,
                      segmentation,
                      platform: dialogListPlatform,
                    },
                  ),
                }).then(() => onAfterMoveConversation(Folder.inbox));
              }
              return sendMessage(message);
            }}
            liveChatIsOn={liveChatIsOn}
            conversationFolder={conversationFolder}
            placeholder={disabled ? '' : undefined}
            disableSendButton={!!sendMessageDisabled || moveConversationLoading}
            disabled={disabled}
            platform={platform}
            showOut7DaysWindowWarning={outOf7DaysWindow}
            showOut24HoursWindowWarning={out24HoursWindow}
            showNotOpen24HoursWindowWarning={notOpen24HoursWindow}
            conversationId={conversationId}
            dialogListPlatform={dialogListPlatform}
          />
        </>
      )}
    </Flex>
  );
};
