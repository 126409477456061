import React, { useRef } from 'react';
import nanoid from 'nanoid';
import cn from 'classnames';
import * as css from './GrowItemControlBox.css';

interface GrowItemControlBoxProps extends React.HTMLProps<HTMLDivElement> {
  renderLabel: () => React.ReactNode;
  children: (bind: { domId: string }) => React.ReactNode;
}

export const GrowItemControlBox: React.FC<GrowItemControlBoxProps> = ({
  className,
  renderLabel,
  children,
  ...props
}) => {
  const domIdRef = useRef(nanoid());

  return (
    <div className={cn(className, css.GrowItemControlBox)} {...props}>
      {/* eslint-disable-next-line jsx-a11y/label-has-for */}
      <label className={css.label} htmlFor={domIdRef.current}>
        {renderLabel()}
      </label>
      <div>{children({ domId: domIdRef.current })}</div>
    </div>
  );
};
