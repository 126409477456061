import React, { useEffect } from 'react';
import { LS, LSKey } from 'cf-common/src/localStorage';
import { InitAuthProps } from '@utils/AuthFlow';
import { Page } from '@ui/Page';
import { PageContent } from '@ui/PageContent';
import { ToasterProvider } from '@ui/Toaster';
import { Box } from '@ui/Box';
import { Flex } from '@ui/Flex';
import { Spacer } from '@ui/Spacer';
import { Type } from '@ui/Type';
import { Button } from '@ui/Button';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { PageHeader } from '@components/PageHeader';
import { QuickAccessToolbar } from '@components/QuickAccessToolbar';
import { LogoSize } from '@components/PageHeader/WhiteLabelLogo';
import { useQuery } from '@apollo/react-hooks';
import { useCreateBotFromTemplate } from '@utils/Data/Bot/useCreateBotFromTemplate';
import { Loader } from '@ui/Loader';
import { getDefaultTab } from '@utils/Routing';
import { useHistory } from 'react-router-dom';
import { BOTS_LIST_QUERY } from '../../common/services/GQLqueries/BotGQService.const';
import { BotsListQuery } from '../../common/services/GQLqueries/@types/BotsListQuery';
import janisSrc from './images/janis.jpg';
import * as css from './JanisTemplate.css';

const JANIS_TEMPLATE_ID =
  LS.getRaw(LSKey.testTemplate) || '5af99253e4b03993f237875e';

export const JanisTemplate: React.FC<InitAuthProps> = () => {
  const { t } = useSafeTranslation();
  const history = useHistory();

  const { data: botListQuery, loading: botListLoading } =
    useQuery<BotsListQuery>(BOTS_LIST_QUERY);

  const [createBotFromTemplate, { data, loading: templateBotLoading }] =
    useCreateBotFromTemplate(botListQuery);

  const botId = data?.createBotByTemplate.id;

  useEffect(() => {
    if (botId) {
      history.push(getDefaultTab(botId), {
        botCreated: true,
      });
    }
  }, [botId, history]);

  const loading = botListLoading || templateBotLoading || botId;

  return (
    <Page>
      <PageHeader logoSize={LogoSize.big} />
      <PageContent className={css.pageContent}>
        <ToasterProvider>
          <Box className={css.box} borderRadiusFactor={2}>
            <Spacer factor={4} />
            <Flex alignItems="center" flexDirection="column">
              <img className={css.pic} src={janisSrc} alt="" />
              <Spacer factor={8} />
              <Type size="18px" weight="medium">
                {t('pages.janisTemplate.title')}
              </Type>
              <Spacer factor={6} />
              <Type size="15px">{t('pages.janisTemplate.description')}</Type>
              <Spacer factor={6} />
              {!loading && (
                <Button
                  onClick={() => {
                    createBotFromTemplate(
                      JANIS_TEMPLATE_ID,
                      t('pages.janisTemplate.templateTitle'),
                    );
                  }}
                >
                  {t('pages.janisTemplate.button')}
                </Button>
              )}
              {loading && <Loader style={{ height: 36 }} />}
            </Flex>
          </Box>
        </ToasterProvider>
        <QuickAccessToolbar />
      </PageContent>
    </Page>
  );
};
