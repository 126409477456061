import { isEmojisOnly } from '@utils/String/emojiUtils';
import { FromType, Platform } from '@globals';
import { ConversationDataQuery_bot_admins as Admin } from './@types/ConversationDataQuery';
import {
  MessagesQuery_livechatMessages_items as Message,
  MessagesQuery_livechatMessages_items_from as Sender,
} from './@types/MessagesQuery';
import { MessageSourceType } from './InstagramMessage/types';
import { MessageTypeKey } from './Message/MessageRow';

export function getMessageType(message: Message): MessageTypeKey {
  return message.from.type === FromType.user ? 'incoming' : 'outgoing';
}

export const getMessageSourceType = (message: Message) =>
  message.from.type === FromType.user
    ? MessageSourceType.incoming
    : MessageSourceType.outgoing;

export interface AdminsById extends Record<string, Admin> {}
export function getAdminAvatarUrl(sender: Sender, adminsById: AdminsById) {
  if (!(sender.admin_id! in adminsById)) {
    return null;
  }
  return adminsById[sender.admin_id!].picture;
}

export function isSameSender(sender1: Sender, sender2: Sender) {
  /**
   * From ./README.md:
   *
   * A `conversation` may have many authors of type `'admin'`.
   * Therefore, if we have a message with `{ type: 'admin' }`,
   * we also look up the `{ admin_id: string }` field.
   *
   */
  if (sender1.type === FromType.admin && sender2.type === FromType.admin) {
    return sender1.admin_id === sender2.admin_id;
  }

  return sender1.type === sender2.type;
}

export function isSameHour(timestamp1: number, timestamp2: number) {
  const date1 = new Date(timestamp1);
  const date2 = new Date(timestamp2);
  return (
    date1.getFullYear() === date2.getFullYear() &&
    date1.getMonth() === date2.getMonth() &&
    date1.getDate() === date2.getDate() &&
    date1.getHours() === date2.getHours()
  );
}

export const isStickerMessage = (
  platform: Platform,
  messages: Message[],
  index: number,
) => {
  return (
    platform === Platform.instagram &&
    messages[index] &&
    isEmojisOnly(messages[index].message.text)
  );
};
