import React from 'react';
import { Flex } from '@ui/Flex';
import { Icon } from '@ui/Icon';
import { Spacer } from '@ui/Spacer';
import { Type } from '@ui/Type';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { useDeviceMedia } from '@utils/DOM/useDeviceMedia';
import * as css from './FacebookPageRestrictedNote.css';

export const FacebookPageRestrictedNote = () => {
  const { t } = useSafeTranslation();
  const { isSmallScreenSize } = useDeviceMedia();

  return (
    <Flex
      flexDirection="row"
      justifyContent={isSmallScreenSize ? 'flex-start' : 'flex-end'}
      alignItems="flex-start"
    >
      <Icon icon="info" color="grey" size="24px" />
      <Spacer factor={1} horizontalFactor={2} />
      <Type size="15px" as="div" className={css.euLinkBlock} color="greyDark">
        {t(
          'FacebookPagesSetupStateConnectedPage-JSXText-7160-your-page-is-subject-to',
        )}{' '}
        <a
          href="https://docs.chatfuel.com/en/articles/6280681-features-affected-by-gdpr-appi-privacy-laws"
          target="_blank"
          rel="noopener noreferrer"
          className={css.euLink}
        >
          {t(
            'FacebookPagesSetupStateConnectedPage-JSXText-3450-the-eu-privacy',
          )}
        </a>{' '}
        {t(
          'FacebookPagesSetupStateConnectedPage-JSXText-1733-law-restrictions',
        )}
      </Type>
    </Flex>
  );
};
