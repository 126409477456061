import { Type } from '@ui/Type';
import { isEmojisOnly } from '@utils/String/emojiUtils';
import cn from 'classnames';
import React from 'react';
import { MessagePosition, MessageSourceType } from '../types';
import { ReactComponent as RedHeart } from './RedHeart.svg';
import * as css from './TextMessage.css';
import {
  MessageFooter,
  MessageFooterProps,
} from '../../MessageTimestamp/MessageTimestamp';

interface TextMessageProps extends MessageFooterProps {
  text: string;
  sourceType: MessageSourceType;
  positionClassName: string;
  position?: MessagePosition;
}

const MAX_EMOJIS_STRING_LENGTH_WITHOUT_BUBBLE = 20;

export const TextMessage: React.FC<TextMessageProps> = ({
  text,
  date,
  type,
  status,
  positionClassName,
  error,
  sourceType,
}) => {
  if (
    isEmojisOnly(text) &&
    text.length < MAX_EMOJIS_STRING_LENGTH_WITHOUT_BUBBLE
  ) {
    return (
      <MessageFooter
        error={error}
        date={date}
        type={type}
        status={status}
        instagramFix
      >
        <div className={css.emojiMessage} data-testid="livechat_message_emojis">
          {text === '❤️' ? <RedHeart /> : <Type size="24px">{text}</Type>}
        </div>
      </MessageFooter>
    );
  }
  return (
    <div
      className={cn(css.message, positionClassName, {
        [css.incoming]: sourceType === MessageSourceType.incoming,
        [css.outgoing]: sourceType === MessageSourceType.outgoing,
      })}
    >
      <MessageFooter
        error={error}
        date={date}
        type={type}
        status={status}
        instagramFix
      >
        <Type
          data-testid="livechat__text-message"
          color={sourceType === MessageSourceType.outgoing ? 'white' : 'black'}
          size="15px_DEPRECATED"
        >
          {text}
        </Type>
      </MessageFooter>
    </div>
  );
};
