import { SimpleCombobox } from '@ui/SimpleCombobox';
import { Button } from '@ui/Button';
import { Icon } from '@ui/Icon';
import { MenuItem } from '@ui/Menu';
import { Tooltip2 } from '@ui/Tooltip2';
import { sendEvent } from '@utils/Analytics';
import { useCurrentBotId } from '@utils/Routing';
import {
  ServerStorageItemKeys,
  useServerStorageForBot,
} from '@utils/ServerStorage';
import React from 'react';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { useAutomatedMessages } from '@components/LivechatAutomatedMessages/useAutomatedMessages';
import { AutomatedMessagesSource } from '@components/LivechatAutomatedMessages/components/types';
import { ManageSubscriptions } from '../ManageSubscriptions';
import * as css from './DialogSettingsMenu.css';
import { useWhatsappEnabled } from '@utils/Whatsapp';
import { useIsLiveChatWebView } from '@utils/useIsLiveChatWebView';

enum DialogSettingsMenuItem {
  muteMessages = 'muteMessages',
  unmuteMessages = 'unmuteMessages',
  manageNotifications = 'manageNotifications',
  manageAutomatedMessages = 'manageAutomatedMessages',
}

interface MenuItem {
  id: DialogSettingsMenuItem;
  title: string;
  tooltip?: string;
}

export const DialogSettingsMenu: React.FC<{ isSmallScreenSize?: boolean }> = ({
  isSmallScreenSize,
}) => {
  const { t } = useSafeTranslation();
  const botId = useCurrentBotId()!;
  const { isWhatsappEnabled } = useWhatsappEnabled();
  const { isLiveChatWebView } = useIsLiveChatWebView();
  const [liveChatSoundsDisabled, setLiveChatSoundDisabled] =
    useServerStorageForBot<boolean>(
      ServerStorageItemKeys.isLiveChatNotificationSoundsDisabled,
      botId,
    );
  const { showModal: showAutomatedMessagesModal } = useAutomatedMessages({
    botId,
    source: AutomatedMessagesSource.dialogs,
    isSmallScreenSize,
  });

  if (!botId) {
    return null;
  }

  const getMuteItem = () => {
    if (isLiveChatWebView) return null;
    return liveChatSoundsDisabled
      ? {
          id: DialogSettingsMenuItem.unmuteMessages,
          title: t('pages.LiveChat.DialogListMenu.unmuteMessages'),
        }
      : {
          id: DialogSettingsMenuItem.muteMessages,
          title: t('pages.LiveChat.DialogListMenu.muteMessages'),
        };
  };

  return (
    <div className={css.menu}>
      <ManageSubscriptions botId={botId}>
        {({ showModal, isConnected }) => {
          const menuItems = [
            getMuteItem(),
            isConnected && !isWhatsappEnabled && !isLiveChatWebView
              ? {
                  id: DialogSettingsMenuItem.manageNotifications,
                  title: t('pages.LiveChat.DialogListMenu.manageNotifications'),
                  tooltip: t(
                    'pages.LiveChat.DialogListMenu.manageNotificationsTooltip',
                  ),
                }
              : undefined,
            {
              id: DialogSettingsMenuItem.manageAutomatedMessages,
              title: t('pages.LiveChat.DialogListMenu.manageMessagesButton'),
            },
          ].filter(Boolean) as MenuItem[];

          return (
            <SimpleCombobox<MenuItem>
              position="right-start"
              unsaveSelectedOption
              ignoreParentsOverflow
              items={menuItems}
              onChange={(item) => {
                switch (item?.id) {
                  case DialogSettingsMenuItem.muteMessages:
                  case DialogSettingsMenuItem.unmuteMessages:
                    sendEvent({
                      category: 'live chat',
                      action: 'manage subscription open',
                      propertyBag: {
                        disabled: !liveChatSoundsDisabled,
                      },
                    });
                    setLiveChatSoundDisabled(!liveChatSoundsDisabled);
                    break;
                  case DialogSettingsMenuItem.manageNotifications:
                    sendEvent({
                      category: 'live chat',
                      action: 'manage subscription open',
                    });
                    showModal();
                    break;
                  case DialogSettingsMenuItem.manageAutomatedMessages:
                    showAutomatedMessagesModal();
                    break;
                  default:
                    break;
                }
              }}
              renderItem={({ getItemProps, item, index, highlightedIndex }) => {
                const active = index === highlightedIndex;

                const commonProps = {
                  ...getItemProps({ item }),
                  key: `${item.id}_${index}`,
                  type: 'item',
                  title: item.title,
                  active,
                };

                switch (item.id) {
                  case DialogSettingsMenuItem.manageNotifications:
                    return (
                      <Tooltip2
                        placement="right"
                        boundariesElement="viewport"
                        key={commonProps.key}
                        content={item.tooltip}
                        type="small"
                        positionFixed
                        disabled={isSmallScreenSize}
                      >
                        {(ref, bind) => (
                          <div {...bind} ref={ref}>
                            <MenuItem {...commonProps} />
                          </div>
                        )}
                      </Tooltip2>
                    );

                  default:
                    return <MenuItem {...commonProps} />;
                }
              }}
              renderInput={({ getToggleButtonProps, ref }) => (
                <Button
                  {...getToggleButtonProps()}
                  ref={ref}
                  intent="text"
                  size="s"
                  icon={<Icon icon="settings" />}
                />
              )}
            />
          );
        }}
      </ManageSubscriptions>
    </div>
  );
};
