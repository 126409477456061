import React from 'react';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { useQuery } from 'react-apollo';
import { SimpleCombobox } from '@ui/SimpleCombobox';
import { MenuItem } from '@ui/Menu';
import { Tooltip2 } from '@ui/Tooltip2';
import { Type } from '@ui/Type';
import { Icon } from '@ui/Icon';
import { Button } from '@ui/Button';
import { Spacer } from '@ui/Spacer';
import { useCurrentBotId } from '@utils/Routing';
import { DIALOGS_COUNT_QUERY } from '../../common/queries';
import { FOLDER_ITEMS } from '../../consts';
import { useLiveChatSegmentation } from '../../hooks/useLiveChatSegmentation';
import {
  DialogsCountQuery,
  DialogsCountQueryVariables,
} from '../../common/@types/DialogsCountQuery';
import { Folder, Platform } from '../../../../../@types/globalTypes';
import * as css from './FolderSelector.css';

interface FolderSelectorProps {
  value: Folder;
  onChange(folder: Folder): void;
  platform: Platform | null;
  isSmallScreenSize?: boolean;
}

export const FolderSelector: React.FC<FolderSelectorProps> = ({
  value,
  onChange,
  platform,
  isSmallScreenSize,
}) => {
  const { t } = useSafeTranslation();
  const botId = useCurrentBotId();
  const item = FOLDER_ITEMS.find((f) => f.id === value)!;
  const segmentation = useLiveChatSegmentation(botId!);
  const { data } = useQuery<DialogsCountQuery, DialogsCountQueryVariables>(
    DIALOGS_COUNT_QUERY,
    {
      fetchPolicy: 'network-only',
      variables: {
        botId: botId!,
        segmentation,
        folder: item.id!,
        platform,
      },
    },
  );
  return (
    <SimpleCombobox
      downshiftContainerClassName={css.folderSelectorCombobox}
      position="bottom-start"
      onChange={(item) => {
        if (item?.id) {
          onChange(item.id);
        }
      }}
      renderInput={({ ref, getToggleButtonProps }) => {
        return (
          <Button
            {...getToggleButtonProps()}
            ref={ref}
            intent="text"
            icon={<Icon icon={item.icon} />}
            iconRight={<Icon icon="triangle" />}
          >
            <div className={css.inputTitle}>
              <Type weight="medium" size="15px_DEPRECATED">
                {t(item.title)}
              </Type>
              <Spacer factor={0} horizontalFactor={2} />
              <Type color="greyDark" weight="medium" size="15px_DEPRECATED">
                {data?.livechatConversationsCount.count}
              </Type>
            </div>
          </Button>
        );
      }}
      renderItem={({ getItemProps, item, index, highlightedIndex }) => {
        const active = index === highlightedIndex;
        const key = item.id;
        const commonProps = {
          ...getItemProps({ item }),
          active: index === highlightedIndex,
          title: t(item.title),
        };

        switch (item.id) {
          case Folder.inbox:
            return (
              <Tooltip2
                key={key}
                placement="right"
                boundariesElement="viewport"
                content={
                  <Type as="p" size="12px" color="white">
                    {t('FolderSelector-JSXText--183-all-active-conversations')}
                  </Type>
                }
                type="small"
                positionFixed
                disabled={isSmallScreenSize}
              >
                {(ref, bind) => (
                  <div {...bind} ref={ref}>
                    <MenuItem
                      {...commonProps}
                      leftElement={
                        <Icon
                          icon={item.icon}
                          color={active ? 'white' : 'black'}
                        />
                      }
                    />
                  </div>
                )}
              </Tooltip2>
            );
          case Folder.closed:
            return (
              <Tooltip2
                key={key}
                placement="right"
                boundariesElement="viewport"
                content={
                  <Type as="p" size="12px" color="white">
                    {window.i18next.t(
                      'FolderSelector-JSXText--439-all-closed-conversations-will-be-moved-to-done',
                    )}
                  </Type>
                }
                type="small"
                positionFixed
                disabled={isSmallScreenSize}
              >
                {(ref, bind) => (
                  <div {...bind} ref={ref}>
                    <MenuItem
                      {...commonProps}
                      leftElement={
                        <Icon
                          icon={item.icon}
                          color={active ? 'white' : 'black'}
                        />
                      }
                    />
                  </div>
                )}
              </Tooltip2>
            );
          case Folder.all:
            return (
              <MenuItem
                {...commonProps}
                key={key}
                leftElement={
                  <Icon icon={item.icon} color={active ? 'white' : 'black'} />
                }
              />
            );
          default:
            return null;
        }
      }}
      items={FOLDER_ITEMS}
      unsaveSelectedOption
    />
  );
};
