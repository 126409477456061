import React, { useCallback } from 'react';
import { Modal } from '@services/.';
import { sendEvent } from '@utils/Analytics';
import { useBotInstagramAccount } from '@utils/Data/Instagram/BotAccount';
import { useGoToGetPermissions } from '@utils/FacebookPages/useGoToGetPermissions';
import { useCurrentBotId } from '@utils/Routing';
import {
  ConnectPageField,
  ConnectPageOrigin,
  useConnectPage,
} from '@components/ConnectPageDialog';
import {
  getInstagramConnectionVariant,
  ConnectInstagramRestorationQueryField,
  useConnectInstagramDialog,
} from '@components/dialogs/ConnectInstagramDialog';
import { DisconnectModal } from './components/DisconnectModal';
import { ConnectedInstagramContent } from './components/ConnectedInstagramContent';
import { InstagramConnectionProblem } from '@globals';
import { SectionBox } from '@components/SectionBox/SectionBox';
import { PlatformConnectionLoading } from '../PlatformConnection/PlatformConnectionLoading/PlatformConnectionLoading';
import { InstagramAccountDisconnected } from './components/InstagramAccountDisconnected';
import { InstagramNotConnectedToPage } from './components/InstagramNotConnectedToPage';

export const InstagramAccount: React.FC = () => {
  const { goToGetInstagramPermissions } = useGoToGetPermissions();
  const botId = useCurrentBotId() || '';
  const {
    instagramAccountLoading,
    instagramConnectionIsChanging,
    hasInstagramMessagingPermissions,
    fbPage,
    instagramConnectedAccount,
    instagramBusinessAccount,
    fbPageIsConnected,
    instagramConnected,
    instagramConnectionProblem,
    disconnectInstagram,
    refetchInstagramAccount,
  } = useBotInstagramAccount(botId, {
    onConnected: () => {
      sendEvent({
        category: 'grow tools',
        action: 'instagram account connected',
      });
    },
  });

  const connectVariant = getInstagramConnectionVariant(
    !!instagramConnected,
    !!instagramBusinessAccount,
    !!hasInstagramMessagingPermissions,
  );
  const { connectInstagram } = useConnectInstagramDialog(connectVariant, {
    [ConnectInstagramRestorationQueryField.name]: 'grow',
  });

  const { connectPage } = useConnectPage({
    botId,
    urlParams: {
      [ConnectPageField.origin]: ConnectPageOrigin.instagramConnect,
    },
    onPageConnected({ close }) {
      close?.();
      refetchInstagramAccount();
      connectInstagram();
      sendEvent({
        category: 'grow tools',
        action: 'connect instagram account',
      });
    },
  });

  const handleConnectClick = () => {
    if (!fbPageIsConnected) {
      connectPage();
    } else {
      connectInstagram();
    }
    sendEvent({
      category: 'grow tools',
      action: 'click connect',
      label: 'instagram',
      propertyBag: { botId },
    });
  };

  const handleDisconnectClick = useCallback(() => {
    sendEvent({
      category: 'grow tools',
      action: 'disconnect instagram account',
    });

    Modal.show(({ close }) => (
      <DisconnectModal
        onClose={close}
        onDisconnect={() => {
          close();
          disconnectInstagram();
          sendEvent({
            category: 'grow tools',
            action: 'disconnect instagram account sure',
          });
        }}
      />
    ));
  }, [disconnectInstagram]);

  const handleUpdatePermissions = () => {
    goToGetInstagramPermissions();
    sendEvent({
      category: 'grow tools',
      action: 'click update permissions',
      label: 'instagram',
      propertyBag: { botId },
    });
  };

  const handleActivate = () => {
    connectInstagram();
    sendEvent({
      category: 'grow tools',
      action: 'click activate',
      label: 'instagram',
      propertyBag: { botId },
    });
  };

  const messagesAllowed =
    instagramConnectionProblem !==
    InstagramConnectionProblem.no_access_to_direct_messages_toggle;

  const renderBoxBody = () => {
    if (instagramConnectionIsChanging || instagramAccountLoading) {
      return <PlatformConnectionLoading />;
    }

    if (
      instagramConnected &&
      instagramConnectionProblem ===
        InstagramConnectionProblem.not_connected_to_page
    ) {
      return (
        <InstagramNotConnectedToPage
          fbPage={fbPage}
          instagramAccount={instagramConnectedAccount}
          onClick={handleDisconnectClick}
        />
      );
    }

    if (instagramBusinessAccount) {
      return (
        <ConnectedInstagramContent
          fbPage={fbPage}
          instagramAccount={instagramConnectedAccount}
          onDisconnect={
            instagramConnected && messagesAllowed
              ? handleDisconnectClick
              : undefined
          }
          onUpdatePermissions={
            instagramConnected && messagesAllowed
              ? handleUpdatePermissions
              : undefined
          }
          onActivate={
            !instagramConnected || !messagesAllowed ? handleActivate : undefined
          }
        />
      );
    }

    return <InstagramAccountDisconnected onClick={handleConnectClick} />;
  };

  return (
    <SectionBox data-testid="instagram-account">{renderBoxBody()}</SectionBox>
  );
};
