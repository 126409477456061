import { Type } from '@ui/Type';
import React from 'react';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { useLiveChatMessagesUploadingContext } from '../../hooks/LiveChatMessagesUploadingContext/LiveChatMessagesUploadingContext';
import { SendingMessageStatus } from '../../hooks/LiveChatMessagesUploadingContext/types';
import { getDialogLastMessageText } from './Utils';
import { DialogsQuery_livechatConversations_items as Dialog } from '../../common/@types/DialogsQuery';
import * as css from './DialogLastMessage.css';

interface DialogLastMessageProps {
  dialog: Dialog;
}

export const DialogLastMessage: React.FC<DialogLastMessageProps> = ({
  dialog,
}) => {
  const { sendingMessages } = useLiveChatMessagesUploadingContext();
  const { t } = useSafeTranslation();

  const conversationSendingMessages = sendingMessages.filter(
    (item) => item.conversationId === dialog.id,
  );

  if (conversationSendingMessages) {
    if (
      conversationSendingMessages.some(
        (item) => item.status === SendingMessageStatus.error,
      )
    ) {
      return (
        <Type
          as="div"
          size="15px_DEPRECATED"
          color="red"
          weight="regular"
          noWrap
        >
          {t('pages.LiveChat.DialogItem.sendAttachmentError')}
        </Type>
      );
    }
    if (
      conversationSendingMessages.some(
        (item) => item.status === SendingMessageStatus.uploading,
      )
    ) {
      return (
        <Type
          as="div"
          size="15px_DEPRECATED"
          color="greyDark"
          weight="regular"
          noWrap
        >
          {t('pages.LiveChat.DialogItem.sendingAttachment')}
        </Type>
      );
    }
  }

  if (dialog.last_message) {
    return (
      <Type
        as="div"
        size="15px_DEPRECATED"
        color={!dialog.unseen_messages_count ? 'greyDark' : 'black'}
        weight={dialog.last_message.seen ? 'regular' : 'medium'}
        noWrap
        data-testid="dialog-item__last-message-preview"
        className={css.lastMessage}
      >
        {getDialogLastMessageText(dialog)}
      </Type>
    );
  }

  return (
    <Type as="p" size="15px_DEPRECATED" color="grey">
      &nbsp;
    </Type>
  );
};
