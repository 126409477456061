import { useCurrentBotId } from '@utils/Routing';
import { useSubscriptionWithAuth } from '@utils/Data/hocks/useSubscriptionWithAuth';
import { DIALOGS_ASSIGNEE_UPDATE_SUBSCRIPTION } from '../../../common/subscriptions';
import { ObservableQuery } from 'apollo-client';
import {
  DialogsQuery,
  DialogsQueryVariables,
} from '../../../common/@types/DialogsQuery';
import { useEffect } from 'react';
import {
  DialogsAssigneeUpdateSubscription,
  DialogsAssigneeUpdateSubscription_livechatDialogsAssigneeUpdate,
  DialogsAssigneeUpdateSubscriptionVariables,
} from '../../../common/@types/DialogsAssigneeUpdateSubscription';
import Maybe from 'graphql/tsutils/Maybe';
import { mapQuery } from '@utils/GQL/mapQuery';
import { useApolloClient } from 'react-apollo';
import { DIALOGS_QUERY } from '../../../common/queries';

const findAssigneeParam = ({ name }: { name: string }) => name === 'assignee';

const getDialogAssignee = (
  dialog: Maybe<DialogsAssigneeUpdateSubscription_livechatDialogsAssigneeUpdate>,
) => dialog?.subscriber.attributes.find(findAssigneeParam)?.values[0];

export const useSubscribeDialogsAssigneeUpdates = (
  observableQuery: ObservableQuery<DialogsQuery>,
) => {
  const botId = useCurrentBotId();
  const client = useApolloClient();

  const { data } = useSubscriptionWithAuth<
    DialogsAssigneeUpdateSubscription,
    DialogsAssigneeUpdateSubscriptionVariables
  >(DIALOGS_ASSIGNEE_UPDATE_SUBSCRIPTION, {
    variables: {
      botId: botId || '',
    },
    skip: !botId,
  });

  const dialog = data?.livechatDialogsAssigneeUpdate;
  const { variables } = observableQuery;
  const { segmentation } = variables;

  useEffect(() => {
    if (!dialog) {
      return;
    }
    const segmentationAssignee =
      segmentation.parameters.find(findAssigneeParam)?.values[0];
    if (!segmentationAssignee) {
      return;
    }

    const dialogId = dialog.id;
    const dialogAssignee = getDialogAssignee(dialog) || 'not set';

    mapQuery<DialogsQuery, DialogsQueryVariables>(
      client,
      {
        query: DIALOGS_QUERY,
        variables: variables as DialogsQueryVariables,
      },
      (data) => {
        const isFoundCurrentDialog = data.livechatConversations.items.some(
          ({ id }) => id === dialogId,
        );
        if (isFoundCurrentDialog && dialogAssignee !== segmentationAssignee) {
          // eslint-disable-next-line no-param-reassign
          data.livechatConversations.items =
            data.livechatConversations.items.filter(
              ({ id }) => id !== dialogId,
            );
        }
        if (!isFoundCurrentDialog && dialogAssignee === segmentationAssignee) {
          // eslint-disable-next-line no-param-reassign
          const updatedItems = data.livechatConversations.items.slice();
          updatedItems.unshift(dialog);
          // eslint-disable-next-line no-param-reassign
          data.livechatConversations.items = updatedItems;
        }
        return data;
      },
    );
  }, [segmentation, dialog, variables, client]);
};
