import React, { useEffect } from 'react';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { sendEvent } from '@utils/Analytics';
import { removeSyntheticLoginFlag } from '@utils/syntheticLogin';
import { acceptGdpr } from 'cf-common/src/analytics';
import { removeLoginCookie } from '@utils/loginCookie';
import { useSetUserLocale } from '@utils/Data/Admin/Locale/useSetUserLocale';
import { Anchor } from '@ui/Links';
import { LoginCommon, LoginProps } from '../LoginCommon';
import * as css from './Login.css';
import { Button, ButtonIntent } from '../../modern-ui/_deprecated/Button';
import { ReactComponent as FbIcon } from '../../modern-ui/_deprecated/Icon/icons/fb.svg';
import { ReactComponent as Rocket } from '../../assets/zero-state/rocket.svg';

export const Login: React.FC<LoginProps> = (props) => {
  const { t } = useSafeTranslation();
  const setUserLocale = useSetUserLocale();

  useEffect(() => {
    removeSyntheticLoginFlag();
    removeLoginCookie();
  }, []);

  return (
    <LoginCommon
      {...props}
      onSuccessLogin={setUserLocale}
      render={({ getTokenFromSdkLoading, goFbAuth }) => (
        <>
          <Rocket />
          <div className={css.message}>
            <span>{t('pages.Login.welcome')}</span>
            <br />
            <span>{t('pages.Login.loginMessage')}</span>
            <br />
            <span>{t('pages.Login.platform')}</span>
          </div>
          <Button
            data-testid="login__button"
            renderIcon={() => <FbIcon />}
            disabled={getTokenFromSdkLoading}
            intent={ButtonIntent.primary}
            onClick={() => {
              sendEvent({
                category: 'onboarding',
                action: 'sign in',
                propertyBag: {
                  referrer: document.referrer,
                },
              });
              acceptGdpr(true);
              goFbAuth();
            }}
          >
            {t('pages.Login.continue')}
          </Button>
          <div className={css.tos}>
            <span>{t('pages.Login.termsAcceptByClicking')}&nbsp;</span>
            <Anchor
              intent="external"
              hideArrow
              href="https://chatfuel.com/files/TermsOfUse.pdf"
              target="_blank"
              rel="noopener noreferrer"
            >
              {t('pages.Login.termsOfService')}
            </Anchor>
          </div>
        </>
      )}
    />
  );
};
