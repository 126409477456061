import { useMutation } from 'react-apollo';
import { Folder, Platform } from '@globals';
import { SEND_TO_BLOCK_MUTATION } from './queries';
import {
  SendToBlockMutation,
  SendToBlockMutationVariables,
} from './@types/SendToBlockMutation';
import { useLiveChatSegmentation } from '../../../../../hooks/useLiveChatSegmentation';
import { useLocalLiveChatMessageV3 } from '@pages/LiveChat/components/SendMessage/useLocalLiveChatMessageV3';
import { moveConversationToFolderInCache } from '../../../../../utils/moveConversationToFolderInCache';

interface UseSendBlockProps {
  botId: string;
  conversationId: string;
  conversationFolder: Folder;
  platform: Platform;
  dialogListPlatform: Platform | null;
  onConversationMoved?(folder: Folder): void;
}

export interface SendBlockProps {
  blockId?: string;
  flowId?: string;
  title: string;
}

/**
 * много копипасты из
 * packages/dashboard/src/pages/LiveChat/components/SendMessage/SendLivechatMessageMutation.tsx
 */
export const useSendBlock = ({
  botId,
  conversationId,
  conversationFolder,
  platform,
  dialogListPlatform,
  onConversationMoved,
}: UseSendBlockProps) => {
  const { loading, error, locallyGeneratedId } = useLocalLiveChatMessageV3({
    botId,
    conversationId,
    platform,
  });
  const segmentation = useLiveChatSegmentation(botId);

  const [sendBlockMutation] = useMutation<
    SendToBlockMutation,
    SendToBlockMutationVariables
  >(SEND_TO_BLOCK_MUTATION);

  return {
    disabled: loading || error,
    sendBlock: ({ blockId, flowId }: SendBlockProps) => {
      sendBlockMutation({
        variables: {
          botId,
          conversationId,
          blockId: (blockId ?? flowId)!,
          platform,
        },
        update: (_, { data: updateData }) => {
          if (!updateData) {
            return;
          }
          if (
            updateData.sendToBlock.id !== locallyGeneratedId &&
            conversationFolder !== Folder.closed
          ) {
            moveConversationToFolderInCache(conversationId, Folder.closed, {
              botId,
              platform: dialogListPlatform,
              segmentation,
            });
            onConversationMoved?.(Folder.closed);
          }
        },
      });
    },
  };
};
