import { ButtonUnstyled } from '@ui/Button';
import { Emoji } from '@ui/Emoji';
import { Icon } from '@ui/Icon';
import { Type } from '@ui/Type';
import { ServerStorageItemKeys, useServerStorage } from '@utils/ServerStorage';
import React, { useCallback } from 'react';
import * as css from './NotificationsBanner.css';

export const NotificationsBanner: React.FC = () => {
  const [
    notificationsPromoHidden,
    setNotificationsPromoHidden,
    { loading: notificationsPromoLoading },
  ] = useServerStorage<boolean>(
    ServerStorageItemKeys.livechatNotificationsPromoHidden,
  );

  const [sendFlowPromoHidden, , { loading: sendFlowPromoLoading }] =
    useServerStorage<boolean>(
      ServerStorageItemKeys.livechatSendFlowPromoHidden,
    );

  const onClose = useCallback(() => {
    setNotificationsPromoHidden(true);
  }, [setNotificationsPromoHidden]);

  if (
    notificationsPromoLoading ||
    sendFlowPromoLoading ||
    notificationsPromoHidden ||
    sendFlowPromoHidden !== true
  ) {
    return null;
  }

  return (
    <div className={css.box}>
      <Icon
        icon="subscribe"
        className={css.notificationsIcon}
        size="24px"
        color="white"
      />
      <ButtonUnstyled className={css.closeButton} onClick={onClose}>
        <Icon icon="close" size="20px" noColor />
      </ButtonUnstyled>
      <Type size="15px_DEPRECATED">
        {window.i18next.t(
          'NotificationsBanner-JSXText-5109-weve-added-sound-notifications-for',
        )}{' '}
        {window.i18next.t(
          'NotificationsBanner-JSXText--131-new-messages-to-turn-them-off-tap️',
        )}{' '}
        {window.i18next.t('NotificationsBanner-JSXText--125-settings')}
        <Emoji emoji="⚙️" size={15} />
        {window.i18next.t('NotificationsBanner-JSXText--365-below')}
      </Type>
    </div>
  );
};
