import React, { useContext, useState } from 'react';
import noop from 'lodash-es/noop';

interface LiveChatDialogsQtyStateContextProps {
  setDialogsQty: (qty: number) => void;
  dialogsQty: number;
}

const LiveChatDialogsQtyStateContext =
  React.createContext<LiveChatDialogsQtyStateContextProps>({
    setDialogsQty: noop,
    dialogsQty: 0,
  });

export const LiveChatDialogsQtyStateContextProvider: React.FC = ({
  children,
}) => {
  const [qty, setDialogsQty] = useState<number>(0);

  return (
    <LiveChatDialogsQtyStateContext.Provider
      value={{
        dialogsQty: qty,
        setDialogsQty,
      }}
    >
      {children}
    </LiveChatDialogsQtyStateContext.Provider>
  );
};

export const useLiveChatDialogsQtyState = () =>
  useContext<LiveChatDialogsQtyStateContextProps>(
    LiveChatDialogsQtyStateContext,
  );
