import i18next from 'i18next';
import { IconNames } from '@ui/Icon/icons';
import memoize from 'lodash-es/memoize';
import { FileAttachmentType } from '@utils/UploadService/types';

export interface AttachmentsItem {
  id: FileAttachmentType;
  title: string;
  iconName: IconNames;
}

export const getAttachmentItems = memoize(
  () =>
    [
      {
        id: FileAttachmentType.image,
        title: i18next.t('pages.LiveChat.MessageInput.images'),
        iconName: 'image',
      },
      {
        id: FileAttachmentType.file,
        title: i18next.t('pages.LiveChat.MessageInput.files'),
        iconName: 'note',
      },
    ] as AttachmentsItem[],
);
