import { Spacer } from '@ui/Spacer';
import { useCurrentBotId } from '@utils/Routing';
import React from 'react';
import { Platform } from '@globals';
import { InstagramAvatar } from '../../../Avatar';
import { PersonaApiToggle } from '../../../PersonaApiToggle';
import { useDeviceMedia } from '@utils/DOM/useDeviceMedia';

interface SenderAvatarProps {
  platform: Platform;
  disabled?: boolean;
}

export const SenderAvatar: React.FC<SenderAvatarProps> = ({
  platform,
  disabled,
}) => {
  const botId = useCurrentBotId();
  const { isSmallScreenSize } = useDeviceMedia();

  if (!botId) {
    return null;
  }

  return platform === Platform.facebook ? (
    <>
      <PersonaApiToggle
        botId={botId}
        disabled={disabled}
        isSmallScreenSize={isSmallScreenSize}
      />
      <Spacer horizontalFactor={2} />
    </>
  ) : (
    <>
      <InstagramAvatar botId={botId} isSmallScreenSize={isSmallScreenSize} />
      <Spacer horizontalFactor={4} />
    </>
  );
};
