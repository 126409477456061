import gql from 'graphql-tag';

export const commentsAutoreplyRuleFragment = gql`
  fragment commentsAutoreplyRuleFragment on CommentsAutoreplyRule {
    id
    title
    phrases
    post_url
    response
    blocks {
      id
      title
      removed
      is_flow
    }
  }
`;

export const COMMENTS_AUTOREPLY_QUERY = gql`
  query CommentsAutoreplyQuery($botId: String!) {
    bot(id: $botId) {
      id
      commentsAutoreplyRules {
        ...commentsAutoreplyRuleFragment
      }
    }
  }
  ${commentsAutoreplyRuleFragment}
`;

export const COMMENTS_AUTOREPLY_RULE_DELETE_MUTATION = gql`
  mutation CommentsAutoreplyRuleDeleteMutation(
    $botId: String!
    $commentsAutoreplyRuleId: String!
  ) {
    deleteCommentsAutoreplyRule(
      botId: $botId
      commentsAutoreplyRuleId: $commentsAutoreplyRuleId
    ) {
      commentsAutoreplyRules {
        ...commentsAutoreplyRuleFragment
      }
    }
  }
  ${commentsAutoreplyRuleFragment}
`;

export const COMMENTS_AUTOREPLY_RULE_SAVE_MUTATION = gql`
  mutation CommentsAutoreplyRuleSaveMutation(
    $botId: String!
    $commentsAutoreplyRule: CommentsAutoreplyRuleInput!
    $position: Int!
  ) {
    saveCommentsAutoreplyRule(
      botId: $botId
      commentsAutoreplyRule: $commentsAutoreplyRule
      position: $position
    ) {
      commentsAutoreplyRules {
        ...commentsAutoreplyRuleFragment
      }
    }
  }
  ${commentsAutoreplyRuleFragment}
`;

export const botCommentsAutoreplyRuleFragment = gql`
  fragment botCommentsAutoreplyRuleFragment on Bot {
    commentsAutoreplyRules {
      ...commentsAutoreplyRuleFragment
    }
  }
  ${commentsAutoreplyRuleFragment}
`;
