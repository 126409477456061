import React from 'react';
import { FlexItem } from '@ui/Flex';
import css from './LiveChatRightPanel.css';
import { RoleConsumer } from '@utils/Roles';
import { Permission } from '@common/services/RoleService';
import { UserAttributes } from '../UserAttributes/UserAttributes';
import { useDeviceMedia } from '@utils/DOM/useDeviceMedia';
import { Platform } from '@globals';
import { useCurrentBotId } from '@utils/Routing';
import { CollapseButton } from '@components/FlowBuilder/EditorPanel/components/editors/components/CollapseButton';
import { LSKey, useLocalStorage } from '@utils/LocalStorage';
import cn from 'classnames';
import { useLiveChatSubscriber } from '../../hooks/useLiveChatSubscriber';
import { Type } from '@ui/Type';
import { LiveChatNotes } from './components/LiveChatNotes';
import { RightPanelDivider } from './components/RightPanelDivider';

interface LiveChatUserAttributes {
  conversationId: string;
  platform: Platform;
}

export const LiveChatRightPanel: React.FC<LiveChatUserAttributes> = ({
  conversationId,
  platform,
}) => {
  const { isSmallScreenSize } = useDeviceMedia();
  const botId = useCurrentBotId()!;
  const [showPanel, setShowPanel] = useLocalStorage(
    LSKey.isLiveChatRightPanelShow,
    true,
  );

  const { subscriber } = useLiveChatSubscriber({
    botId,
    conversationId,
    platform,
  });

  return (
    <FlexItem
      className={cn(
        css.box,
        isSmallScreenSize ? css.boxCommon_mobile : css.boxCommon,
        !showPanel && css.collapse,
      )}
    >
      <RoleConsumer domain="people" can={Permission.EDIT}>
        {({ loading, allowed }) =>
          loading ? null : (
            <>
              {showPanel ? (
                <div className={css.panel}>
                  <div
                    className={
                      isSmallScreenSize ? css.userNameMobile : css.userName
                    }
                  >
                    <Type size="24px">{subscriber?.name}</Type>
                  </div>
                  <RightPanelDivider />
                  <LiveChatNotes
                    conversationId={conversationId}
                    platform={platform}
                  />
                  <UserAttributes
                    id={conversationId}
                    botId={botId || ''}
                    platform={platform}
                    readOnly={!allowed}
                    isSmallScreenSize={isSmallScreenSize}
                  />
                </div>
              ) : null}
              {!isSmallScreenSize && (
                <CollapseButton
                  onClick={() => {
                    setShowPanel?.(!showPanel);
                  }}
                  active={!showPanel}
                  className={css.collapseButton}
                />
              )}
            </>
          )
        }
      </RoleConsumer>
    </FlexItem>
  );
};
