import React from 'react';
import { TutorialLinks } from '@ui/Links';
import { useSafeTranslation } from '@utils/useSafeTranslation';

interface AdManagerTutorialLinks {
  onClick?: (text: string) => void;
}

export const AdManagerTutorialLinks: React.FC<AdManagerTutorialLinks> = ({
  onClick,
}) => {
  const { t } = useSafeTranslation();

  return (
    <TutorialLinks
      variant="default"
      linkItems={[
        {
          onClick: () => onClick?.('ctm'),
          text: t('AdManagerTutorialLinks-string--177-how-to-video'),
          href: t('common.helpDocLinks.how_to_create_an_ad'),
        },
      ]}
    />
  );
};
