import React from 'react';
import cn from 'classnames';
import { Color, ColorKey } from '../../../../modern-ui/_common/colors';
import { Flex } from '../../../../modern-ui/Flex';
import { Type } from '../../../../modern-ui/Type';
import { ButtonUnstyled } from '../../../../modern-ui/Button';
import { ReactComponent as CloseIcon } from '../../../../modern-ui/_deprecated/Icon/icons/ic_close_2.svg';
import * as css from './GrowItemTopBannerInner.css';

export interface GrowItemTopBannerCommonProps
  extends React.HTMLProps<HTMLDivElement> {
  children: React.ReactNode;
  color?: ColorKey;
  icon?: React.ReactElement;
}

interface GrowItemTopBannerInnerProps extends GrowItemTopBannerCommonProps {
  onCloseClick: () => void;
}

export const GrowItemTopBannerInner: React.FC<GrowItemTopBannerInnerProps> = ({
  className,
  children,
  icon,
  style,
  color = 'yellowLight',
  onCloseClick,
  ...props
}) => {
  return (
    <div
      className={cn(className, css.GrowItemTopBanner)}
      style={{
        backgroundColor: Color[color],
        ...style,
      }}
      {...props}
    >
      <Flex alignItems="center" style={{ height: '100%' }}>
        {icon && <Flex className={css.iconBox}>{icon}</Flex>}
        <div className={css.textBox}>
          <Type size="18px">{children}</Type>
        </div>
      </Flex>
      <ButtonUnstyled className={css.closeButton} onClick={onCloseClick}>
        <CloseIcon />
      </ButtonUnstyled>
    </div>
  );
};
