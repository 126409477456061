import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

export const useConversationId = () => {
  const location = useLocation();
  return useMemo(
    () => new URLSearchParams(location.search).get('conversationId') || '',
    [location.search],
  );
};
