import React from 'react';
import { Flex } from '@ui/Flex';
import { Type } from '@ui/Type';
import * as css from './MediaMessageCaption.css';

export interface MediaMessageCaptionProps {
  caption: string;
}

export const MediaMessageCaption: React.FC<MediaMessageCaptionProps> = ({
  caption,
}) => (
  <Flex className={css.wrapper}>
    <Type size="12px" wordBreak="break-all">
      {caption}
    </Type>
  </Flex>
);
