import { complement, propEq } from 'ramda';
import { removeTypename } from '@utils/GQL/utils';
import client from '../../../common/services/ApolloService';
import {
  botCommentsAutoreplyRuleFragment,
  COMMENTS_AUTOREPLY_QUERY,
  COMMENTS_AUTOREPLY_RULE_DELETE_MUTATION,
  COMMENTS_AUTOREPLY_RULE_SAVE_MUTATION,
} from './commentsAutoreplyConsts';
import {
  CommentsAutoreplyRuleSaveMutation,
  CommentsAutoreplyRuleSaveMutation_saveCommentsAutoreplyRule_commentsAutoreplyRules as CommentsAutoreplyRule,
  CommentsAutoreplyRuleSaveMutationVariables,
} from './@types/CommentsAutoreplyRuleSaveMutation';
import {
  CommentsAutoreplyRuleDeleteMutation,
  CommentsAutoreplyRuleDeleteMutationVariables,
} from './@types/CommentsAutoreplyRuleDeleteMutation';
import { botCommentsAutoreplyRuleFragment as botCommentsAutoreplyRuleFragmentType } from './@types/botCommentsAutoreplyRuleFragment';
import {
  CommentsAutoreplyQuery,
  CommentsAutoreplyQueryVariables,
} from './@types/CommentsAutoreplyQuery';
import { ApolloQueryResult } from 'apollo-client';

const idNotEq = complement(propEq('id'));

const commonFragmentParams = {
  fragment: botCommentsAutoreplyRuleFragment,
  fragmentName: 'botCommentsAutoreplyRuleFragment',
};

export const removeCommentsAutoreplyRule = (
  botId: string,
  commentsAutoreplyRuleId: string,
) => {
  const idNotEqDeleted = idNotEq(commentsAutoreplyRuleId);
  const fragmentParams = {
    ...commonFragmentParams,
    id: `Bot:${botId}`,
  };
  const { commentsAutoreplyRules = [] } =
    client.readFragment(fragmentParams) || {};
  return client.mutate<
    CommentsAutoreplyRuleDeleteMutation,
    CommentsAutoreplyRuleDeleteMutationVariables
  >({
    mutation: COMMENTS_AUTOREPLY_RULE_DELETE_MUTATION,
    variables: {
      botId,
      commentsAutoreplyRuleId,
    },
    optimisticResponse: {
      deleteCommentsAutoreplyRule: {
        commentsAutoreplyRules: commentsAutoreplyRules.filter(
          idNotEqDeleted as any,
        ),
        __typename: 'CommentsAutoreplyRuleMutationResult',
      },
    },
    update: (cache, mutationResult) => {
      const commentsAutoreplyRuleData = (mutationResult.data || {})
        .deleteCommentsAutoreplyRule;
      if (commentsAutoreplyRuleData) {
        cache.writeFragment({
          ...fragmentParams,
          data: {
            ...commentsAutoreplyRuleData,
            __typename: 'Bot',
          },
        });
      }
    },
  });
};

export const saveCommentsAutoreplyRule = (
  botId: string,
  commentsAutoreplyRule: CommentsAutoreplyRule,
  position: number,
) => {
  const fragmentParams = {
    ...commonFragmentParams,
    id: `Bot:${botId}`,
  };
  let commentsAutoreplyRules: CommentsAutoreplyRule[];
  try {
    const commentsAutoreplyData: botCommentsAutoreplyRuleFragmentType | null =
      client.readFragment(fragmentParams);
    commentsAutoreplyRules =
      (commentsAutoreplyData && commentsAutoreplyData.commentsAutoreplyRules) ||
      [];
  } catch {
    commentsAutoreplyRules = [];
  }
  const ruleIndex = commentsAutoreplyRules.findIndex(
    propEq('id', commentsAutoreplyRule.id),
  );
  if (ruleIndex !== -1) {
    commentsAutoreplyRules.splice(ruleIndex, 1);
  }
  commentsAutoreplyRules.splice(position, 0, {
    ...commentsAutoreplyRule,
    __typename: 'CommentsAutoreplyRule',
  });
  return client.mutate<
    CommentsAutoreplyRuleSaveMutation,
    CommentsAutoreplyRuleSaveMutationVariables
  >({
    mutation: COMMENTS_AUTOREPLY_RULE_SAVE_MUTATION,
    variables: {
      botId,
      position,
      commentsAutoreplyRule: removeTypename(commentsAutoreplyRule),
    },
    optimisticResponse: {
      saveCommentsAutoreplyRule: {
        commentsAutoreplyRules,
        __typename: 'CommentsAutoreplyRuleMutationResult',
      },
    },
    update: (cache, mutationResult) => {
      const commentsAutoreplyRuleData = (mutationResult.data || {})
        .saveCommentsAutoreplyRule;
      if (commentsAutoreplyRuleData) {
        cache.writeFragment({
          ...fragmentParams,
          data: {
            ...commentsAutoreplyRuleData,
            __typename: 'Bot',
          },
        });
      }
    },
  });
};

export const subscribeToRulesList = (
  botId: string,
  callback: (data: ApolloQueryResult<CommentsAutoreplyQuery>) => void,
) =>
  client
    .watchQuery<CommentsAutoreplyQuery, CommentsAutoreplyQueryVariables>({
      query: COMMENTS_AUTOREPLY_QUERY,
      variables: {
        botId,
      },
    })
    .subscribe(callback);
