import React from 'react';
import { Flex } from '@ui/Flex';
import { Type } from '@ui/Type';
import { useDeviceMedia } from '@utils/DOM/useDeviceMedia';
import * as css from './PlatformBody.css';

export interface PlatformBodyProps {
  wrapperTestId?: string;
  textTestId?: string;
  description: React.ReactNode;
}

export const PlatformBody: React.FC<PlatformBodyProps> = ({
  wrapperTestId,
  textTestId,
  description,
  children,
}) => {
  const { isSmallScreenSize } = useDeviceMedia();

  return (
    <Flex
      justifyContent="space-between"
      flexDirection={isSmallScreenSize ? 'column' : 'row'}
      alignItems={isSmallScreenSize ? 'flex-start' : 'baseline'}
      data-testid={wrapperTestId}
    >
      <Type size="15px" className={css.textBox} data-testid={textTestId}>
        {description}
      </Type>
      {children}
    </Flex>
  );
};
