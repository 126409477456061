import { useQuery } from '@apollo/react-hooks';
import { useEffect } from 'react';
import gql from 'graphql-tag';
import {
  FacebookAdCampaignsListQuery,
  FacebookAdCampaignsListQueryVariables,
} from './@types/FacebookAdCampaignsListQuery';
import { useConnectedFacebookAdAccount } from '../../../../utils/MarketingApi';

export const FACEBOOK_AD_FRAGMENT = gql`
  fragment facebookAdFragment on FacebookAd {
    id
    name
    status
    campaign_id
    customer_action_type
    media_type
    has_greeting
    connected_bot {
      id
      title
    }
    quick_replies {
      buttons {
        title
        blocks {
          id
          title
          removed
          flow_id
          is_flow
        }
      }
    }
    buttons {
      title
      blocks {
        id
        title
        removed
        flow_id
        is_flow
      }
    }
  }
`;

const FACEBOOK_AD_CAMPAIGNS_LIST_QUERY = gql`
  query FacebookAdCampaignsListQuery(
    $botId: String!
    $accountId: String!
    $category: FacebookAdCampaignCategory!
    $limit: Int
  ) {
    bot(id: $botId) {
      id
      facebookAdCampaignsCtm(
        accountId: $accountId
        category: $category
        limit: $limit
      ) {
        id
        name
        status
        after
        type
        ads {
          ...facebookAdFragment
          has_greeting
          insights {
            reach
            impressions
            clicks
            cpa
          }
        }
      }
    }
  }
  ${FACEBOOK_AD_FRAGMENT}
`;

export const useFacebookAdCampaignsListQuery = (
  variables: Omit<FacebookAdCampaignsListQueryVariables, 'accountId'>,
) => {
  const { connectedFacebookAdAccount } = useConnectedFacebookAdAccount(
    variables.botId,
  );
  const { refetch, ...queryState } = useQuery<
    FacebookAdCampaignsListQuery,
    FacebookAdCampaignsListQueryVariables
  >(FACEBOOK_AD_CAMPAIGNS_LIST_QUERY, {
    variables: {
      accountId: connectedFacebookAdAccount?.id || '',
      ...variables,
    },
    notifyOnNetworkStatusChange: true,
    skip: !connectedFacebookAdAccount,
  });

  const connectedFacebookAdAccountId = connectedFacebookAdAccount?.id;

  useEffect(() => {
    if (connectedFacebookAdAccountId) {
      refetch();
    }
  }, [connectedFacebookAdAccountId, refetch]);

  return {
    refetch,
    ...queryState,
  };
};
