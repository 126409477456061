import { useApolloClient } from 'react-apollo';
import {
  MESSAGE_REACTION_SUBSCRIPTION,
  MESSAGE_STATUS_SUBSCRIPTION,
  MESSAGES_QUERY,
  NEW_MESSAGE_SUBSCRIPTION,
} from '../components/Messages/queries';
import { useEffect } from 'react';
import { FetchMessageParams } from './useFetchMessage';
import { MessagesQuery } from '../components/Messages/@types/MessagesQuery';
import Maybe from 'graphql/tsutils/Maybe';
import { log } from 'cf-common/src/logger';
import {
  NewMessageSubscription,
  NewMessageSubscriptionVariables,
} from '../components/Messages/@types/NewMessageSubscription';
import {
  MessageStatusSubscription,
  MessageStatusSubscriptionVariables,
} from '../components/Messages/@types/MessageStatusSubscription';
import { useSubscriptionWithAuth } from '@utils/Data/hocks/useSubscriptionWithAuth';
import {
  MessageReactionSubscription,
  MessageReactionSubscriptionVariables,
} from '../components/Messages/@types/MessageReactionSubscription';

export const useMessagesSubscription = (params: FetchMessageParams) => {
  const client = useApolloClient();

  useSubscriptionWithAuth<
    MessageStatusSubscription,
    MessageStatusSubscriptionVariables
  >(MESSAGE_STATUS_SUBSCRIPTION, {
    variables: {
      botId: params.botId,
      conversationId: params.conversationId,
    },
  });

  useSubscriptionWithAuth<
    MessageReactionSubscription,
    MessageReactionSubscriptionVariables
  >(MESSAGE_REACTION_SUBSCRIPTION, {
    variables: {
      botId: params.botId,
      conversationId: params.conversationId,
    },
  });

  const { data: updateMessageData } = useSubscriptionWithAuth<
    NewMessageSubscription,
    NewMessageSubscriptionVariables
  >(NEW_MESSAGE_SUBSCRIPTION, {
    variables: {
      botId: params.botId,
      conversationId: params.conversationId,
    },
  });

  useEffect(() => {
    if (!updateMessageData) {
      return;
    }

    let data: Maybe<MessagesQuery>;

    try {
      data = client.readQuery<MessagesQuery>({
        query: MESSAGES_QUERY,
        variables: params,
      });
    } catch (error) {
      log.error({
        msg: 'LiveChat read messages query failed',
        data: { error },
      });
      data = null;
    }
    if (!data) {
      return;
    }

    const { items: messagesList, cursors } = data.livechatMessages;
    const updatedMessage = updateMessageData.livechatNewMessage;

    const messageIndex = messagesList.findIndex(
      ({ id }) => id === updatedMessage.id,
    );

    const messageIndexByOutpacingId = messagesList.findIndex(
      ({ outpacingId }) =>
        outpacingId && outpacingId === updatedMessage.outpacingId,
    );

    if (messageIndex !== -1) {
      return;
    }

    const updatedMessagesList = messagesList.slice();

    if (messageIndexByOutpacingId !== -1) {
      updatedMessagesList[messageIndexByOutpacingId] = updatedMessage;
    } else {
      updatedMessagesList.push(updatedMessage);
    }

    try {
      client.writeQuery<MessagesQuery>({
        query: MESSAGES_QUERY,
        variables: params,
        data: {
          livechatMessages: {
            __typename: 'LivechatMessagesList',
            cursors,
            items: updatedMessagesList,
          },
        },
      });
    } catch (error) {
      log.error({
        msg: 'LiveChat write messages query failed',
        data: { error },
      });
    }
  }, [client, params, updateMessageData]);
};
