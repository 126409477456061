import { Flex } from '@ui/Flex';
import { Spacer } from '@ui/Spacer';
import { Type } from '@ui/Type';
import { formatPriceEx } from '@utils/Format/priceFormatter';
import React from 'react';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { MessagesQuery_livechatMessages_items_message_receipt } from '../../@types/MessagesQuery';
import * as css from './ReceiptMessage.css';
import {
  MessageFooter,
  MessageFooterProps,
} from '../../MessageTimestamp/MessageTimestamp';

interface Props extends MessageFooterProps {
  receipt: MessagesQuery_livechatMessages_items_message_receipt;
}

const ITEMS_LIMIT = 2;

export const ReceiptMessage: React.FC<Props> = ({
  receipt,
  date,
  type,
  status,
  error,
}) => {
  const { t } = useSafeTranslation();
  return (
    <div className={css.root}>
      <MessageFooter error={error} date={date} type={type} status={status}>
        <Type as="div" color="white" size="15px_DEPRECATED">
          {t('shopify.orderReceiptLifeChat.title')}
        </Type>
        <Spacer factor={4} />
        <div>
          {receipt.line_items
            ?.slice(0, ITEMS_LIMIT)
            .map(
              ({ image_url: imageUrl, title, quantity, price, currency }) => (
                <>
                  <Flex alignItems="center">
                    <div className={css.imageBox}>
                      {imageUrl && (
                        <img className={css.image} src={imageUrl} alt="" />
                      )}
                    </div>
                    <Spacer factor={1} horizontalFactor={2} />
                    <div>
                      <Type as="div" color="white" size="15px_DEPRECATED">
                        {title}
                      </Type>
                      <Spacer factor={0.5} />
                      <Type as="div" color="greyLight30" size="15px_DEPRECATED">
                        {quantity}
                        {window.i18next.t('ReceiptMessage-JSXText-3450-x')}
                        {formatPriceEx(price || 0, currency || 'USD')}
                      </Type>
                    </div>
                  </Flex>
                  <Spacer factor={2} />
                </>
              ),
            )}
        </div>
        {(receipt.line_items?.length || 0) > ITEMS_LIMIT && (
          <>
            <Type color="white" size="15px_DEPRECATED">
              {window.i18next.t('ReceiptMessage-JSXText-3178-and')}
              {(receipt.line_items?.length || 0) - ITEMS_LIMIT}
              {window.i18next.t('ReceiptMessage-JSXText-1561-more')}
            </Type>
            <Spacer factor={2} />
          </>
        )}
        <Spacer factor={4} />
        <Type as="div" color="greyLight30" size="15px_DEPRECATED">
          {t('shopify.orderReceiptLifeChat.paidWith')}
        </Type>
        <Type as="div" color="white" size="15px_DEPRECATED">
          {receipt.payment_method}
        </Type>
        <Spacer factor={2} />
        <Type as="div" color="greyLight30" size="15px_DEPRECATED">
          {t('shopify.orderReceiptLifeChat.shipTo')}
        </Type>
        <Type as="div" color="white" size="15px_DEPRECATED">
          {receipt.shipping_address?.address_1 || ''}{' '}
          {receipt.shipping_address?.address_2 || ''}
          {/* eslint-disable-next-line react/jsx-curly-brace-presence */}
          {', '}
          {receipt.shipping_address?.city} {receipt.shipping_address?.zip_code}
          <br />
          {receipt.shipping_address?.country}
        </Type>
        <Spacer factor={6} />
        <Flex justifyContent="space-between">
          <Type color="white" size="15px_DEPRECATED">
            {t('shopify.orderReceiptLifeChat.total')}
          </Type>
          <Type color="white" size="15px_DEPRECATED">
            {formatPriceEx(receipt.total_cost || 0, receipt.currency)}
          </Type>
        </Flex>
      </MessageFooter>
    </div>
  );
};
