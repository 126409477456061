import { changeTriggerType } from '@components/FlowBuilder/EditorPanel/components/plugins/PopupEntryPoint/helpers';
import React, { useCallback, useEffect } from 'react';
import TextareaAutosize from 'react-textarea-autosize';
import { Focus } from 'react-powerplug';
import { useMutation, useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import * as Yup from 'yup';
import {
  complement,
  contains,
  filter,
  map,
  omit,
  pathOr,
  pipe,
  prop,
  propEq,
  propOr,
} from 'ramda';
import { Formik } from 'formik';
import { useHistory } from 'react-router-dom';
import { log } from 'cf-common/src/logger';
import { BotTabs, getTabLink, useCurrentBotId } from '@utils/Routing';
import { removeTypename } from '@utils/GQL/utils';
import { GrowItemControlBox } from '../../../common/GrowItem/GrowItemControlBox';
import { Input } from '../../../../../modern-ui/Input';
import { SymbolsLimitBubble } from '../../../../../modern-ui/SymbolsLimitBubble';
import { SimpleCombobox } from '@ui/SimpleCombobox';
import { SEND_TO_MESSENGER_TEXTS } from '../sendToMessengerTexts';
import { Button } from '../../../../../modern-ui/Button';
import { ReactComponent as DropdownIcon } from '../../../../../modern-ui/_deprecated/Icon/icons/ic_dropdown_arr.svg';
import { ReactComponent as InfoIcon } from '../../../../../modern-ui/_deprecated/Icon/icons/error-info.svg';
import { Flex } from '../../../../../modern-ui/Flex';
import { BlocksSelectorData } from '../../../../../modern-components/BlocksSelector2';
import { BlocksSelector } from '../../../../AiSetupPage/BlocksSelector';
import { BlockWithPermissions } from '../../../../AiSetupPage';
import {
  ShopifyShopPopupQuery,
  ShopifyShopPopupQuery_bot_shopifyShopPopup as ShopifyShopPopup,
  ShopifyShopPopupQueryVariables,
} from './@types/ShopifyShopPopupQuery';
import { CenteredLoader } from '../../../../../modern-ui/Loader';
import {
  Platform,
  BlockInput,
  ShopifyShopPopupInput,
  ShopifyShopPopupTriggerInput,
} from '../../../../../../@types/globalTypes';
import {
  ShopifyShopPopupMutation,
  ShopifyShopPopupMutationVariables,
} from './@types/ShopifyShopPopupMutation';
import { toaster } from '../../../../../services/MessageService';
import { ServiceMessageType } from '../../../../../modern-ui/ServiceMessage2';
import {
  addBlockToBlockArchiveCache,
  OPTIMISTIC_BLOCK_ID_PREFIX,
} from '../../../../../modern-components/Aside/Mutations/BlockMutations';
import { sendEvent } from '../../../../../utils/Analytics';
import {
  BLOCK_FRAGMENT,
  BLOCKS_GROUP_FRAGMENT,
  GROUPS_TITLES_QUERY,
} from '../../../../../modern-components/Aside/Mutations/GQL';
import {
  ShopifyGetOptInBlockMutation,
  ShopifyGetOptInBlockMutationVariables,
} from './@types/ShopifyGetOptInBlockMutation';
import { BlockGroupsQuery_bot_blocksGroups_blocks } from '../../../../AiSetupPage/@types/BlockGroupsQuery';
import { GroupsTitlesQuery } from '../../../../../modern-components/Aside/Mutations/@types/GroupsTitlesQuery';
import * as css from '../AbandonedCartPage.css';
import { BubbleEditor } from '../../../../../modern-ui/BubbleEditor';
import { Spacer } from '../../../../../modern-ui/Spacer';
import { UrlsPlaceholderBubble } from '../../../../../modern-ui/BubbleEditor/RichPlaceholder/RichPlaceholder';
import { CheckBox } from '../../../../../modern-ui/CheckBox';
import { Type } from '../../../../../modern-ui/Type';
import { Tooltip2 } from '../../../../../modern-ui/Tooltip2';

interface AbandonedCartPageMessageTabProps {
  onChangePreviewData: (data: Partial<ShopifyShopPopupInput>) => void;
}

export const SHOPIFY_SHOP_POPUP_FRAGMENT = gql`
  fragment shopifyShopPopupFragment on ShopifyShopPopup {
    id
    title
    description
    enabled
    button_text
    blocks {
      id
      title
      removed
    }
    trigger {
      type
      idle_timeout_seconds
    }
    excluded_urls
  }
`;

const SHOPIFY_SHOP_POPUP_QUERY = gql`
  query ShopifyShopPopupQuery($botId: String!) {
    bot(id: $botId) {
      id
      shopifyShopPopup {
        ...shopifyShopPopupFragment
      }
    }
  }
  ${SHOPIFY_SHOP_POPUP_FRAGMENT}
`;

export const SHOPIFY_SHOP_POPUP_MUTATION = gql`
  mutation ShopifyShopPopupMutation(
    $botId: String!
    $shopifyShopPopup: ShopifyShopPopupInput!
  ) {
    updateShopifyShopPopup(botId: $botId, shopifyShopPopup: $shopifyShopPopup) {
      id
      shopifyShopPopup {
        ...shopifyShopPopupFragment
      }
    }
  }
  ${SHOPIFY_SHOP_POPUP_FRAGMENT}
`;

const SHOPIFY_GET_OPT_IN_BLOCK_DATA_MUTATION = gql`
  mutation ShopifyGetOptInBlockMutation($botId: String!) {
    getOptInBlockData(botId: $botId) {
      block {
        ...blockFragment
      }
      group {
        ...blocksGroupFragment
      }
    }
  }
  ${BLOCKS_GROUP_FRAGMENT}
  ${BLOCK_FRAGMENT}
`;

enum ShopifyShopPopupTriggerType {
  addToCart = 'add_to_cart',
  exitIntent = 'exit_intent',
}

const initialTrigger: ShopifyShopPopupTriggerInput = {
  type: ShopifyShopPopupTriggerType.addToCart,
  idle_timeout_seconds: 30,
};

const INITIAL_VALUES: () => ShopifyShopPopupInput = () => ({
  title: window.i18next.t(
    'AbandonedCartPageMessageTab-string--347-get-exclusive-offers',
  ),
  description: window.i18next.t(
    'AbandonedCartPageMessageTab-string-1958-click-the-button-below-to-get-exclusive-access-to-our-deals',
  ),
  button_text: SEND_TO_MESSENGER_TEXTS()[0].id,
  trigger: initialTrigger,
  blocks: [],
  excluded_urls: [],
});

const prepareInitialValues = (
  { id, title }: BlockInput = {} as BlockInput,
) => ({
  ...INITIAL_VALUES(),
  blocks: [
    id ? { id, title, removed: false } : undefined,
    ...(INITIAL_VALUES().blocks || []),
  ].filter(Boolean),
});

const TEXTS_LIMITS = {
  title: 52,
  description: 60,
};

const prepareShopifyShopPopupData = pipe(
  pathOr<ShopifyShopPopup>({} as ShopifyShopPopup, ['bot', 'shopifyShopPopup']),
  omit(['id']),
  removeTypename,
);

const prepareBlocksData = pipe(
  map(pipe(omit(['stats', 'removed']), removeTypename)),
  // @ts-ignore
  filter(pipe(prop('id'), complement(contains(OPTIMISTIC_BLOCK_ID_PREFIX)))),
);

export const AbandonedCartPageMessageTab: React.FC<AbandonedCartPageMessageTabProps> =
  ({ onChangePreviewData }) => {
    const botId = useCurrentBotId();

    const { data, loading, called } = useQuery<
      ShopifyShopPopupQuery,
      ShopifyShopPopupQueryVariables
    >(SHOPIFY_SHOP_POPUP_QUERY, {
      variables: { botId: botId || '' },
      skip: !botId,
    });

    const [
      getOptInBlockDataMutation,
      {
        data: { getOptInBlockData: { block: defaultOptInBlock } } = {
          getOptInBlockData: {},
        } as ShopifyGetOptInBlockMutation,
        called: getOptInBlockDataCalled,
        loading: getOptInBlockDataLoading,
      },
    ] = useMutation<
      ShopifyGetOptInBlockMutation,
      ShopifyGetOptInBlockMutationVariables
    >(SHOPIFY_GET_OPT_IN_BLOCK_DATA_MUTATION, {
      update: (cache, { data }) => {
        if (!data || !botId) {
          return;
        }
        const {
          getOptInBlockData: { block, group },
        } = data;
        addBlockToBlockArchiveCache(cache, botId, block);
        let groupsTitlesQuery: GroupsTitlesQuery | null = null;
        try {
          groupsTitlesQuery = cache.readQuery({
            query: GROUPS_TITLES_QUERY,
            variables: { botId },
          });
        } catch (e) {
          groupsTitlesQuery = null;
        }
        if (groupsTitlesQuery) {
          const {
            blocksGroups: [...blocksGroups],
          } = groupsTitlesQuery.bot;
          if (blocksGroups.find(propEq('id', group.id))) {
            return;
          }
          blocksGroups.push(group);
          cache.writeQuery({
            query: GROUPS_TITLES_QUERY,
            variables: { botId },
            data: {
              bot: {
                ...groupsTitlesQuery.bot,
                blocksGroups,
              },
            },
          });
        }
      },
    });

    const history = useHistory();

    const [updateShopPopup] = useMutation<
      ShopifyShopPopupMutation,
      ShopifyShopPopupMutationVariables
    >(SHOPIFY_SHOP_POPUP_MUTATION);

    const goToHomeTab = useCallback(() => {
      history.push(getTabLink(BotTabs.home, botId));
    }, [botId, history]);

    useEffect(() => {
      if (!loading && called) {
        const values = prepareShopifyShopPopupData(data);
        onChangePreviewData(
          values.title ? values : prepareInitialValues(defaultOptInBlock),
        );
      }
    }, [data, onChangePreviewData, loading, called, defaultOptInBlock]);

    useEffect(() => {
      const values = prepareShopifyShopPopupData(data);
      if (
        !loading &&
        called &&
        !getOptInBlockDataLoading &&
        !getOptInBlockDataCalled &&
        (!values.blocks || values.blocks.length === 0)
      ) {
        getOptInBlockDataMutation({
          variables: {
            botId: botId || '',
          },
        });
      }
    }, [
      botId,
      data,
      loading,
      called,
      getOptInBlockDataCalled,
      getOptInBlockDataLoading,
      getOptInBlockDataMutation,
    ]);

    if (loading || getOptInBlockDataLoading) {
      return <CenteredLoader />;
    }

    if (!botId) {
      return null;
    }

    const shopifyShopPopupValues = prepareShopifyShopPopupData(data);

    const formInitialValue: ShopifyShopPopupInput = {
      ...prepareInitialValues(defaultOptInBlock),
      ...removeTypename(shopifyShopPopupValues),
    };

    return (
      <Formik
        initialValues={formInitialValue}
        validationSchema={Yup.object().shape({
          title: Yup.string().required(),
          description: Yup.string().required(),
        })}
        onSubmit={async (values, { setSubmitting }) => {
          try {
            sendEvent({
              category: 'cart reminders',
              action: 'save setup',
              propertyBag: {
                headline: values.title,
                description: values.description,
                'button text': SEND_TO_MESSENGER_TEXTS().find(
                  propEq('id', values.button_text),
                ),
                'send block': (values.blocks || [])
                  .map(prop('title'))
                  .join(','),
                excluded_urls: values.excluded_urls,
                trigger: values.trigger,
              },
            });
            await updateShopPopup({
              variables: {
                botId,
                shopifyShopPopup: {
                  ...values,
                  blocks: prepareBlocksData(values.blocks || []),
                },
              },
            });
            goToHomeTab();
          } catch (error) {
            toaster.show({
              type: ServiceMessageType.error,
              payload: {
                message: window.i18next.t(
                  'AbandonedCartPageMessageTab-string-6164-update-shopify-shop-popup-error',
                ),
              },
            });
            log.warn({ error, msg: 'Update Shopify Shop popup error' });
          } finally {
            setSubmitting(false);
          }
        }}
        validate={(values) => {
          // Formik hasn't global onChange or stateReduce
          onChangePreviewData(values);
          return {};
        }}
        isInitialValid
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          setFieldValue,
          isSubmitting,
          handleSubmit,
          isValid,
        }) => (
          <form onSubmit={handleSubmit}>
            <Type size="24px" weight="medium">
              {window.i18next.t(
                'AbandonedCartPageMessageTab-JSXText--193-opt-in-popup',
              )}
            </Type>
            <Spacer factor={7} />
            <Type as="div" weight="medium" size="18px">
              {window.i18next.t(
                'AbandonedCartPageMessageTab-JSXText--211-1-pop-up-content',
              )}
            </Type>
            <Spacer factor={4} />
            <GrowItemControlBox
              renderLabel={() =>
                window.i18next.t(
                  'AbandonedCartPageMessageTab-string--105-headline',
                )
              }
            >
              {({ domId }) => (
                <Focus>
                  {({ bind, focused }) => (
                    <Input
                      id={domId}
                      renderIconEnd={() =>
                        focused ? (
                          <SymbolsLimitBubble
                            value={
                              TEXTS_LIMITS.title - (values.title?.length || 0)
                            }
                          />
                        ) : null
                      }
                      value={values.title || undefined}
                      onChange={handleChange}
                      name="title"
                      error={!!errors.title && touched.title}
                      onBlur={(e) => {
                        bind.onBlur();
                        handleBlur(e);
                      }}
                      onFocus={bind.onFocus}
                      maxLength={TEXTS_LIMITS.title}
                    />
                  )}
                </Focus>
              )}
            </GrowItemControlBox>
            <GrowItemControlBox
              renderLabel={() =>
                window.i18next.t(
                  'AbandonedCartPageMessageTab-string-1694-main-text',
                )
              }
            >
              {({ domId }) => (
                <Focus>
                  {({ bind, focused }) => (
                    <Input
                      error={!!errors.description && touched.description}
                      containerClassName={css.textAreaBox}
                      render={({ getInputProps }) => (
                        <TextareaAutosize
                          {...getInputProps({
                            className: css.textArea,
                            id: domId,
                            onBlur: (
                              e: React.FocusEvent<HTMLTextAreaElement>,
                            ) => {
                              handleBlur(e);
                              bind.onBlur();
                            },
                            onFocus: bind.onFocus,
                            onChange: handleChange,
                            name: 'description',
                            value: values.description || undefined,
                            maxLength: TEXTS_LIMITS.description,
                          })}
                        />
                      )}
                      renderIconEnd={() =>
                        focused ? (
                          <SymbolsLimitBubble
                            value={
                              TEXTS_LIMITS.description -
                              (values.description?.length || 0)
                            }
                          />
                        ) : null
                      }
                      style={{}}
                    />
                  )}
                </Focus>
              )}
            </GrowItemControlBox>
            <GrowItemControlBox
              renderLabel={() =>
                window.i18next.t(
                  'AbandonedCartPageMessageTab-string--149-button-text',
                )
              }
            >
              {({ domId }) => (
                <div className={css.buttonTextCombobox}>
                  <SimpleCombobox
                    items={SEND_TO_MESSENGER_TEXTS()}
                    onChange={(selectedItem) => {
                      setFieldValue('button_text', (selectedItem || {}).id);
                    }}
                    selectedItem={SEND_TO_MESSENGER_TEXTS().find(
                      propEq('id', values.button_text),
                    )}
                    itemToString={propOr<string>('', 'title')}
                    renderInput={({ getToggleButtonProps, selectedItem }) => (
                      <Button
                        intent="secondary"
                        {...getToggleButtonProps()}
                        iconRight={<DropdownIcon />}
                        id={domId}
                        className={css.buttonTextCombobox}
                      >
                        {selectedItem ? selectedItem.title : ''}
                      </Button>
                    )}
                  />
                </div>
              )}
            </GrowItemControlBox>
            <Spacer factor={6} />
            <Type as="div" weight="medium" size="18px">
              {window.i18next.t(
                'AbandonedCartPageMessageTab-JSXText-1098-2-pop-up-trigger',
              )}
            </Type>
            <Spacer factor={4} />
            <GrowItemControlBox
              renderLabel={() =>
                window.i18next.t(
                  'AbandonedCartPageMessageTab-string--203-show-pop-up-when',
                )
              }
            >
              {() => (
                <div>
                  {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                  <label className={css.checkboxLine} htmlFor="AddToCart">
                    <CheckBox
                      id="AddToCart"
                      className={css.checkbox}
                      checked={values.trigger?.type.includes(
                        ShopifyShopPopupTriggerType.addToCart,
                      )}
                      onChange={() => {
                        setFieldValue('trigger', {
                          ...values.trigger,
                          type: changeTriggerType(
                            values.trigger?.type || '',
                            ShopifyShopPopupTriggerType.addToCart,
                          ),
                        });
                      }}
                    />
                    {window.i18next.t(
                      'AbandonedCartPageMessageTab-JSXText-1378-user-adds-a-product-to-cart',
                    )}
                  </label>
                  {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                  <label className={css.checkboxLine} htmlFor="ExitIntent">
                    <CheckBox
                      id="ExitIntent"
                      className={css.checkbox}
                      checked={values.trigger.type.includes(
                        ShopifyShopPopupTriggerType.exitIntent,
                      )}
                      onChange={() => {
                        setFieldValue('trigger', {
                          ...values.trigger,
                          type: changeTriggerType(
                            values.trigger.type,
                            ShopifyShopPopupTriggerType.exitIntent,
                          ),
                        });
                      }}
                    />
                    {window.i18next.t(
                      'AbandonedCartPageMessageTab-JSXText-1941-user-shows-exit-intent',
                    )}
                  </label>
                </div>
              )}
            </GrowItemControlBox>
            <GrowItemControlBox
              renderLabel={() => (
                <Flex alignItems="center">
                  <Type size="15px_DEPRECATED">
                    {window.i18next.t(
                      'AbandonedCartPageMessageTab-JSXText-1904-dont-show-on-these-pages',
                    )}
                  </Type>
                  <Tooltip2
                    hoverable
                    content={
                      <span>
                        {window.i18next.t(
                          'AbandonedCartPageMessageTab-JSXText-1027-use',
                        )}{' '}
                        <Type color="blue" size="15px_DEPRECATED">
                          *
                        </Type>{' '}
                        {window.i18next.t(
                          'AbandonedCartPageMessageTab-JSXText--367-to-exclude-all-pages-in-a-subdomain-e-g',
                        )}{' '}
                        <Type color="blue" size="12px">
                          {/* eslint-disable-next-line react/jsx-curly-brace-presence */}
                          {'/product/*'}
                        </Type>{' '}
                        {window.i18next.t(
                          'AbandonedCartPageMessageTab-JSXText--249-will-exclude-all-pages-in-product-directory',
                        )}
                      </span>
                    }
                  >
                    {(ref, bind) => (
                      <Flex {...bind} ref={ref} alignItems="center">
                        <InfoIcon className={css.infoIcon} />
                      </Flex>
                    )}
                  </Tooltip2>
                </Flex>
              )}
            >
              {({ domId }) => (
                <>
                  <Input
                    render={() => (
                      <BubbleEditor
                        onStringChange={(line) => {
                          setFieldValue(
                            'excluded_urls',
                            line
                              .split('\n')
                              .map((item) => item.trim())
                              .filter((item) => item?.length > 0),
                          );
                        }}
                        defaultValue={(values.excluded_urls || []).join('\n')}
                        id={domId}
                        validationErrorFunc={(item) => {
                          if (
                            item &&
                            (item.trim()[0] !== '/' ||
                              item.trim().indexOf(' ') !== -1)
                          ) {
                            return {
                              isValid: false,
                              errorMessage: window.i18next.t(
                                'AbandonedCartPageMessageTab-string--157-bad-url-please-enter-a-valid',
                              ),
                            };
                          }
                          return { isValid: true };
                        }}
                        placeholder={<UrlsPlaceholderBubble />}
                      />
                    )}
                  />
                </>
              )}
            </GrowItemControlBox>
            <Spacer factor={6} />
            <Type as="div" weight="medium" size="18px">
              {window.i18next.t(
                'AbandonedCartPageMessageTab-JSXText--104-3-send-message-in-facebook-messenger',
              )}
            </Type>
            <Spacer factor={4} />
            <GrowItemControlBox
              renderLabel={() =>
                window.i18next.t(
                  'AbandonedCartPageMessageTab-string--140-send-users-who-opt-in-to-block',
                )
              }
            >
              {() => (
                <BlocksSelectorData botId={botId}>
                  {({ blocksGroups }) => (
                    <div className={css.blocksSelectorBox}>
                      <BlocksSelector
                        platform={Platform.facebook}
                        blocksSelected={
                          values.blocks as BlockGroupsQuery_bot_blocksGroups_blocks[]
                        }
                        blocksGroups={blocksGroups as BlockWithPermissions[]}
                        onChange={(blocksSelected) => {
                          setFieldValue('blocks', blocksSelected);
                        }}
                        currentBotId={botId}
                        openBlockInNewTab
                      />
                    </div>
                  )}
                </BlocksSelectorData>
              )}
            </GrowItemControlBox>
            <Flex justifyContent="flex-end" className={css.buttonBox}>
              <Button
                intent="secondary"
                style={{ marginRight: 16 }}
                type="button"
                disabled={isSubmitting}
                onClick={() => {
                  sendEvent({
                    category: 'abandoned cart',
                    action: 'back to growth tools',
                    label: 'abandoned cart reminders',
                  });
                  goToHomeTab();
                }}
              >
                {window.i18next.t(
                  'AbandonedCartPageMessageTab-JSXText--125-back-to-growth-tools',
                )}
              </Button>
              <Button
                intent="primary"
                type="submit"
                disabled={isSubmitting || !isValid}
              >
                {window.i18next.t(
                  'AbandonedCartPageMessageTab-JSXText-1052-save',
                )}
              </Button>
            </Flex>
          </form>
        )}
      </Formik>
    );
  };
