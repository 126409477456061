import React from 'react';
import { Anchor } from '@ui/Links';
import { ButtonUnstyled } from '@ui/Button';
import { MediaMessageIcon } from '../MediaMessageIcon/MediaMessageIcon';
import { MediaMessageBox } from '../MediaMessageBox/MediaMessageBox';
import { Placeholder } from '../../helpers/getMediaMessagePlaceholders';
import * as css from './MediaMessagePlaceholder.css';
import { useIsLiveChatWebView } from '@utils/useIsLiveChatWebView';
import { sendMessageToWebView } from '@utils/sendMessageToWebView';

export interface MediaMessagePlaceholderProps extends Placeholder {
  big: boolean;
  onClick: VoidFunction;
  type?: 'incoming' | 'outgoing';
}

export const MediaMessagePlaceholder: React.FC<MediaMessagePlaceholderProps> =
  ({ onClick, big, iconName, text, type }) => {
    return (
      <ButtonUnstyled
        data-testid="live-chat__load-attachment-button"
        onClick={onClick}
        className={css.box}
      >
        <MediaMessageBox big={big} text={text} type={type} iconName={iconName}>
          {big ? (
            <MediaMessageIcon
              color="baseSub"
              icon="download"
              iconSize="24px"
              size="36px"
            />
          ) : (
            <MediaMessageIcon
              color="baseSub"
              icon="download"
              iconSize="16px"
              size="24px"
            />
          )}
        </MediaMessageBox>
      </ButtonUnstyled>
    );
  };

export interface MediaMessageOpenPlaceholderProps
  extends Omit<MediaMessagePlaceholderProps, 'onClick'> {
  url: string;
  type?: 'incoming' | 'outgoing';
  filename?: string;
}

export const MediaMessageOpenPlaceholder: React.FC<MediaMessageOpenPlaceholderProps> =
  ({ big, iconName, text, url, type, filename }) => {
    const { isLiveChatWebView } = useIsLiveChatWebView();
    return (
      <Anchor
        href={url}
        target="_blank"
        rel="noopener noreferrer"
        hideArrow
        noUnderline
        download={filename}
        onClick={(event) => {
          if (isLiveChatWebView) {
            event.preventDefault();
            sendMessageToWebView({ type: 'openURL', payload: { url } });
          }
        }}
      >
        <MediaMessageBox big={big} text={text} type={type} iconName={iconName}>
          <MediaMessageIcon
            icon="open"
            color={type === 'incoming' ? 'baseSub' : 'blue'}
            iconSize="16px"
            size="24px"
          />
        </MediaMessageBox>
      </Anchor>
    );
  };
