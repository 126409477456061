import React from 'react';
import * as css from './AttachmentSelector.css';
import { MessageAttachment } from '../../../../../hooks/LiveChatMessagesUploadingContext/types';
import { Button } from '@ui/Button';
import { MenuItem } from '@ui/Menu';
import { Flex } from '@ui/Flex';
import { Icon } from '@ui/Icon';
import { Type } from '@ui/Type';
import { MenuCombobox } from '@ui/SimpleCombobox';
import { useDeviceMedia } from '@utils/DOM/useDeviceMedia';
import {
  AttachmentsItem,
  getAttachmentItems,
} from './helpers/getAttachmentsItems';
import { sendEvent } from '@utils/Analytics';
import { Platform } from '@globals';
import { FileSelector } from '@components/FileSelector';
import { FileAttachmentType } from '@utils/UploadService/types';

interface AttachmentSelectorProps {
  onAttachmentChoose: (attachment: MessageAttachment) => void;
  disabled: boolean;
  conversationId: string;
  platform: Platform;
}

export const AttachmentSelector: React.FC<AttachmentSelectorProps> = ({
  disabled,
  conversationId,
  platform,
  onAttachmentChoose,
}) => {
  const { isSmallScreenSize } = useDeviceMedia();

  return (
    <FileSelector
      platform={platform}
      onFileChoose={({ name }, attachmentType) =>
        onAttachmentChoose({ name, type: attachmentType })
      }
      onFileProcessed={({ name, url: file, blob }, attachmentType) => {
        sendEvent({
          category: 'live chat',
          action: `${attachmentType} button click`,
          propertyBag: {
            conversationId,
            platform,
          },
        });
        onAttachmentChoose({
          type: attachmentType,
          name,
          file,
          blob,
        });
      }}
      onFileSizeExceeded={({ size }) => {
        sendEvent({
          category: 'live chat',
          action: 'choose large image',
          propertyBag: {
            size,
          },
        });
      }}
      onFileWrongFormat={([{ type }]) => {
        sendEvent({
          category: 'live chat',
          action: 'choose wrong file format',
          propertyBag: {
            format: type,
          },
        });
      }}
      multiple
      withTooltip
    >
      {({ chooseFile }) =>
        platform === Platform.whatsapp ? (
          <MenuCombobox<AttachmentsItem>
            placement="bottom-start"
            onSelect={(item) => {
              chooseFile(item!.id);
            }}
            items={getAttachmentItems()}
            renderInput={({
              popperReference: { ref },
              downshift: { getToggleButtonProps },
            }) => (
              <Button
                intent="text"
                size={isSmallScreenSize ? 'xs' : 's'}
                icon={<Icon icon="attachment2" />}
                data-testid="livechat_message-input_attachment-selector"
                {...getToggleButtonProps({ disabled })}
                ref={ref}
              />
            )}
          >
            {({
              downshift: { getItemProps, highlightedIndex },
              props: { item, index },
            }) => {
              const color = index === highlightedIndex ? 'white' : 'black';
              return (
                <MenuItem
                  active={index === highlightedIndex}
                  className={css.menuItem}
                  titleElement={
                    <Flex alignItems="center" gapFactor={2}>
                      <Icon icon={item.iconName} size="16px" color={color} />
                      <Type color={color}>{item.title}</Type>
                    </Flex>
                  }
                  key={item.id}
                  data-testid={`livechat_message-input_attachment-selector__item__${item.id}`}
                  {...getItemProps({ item })}
                />
              );
            }}
          </MenuCombobox>
        ) : (
          <Button
            intent="text"
            size={isSmallScreenSize ? 'xs' : 's'}
            icon={<Icon icon="image" />}
            data-testid="livechat_message-input_attachment-image"
            onClick={() => {
              chooseFile(FileAttachmentType.image);
            }}
          />
        )
      }
    </FileSelector>
  );
};
