import { Flex } from '@ui/Flex';
import { Spacer } from '@ui/Spacer';
import { Type } from '@ui/Type';
import React from 'react';
import * as css from './UnsupportedMessage.css';

export const UnsupportedMessage: React.FC = () => {
  return (
    <Flex className={css.unsupportedMessage} alignItems="center">
      <Spacer factor={4} />
      <Type
        color="grey"
        size="12px"
        data-testid="livechat__message-unsupported"
      >
        {window.i18next.t(
          'UnsupportedMessage-JSXText--217-unsupported-message-type',
        )}
      </Type>
      <Spacer factor={4} />
    </Flex>
  );
};
