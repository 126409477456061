import cn from 'classnames';
import OutsideClick from 'react-outsideclick';
import { Button } from '@ui/Button';
import { Flex } from '@ui/Flex';
import { Icon } from '@ui/Icon';
import { Spacer } from '@ui/Spacer';
import { Tooltip2 } from '@ui/Tooltip2';
import { usePageConnected } from '@utils/FacebookPages/usePageConnected';
import { sendEvent } from '@utils/Analytics';
import React, { useCallback, useMemo, useState } from 'react';
import { Hover } from 'react-powerplug';
import { Platform } from '@globals';
import { MESSAGE_REACTIONS } from '../../../consts';
import { MessagesQuery_livechatMessages_items_message } from '../@types/MessagesQuery';
import { MessageReaction, useMessageReaction } from '../MessageReaction';
import { ImageMessage } from './ImageMessage';
import { StoryMessage } from './StoryMessage';
import { TextMessage } from './TextMessage';
import { MessagePosition, MessageSourceType } from './types';
import { UnsupportedMessage } from './UnsupportedMessage';
import { VideoMessage } from './VideoMessage/VideoMessage';
import * as css from './InstagramMessage.css';
import { useDeviceMedia } from '@utils/DOM/useDeviceMedia';
import {
  MessageFooter,
  MessageFooterProps,
} from '../MessageTimestamp/MessageTimestamp';

interface InstagramMessageProps extends MessageFooterProps {
  botId: string;
  conversationId: string;
  position: MessagePosition;
  sourceType: MessageSourceType;
  optimistic?: boolean;
  message: MessagesQuery_livechatMessages_items_message;
  messageId: string;
  messageUrl?: string;
  withAvatar?: boolean;
}

export const getPositionClass = (
  sourceType: MessageSourceType,
  position: MessagePosition = MessagePosition.middle,
) =>
  cn({
    [css.leading]: position === MessagePosition.leading,
    [css.middle]: position === MessagePosition.middle,
    [css.trailing]: position === MessagePosition.trailing,
    [css.single]: position === MessagePosition.single,

    [css.incoming]: sourceType === MessageSourceType.incoming,
    [css.outgoing]: sourceType === MessageSourceType.outgoing,
  });

const HoverContainer: React.FC<{
  children: (payload: { bind: any; hovered: boolean }) => React.ReactNode;
}> = ({ children }) => {
  const { isSmallScreenSize } = useDeviceMedia();
  const [hovered, setHovered] = useState(false);

  return isSmallScreenSize ? (
    <OutsideClick
      className={css.outsideClick}
      onClickOutside={hovered ? () => setHovered(false) : undefined}
    >
      {children({
        hovered,
        bind: { onClick: () => setHovered((state) => !state) },
      })}
    </OutsideClick>
  ) : (
    <Hover render={children} />
  );
};

export const InstagramMessage: React.FC<InstagramMessageProps> = ({
  error,
  position,
  message,
  sourceType,
  optimistic,
  messageId,
  botId,
  conversationId,
  withAvatar,
  date,
  type,
  status,
}) => {
  const { setMessageReaction, loading: reactionLoading } = useMessageReaction({
    botId,
    messageId,
    conversationId,
    platform: Platform.instagram,
  });
  const { pageId } = usePageConnected(botId);
  const { isSmallScreenSize } = useDeviceMedia();

  const messageLikedByPage = !!message.reactions?.some(
    (v) => v.sender_id === pageId,
  );
  const positionClassName = useMemo(
    () => getPositionClass(sourceType, position),
    [sourceType, position],
  );

  const renderMessage = useCallback(() => {
    if (message.attachment?.type === 'image') {
      return (
        <MessageFooter
          error={error}
          status={status}
          date={date}
          type={type}
          asOverlay
        >
          <ImageMessage
            message={message}
            positionClassName={positionClassName}
          />
        </MessageFooter>
      );
    }
    if (message.attachment?.type === 'video') {
      return (
        <MessageFooter
          error={error}
          status={status}
          date={date}
          type={type}
          asOverlay
        >
          <VideoMessage
            message={message}
            positionClassName={positionClassName}
          />
        </MessageFooter>
      );
    }
    if (
      message.attachment?.type === 'story_reply' ||
      message.attachment?.type === 'story_mention'
    ) {
      return (
        <StoryMessage
          error={error}
          status={status}
          date={date}
          type={type}
          sourceType={sourceType}
          message={message}
          position={position}
          messageId={messageId}
          positionClassName={positionClassName}
        />
      );
    }
    if (!message.text) {
      return (
        <MessageFooter
          error={error}
          status={status}
          date={date}
          type={type}
          instagramFix
        >
          <UnsupportedMessage />
        </MessageFooter>
      );
    }
    return (
      <TextMessage
        error={error}
        date={date}
        status={status}
        type={type}
        position={position}
        text={message.text}
        sourceType={sourceType}
        positionClassName={positionClassName}
      />
    );
  }, [
    message,
    error,
    date,
    status,
    type,
    position,
    sourceType,
    positionClassName,
    messageId,
  ]);

  return (
    <HoverContainer>
      {({ bind, hovered }) => (
        <Flex
          {...bind}
          data-testid="livechat_message-hover-container"
          flexDirection="column"
          className={cn(css.messageWrapper, {
            [css.optimistic]: optimistic,
            [css.withAvatar]: withAvatar && !isSmallScreenSize,
            [css.withoutAvatar]: !withAvatar,
          })}
          alignItems={
            sourceType === MessageSourceType.incoming
              ? 'flex-start'
              : 'flex-end'
          }
        >
          <Spacer factor={1} />
          <Flex alignItems="center" className={css.message}>
            {renderMessage()}

            {hovered && !error && (
              <div
                className={cn(css.messageHoverReaction, {
                  [css.messageHoverReactionIncoming]:
                    sourceType === MessageSourceType.incoming,
                  [css.messageHoverReactionOutgoing]:
                    sourceType === MessageSourceType.outgoing,
                })}
              >
                <Tooltip2
                  content={
                    messageLikedByPage
                      ? window.i18next.t('InstagramMessage-string--175-unlike')
                      : window.i18next.t('InstagramMessage-string-2368-like')
                  }
                  placement="right"
                  type="small"
                >
                  {(ref, bind) => (
                    <Button
                      ref={ref}
                      intent="secondary"
                      size="s"
                      {...bind}
                      disabled={reactionLoading}
                      data-testid="livechat_message_like-button"
                      onClick={(e) => {
                        e.preventDefault();
                        sendEvent({
                          category: 'live chat',
                          action: 'send reaction',
                          propertyBag: {
                            messageId,
                            type: 'button click',
                            reaction: MESSAGE_REACTIONS.love,
                            unreact: messageLikedByPage,
                          },
                        });
                        setMessageReaction({
                          reaction: MESSAGE_REACTIONS.love,
                          unreact: messageLikedByPage,
                        });
                      }}
                      icon={
                        <Icon
                          icon="heart"
                          size="24px"
                          filled={!messageLikedByPage}
                        />
                      }
                    />
                  )}
                </Tooltip2>
              </div>
            )}
          </Flex>
          {message.reactions && (
            <MessageReaction
              sourceType={sourceType}
              reactions={message.reactions!}
            />
          )}
        </Flex>
      )}
    </HoverContainer>
  );
};
