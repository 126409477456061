import React, { useEffect } from 'react';
import * as css from './AudioAttachment.css';
import { MessageAttachmentProps } from '../../MessageInputAttachment';
import { Type } from '@ui/Type';
import { ButtonUnstyled } from '@ui/Button';
import cn from 'classnames';
import { RecorderState, useMediaRecorder } from './hooks/useMediaRecorder';
import { DateFormat } from '@utils/DateTime';
import { exhaustiveCheck } from '@utils/exhaustiveCheck';
import { AttachmentBase } from '../AttachmentBase';

interface AudioAttachmentProps extends MessageAttachmentProps {}

export const AudioAttachment: React.FC<AudioAttachmentProps> = ({
  onRemove,
  attachment,
  onChange,
}) => {
  const { record, stop, state, play, currentTime, audioBlob } =
    useMediaRecorder();

  useEffect(() => {
    if (state !== RecorderState.inactive) {
      return;
    }
    record().catch(() => {
      onRemove();
    });
  }, [onRemove, record, state]);

  useEffect(() => {
    if (audioBlob && attachment.active) {
      onChange?.({
        ...attachment,
        blob: audioBlob,
        active: false,
      });
    }
  }, [attachment, audioBlob, onChange]);

  return (
    <AttachmentBase onRemove={onRemove}>
      <Type
        data-testid="livechat_message-input_attachment-audio-timer"
        size="12px"
        as="div"
        className={css.time}
      >
        {DateFormat.mmss(currentTime)}
      </Type>
      <ButtonUnstyled
        data-testid="livechat_message-input_attachment-play_stop_audio"
        onClick={() => {
          switch (state) {
            case RecorderState.recording:
            case RecorderState.playing:
              stop();
              break;
            case RecorderState.dataReady:
              play();
              break;
            case RecorderState.inactive:
              break;
            default:
              exhaustiveCheck(state);
          }
        }}
        className={cn(
          css.button,
          [RecorderState.recording, RecorderState.playing].includes(state)
            ? css.stop
            : css.play,
        )}
      />
    </AttachmentBase>
  );
};
