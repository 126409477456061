import React, { useEffect } from 'react';
import Maybe from 'graphql/tsutils/Maybe';
import { usePrevious } from 'cf-common/src/utils/hooks';
import debounce from 'lodash-es/debounce';
import { Flex } from '@ui/Flex';
import { Spacer } from '@ui/Spacer';
import { Type } from '@ui/Type';
import { Icon } from '@ui/Icon';
import { Button, ButtonUnstyled } from '@ui/Button';
import { useSegmentation, DEFAULT_SEGMENTATION } from '@utils/Data/LiveChat';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { SegmentationInput, SegmentationParameterInput } from '@globals';
import { UserFilter } from '@components/FlowBuilder/EditorPanel/components/common/UserFilter';
import { UserFilterParameter } from '@components/FlowBuilder/EditorPanel/components/common/UserFilter/types';
import { useCurrentBotId } from '@utils/Routing';
import { Divider } from '@ui/Menu';
import * as css from './AttributesFilter.css';
import { useUserCount } from '@pages/BotPage/WhatsappReEngageTab/hooks/useUserCount';
import isEqual from 'lodash-es/isEqual';
import { ScrollBox } from '@ui/ScrollBox';

export interface IdentifiableUserFilterParameter extends UserFilterParameter {
  id: string;
}

export interface AttributesFilterProps {
  onClose: VoidFunction;
  onSubmit: (
    e: React.FormEvent<HTMLFormElement>,
    segmentation: SegmentationInput,
  ) => void;
  initialValues?: Maybe<SegmentationInput>;
}

export const AttributesFilter: React.FC<AttributesFilterProps> = ({
  initialValues,
  onClose,
  onSubmit,
}) => {
  const botId = useCurrentBotId()!;
  const { t } = useSafeTranslation();
  const {
    segmentation,
    removeParameterAt,
    replaceParameter,
    createNewParameter,
    changeSegmentationOperation,
  } = useSegmentation(initialValues || DEFAULT_SEGMENTATION);
  const [fetchUserCount, { data, loading }] = useUserCount();
  const prevSegmentation = usePrevious(segmentation);

  const fetchUserCountDebounced = debounce(fetchUserCount, 500);

  useEffect(() => {
    if (isEqual(prevSegmentation, segmentation)) {
      return undefined;
    }

    fetchUserCountDebounced({
      variables: {
        botId,
        withInstagram: false,
        segmentation,
      },
    });

    return fetchUserCountDebounced.cancel;
  }, [botId, fetchUserCountDebounced, prevSegmentation, segmentation]);

  const parameters = segmentation.parameters?.filter(
    Boolean,
  ) as SegmentationParameterInput[];
  const canAddMore = parameters.length < 30;

  return (
    <form
      noValidate
      className={css.wrapper}
      onSubmit={(e) => {
        e.preventDefault();
        onSubmit(e, segmentation);
      }}
    >
      <Flex justifyContent="space-between">
        <Type as="h1" size="18px" weight="medium">
          {t('pages.LiveChat.FilterableSearch.attribute.title')}
        </Type>
        <ButtonUnstyled onClick={onClose}>
          <Icon icon="close" />
        </ButtonUnstyled>
      </Flex>

      <Spacer factor={8} />

      <ScrollBox className={css.scrollBox}>
        {parameters?.map((parameter, index, arr) => (
          <UserFilter
            botId={botId}
            parameter={parameter as UserFilterParameter}
            disabled={false}
            parameters={arr as UserFilterParameter[]}
            operator={segmentation.operation!}
            savePlugin={() => null}
            savePluginAsync={() => Promise.resolve()}
            onParameterUpdate={(param) => replaceParameter(param, index)}
            onOperatorUpdate={changeSegmentationOperation}
            onRemoveFilter={() => removeParameterAt(index)}
            userAttributeTypeSelectorHidden
            invalid={false}
            disableGenericFilter={false}
            autoCheckPlatform
          />
        ))}
      </ScrollBox>

      <Spacer factor={1} />

      {canAddMore && (
        <Button
          onClick={() => createNewParameter()}
          intent="secondary"
          fullWidth
        >
          <Flex justifyContent="center" alignItems="center">
            <Icon icon="plus" />
            <Spacer factor={0} horizontalFactor={1} />
            <Type>
              {t('pages.LiveChat.FilterableSearch.attribute.addAttribute')}
            </Type>
          </Flex>
        </Button>
      )}

      <Spacer factor={4} />
      <Divider style={{ margin: 0 }} />
      <Spacer factor={4} />

      <Flex>
        <Button onClick={onClose} intent="secondary">
          {t('pages.LiveChat.FilterableSearch.attribute.cancel')}
        </Button>
        <Spacer horizontalFactor={2} factor={0} />
        <Button loading={loading} type="submit" intent="primary" fullWidth>
          {t('pages.LiveChat.FilterableSearch.attribute.search', {
            amount: data?.bot?.userCountByFilter || 0,
          })}
        </Button>
      </Flex>
    </form>
  );
};
