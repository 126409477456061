import { BotTabPlaceholder } from '@components/BotTab';
import { Icon } from '@ui/Icon';
import React from 'react';

export const MessagesEmptyStatePlaceholder: React.FC = () => {
  return (
    <BotTabPlaceholder
      icon={<Icon icon="dialog" />}
      title={window.i18next.t(
        'MessagesEmptyStatePlaceholder-string--726-no-messages-yet',
      )}
      subtitle={window.i18next.t(
        'MessagesEmptyStatePlaceholder-string--166-conversation-thread-will-appear-here',
      )}
    />
  );
};
