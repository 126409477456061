import { useCallback, useEffect } from 'react';

const values = new Map<string, string>();

/**
 *  Храним значения инпута сообщений при переключении между конверсешинами
 *  в контексте одной сессии
 */
export const useMessageInputDefaultValue = (
  conversationId: string,
): [string, (value: string) => void] => {
  const setValue = useCallback(
    (value: string) => {
      if (!value) {
        values.delete(conversationId);
        return;
      }
      values.set(conversationId, value);
    },
    [conversationId],
  );

  useEffect(() => () => {
    const value = (
      document.getElementsByName('livechatInput')?.[0] as HTMLInputElement
    )?.value;
    setValue(value);
  });

  return [values.get(conversationId) || '', setValue];
};
