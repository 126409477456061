import { Button } from '@ui/Button';
import { Spacer } from '@ui/Spacer';
import { Type } from '@ui/Type';
import { sendEvent } from '@utils/Analytics';
import { goToGetInstagramPermissions } from '@utils/FacebookPages/goToGetPermissions';
import React from 'react';
import * as css from './InstagramPermissionsBanner.css';

export const InstagramPermissionsBanner: React.FC = () => {
  return (
    <div className={css.instagramPermissionBox}>
      <Type size="15px_DEPRECATED">
        {window.i18next.t(
          'InstagramPermissionsBanner-JSXText-6319-give-permissions-to-receive-all',
        )}
        <br />
        {window.i18next.t(
          'InstagramPermissionsBanner-JSXText-7927-messages-via-instagram-direct',
        )}
      </Type>
      <Spacer factor={2} />
      <Button
        onClick={() => {
          sendEvent({
            category: 'live chat',
            action: 'get permissions click',
          });
          goToGetInstagramPermissions();
        }}
      >
        {window.i18next.t(
          'InstagramPermissionsBanner-JSXText--136-give-permissions',
        )}
      </Button>
    </div>
  );
};
