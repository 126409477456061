import { useRef } from 'react';
import { useComponentWillUnmount } from 'cf-common/src/utils/hooks';
import scrollIntoView from 'scroll-into-view-if-needed';
import * as listCSS from '../../MessagesList.css';

function isScrolledIntoView(el: Element) {
  const rect = el.getBoundingClientRect();

  const isVisible = rect.top >= 0 && rect.bottom <= window.innerHeight;

  return isVisible;
}

export const ANIMATION_MESSAGES_CONTAINER = 'messagesContainer';

export const useAnimation = () => {
  const ref = useRef<Function[]>([]);

  const clearRef = () => {
    ref.current.forEach((cb) => cb());
    ref.current = [];
  };

  useComponentWillUnmount(clearRef);

  const runAnimation = (messageId: string) => {
    clearRef();

    const messageElement = document.querySelector(`[data-mid='${messageId}']`);
    if (!messageElement) {
      return;
    }

    const clearClasses = () => {
      messageElement.classList.remove(listCSS.messageContainer);
      messageElement.classList.remove(listCSS.hide);
    };

    const container = document.getElementById(ANIMATION_MESSAGES_CONTAINER);
    if (!container) {
      return;
    }

    clearClasses();

    const startFadeAnimation = () => {
      messageElement.classList.add(listCSS.hide);
      const finishingAnimationTimer = setTimeout(() => {
        clearRef();
        clearClasses();
      }, 1000);
      ref.current.push(() => clearTimeout(finishingAnimationTimer));
    };

    // Если спустя 300 мс, messageElement во вью, то до него, скорее всего,
    // не надо было скроллить. Поэтому надо проиграть анимацию вручную
    const timer = setTimeout(() => {
      if (isScrolledIntoView(messageElement)) {
        startFadeAnimation();
      }
    }, 300);
    ref.current.push(() => clearTimeout(timer));

    container.addEventListener('scrollend', startFadeAnimation);
    ref.current.push(() =>
      container.removeEventListener('scrollend', startFadeAnimation),
    );

    messageElement.classList.add(listCSS.messageContainer);
    scrollIntoView(messageElement, {
      block: 'center',
      scrollMode: 'if-needed',
      behavior: 'smooth',
    });
  };

  return { runAnimation };
};
