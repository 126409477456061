import React from 'react';
import { getMediaMessagePlaceholders } from '../../helpers/getMediaMessagePlaceholders';
import { CommonAttachment } from './CommonAttachment';
import { AttachmentProps } from './types';
import * as css from './VideoAttachment.css';
import { HoverDisclosure } from '@ui/HoverDisclosure';
import cn from 'classnames';

export const VideoAttachment: React.FC<AttachmentProps> = ({
  className,
  ...props
}) => {
  const placeholder = getMediaMessagePlaceholders().video;

  return (
    <CommonAttachment {...props} placeholder={placeholder}>
      {(url) => (
        <HoverDisclosure
          render={({ isVisible, bind }) => (
            // eslint-disable-next-line jsx-a11y/media-has-caption
            <video
              controls={isVisible}
              className={cn(className, css.video)}
              src={url}
              {...bind}
            />
          )}
        />
      )}
    </CommonAttachment>
  );
};
