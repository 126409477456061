import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { Spacer } from '@ui/Spacer';
import { Flex } from '@ui/Flex';
import { Type } from '@ui/Type';
import { FacebookAdAccountSelectableTable } from '../components';
import { GrowSubPage, GrowSubPageTitle } from '../../common/GrowSubPage';
import { useCurrentBotId } from '../../../../utils/Routing';
import { sendEvent } from '../../../../utils/Analytics';

export const ClickToMessengerFullCampaignsListPage: React.FC = () => {
  const botId = useCurrentBotId();
  const history = useHistory();
  const goToGrow = useCallback(() => {
    history.push(`/bot/${botId}/grow`);
    sendEvent({
      category: 'ads manager',
      action: 'back to growth tools',
    });
  }, [botId, history]);

  return (
    <GrowSubPage>
      <GrowSubPageTitle
        breadcrumbs={[
          {
            title: window.i18next.t(
              'ClickToMessengerFullCampaignsListPage-string-3147-growth-tools',
            ),
            onClick: goToGrow,
          },
        ]}
      >
        <Flex alignItems="center" justifyContent="space-between">
          {window.i18next.t(
            'ClickToMessengerFullCampaignsListPage-JSXText--850-click-to-messenger-and-sponsored-messages-campaigns',
          )}
        </Flex>
      </GrowSubPageTitle>
      <Flex alignItems="center" justifyContent="space-between">
        <div style={{ width: 520 }}>
          <Type color="greyDark" size="15px_DEPRECATED">
            {window.i18next.t(
              'ClickToMessengerFullCampaignsListPage-JSXText--148-start-conversations-at-scale-leveraging-facebook-targeting-to-find-customers-relevant-to-your-business',
            )}
          </Type>
        </div>
      </Flex>
      <Spacer factor={6} />
      <FacebookAdAccountSelectableTable
        botId={botId || ''}
        goToGrow={goToGrow}
      />
    </GrowSubPage>
  );
};
