import React from 'react';
import ng from 'angular';
import { ApolloProvider } from '@apollo/react-common';
import { react2angular } from 'react2angular';
import { Router } from 'react-router-dom';
import {
  CommentsAutoreplyRuleDialog,
  CommentsAutoreplyDialogProps,
} from './CommentsAutoreplyRuleDialog';
import { Modal } from '../../../modern-ui/Modal';
import client from '../../../common/services/ApolloService';
import { globalHistory } from '../../../utils/Routing';

const CommentsAutoreplyPopup: React.FC<CommentsAutoreplyDialogProps> = (
  props,
) => (
  <ApolloProvider client={client}>
    <Router history={globalHistory}>
      <Modal onDismiss={props.onRequestClose}>
        <CommentsAutoreplyRuleDialog {...props} />
      </Modal>
    </Router>
  </ApolloProvider>
);

export const ngCommentsAutoreplyPopup = ng
  .module('app.modernComponents.commentsAutoreplyPopup', [])
  .component(
    'commentsAutoreplyPopup',
    react2angular(
      CommentsAutoreplyPopup,
      ['initCommentsAutoreplyRule', 'position', 'botId', 'onRequestClose'],
      [],
    ),
  ).name;
