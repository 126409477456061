import React from 'react';
import cx from 'classnames';
import * as s from './MessageContentShape.css';

export interface MessageContentShapeProps
  extends React.HTMLProps<HTMLDivElement> {
  type: 'incoming' | 'outgoing';
  leading?: boolean;
  trailing?: boolean;
  capped?: boolean;
  colored?: boolean;
}

export const MessageContentShape: React.FC<MessageContentShapeProps> = ({
  type,
  leading,
  trailing,
  capped = false,
  className,
  colored,
  ...rest
}) => (
  <div
    className={cx(s.root, className, {
      [s.incoming]: type === 'incoming',
      [s.outgoing]: type === 'outgoing',
      [s.leading]: leading,
      [s.trailing]: trailing,
      [s.capped]: capped,
      [s.colored]: colored,
    })}
    {...rest}
  />
);
