import React from 'react';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { AnchorUnstyled } from '@ui/Links';
import { ReactComponent as ChatfuelLogo } from '@ui/Icon/icons/chatfuel-logo.svg';
import { ReactComponent as MetaCircle } from '@ui/Icon/icons/social/MetaCircle.svg';
import { ReactComponent as WhatsappCircle } from '@ui/Icon/icons/social/Whatsapp.svg';
import { ReactComponent as PlayCircle } from './PlayCircle.svg';
import { HomeBanner } from './HomeBanner/HomeBanner';
import { sendHomeTabEvent } from '../../BotPage/HomeTab/utils';
import * as css from './HomeBannersGroup.css';
import { useBotAllowedPlatforms } from '@utils/Platform/useBotAllowedPlatforms';
import { useCurrentBotId } from '@utils/Routing';
import { Platform } from '@globals';

export const HomeBannersGroup: React.FC = () => {
  const { t } = useSafeTranslation();
  const botId = useCurrentBotId();
  const { allowedPlatforms } = useBotAllowedPlatforms(botId);

  return (
    <div className={css.bannersContainer}>
      <AnchorUnstyled
        href="https://chatfuel.com/blog"
        target="_blank"
        data-testid="banner__blog-link"
        onClick={() => sendHomeTabEvent({ action: 'blog banner click' })}
      >
        <HomeBanner
          logo={<ChatfuelLogo width="40" height="40" />}
          title={t('pages.GrowPage.HomeBanners.blogTitle')}
          content={t('pages.GrowPage.HomeBanners.blogContent')}
        />
      </AnchorUnstyled>

      {allowedPlatforms?.includes(Platform.facebook) && (
        <AnchorUnstyled
          href="https://fb.com/groups/chatfuel/"
          target="_blank"
          data-testid="banner__facebook-community"
          onClick={() =>
            sendHomeTabEvent({ action: 'facebook community banner click' })
          }
        >
          <HomeBanner
            logo={<MetaCircle width="40" height="40" />}
            title={t('pages.GrowPage.HomeBanners.fbGroupTitle')}
            content={t('pages.GrowPage.HomeBanners.fbGroupContent')}
            color="white"
            className={css.fbGroupBanner}
          />
        </AnchorUnstyled>
      )}

      {allowedPlatforms?.includes(Platform.whatsapp) && (
        <AnchorUnstyled
          href="https://chat.whatsapp.com/GcQhqdK4mIjJyEBjRQUCW0"
          target="_blank"
          data-testid="banner__whatsapp-community"
          onClick={() =>
            sendHomeTabEvent({ action: 'whatsapp community banner click' })
          }
        >
          <HomeBanner
            logo={<WhatsappCircle width="40" height="40" />}
            title={t('pages.GrowPage.HomeBanners.waGroupTitle')}
            content={t('pages.GrowPage.HomeBanners.waGroupContent')}
            color="accent2Text"
            className={css.waGroupBanner}
          />
        </AnchorUnstyled>
      )}

      <AnchorUnstyled
        href="https://www.youtube.com/channel/UC1_z5c5UnMJ0waRJ8Bx1cFg"
        target="_blank"
        data-testid="banner__youtube-channel"
        onClick={() =>
          sendHomeTabEvent({ action: 'youtube channel banner click' })
        }
      >
        <HomeBanner
          logo={<PlayCircle width="40" height="40" />}
          title={t('pages.GrowPage.HomeBanners.youtubeTitle')}
          content={t('pages.GrowPage.HomeBanners.youtubeContent')}
          color="white"
          className={css.youtubeBanner}
        />
      </AnchorUnstyled>
    </div>
  );
};
