import gql from 'graphql-tag';

export const MANAGE_SUBSCRIPTIONS_DIALOG_QUERY = gql`
  query ManageSubscriptionsDialogQuery($botId: String!) {
    me {
      id
      roles {
        bot_id
        role_id
      }
    }
    bot(id: $botId) {
      id
      status {
        page
      }
      livechatSubscribers {
        id
        messengerId
        name
        subscribed
        picture
        role
      }
    }
  }
`;

export const CHANGE_NOTIFICATIONS_SUBSCRIPTION_MUTATION = gql`
  mutation ChangeNotificationsSubscriptionMutation(
    $botId: String!
    $adminId: String!
    $subscribed: Boolean!
  ) {
    changeNotificationsSubscription(
      botId: $botId
      adminId: $adminId
      subscribed: $subscribed
    )
  }
`;
