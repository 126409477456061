import {
  ServerStorageItemKeys,
  serverStorageItemGet,
} from '@utils/ServerStorage';
import throttle from 'lodash-es/throttle';
import { playNotificationSound } from '@utils/Audio/playNotificationSound';

export const playLiveChatNotificationSound = throttle(
  async (botId?: string) => {
    if (!document.hasFocus() && botId) {
      const isNotificationsDisable = await serverStorageItemGet(
        ServerStorageItemKeys.isLiveChatNotificationSoundsDisabled,
      );
      if (!isNotificationsDisable?.[botId]) {
        return playNotificationSound();
      }
      return undefined;
    }
    return undefined;
  },
  1000,
);
