import gql from 'graphql-tag';
import { QueryHookOptions, useQuery } from '@apollo/react-hooks';
import {
  FacebookAdCampaignsBasicQuery,
  FacebookAdCampaignsBasicQueryVariables,
} from './@types/FacebookAdCampaignsBasicQuery';

const FACEBOOK_AD_CAMPAIGNS_BASIC_QUERY = gql`
  query FacebookAdCampaignsBasicQuery($botId: String!, $accountId: String!) {
    bot(id: $botId) {
      id
      facebookAdCampaigns(accountId: $accountId) {
        id
        name
        type
      }
    }
  }
`;

export const useFacebookAdCampaignsBasicListQuery = (
  options: QueryHookOptions<
    FacebookAdCampaignsBasicQuery,
    FacebookAdCampaignsBasicQueryVariables
  >,
) =>
  useQuery<
    FacebookAdCampaignsBasicQuery,
    FacebookAdCampaignsBasicQueryVariables
  >(FACEBOOK_AD_CAMPAIGNS_BASIC_QUERY, options);
