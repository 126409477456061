import React from 'react';
import cn from 'classnames';
import { Flex } from '@ui/Flex';
import { Spacer } from '@ui/Spacer';
import { Type } from '@ui/Type';
import { Icon, IconProps } from '@ui/Icon';
import { Tooltip2, Tooltip2Props } from '@ui/Tooltip2';
import { ColorKey } from '@ui/_common/colors';
import { DateFormat } from '@utils/DateTime';
import * as css from './MessageTimestamp.css';
import { MessageWarningWrapper } from '../Message/MessageRow/MessageWarning';
import { MessageError, MessageTypeKey } from '../Message/MessageRow';
import { MessageType } from '../Message/MessageRow/types';
import { WhatsappStatusEnum } from '@globals';
import Maybe from 'graphql/tsutils/Maybe';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { DeliveredStatusThemeType, getColorTheme } from './consts';
import { exhaustiveCheck } from '@utils/exhaustiveCheck';

interface DeliveredStatusTooltipProps extends Pick<Tooltip2Props, 'content'> {}

const DeliveredStatusTooltip: React.FC<DeliveredStatusTooltipProps> = ({
  children,
  content,
}) => (
  <Tooltip2 placement="bottom" type="small" hoverable content={content}>
    {(ref, bind) => (
      <div ref={ref} {...bind}>
        {children}
      </div>
    )}
  </Tooltip2>
);

export interface MessageTimestampProps {
  date?: number;
  color: ColorKey;
}

export const MessageTimestamp: React.FC<MessageTimestampProps> = ({
  date,
  color,
}) => {
  if (!date) {
    return null;
  }

  return (
    <Type size="12px" color={color} data-testid="message-timestamp">
      {DateFormat.LT(date)}
    </Type>
  );
};

interface DeliveredStatusViewProps {
  status: WhatsappStatusEnum;
  size: IconProps['size'];
  theme: DeliveredStatusThemeType;
  error?: MessageError;
  customReadColor?: ColorKey;
}

export const DeliveredStatusView: React.FC<DeliveredStatusViewProps> = ({
  status,
  error,
  size,
  theme,
  customReadColor,
}) => {
  const { t } = useSafeTranslation();

  const colors = getColorTheme(theme);

  switch (status) {
    case WhatsappStatusEnum.failed:
      return (
        <MessageWarningWrapper
          messageId={error?.messageId!}
          errorContent={error?.content}
          forceTooltip={error?.forceTooltip}
          placement="bottom"
        >
          <Icon
            icon="circleWarning"
            size={size}
            color={colors.failed}
            data-testid="message-status-icon__failed"
          />
        </MessageWarningWrapper>
      );
    case WhatsappStatusEnum.pending:
      return (
        <DeliveredStatusTooltip
          content={t('pages.LiveChat.Message.messageStatus.pending')}
        >
          <Icon icon="clock" size={size} color={colors.pending} />
        </DeliveredStatusTooltip>
      );
    case WhatsappStatusEnum.sent:
      return (
        <DeliveredStatusTooltip
          content={t('pages.LiveChat.Message.messageStatus.sent')}
        >
          <Icon
            icon="check"
            size={size}
            color={colors.sent}
            data-testid="message-status-icon__sent"
          />
        </DeliveredStatusTooltip>
      );
    case WhatsappStatusEnum.delivered:
      return (
        <DeliveredStatusTooltip
          content={t('pages.LiveChat.Message.messageStatus.delivered')}
        >
          <Icon
            icon="delivered"
            size={size}
            color={colors.delivered}
            data-testid="message-status-icon__delivered"
          />
        </DeliveredStatusTooltip>
      );
    case WhatsappStatusEnum.read:
      return (
        <DeliveredStatusTooltip
          content={t('pages.LiveChat.Message.messageStatus.read')}
        >
          <Icon
            icon="delivered"
            size={size}
            color={customReadColor || colors.read}
            data-testid="message-status-icon__read"
          />
        </DeliveredStatusTooltip>
      );
    default:
      exhaustiveCheck(status);
      return null;
  }
};

export interface MessageFooterProps
  extends Omit<MessageTimestampProps, 'color'>,
    Omit<DeliveredStatusViewProps, 'size' | 'status' | 'theme'> {
  status: Maybe<DeliveredStatusViewProps['status']>;
  type: MessageTypeKey;
  asOverlay?: boolean;
  hideFooter?: boolean;
  testing?: boolean;
  // Удалить пропсу и одноименное имя класса когда все платформы в лайвчате будут приведены к одному виду
  instagramFix?: boolean;
  // Удалить пропсу когда audio сообщения будут рисоваться нашим собственным компонентом
  isAudio?: boolean;
  noAccent?: boolean;
}

export const MessageFooter: React.FC<MessageFooterProps> = ({
  date,
  type,
  status,
  asOverlay = false,
  testing = false,
  instagramFix = false,
  error,
  isAudio,
  hideFooter,
  children,
  noAccent,
}) => {
  if (testing) {
    return <>{children}</>;
  }

  const customColor = isAudio ? 'baseSemilight' : undefined;
  const color: ColorKey =
    customColor || type === MessageType.incoming || noAccent
      ? ('cobaltTertiary' as const)
      : ('accent1Tertiary' as const);
  const showStatus = type === MessageType.outgoing && status;

  return (
    <Flex flexDirection="column" className={cn(css.wrapper)}>
      {children}

      {!asOverlay && !hideFooter && <Spacer factor={3} />}

      <Flex
        justifyContent="flex-end"
        alignItems="center"
        className={cn(
          css.box,
          asOverlay && css.colored,
          instagramFix && css.instagramFix,
        )}
      >
        <MessageTimestamp date={date} color={color} />
        {showStatus && (
          <>
            <Spacer factor={0} horizontalFactor={1} />
            <DeliveredStatusView
              status={status}
              error={error}
              size="12px"
              customReadColor={customColor}
              theme={DeliveredStatusThemeType.messagesList}
            />
          </>
        )}
      </Flex>
    </Flex>
  );
};
