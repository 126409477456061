import { ButtonUnstyled } from '@ui/Button';
import { Flex } from '@ui/Flex';
import { Icon } from '@ui/Icon';
import { Spacer } from '@ui/Spacer';
import { sendEvent } from '@utils/Analytics';
import cn from 'classnames';
import React, { useEffect, useRef } from 'react';
import { useLiveChatMessagesUploadingContext } from '../../../hooks/LiveChatMessagesUploadingContext/LiveChatMessagesUploadingContext';
import {
  SendingMessage,
  SendingMessageStatus,
} from '../../../hooks/LiveChatMessagesUploadingContext/types';
import { getPositionClass } from '../InstagramMessage';
import { MessagePosition, MessageSourceType } from '../InstagramMessage/types';
import * as css from './UploadingAttachmentPlaceholder.css';
import { FileAttachmentType } from '@utils/UploadService/types';

interface UploadingAttachmentPlaceholderProps {
  message: SendingMessage;
  position: MessagePosition;
  conversationId: string;
}

export const UploadingAttachmentPlaceholder: React.FC<UploadingAttachmentPlaceholderProps> = ({
  message,
  position,
  conversationId,
}) => {
  const messageRef = useRef<HTMLDivElement | null>(null);
  const {
    uploadingProgress,
    cancelMessage,
    retryMessage,
  } = useLiveChatMessagesUploadingContext();

  useEffect(() => {
    if (messageRef.current) {
      messageRef.current?.scrollIntoView({ behavior: 'smooth' });
    }
  }, []);

  if (conversationId !== message.conversationId || !message.attachment?.file) {
    return null;
  }

  if (message.attachment?.type === FileAttachmentType.image) {
    return (
      <div
        ref={messageRef}
        className={cn(
          css.sendingContainer,
          css.imageMessageContainer,
          getPositionClass(MessageSourceType.outgoing, position),
          {
            [css.error]: message.status === SendingMessageStatus.error,
          },
        )}
      >
        <img
          data-testid="livechat_message-uploading_image"
          src={message.attachment.file}
          alt="uploading"
          className={cn(css.imageMessageContainer, css.image)}
        />
        <Flex
          alignItems="center"
          justifyContent="center"
          className={css.buttonContainer}
        >
          {message.status === SendingMessageStatus.error && (
            <>
              <ButtonUnstyled
                data-testid="livechat_message-uploading_image-retry_button"
                onClick={() => {
                  sendEvent({
                    category: 'live chat',
                    action: 'retry send attachment',
                    propertyBag: {
                      conversationId,
                    },
                  });
                  retryMessage(message.id);
                }}
                className={css.button}
              >
                <Icon icon="restart" color="white" />
              </ButtonUnstyled>
              <Spacer horizontalFactor={4} />
            </>
          )}
          {!(
            message.status === SendingMessageStatus.uploading &&
            uploadingProgress === 100
          ) && (
            <ButtonUnstyled
              data-testid="livechat_message-uploading_image-cancel_button"
              onClick={() => {
                sendEvent({
                  category: 'live chat',
                  action: 'cancel send attachment',
                  propertyBag: {
                    conversationId,
                  },
                });
                cancelMessage(message.id);
              }}
              className={css.button}
            >
              <Icon icon="close" color="white" />
            </ButtonUnstyled>
          )}
        </Flex>
        <div className={css.loaderLine}>
          {message.status === SendingMessageStatus.uploading && (
            <div
              className={css.loader}
              style={{ width: `${uploadingProgress ?? 0}%` }}
            />
          )}
        </div>
      </div>
    );
  }
  return null;
};
