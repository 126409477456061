import React from 'react';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { Flex } from '@ui/Flex';
import { Anchor } from '@ui/Links';
import { Type } from '@ui/Type';
import { sendEvent } from '@utils/Analytics';
import { getTabLink, BotTabs } from '@utils/Routing';
import { useIsLiveChatWebView } from '@utils/useIsLiveChatWebView';
import * as css from './SentBlockMessage.css';

interface SentBlockMessageProps {
  title: string;
  bot_id: string;
  block_id: string | null;
  flow_id: string | null;
}

export const SentBlockMessage: React.FC<SentBlockMessageProps> = ({
  title,
  bot_id: botId,
  block_id: blockId,
  flow_id: flowId,
}) => {
  const { t } = useSafeTranslation();
  const { isLiveChatWebView } = useIsLiveChatWebView();

  if (isLiveChatWebView) return null;

  return (
    <Flex
      data-testid="livechat__sent-block-message"
      className={css.messageContainer}
      justifyContent="center"
    >
      <Type size="12px" color="greyDark">
        {t('pages.LiveChat.Message.SentBlockMessage.sentTo')}
        <Anchor
          data-testid="livechat__sent-block-anchor"
          href={
            blockId
              ? getTabLink(BotTabs.automate, botId, {
                  blockId: blockId ?? undefined,
                })
              : getTabLink(BotTabs.flows, botId, {
                  flowId: flowId ?? undefined,
                })
          }
          style={{ color: 'var(--greyDark)' }}
          onMouseDown={() => {
            sendEvent({
              category: 'live chat',
              action: `open sent ${blockId ? 'block' : 'flow'} href`,
              propertyBag: {
                botId,
                flowId,
                blockId,
              },
            });
          }}
        >
          {title}
        </Anchor>
      </Type>
    </Flex>
  );
};
