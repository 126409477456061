import React from 'react';
import cx from 'classnames';
import { Type } from '@ui/Type';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { MessageTypeKey } from './types';
import { MessageWarning } from './MessageWarning';
import * as s from './MessageRow.css';

interface MessageRowProps extends React.HTMLProps<HTMLDivElement> {
  type?: MessageTypeKey;
  preceding?: boolean;
  renderMedia?: () => React.ReactNode;
  padStart?: number;
  padEnd?: number;
  isSmallScreenSize?: boolean;
  error?: MessageError;
}

export interface MessageError {
  messageId: string;
  content: React.ReactNode;
  forceTooltip: boolean;
}

export const MessageRow: React.FC<MessageRowProps> = ({
  preceding = false,
  type,
  padStart = 0,
  padEnd = 0,
  renderMedia,
  children,
  isSmallScreenSize,
  error,
  ...domAttrs
}) => {
  const { t } = useSafeTranslation();

  const commonClassNames = cx(s.row, {
    [s.isPrecedingMessageRow]: preceding,
    [s.incoming]: type === 'incoming',
    [s.outgoing]: type === 'outgoing',
    [s.root_mobile]: isSmallScreenSize,
  });

  return (
    <>
      <div
        data-testid={`livechat_message_row_${type}`}
        {...domAttrs}
        style={{
          paddingLeft: padStart,
          paddingRight: padEnd,
        }}
        className={cx(commonClassNames, {
          [s.hasError]: !!error,
        })}
      >
        {type === 'outgoing' && error && (
          <MessageWarning
            messageId={error.messageId}
            type={type}
            forceTooltip={error.forceTooltip}
            errorContent={error.content}
          />
        )}
        {type === 'incoming' && renderMedia ? renderMedia() : null}
        {children}
        {type === 'outgoing' && renderMedia ? renderMedia() : null}
      </div>
      {error && (
        <div className={cx(commonClassNames, s.errorText)}>
          <Type color="red" size="12px">
            {t('pages.LiveChat.Message.rejected.failedSendMessage')}
          </Type>
        </div>
      )}
    </>
  );
};
