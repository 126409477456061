import { ServerStorageItemKeys, useServerStorage } from '@utils/ServerStorage';
import { useCallback } from 'react';

export const useCopilotPromoBanner = () => {
  const [isHiddenResponse, hideMutation] = useServerStorage(
    ServerStorageItemKeys.copilotPromoBanner,
  );

  const hide = useCallback(async () => {
    await hideMutation(true);
  }, [hideMutation]);

  return {
    isHidden: typeof isHiddenResponse === 'boolean' ? isHiddenResponse : true,
    hide,
  };
};
