import { useMemo } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { VariablesType, VariableSuggestType } from '@globals';
import { VARIABLE_SUGGEST_QUERY } from '../queries';
import {
  VariableSuggest,
  VariableSuggestVariables,
} from '../@types/VariableSuggest';

export const useAttributeSuggests = (
  botId: string,
  readOnly: boolean,
): string[] => {
  const suggestsQueryResult = useQuery<
    VariableSuggest,
    VariableSuggestVariables
  >(VARIABLE_SUGGEST_QUERY, {
    skip: readOnly,
    variables: {
      botId,
      suggestType: VariableSuggestType.template,
      type: VariablesType.custom,
    },
  });

  return useMemo(() => {
    const { data, loading, error } = suggestsQueryResult;
    const dataIsNotReady = error || loading || !data;
    return readOnly || dataIsNotReady
      ? []
      : data!.bot.variableSuggest.map((x) => x.name);
  }, [suggestsQueryResult, readOnly]);
};
