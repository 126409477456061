import React from 'react';
import cn from 'classnames';
import { Flex } from '@ui/Flex';
import { Icon } from '@ui/Icon';
import { Type } from '@ui/Type';
import { TextEllipsis } from '@ui/TextEllipsis';
import { Placeholder } from '../../helpers/getMediaMessagePlaceholders';
import * as css from './MediaMessageBox.css';

export interface MediaMessageBoxProps extends Omit<Placeholder, 'expired'> {
  big: boolean;
  type?: 'incoming' | 'outgoing';
}

export const MediaMessageBox: React.FC<MediaMessageBoxProps> = ({
  big,
  text,
  iconName,
  children,
  type,
}) => {
  const color = type === 'incoming' ? 'baseSub' : 'white';
  return (
    <Flex
      justifyContent={big ? 'center' : 'space-between'}
      alignItems="center"
      className={cn(css.box, big && css.bigBox)}
    >
      <Flex
        gapFactor={1}
        alignItems="center"
        className={cn(css.subBox, big && css.placeholderNote)}
      >
        <Icon icon={iconName} size="16px" color={color} />
        <Type className={css.textBox} size="12px" color={color}>
          <TextEllipsis className={css.text} title={text}>
            {text}
          </TextEllipsis>
        </Type>
      </Flex>

      {children}
    </Flex>
  );
};
