import React from 'react';
import cx from 'classnames';
import * as s from './MessageButtons.css';
import { IconNames } from '@ui/Icon/icons';
import { Flex } from '@ui/Flex';
import { Icon } from '@ui/Icon';
import Maybe from 'graphql/tsutils/Maybe';
import { Tooltip2 } from '@ui/Tooltip2';
import { Anchor } from '@ui/Links';

export interface MessageButtonType {
  text: string;
  icon?: IconNames;
  url?: Maybe<string>;
  isClickable?: boolean;
}
export type MessageButtonsType = MessageButtonType[];

interface Props {
  buttons: MessageButtonsType;
  testing?: boolean;
  onButtonClick?(index: number, text: string): void;
}

export const MessageButtons: React.FC<Props> = ({
  buttons,
  testing,
  onButtonClick,
}) => (
  <div className={cx(s.buttons, { [s.testing]: testing })}>
    {buttons!.map(({ text: buttonText, url, icon, isClickable }, index) => (
      // eslint-disable-next-line jsx-a11y/click-events-have-key-events
      <Tooltip2
        // eslint-disable-next-line react/no-array-index-key
        key={index}
        hoverable
        type="small"
        disabled={!url}
        placement="bottom"
        boundariesElement="viewport"
        content={
          <Anchor
            intent="internal"
            target="_blank"
            href={url!}
            className={s.anchor}
          >
            {url}
          </Anchor>
        }
      >
        {(ref, bind) => (
          <div ref={ref} {...bind}>
            <Flex
              className={cx(s.button, {
                [s.buttonTopDivider]: index > 0,
                [s.testing]: testing,
                [s.clickable]: isClickable,
              })}
              onClick={() => onButtonClick?.(index, buttonText)}
              justifyContent="center"
              data-testid="livechat-message__button"
            >
              <Flex gapFactor={1} alignItems="center">
                {icon && <Icon icon={icon} color="blue" />}
                {buttonText}
              </Flex>
            </Flex>
          </div>
        )}
      </Tooltip2>
    ))}
  </div>
);
