import gql from 'graphql-tag';
import { useQuery } from 'react-apollo';
import {
  LivechatAdminsQuery,
  LivechatAdminsQueryVariables,
  LivechatAdminsQuery_bot_livechatAdmins,
} from './@types/LivechatAdminsQuery';
import { useMemo } from 'react';

export type LivechatAdmin = LivechatAdminsQuery_bot_livechatAdmins;

export const LIVECHAT_ADMINS_QUERY = gql`
  query LivechatAdminsQuery($botId: String!) {
    bot(id: $botId) {
      id
      livechatAdmins {
        id
        name
        picture
      }
    }
  }
`;

export const useLiveChatAdmins = (botId: string) => {
  const { data, loading, error } = useQuery<
    LivechatAdminsQuery,
    LivechatAdminsQueryVariables
  >(LIVECHAT_ADMINS_QUERY, {
    variables: { botId },
  });

  const livechatAdmins = data?.bot.livechatAdmins;
  const admins = useMemo(
    () => (livechatAdmins || []).filter((a) => a.name),
    [livechatAdmins],
  );

  return {
    admins,
    loading,
    error,
  };
};
