import React from 'react';
import {
  MessageAttachment,
} from '../../../hooks/LiveChatMessagesUploadingContext/types';
import { ImageAttachment } from './components/ImageAttachment';
import { AudioAttachment } from './components/AudioAttachment';
import { FileAttachment } from './components/FileAttachment';
import { FileAttachmentType } from '@utils/UploadService/types';

export interface MessageAttachmentProps {
  attachment: MessageAttachment;
  onRemove: () => void;
  onChange?: (attachment: MessageAttachment) => void;
}

export const MessageInputAttachment: React.FC<MessageAttachmentProps> = ({
  attachment,
  onRemove,
  onChange,
}) => {
  switch (attachment.type) {
    case FileAttachmentType.image:
      return <ImageAttachment attachment={attachment} onRemove={onRemove} />;
    case FileAttachmentType.file:
      return <FileAttachment attachment={attachment} onRemove={onRemove} />;
    case FileAttachmentType.audio:
      return (
        <AudioAttachment
          attachment={attachment}
          onRemove={onRemove}
          onChange={onChange}
        />
      );
    default:
      return null;
  }
};
