import React from 'react';
import cx from 'classnames';
import * as s from './MessageExtraShape.css';

interface Props extends React.HTMLProps<HTMLDivElement> {
  type: 'incoming' | 'outgoing';
  trailing?: boolean;
  testing?: boolean;
}

export const MessageExtraShape: React.FC<Props> = ({
  type,
  trailing,
  testing,
  className,
  ...rest
}) => (
  <div
    className={cx(s.root, className, {
      [s.joinBottomStart]: type === 'incoming' && !trailing,
      [s.joinBottomEnd]: type === 'outgoing' && !trailing,
      [s.testing]: testing,
    })}
    {...rest}
  />
);

export const Divider: React.FC = () => <div className={s.divider} />;
