import { useConnectKommo } from '@utils/Data/Kommo/useConnectKommo';
import React, { useEffect, useMemo, useRef } from 'react';
import { KOMMO_OAUTH_PARAMS } from './consts';
import { useSearchParams } from '@utils/UrlUtils/useSearchParams';
import { useHistory } from 'react-router-dom';
import { BotTabs, getTabLink } from '@utils/Routing';
import { log } from 'cf-common/src/logger';

export const KommoConnectPage: React.FC = () => {
  const errorSendedRef = useRef(false);
  const { searchParams } = useSearchParams();

  const { botId, code, referer, clientId } = useMemo(() => {
    const state = searchParams.get(KOMMO_OAUTH_PARAMS.STATE);
    const code = searchParams.get(KOMMO_OAUTH_PARAMS.CODE);
    const referer = searchParams.get(KOMMO_OAUTH_PARAMS.REFERER);
    const clientId = searchParams.get(KOMMO_OAUTH_PARAMS.CLIENT_ID);

    let botId: string | undefined;

    if (state) {
      try {
        ({ botId } = JSON.parse(state));
      } catch (error) {
        if (!errorSendedRef.current) {
          errorSendedRef.current = true;
          log.error({
            error,
            msg: 'invalid state data in kommo connect oauth url',
          });
        }
      }
    }

    return { botId, code, referer, clientId };
  }, [searchParams]);

  const history = useHistory();

  const [connectKommo, { called }] = useConnectKommo({
    onCompleted() {
      history.push(getTabLink(BotTabs.configure, botId));
    },
  });

  useEffect(() => {
    if (called) return;
    if (botId && code && referer && clientId)
      connectKommo({
        variables: {
          botId,
          code,
          referer,
          clientId,
        },
      });
    else history.push(getTabLink(BotTabs.home, botId));
  }, [connectKommo, botId, code, referer, clientId, history, called]);

  return null;
};
