import { ButtonUnstyled } from '@ui/Button';
import { Icon } from '@ui/Icon';
import { Type } from '@ui/Type';
import { ServerStorageItemKeys, useServerStorage } from '@utils/ServerStorage';
import React, { useCallback } from 'react';
import { Trans } from 'react-i18next';
import * as css from './SendFlowBanner.css';

export const SendFlowBanner: React.FC = () => {
  const [sendFlowPromoHidden, setSendFlowPromoHidden, { loading }] =
    useServerStorage<boolean>(
      ServerStorageItemKeys.livechatSendFlowPromoHidden,
    );

  const onClose = useCallback(() => {
    setSendFlowPromoHidden(true);
  }, [setSendFlowPromoHidden]);

  if (loading || sendFlowPromoHidden) {
    return null;
  }
  return (
    <div className={css.box}>
      <div className={css.iconContainer}>
        <Icon icon="flow" size="16px" color="white" />
      </div>
      <ButtonUnstyled className={css.closeButton} onClick={onClose}>
        <Icon icon="close" size="20px" noColor />
      </ButtonUnstyled>
      <Type size="15px">
        <Trans i18nKey="pages.LiveChat.SendFlow.banner.text" />
      </Type>
    </div>
  );
};
