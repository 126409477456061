import React, { CSSProperties, useCallback, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { ApolloError } from 'apollo-client';
import cn from 'classnames';
import { useCurrentBotId } from '@utils/Routing';
import { Flex } from '@ui/Flex';
import { Type } from '@ui/Type';
import { Anchor } from '@ui/Links';
import { LoadingError } from '@ui/LoadingError';
import { Spacer } from '@ui/Spacer';
import { sendEvent } from '@utils/Analytics';
import { useFbPermissions } from '@utils/FacebookPages/useFbPermissions';
import { useAutomateEnabled } from '@utils/Data/Admin/Automate';
import { extractGQLErrorData } from '@utils/GQL/utils';
import * as css from './FacebookAdCampaignsTable.css';
import { AdStatus } from '../../../../../@types/globalTypes';
import { FacebookAdCampaignsListLoader } from './FacebookAdCampaignsListLoader';
import {
  FacebookAdCampaignsListQuery,
  FacebookAdCampaignsListQuery_bot_facebookAdCampaignsCtm_ads as FacebookAd,
  FacebookAdCampaignsListQuery_bot_facebookAdCampaignsCtm_ads_insights as FacebookAdInsights,
  FacebookAdCampaignsListQuery_bot_facebookAdCampaignsCtm_ads_quick_replies_buttons as QRButton,
  FacebookAdCampaignsListQuery_bot_facebookAdCampaignsCtm_ads_quick_replies_buttons_blocks as QRButtonBlocks,
} from '../hooks/@types/FacebookAdCampaignsListQuery';
import {
  getFacebookAdCampaignsListAdNote,
  renderFacebookAdCampaignsListAdNote,
  renderFacebookAdCampaignsListButtonNote,
} from './FacebookAdCampaignsListNote';
import {
  BlocksStatsType,
  useMultipleBlocksStatsForCampaigns,
} from './FacebookAdUtils';
import { FacebookAdCampaignsListBlockStat } from './FacebookAdCampaignsListBlockStat';
import { FacebookAdCampaignsListEmpty } from './FacebookAdCampaignsListEmpty';
import { FacebookAdCampaignsListNeedRequest } from './FacebookAdCampaignsListNeedRequest';
import { FacebookAdTypeBage } from './FacebookAdTypeBage';
import { getSettingsPageUrl, extractFirstBlock } from './helpers';

interface AdsCellLinkProps<T> {
  data?: T;
  href: string;
  adId: string;
}

function AdsCellLink<T extends { title: string }>({
  data,
  adId,
  href,
}: AdsCellLinkProps<T>) {
  const { isAutomateEnabled } = useAutomateEnabled();
  const botId = useCurrentBotId();

  if (!data) {
    return (
      <Anchor
        intent="external"
        size="small"
        hideArrow
        href={`/bot/${botId}/home/ctm-ads-settings/${adId}`}
      >
        {window.i18next.t('FacebookAdCampaignsTable-JSXText--160-set-up')}
        {isAutomateEnabled
          ? window.i18next.t('FacebookAdCampaignsTable-string-9383-block')
          : window.i18next.t('FacebookAdCampaignsTable-string-3146-flow')}
      </Anchor>
    );
  }

  return (
    <>
      <Type size="12px" color="greyDark">
        {window.i18next.t('FacebookAdCampaignsTable-JSXText-9467-sends-to')}
      </Type>
      <Anchor
        hideArrow
        intent="subtitle"
        size="regular"
        href={href}
        onClick={(e) => {
          e.stopPropagation();
          sendEvent({
            category: 'ads manager',
            action: 'click sends to',
            propertyBag: {
              destination: data.title,
            },
          });
        }}
        data-testid="ads-cell-link"
      >
        <Type size="12px" color="greyDark">
          {data.title}
        </Type>
      </Anchor>
    </>
  );
}

interface TailButtonProps {
  buttonIndex: number;
  firstBlock: QRButtonBlocks | undefined;
  blockUrl: string;
  ad: FacebookAd;
  button: QRButton;
  blocksStats: BlocksStatsType | undefined;
}

const TailButton: React.FC<TailButtonProps> = ({
  buttonIndex,
  firstBlock,
  blockUrl,
  ad,
  button,
  blocksStats,
}) => {
  const { isAutomateEnabled } = useAutomateEnabled();
  return (
    <tr key={buttonIndex}>
      <td className={css.spacer} />
      <td>
        <div>
          <AdsCellLink data={firstBlock} href={blockUrl} adId={ad.id} />
        </div>
      </td>
      <td colSpan={5} className={css.rightBorder} />
      {renderFacebookAdCampaignsListButtonNote(button, isAutomateEnabled) || (
        <FacebookAdCampaignsListBlockStat
          blocksStats={blocksStats}
          blockId={firstBlock?.id}
        />
      )}
      <td className={css.spacer} />
    </tr>
  );
};

const AD_STAT_PLACEHOLDER: FacebookAdInsights = {
  __typename: 'FacebookAdInsights',
  reach: 0,
  cpa: 0,
  impressions: 0,
  clicks: 0,
};

const cpaFormater = new Intl.NumberFormat('en-En', {
  style: 'currency',
  currency: 'USD',
});

interface FacebookAdCampaignsTableProps {
  data?: FacebookAdCampaignsListQuery;
  error?: ApolloError;
  loading: boolean;
  refetchAdAccounts: () => void;
  refetchAdCampaigns: () => void;
  tableClassName?: string;
  showFooterSpacer?: boolean;
  wrapperStyles?: CSSProperties;
  flowIds: string[];
  showEmptyAccountView: boolean;
}

const NAMESPACE = 'pages.GrowPage.ClickToMessenger.table';

export const FacebookAdCampaignsTable: React.FC<FacebookAdCampaignsTableProps> =
  ({
    data,
    loading: campaignsLoading,
    error,
    refetchAdAccounts,
    refetchAdCampaigns,
    tableClassName,
    showFooterSpacer,
    wrapperStyles,
    flowIds,
    showEmptyAccountView,
  }) => {
    const { t } = useSafeTranslation();
    const botId = useCurrentBotId() || '';
    const history = useHistory();

    const {
      permissions: { hasMarketingPermissions },
      loading: loadingPermissions,
    } = useFbPermissions();

    const facebookAdCampaignsCtm = data?.bot.facebookAdCampaignsCtm;

    const { isAutomateEnabled } = useAutomateEnabled();

    const { executeMultipleBlocksStatsForCampaigns, blocksStats } =
      useMultipleBlocksStatsForCampaigns(botId);

    useEffect(() => {
      if (facebookAdCampaignsCtm) {
        executeMultipleBlocksStatsForCampaigns(facebookAdCampaignsCtm);
      }
    }, [facebookAdCampaignsCtm, executeMultipleBlocksStatsForCampaigns]);

    const onRowClick = useCallback(
      (ad, isConnectedToCurrentBot, campaign) => {
        sendEvent({
          category: 'ads manager',
          action: 'ad click',
          propertyBag: {
            campaignId: campaign.id,
            campaignName: campaign.name,
            adId: ad.id,
            adName: ad.name,
            error: (
              getFacebookAdCampaignsListAdNote(
                ad,
                botId,
                false,
                isAutomateEnabled,
              ) || {}
            ).text,
          },
        });
        if (isConnectedToCurrentBot) {
          history.push(getSettingsPageUrl(botId, ad.id));
        }
      },
      [botId, history, isAutomateEnabled],
    );

    const loading = loadingPermissions || campaignsLoading;
    const { message: errorMessage } = extractGQLErrorData(error) || {};

    return (
      <div style={wrapperStyles || {}}>
        <table className={cn(css.table, tableClassName)}>
          <thead>
            <tr>
              <th className={css.spacer} />
              <th>{t(`${NAMESPACE}.adsType`)}</th>
              <th>{t(`${NAMESPACE}.status`)}</th>
              <th>{t(`${NAMESPACE}.reach`)}</th>
              <th>{t(`${NAMESPACE}.impressions`)}</th>
              <th>{t(`${NAMESPACE}.clicks`)}</th>
              <th>{t(`${NAMESPACE}.cpa`)}</th>
              <th>{t(`${NAMESPACE}.newSubs`)}</th>
              <th>{t(`${NAMESPACE}.openRate`)}</th>
              <th>{t(`${NAMESPACE}.blockCTR`)}</th>
              <th className={css.spacer} />
            </tr>
          </thead>
          {error && hasMarketingPermissions && (
            <tbody className={css.disabled}>
              <tr>
                <td colSpan={12}>
                  <LoadingError
                    style={{ height: 72 }}
                    onTryAgainClick={() => {
                      sendEvent({
                        category: 'ads manager',
                        action: 'try again click',
                        propertyBag: {
                          error: errorMessage,
                        },
                      });
                      refetchAdCampaigns();
                    }}
                  />
                </td>
              </tr>
            </tbody>
          )}
          {!loading && !hasMarketingPermissions && (
            <>
              <FacebookAdCampaignsListNeedRequest />
              {showFooterSpacer && <Spacer factor={6} />}
            </>
          )}
          {loading && <FacebookAdCampaignsListLoader />}
          {!loading &&
            !showEmptyAccountView &&
            hasMarketingPermissions &&
            facebookAdCampaignsCtm?.map((campaign) => {
              const { id, name, ads, type } = campaign;

              return (
                <React.Fragment key={id}>
                  <tbody className={css.category}>
                    <tr>
                      <td className={css.spacer} />
                      <td colSpan={6} className={css.rightBorder}>
                        <Flex alignItems="center">
                          {type && <FacebookAdTypeBage type={type} />}
                          <div className={css.campaignName} title={name}>
                            <Type size="15px_DEPRECATED" weight="medium">
                              {name}
                            </Type>
                          </div>
                        </Flex>
                      </td>
                      <td colSpan={3} />
                      <td className={css.spacer} />
                    </tr>
                  </tbody>
                  {ads &&
                    ads.map((ad) => {
                      const active = ad.status === AdStatus.ACTIVE;
                      const insights = ad.insights || AD_STAT_PLACEHOLDER;
                      const [headButton, ...tailButtons] =
                        ad.quick_replies?.buttons || ad.buttons || [];
                      const headButtonFirstBlock =
                        extractFirstBlock(headButton);
                      const { id: block_id, flow_id } =
                        headButtonFirstBlock || {};
                      const isConnectedToCurrentBot =
                        ad.connected_bot?.id === botId;

                      const redirectFlowId =
                        flowIds.find((id) => id === block_id) || flow_id;
                      const flowUrl = block_id
                        ? `/bot/${botId}/flows/${redirectFlowId}?blockId=${block_id}`
                        : `/bot/${botId}/flows/${redirectFlowId}`;
                      const blockUrl = `/bot/${botId}/structure/${block_id}`;
                      const href = redirectFlowId ? flowUrl : blockUrl;

                      return (
                        // eslint-disable-next-line jsx-a11y/click-events-have-key-events
                        <tbody
                          key={ad.id}
                          className={cn({
                            [css.disabled]: !isConnectedToCurrentBot,
                          })}
                          onClick={() =>
                            onRowClick(ad, isConnectedToCurrentBot, campaign)
                          }
                        >
                          <tr>
                            <td className={css.spacer} />
                            <td>
                              <div className={css.addName}>{ad.name}</div>
                              <div>
                                {ad.connected_bot?.id === botId && (
                                  <AdsCellLink
                                    data={headButtonFirstBlock}
                                    href={href}
                                    adId={ad.id}
                                  />
                                )}
                              </div>
                            </td>
                            <td>
                              <div
                                className={cn({
                                  [css.statusInactive]: !active,
                                  [css.statusActive]: active,
                                })}
                              >
                                {active
                                  ? window.i18next.t(
                                      'FacebookAdCampaignsTable-string-1955-active',
                                    )
                                  : window.i18next.t(
                                      'FacebookAdCampaignsTable-string-8930-inactive',
                                    )}
                              </div>
                            </td>
                            <td>{insights.reach.toLocaleString('en-EN')}</td>
                            <td>
                              {insights.impressions.toLocaleString('en-EN')}
                            </td>
                            <td>{insights.clicks.toLocaleString('en-EN')}</td>
                            <td className={css.rightBorder}>
                              {cpaFormater.format(insights.cpa)}
                            </td>
                            {renderFacebookAdCampaignsListAdNote(
                              ad,
                              botId,
                              isAutomateEnabled,
                            ) || (
                              <FacebookAdCampaignsListBlockStat
                                blocksStats={blocksStats}
                                blockId={headButtonFirstBlock?.id}
                              />
                            )}
                            <td className={css.spacer} />
                          </tr>
                          {tailButtons?.length && isConnectedToCurrentBot
                            ? tailButtons.map((button, buttonIndex) => (
                                <TailButton
                                  ad={ad}
                                  button={button}
                                  blockUrl={blockUrl}
                                  blocksStats={blocksStats}
                                  buttonIndex={buttonIndex}
                                  firstBlock={extractFirstBlock(button)}
                                />
                              ))
                            : null}
                        </tbody>
                      );
                    })}
                </React.Fragment>
              );
            })}
        </table>
        {!loading &&
          !error &&
          !showEmptyAccountView &&
          facebookAdCampaignsCtm?.length === 0 &&
          hasMarketingPermissions && (
            <div className={css.emptyViewWrapper}>
              <FacebookAdCampaignsListEmpty
                refetchRequest={refetchAdCampaigns}
                title={window.i18next.t(
                  'FacebookAdCampaignsTable-string-6116-you-dont-have-any-click-to-messenger-or-sponsored-messages-campaigns',
                )}
                message={`${window.i18next.t(
                  'FacebookAdCampaignsTable-Template--544-create-a-campaign-with-the-messages-сonsideration-at',
                )}`}
                refreshButtonLabel={window.i18next.t(
                  'FacebookAdCampaignsTable-string-1203-refresh-campaign-list',
                )}
              />
            </div>
          )}
        {!loading && !error && showEmptyAccountView && hasMarketingPermissions && (
          <div className={css.emptyViewWrapper}>
            <FacebookAdCampaignsListEmpty
              refetchRequest={refetchAdAccounts}
              title={window.i18next.t(
                'FacebookAdCampaignsTable-string-6313-you-dont-have-any-facebook-ad-accounts',
              )}
              message={window.i18next.t(
                'FacebookAdCampaignsTable-string--539-create-an-account-for-your-facebook-page',
              )}
              refreshButtonLabel={window.i18next.t(
                'FacebookAdCampaignsTable-string--917-refresh-accounts-list',
              )}
            />
          </div>
        )}
      </div>
    );
  };
