import { AvatarWithPlatform } from '@ui/Avatar';
import { Badge, Kind } from '@ui/Badge';
import { Button } from '@ui/Button';
import { Flex } from '@ui/Flex';
import { Icon } from '@ui/Icon';
import { LoadingPlaceholder } from '@ui/LoadingPlaceholder';
import { Spacer } from '@ui/Spacer';
import { Tooltip2 } from '@ui/Tooltip2';
import { Type } from '@ui/Type';
import { sendEvent } from '@utils/Analytics';
import { combineRefs } from '@utils/combineRefs';
import { useCurrentBotId } from '@utils/Routing';
import {
  ServerStorageItemKeys,
  useServerStorageForBot,
} from '@utils/ServerStorage';
import cn from 'classnames';
import React from 'react';
import { Hover } from 'react-powerplug';
import { Link, LinkProps } from 'react-router-dom';
import { Folder, Platform } from '@globals';
import { AssigneesMenu } from '../AssigneesMenu';
import { Avatar } from '../Avatar';
import { DialogLastMessage } from './DialogLastMessage';
import { getAssigneeForSubscriber, getLastActiveDateFormatted } from './Utils';
import {
  useLiveChatAdmins,
  LivechatAdmin,
} from '../../hooks/useLiveChatAdmins';
import { DialogsQuery_livechatConversations_items as Dialog } from '../../common/@types/DialogsQuery';
import * as css from './DialogItem.css';
import { useLiveChatMoveConversation } from '@pages/LiveChat/useLiveChatMoveConversation';
import { DeliveredStatusView } from '../Messages/MessageTimestamp/MessageTimestamp';
import { DeliveredStatusThemeType } from '../Messages/MessageTimestamp/consts';

interface DialogItemProps {
  dialog: Dialog;
  to: LinkProps['to'];
  isSelected?: boolean;
  onAdminAssign?(admin?: LivechatAdmin): void;
  isSmallScreenSize: boolean;
  onClick(): void;
}

export const DialogItem: React.FC<DialogItemProps> = ({
  dialog,
  to,
  isSelected = false,
  onAdminAssign,
  isSmallScreenSize,
  onClick,
}) => {
  const lastMessageDate = +(
    dialog.last_message?.date || dialog.last_action_date
  );
  const botId = useCurrentBotId();
  const { admins } = useLiveChatAdmins(botId!);
  const isFromInbox = dialog.folder === Folder.inbox;
  const assignee = getAssigneeForSubscriber(dialog.subscriber, admins);
  const [liveChatSoundsDisabled] = useServerStorageForBot(
    ServerStorageItemKeys.isLiveChatNotificationSoundsDisabled,
    botId,
  );
  const { moveConversationToClose, moveConversationToOpen } =
    useLiveChatMoveConversation({
      conversationId: dialog.id,
      platform: dialog.platform,
    });

  const getDialogLastMessageBadge = () => {
    if (dialog.unseen_messages_count) {
      return (
        <Badge kind={liveChatSoundsDisabled ? Kind.gray : Kind.primary} circle>
          {dialog.unseen_messages_count}
        </Badge>
      );
    }

    if (dialog.last_message?.status) {
      return (
        <DeliveredStatusView
          status={dialog.last_message?.status}
          size="16px"
          theme={DeliveredStatusThemeType.dialogsList}
        />
      );
    }

    return undefined;
  };

  return (
    <Hover
      render={({ bind, hovered }) => (
        <Link
          {...bind}
          onClick={() => {
            sendEvent({
              category: 'live chat',
              action: 'subscriber click',
              propertyBag: {
                subscriberId: dialog.subscriber.id,
              },
            });
            onClick();
          }}
          className={cn(
            css.item,
            isSmallScreenSize && css.item_mobile,
            isSelected && !isSmallScreenSize && css.highlighted,
          )}
          to={to}
          data-testid="livechat-dialog-list__dialog-item"
        >
          <AvatarWithPlatform
            src={dialog.subscriber.avatarUrl}
            platform={dialog.platform ?? Platform.facebook}
          />
          <Spacer horizontalFactor={3} factor={0} />
          <div className={css.subscriberInfoContainer}>
            <div className={css.subscriberDialogInfo}>
              <div className={css.subscriberProfile}>
                {assignee && (
                  <>
                    <Tooltip2
                      placement="top"
                      boundariesElement="viewport"
                      content={
                        <Type size="12px" color="white">
                          {assignee.name}
                        </Type>
                      }
                      type="small"
                      positionFixed
                    >
                      {(ref, bind) => (
                        <div
                          {...bind}
                          ref={ref}
                          data-testid="dialog-item__assignee-avatar"
                        >
                          <Avatar src={assignee.picture} size={16} />
                        </div>
                      )}
                    </Tooltip2>
                    <Icon icon="arrow_right" size="16px" />
                  </>
                )}
                <Type
                  as="p"
                  size="15px_DEPRECATED"
                  weight={
                    dialog.unseen_messages_count === 0 ? 'medium' : 'semibold'
                  }
                  noWrap
                  data-testid="dialog-item__subscriber-name"
                >
                  {dialog.subscriber.name}
                </Type>
              </div>
              <Spacer horizontalFactor={2} factor={0} />
              {!hovered && (
                <div className={css.dialogStatus}>
                  <Type
                    color="greyDark"
                    size="15px_DEPRECATED"
                    data-testid="dialog-item__last-action-time"
                  >
                    {getLastActiveDateFormatted(lastMessageDate)}
                  </Type>
                </div>
              )}
            </div>
            <Flex alignItems="center">
              <DialogLastMessage dialog={dialog} />
              {!hovered && getDialogLastMessageBadge()}
            </Flex>
          </div>
          {hovered && !isSmallScreenSize && (
            <div className={css.actionButtons}>
              <AssigneesMenu
                title={window.i18next.t(
                  'DialogItem-string-8090-assign-to-teammate',
                )}
                assigneeId={assignee?.id}
                onAssigneeChange={onAdminAssign}
                comboboxProps={{
                  disableFixedMode: true,
                }}
                unassignedByDefault
              >
                {({ ref, getToggleButtonProps }: any) => (
                  <Tooltip2
                    placement="right"
                    boundariesElement="viewport"
                    content={
                      <Type size="12px" color="white">
                        {window.i18next.t(
                          'DialogItem-JSXText-3767-assign-conversation-to-a-teammate',
                        )}
                      </Type>
                    }
                    type="small"
                    positionFixed
                  >
                    {(tooltipRef, bind) => (
                      <Button
                        {...bind}
                        ref={combineRefs([tooltipRef, ref])}
                        icon={<Icon icon="assign" />}
                        intent="text"
                        size="s"
                        onClick={(event) => {
                          event.preventDefault();
                          getToggleButtonProps()?.onClick(event);
                        }}
                      />
                    )}
                  </Tooltip2>
                )}
              </AssigneesMenu>
              <Spacer horizontalFactor={1} factor={0} />
              {isFromInbox ? (
                <>
                  <Tooltip2
                    placement="right"
                    boundariesElement="viewport"
                    content={
                      <Type size="12px" color="white">
                        {window.i18next.t(
                          'DialogItem-JSXText-1585-move-conversation-to-done',
                        )}
                      </Type>
                    }
                    type="small"
                    positionFixed
                  >
                    {(ref, bind) => (
                      <Button
                        {...bind}
                        ref={ref}
                        icon={<Icon icon="check" />}
                        intent="text"
                        size="s"
                        onClick={(event) => {
                          event.preventDefault();
                          moveConversationToClose();
                        }}
                      />
                    )}
                  </Tooltip2>
                </>
              ) : (
                <Tooltip2
                  placement="right"
                  boundariesElement="viewport"
                  content={
                    <Type size="12px" color="white">
                      {window.i18next.t(
                        'DialogItem-JSXText--475-move-conversation-to-inbox',
                      )}
                    </Type>
                  }
                  type="small"
                  positionFixed
                >
                  {(ref, bind) => (
                    <Button
                      {...bind}
                      ref={ref}
                      icon={<Icon icon="inboxActive" />}
                      intent="text"
                      size="s"
                      onClick={(event) => {
                        event.preventDefault();
                        moveConversationToOpen();
                      }}
                    />
                  )}
                </Tooltip2>
              )}
            </div>
          )}
        </Link>
      )}
    />
  );
};

export const DialogItemPlaceholder: React.FC<{}> = () => {
  const opacity = 0.6;
  return (
    <div className={cn(css.item, css.placeholderItem)}>
      <LoadingPlaceholder
        color="grey"
        style={{ width: 40, height: 40, borderRadius: '50%', opacity }}
      />
      <Spacer horizontalFactor={3} factor={0} />
      <div className={css.subscriberInfoContainer}>
        <LoadingPlaceholder
          color="grey"
          style={{ height: 10, width: 132, opacity }}
        />
        <Spacer factor={3} />
        <LoadingPlaceholder
          color="grey"
          style={{ height: 10, width: 60, opacity }}
        />
      </div>
      <LoadingPlaceholder
        color="grey"
        style={{
          height: 10,
          width: 34,
          position: 'relative',
          top: -11,
          opacity,
        }}
      />
    </div>
  );
};
