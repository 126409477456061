import React, { useEffect, useState } from 'react';
import { InitAuthProps } from '@utils/AuthFlow';
import { logoutIntercom } from '@utils/Intercom';
import { redirect } from '@utils/UrlUtils';
import { callFacebookSDK } from '@components/FBPlugins/callFacebookSDK';
import {
  FbLoginStatus,
  getFbLoginStatus,
} from '@components/FBPlugins/FbLoginStatus';
import timer from '@utils/timer';
import { Oops } from '../Oops';
import { removeAuthToken } from '../MultiSystemAuth/utils/removeAuthToken';

interface LogoutProps extends InitAuthProps {
  interruptedHref?: string;
}

export const Logout: React.FC<LogoutProps> = ({
  client,
  interruptedHref = '/',
}) => {
  const [errorText, setErrorText] = useState('');

  useEffect(() => {
    removeAuthToken(client)
      .then(logoutIntercom)
      .then(() => timer(100)) // wait intercom logout
      .then(async () => {
        if ((await getFbLoginStatus()).status !== FbLoginStatus.connected) {
          redirect(interruptedHref);
          return;
        }
        if (
          !callFacebookSDK((FB) => {
            FB.logout(() => {
              redirect(interruptedHref);
            });
          })
        ) {
          redirect(interruptedHref);
        }
      })
      .catch((e) => {
        setErrorText(e.toString());
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (errorText) {
    return <Oops reason="Error on logout" errorText={errorText} />;
  }

  return null;
};

export default Logout;
