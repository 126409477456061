import { useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import { Platform, WhatsappStatusEnum } from '@globals';
import { useJustSentLivechatMessagesActions } from '@utils/Data/LiveChat/Messages/hooks';
import { MESSAGE_REACTIONS } from '../../consts';
import {
  MessagesQuery_livechatMessages_items as Message,
  MessagesQuery_livechatMessages_items_message_attachment as Attachment,
} from '../Messages/@types/MessagesQuery';
import {
  SendLivechatMessage,
  SendLivechatMessageVariables,
} from './@types/SendLivechatMessage';
import { RestAttachment, RestMessage } from './types';
import { useLocalLiveChatMessageV3 } from './useLocalLiveChatMessageV3';

const SEND_LIVECHAT_MESSAGE = gql`
  mutation SendLivechatMessage(
    $botId: String!
    $conversationId: String!
    $message: String
    $sticker_id: String
    $platform: Platform!
    $outpacingId: String
  ) {
    sendLivechatMessage(
      botId: $botId
      conversationId: $conversationId
      message: $message
      sticker_id: $sticker_id
      platform: $platform
      outpacingId: $outpacingId
    ) {
      id
      date
      mid
      outpacingId
    }
  }
`;

interface useSendMessageProps {
  botId: string;
  conversationId: string;
  platform: Platform;
}

export interface sendMessageProps {
  text?: string;
  sticker_id?: string;
}

/**
 * много копипасты из
 * packages/dashboard/src/pages/LiveChat/components/Messages/MessageInput/InputControls/SendFlow/useSendBlock.ts
 */
export const useSendMessage = ({
  botId,
  conversationId,
  platform,
}: useSendMessageProps) => {
  const { loading, error, writeNewMessage, locallyGeneratedId } =
    useLocalLiveChatMessageV3({
      botId,
      conversationId,
      platform,
    });
  const { addJustSentLivechatMessageId } = useJustSentLivechatMessagesActions();

  const [sendMessageMutation] = useMutation<
    SendLivechatMessage,
    SendLivechatMessageVariables
  >(SEND_LIVECHAT_MESSAGE);

  return {
    disabled: loading || error,
    sendMessage: ({ text, sticker_id }: sendMessageProps) => {
      writeNewMessage({
        message: {
          text: text || (sticker_id === MESSAGE_REACTIONS.love ? '❤️' : ''),
          sticker_id: sticker_id || null,
        },
      });

      return sendMessageMutation({
        variables: {
          botId,
          conversationId,
          message: text,
          sticker_id,
          platform,
          outpacingId: locallyGeneratedId,
        },
        update: (_, { data }) => {
          if (!data) {
            return;
          }
          const { sendLivechatMessage: message } = data;
          addJustSentLivechatMessageId(message.id);
        },
      });
    },
  };
};

const attachmentWithNulls = (
  {
    attachment_id,
    url,
    type,
    mime_type,
    filename,
    caption,
    file_size,
    expired,
  }: RestAttachment,
  id: string,
): Attachment => ({
  __typename: 'MessageAttachment',
  id,
  attachment_id: attachment_id || null,
  type,
  url: url || null,
  mime_type: mime_type || null,
  filename: filename || null,
  caption: caption || null,
  file_size: file_size || null,
  expired: expired || null,
});

export const extendMessageWithNulls = (
  message: RestMessage,
  conversationId: string,
  platform: Platform,
  outpacingId?: string,
): Message => ({
  mid: message.mid ?? null,
  id: message.id,
  date: message.date,
  seen: message.seen,
  outpacingId: message.outpacingId ?? outpacingId ?? null,
  __typename: 'LivechatMessageV3',
  conversationId,
  status: platform === Platform.whatsapp ? WhatsappStatusEnum.pending : null,
  message: {
    __typename: 'Message' as 'Message',
    text: message.message.text ?? null,
    buttons: message.message.buttons ?? null,
    sticker_id: message.message.sticker_id ?? null,
    gallery: message.message.gallery ?? null,
    reactions: message.message.reactions ?? null,
    receipt: message.message.receipt ?? null,
    send_to: message.message.send_to ?? null,
    whatsapp_list: message.message.whatsapp_list ?? null,
    whatsapp_list_reply: message.message.whatsapp_list_reply ?? null,
    whatsapp_template: message.message.whatsapp_template ?? null,
    whatsapp_location: message.message.whatsapp_location ?? null,
    whatsapp_context: message.message.whatsapp_context ?? null,
    attachment: message.message.attachment
      ? attachmentWithNulls(message.message.attachment, message.id)
      : null,
  },
  from: {
    __typename: 'From' as 'From',
    admin_id: message.from.admin_id ?? null,
    type: message.from.type,
    persona: message.from.persona
      ? {
          ...message.from.persona,
          __typename: 'Persona' as 'Persona',
        }
      : null,
  },
  error: null,
});
