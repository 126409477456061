import { Platform } from '@globals';
import { useSubscriptionWithAuth } from '@utils/Data/hocks/useSubscriptionWithAuth';
import {
  LIVECHAT_NOTE_CREATED,
  LIVECHAT_NOTE_DELETED,
  LIVECHAT_NOTE_UPDATED,
} from './subscriptions';
import {
  LivechatNoteUpdated,
  LivechatNoteUpdatedVariables,
} from './@types/LivechatNoteUpdated';
import { useCurrentBotId } from '@utils/Routing';
import {
  LivechatNoteCreated,
  LivechatNoteCreatedVariables,
} from './@types/LivechatNoteCreated';
import { useEffect, useMemo } from 'react';
import { notesCacheUpdater } from './helpers/notesCacheUpdater';
import {
  LivechatNoteDeleted,
  LivechatNoteDeletedVariables,
} from './@types/LivechatNoteDeleted';
import { replaceOptimisticItem } from './helpers/replaceOptimisticItem';

export const useLiveChatNotesSubscription = (
  conversationId: string,
  platform: Platform,
) => {
  const botId = useCurrentBotId()!;
  const commonVariables = useMemo(
    () => ({
      conversationId,
      botId,
      platform,
    }),
    [botId, conversationId, platform],
  );

  useSubscriptionWithAuth<LivechatNoteUpdated, LivechatNoteUpdatedVariables>(
    LIVECHAT_NOTE_UPDATED,
    {
      variables: {
        botId,
        conversationId,
      },
      skip: !botId,
    },
  );

  const { data: newConversationData } = useSubscriptionWithAuth<
    LivechatNoteCreated,
    LivechatNoteCreatedVariables
  >(LIVECHAT_NOTE_CREATED, {
    variables: {
      botId,
      conversationId,
    },
    skip: !botId,
  });

  const newConversation = newConversationData?.livechatNoteCreated;

  useEffect(() => {
    if (!newConversation) {
      return;
    }
    replaceOptimisticItem(newConversation, commonVariables);
  }, [commonVariables, newConversation]);

  const { data: deletedConversationData } = useSubscriptionWithAuth<
    LivechatNoteDeleted,
    LivechatNoteDeletedVariables
  >(LIVECHAT_NOTE_DELETED, {
    variables: {
      botId,
      conversationId,
    },
    skip: !botId,
  });

  const deletedConversationId = deletedConversationData?.livechatNoteDeleted.id;

  useEffect(() => {
    if (!deletedConversationId) {
      return;
    }
    notesCacheUpdater(
      (items) => items.filter(({ id }) => id !== deletedConversationId),
      commonVariables,
    );
  }, [commonVariables, deletedConversationId, newConversation]);
};
