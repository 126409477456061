import React, { useCallback } from 'react';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { omit } from 'ramda';
import { useHistory } from 'react-router-dom';
import gql from 'graphql-tag';
import { removeTypename } from '@utils/GQL/utils';
import { Type } from '../../../../modern-ui/Type';
import { Flex } from '../../../../modern-ui/Flex';
import { Button } from '../../../../modern-ui/Button';
import { sendEvent } from '../../../../utils/Analytics';
import { LoadingPlaceholder } from '../../../../modern-ui/LoadingPlaceholder';
import { TabsIds } from '../AbandonedCartConst';
import {
  ShopifyShopPopupMutation,
  ShopifyShopPopupMutationVariables,
} from '../AbandonedCartPage/tabs/@types/ShopifyShopPopupMutation';
import {
  SHOPIFY_SHOP_POPUP_FRAGMENT,
  SHOPIFY_SHOP_POPUP_MUTATION,
} from '../AbandonedCartPage/tabs/AbandonedCartPageMessageTab';
import { useCurrentBotId } from '../../../../utils/Routing';
import { ReactComponent as PauseIcon } from '../../../../modern-ui/_deprecated/Icon/icons/pause.svg';
import { ReactComponent as PlayIcon } from '../../../../modern-ui/_deprecated/Icon/icons/play.svg';
import {
  ShopPopupEnabledQuery,
  ShopPopupEnabledQueryVariables,
} from './@types/ShopPopupEnabledQuery';

const SHOP_POPUP_ENABLED_QUERY = gql`
  query ShopPopupEnabledQuery($botId: String!) {
    bot(id: $botId) {
      id
      shopifyShopPopup {
        ...shopifyShopPopupFragment
      }
    }
  }
  ${SHOPIFY_SHOP_POPUP_FRAGMENT}
`;

export const OptInSetup: React.FC = () => {
  const botId = useCurrentBotId();
  const history = useHistory();

  const { data: shopPopupData, loading: shopPopupDataLoading } = useQuery<
    ShopPopupEnabledQuery,
    ShopPopupEnabledQueryVariables
  >(SHOP_POPUP_ENABLED_QUERY, {
    variables: {
      botId: botId || '',
    },
    skip: !botId,
  });

  const [updateShopPopup, { loading: updateShopPopupLoading }] = useMutation<
    ShopifyShopPopupMutation,
    ShopifyShopPopupMutationVariables
  >(SHOPIFY_SHOP_POPUP_MUTATION, {
    refetchQueries: [{ query: SHOP_POPUP_ENABLED_QUERY, variables: { botId } }],
    awaitRefetchQueries: true,
  });

  const changeShopPopupEnabled = useCallback(() => {
    if (botId && shopPopupData?.bot.shopifyShopPopup) {
      // fill all fields in update request (we change only "enabled")
      const currentPopup = omit(['id'])(shopPopupData.bot.shopifyShopPopup);
      updateShopPopup({
        variables: {
          botId,
          shopifyShopPopup: {
            ...removeTypename(currentPopup),
            enabled: !currentPopup.enabled,
            trigger: removeTypename(currentPopup.trigger),
            blocks: currentPopup.blocks?.map(removeTypename) || null,
          },
        },
      });
    }
  }, [updateShopPopup, botId, shopPopupData]);

  return (
    <Flex justifyContent="space-between" alignItems="center">
      <Type size="15px_DEPRECATED" weight="medium">
        {window.i18next.t('OptInSetup-JSXText--193-opt-in-popup')}
      </Type>
      {!shopPopupDataLoading ? (
        <Flex>
          {shopPopupData?.bot.shopifyShopPopup?.id && (
            <Button
              intent="primary"
              icon={
                updateShopPopupLoading ? undefined : shopPopupData?.bot
                    .shopifyShopPopup.enabled ? (
                  <PauseIcon />
                ) : (
                  <PlayIcon />
                )
              }
              onClick={() => {
                if (shopPopupData?.bot.shopifyShopPopup?.enabled) {
                  sendEvent({
                    category: 'cart reminders',
                    action: 'pause',
                  });
                } else {
                  sendEvent({
                    category: 'cart reminders',
                    action: 'set live',
                  });
                }
                changeShopPopupEnabled();
              }}
            >
              {updateShopPopupLoading ? (
                <LoadingPlaceholder />
              ) : shopPopupData?.bot.shopifyShopPopup.enabled ? (
                window.i18next.t('OptInSetup-string-7688-pause')
              ) : (
                window.i18next.t('OptInSetup-string-1420-set-live')
              )}
            </Button>
          )}
          <div style={{ width: '16px' }} />
          <Button
            intent="secondary"
            onClick={() => {
              sendEvent({
                category: 'cart reminders',
                action: 'edit',
              });
              history.push(
                `/bot/${botId}/home/abandoned-cart/${TabsIds.message}`,
              );
            }}
          >
            {shopPopupData?.bot.shopifyShopPopup?.id
              ? window.i18next.t('OptInSetup-string-2155-edit')
              : window.i18next.t('OptInSetup-string--182-set-up')}
          </Button>
        </Flex>
      ) : (
        <div style={{ height: '36px' }} />
      )}
    </Flex>
  );
};
