import React, { useCallback } from 'react';
import { Redirect, useHistory, useLocation } from 'react-router-dom';
import cn from 'classnames';

import { useBotInstagramAccount } from '@utils/Data/Instagram/BotAccount';
import { useLivechatSettings } from '@utils/Data/LiveChat/Settings/useLivechatSettings';
import { usePageConnected } from '@utils/FacebookPages/usePageConnected';
import { RoleConsumer, RoleProvider } from '@utils/Roles';
import { useCurrentBotId } from '@utils/Routing';

import { Flex, FlexItem } from '@ui/Flex';

import { Folder, Platform } from '@globals';

import { DISPLAYED_FOLDERS } from './consts';
import { LiveChatMessagesUploadingProvider } from './hooks/LiveChatMessagesUploadingContext/LiveChatMessagesUploadingContext';
import {
  AddLivechatPluginPlaceholder,
  NoConversationsPlaceholder,
  SelectConversationPlaceholder,
} from './components/Dialogs/Placeholders';

import { addUrlSearchParams } from '@utils/Routing/addUrlSearchParams';
import { MobileTransition } from './MobileTransition';
import {
  LiveChatMobilePage,
  useLiveChatMobilePageNavigation,
} from './useLiveChatMobilePageNavigation';
import { Permission } from '@common/services/RoleService';
import { MessagesColumn } from './components/Messages';
import { useDeviceMedia } from '@utils/DOM/useDeviceMedia';
import css from './LiveChatPageContent.css';
import { ZzzPlaceholder } from './components/Dialogs/Placeholders/ZzzPlaceholder';
import { useWhatsappEnabled } from '@utils/Whatsapp';
import { useLiveChatDialogsQtyState } from './hooks/useLiveChatDialogsQtyState';
import { LiveChatRightPanel } from './components/LiveChatRightPanel';
import { FilterableSearchContextProvider } from '@components/FilterableSearchField';
import { ChatList } from './components/ChatList';

enum Placeholder {
  addPlugin = 'addPlugin',
  selectConversation = 'selectConversation',
  connect = 'connect',
  zzz = 'zzz',
}
const getPlaceholder = (
  isLiveChatPluginAdded: boolean,
  isFacebookPageConnected: boolean,
  isWhatsappBot: boolean,
  hasDailogs: boolean,
) => {
  if (isWhatsappBot) {
    return hasDailogs ? Placeholder.selectConversation : Placeholder.zzz;
  }
  if (!isFacebookPageConnected) {
    return Placeholder.connect;
  }
  if (!isLiveChatPluginAdded) {
    return Placeholder.addPlugin;
  }
  return Placeholder.selectConversation;
};

export const LiveChatPageContent: React.FC = React.memo(() => {
  const { isSmallScreenSize } = useDeviceMedia();
  const { isWhatsappEnabled } = useWhatsappEnabled();

  const history = useHistory();
  const location = useLocation();
  const botId = useCurrentBotId();
  const { dialogsQty } = useLiveChatDialogsQtyState();
  const params = new URLSearchParams(location.search);
  const folder = params.get('folder') as Folder;
  const conversationId = params.get('conversationId');
  const platform = (params.get('platform') as Platform) || Platform.facebook;

  const [activeMobilePage, setActiveMobilePage] =
    useLiveChatMobilePageNavigation();

  const { livechatSettings, loading: livechatSettingsLoading } =
    useLivechatSettings(botId!);
  const { hasInstagramPermissions, instagramConnected } =
    useBotInstagramAccount(botId ?? '');
  const { isConnected: isFacebookPageConnected } = usePageConnected(botId);

  const itemClick = useCallback(() => {
    if (isSmallScreenSize) setActiveMobilePage(LiveChatMobilePage.chat);
  }, [isSmallScreenSize, setActiveMobilePage]);

  const dialogListPlatform = params.get(
    'dialogListPlatform',
  ) as Platform | null;

  if (folder == null || !DISPLAYED_FOLDERS.includes(folder as Folder)) {
    return (
      <Redirect
        to={{
          pathname: location.pathname,
          search: `?${addUrlSearchParams(
            location.search,
            'folder',
            Folder.all,
          )}`,
        }}
      />
    );
  }

  if (
    conversationId &&
    platform &&
    dialogListPlatform &&
    platform !== dialogListPlatform
  ) {
    return (
      <Redirect
        to={{
          pathname: location.pathname,
          search: `?${addUrlSearchParams(
            location.search,
            'dialogListPlatform',
            platform,
          )}`,
        }}
      />
    );
  }

  if (!botId) {
    return null;
  }

  const isLiveChatPluginAdded = Boolean(
    !livechatSettingsLoading && livechatSettings?.has_livechat_plugins,
  );
  const placeholder = getPlaceholder(
    isLiveChatPluginAdded,
    isFacebookPageConnected,
    isWhatsappEnabled,
    dialogsQty > 0,
  );

  return (
    <LiveChatMessagesUploadingProvider>
      <RoleProvider botId={botId}>
        <Flex
          fullHeight
          className={cn(isSmallScreenSize ? css.root_mobile : css.root)}
        >
          <MobileTransition
            pages={[
              {
                id: LiveChatMobilePage.chatList,
                page: (
                  <FilterableSearchContextProvider>
                    <ChatList
                      folder={folder}
                      instagramConnected={Boolean(instagramConnected)}
                      hasInstagramPermissions={hasInstagramPermissions}
                      dialogListPlatform={dialogListPlatform}
                      conversationId={conversationId}
                      botId={botId}
                      itemClick={itemClick}
                    />
                  </FilterableSearchContextProvider>
                ),
                active: activeMobilePage === LiveChatMobilePage.chatList,
              },
              {
                id: LiveChatMobilePage.chat,
                page: (
                  <>
                    {conversationId ? (
                      <FlexItem
                        growWidth
                        className={cn(!isSmallScreenSize && css.chatCol)}
                      >
                        <RoleConsumer domain="inbox" can={Permission.EDIT}>
                          {({ loading: permissionsAreLoading, allowed }) => (
                            <MessagesColumn
                              botId={botId}
                              conversationId={conversationId}
                              permissionsAreLoading={permissionsAreLoading}
                              editingAllowed={allowed}
                              folder={folder}
                              platform={platform}
                              dialogListPlatform={dialogListPlatform}
                              onAfterMoveConversation={(destinationFolder) => {
                                if (
                                  destinationFolder === Folder.inbox &&
                                  ![Folder.inbox, Folder.all].includes(folder)
                                ) {
                                  history.push({
                                    pathname: location.pathname,
                                    search: `?${addUrlSearchParams(
                                      location.search,
                                      'folder',
                                      Folder.inbox,
                                    )}`,
                                  });
                                }
                              }}
                            />
                          )}
                        </RoleConsumer>
                      </FlexItem>
                    ) : (
                      <FlexItem growWidth>
                        {placeholder === Placeholder.connect && (
                          <NoConversationsPlaceholder />
                        )}
                        {placeholder === Placeholder.addPlugin && (
                          <AddLivechatPluginPlaceholder />
                        )}
                        {placeholder === Placeholder.selectConversation && (
                          <SelectConversationPlaceholder />
                        )}
                        {placeholder === Placeholder.zzz && <ZzzPlaceholder />}
                      </FlexItem>
                    )}
                  </>
                ),
                active: activeMobilePage === LiveChatMobilePage.chat,
              },
              {
                id: LiveChatMobilePage.attributes,
                page: conversationId ? (
                  <LiveChatRightPanel
                    conversationId={conversationId}
                    platform={platform}
                  />
                ) : null,
                active: activeMobilePage === LiveChatMobilePage.attributes,
              },
            ]}
          />
        </Flex>
      </RoleProvider>
    </LiveChatMessagesUploadingProvider>
  );
});
