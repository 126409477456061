import React from 'react';
import { Flex } from '@ui/Flex';
import { Icon } from '@ui/Icon';
import { Spacer } from '@ui/Spacer';
import { Type } from '@ui/Type';
import { useDeviceMedia } from '@utils/DOM/useDeviceMedia';

export const PlatformErrorLabel: React.FC = ({ children }) => {
  const { isSmallScreenSize } = useDeviceMedia();

  return (
    <Flex alignItems="center">
      {!isSmallScreenSize && <Spacer horizontalFactor={4} />}
      <Icon icon="info" size="24px" color="red" />
      <Spacer horizontalFactor={1} />
      <Type as="p" size="15px" color="red" noWrap>
        {children}
      </Type>
    </Flex>
  );
};
