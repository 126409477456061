import React from 'react';
import cn from 'classnames';
import { Flex } from '@ui/Flex';
import { Spacer } from '@ui/Spacer';
import { Type, TypeProps } from '@ui/Type';
import * as css from './HomeBanner.css';

interface HomeBannerProps {
  title: string;
  content: string;
  logo: React.ReactNode;
  color?: TypeProps<HTMLSpanElement>['color'];
  className?: string;
}

export const HomeBanner: React.FC<HomeBannerProps> = ({
  title,
  content,
  logo,
  color,
  className,
}) => {
  return (
    <Flex
      className={cn(css.bannerContainer, className)}
      flexDirection="column"
      alignItems="flex-start"
      justifyContent="space-between"
    >
      <Flex flexDirection="column">
        {logo}
        <Spacer factor={6} />
        <Type size="24px" color={color} weight="semibold">
          {title}
        </Type>
        <Spacer factor={2} />
        <Type size="15px" color={color}>
          {content}
        </Type>
      </Flex>
    </Flex>
  );
};
