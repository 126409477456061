import React, { useState } from 'react';
import OutsideClick from 'react-outsideclick';
import { Button } from '@ui/Button';
import { Flex } from '@ui/Flex';
import { Type } from '@ui/Type';
import { Spacer } from '@ui/Spacer';
import { Divider } from '@ui/Menu';
import { RadioButton } from '@ui/Radio';
import { IconButton } from '@ui/IconButton';
import {
  BotTestingQuery_botTesting_message_whatsapp_list,
  BotTestingQuery_botTesting_message_whatsapp_list_sections,
  BotTestingQuery_botTesting_message_whatsapp_list_sections_rows as Row,
} from '@utils/Data/BotTesting/@types/BotTestingQuery';
import * as css from './WhatsappList.css';
import { useSafeTranslation } from '@utils/useSafeTranslation';

interface SectionProps {
  section: BotTestingQuery_botTesting_message_whatsapp_list_sections;
  selectedItem: Row | null;
  onItemChange: (item: Row) => void;
}

const Section: React.FC<SectionProps> = ({
  section,
  onItemChange,
  selectedItem,
}) => {
  return (
    <Flex flexDirection="column">
      <Type weight="medium" size="12px">
        {section.title}
      </Type>
      <Spacer factor={1} />
      <ul className={css.list}>
        {section.rows.map((row) => (
          <li key={row.id}>
            <RadioButton
              reversed
              verticallyCentered
              className={css.radioButton}
              labelClassName={css.label}
              name="list_item"
              id={row.id}
              value={selectedItem?.id}
              onChange={() => onItemChange(row)}
              renderLabel={() => (
                <Flex flexDirection="column">
                  <Type>{row.title}</Type>
                  <Type size="12px" color="baseSecondary">
                    {row.description}
                  </Type>
                </Flex>
              )}
            />
          </li>
        ))}
      </ul>
    </Flex>
  );
};

export interface WhatsappListProps {
  config: BotTestingQuery_botTesting_message_whatsapp_list;
  onSubmit(selectedItem: Row): void;
  onClose(): void;
}

export const WhatsappList: React.FC<WhatsappListProps> = ({
  config,
  onSubmit,
  onClose,
}) => {
  const { t } = useSafeTranslation();
  const [selectedItem, setSelectedItem] = useState<Row | null>(null);

  return (
    <form
      noValidate
      className={css.form}
      onSubmit={(event) => {
        event.preventDefault();
        onSubmit(selectedItem!);
      }}
    >
      <Type as="h1" align="center" weight="medium" className={css.header}>
        {config.button}
      </Type>

      <IconButton
        data-testid="whatsapp-list__close"
        className={css.closeIcon}
        icon="close"
        color="cobaltTertiary"
        onClick={() => onClose()}
      />

      <Spacer factor={4} />

      <Flex flexDirection="column">
        {config.sections?.map((section, index, arr) => (
          <React.Fragment key={section.title!}>
            <Section
              onItemChange={setSelectedItem}
              section={section}
              selectedItem={selectedItem}
            />
            <Spacer factor={2} />
            <Divider />
            {index !== arr.length - 1 && <Spacer factor={2} />}
          </React.Fragment>
        ))}
      </Flex>

      <Spacer factor={3} />

      <Button
        fullWidth
        disabled={!selectedItem}
        data-testid="whatsapp-list__send"
        type="submit"
      >
        {t('pages.LiveChat.Message.send')}
      </Button>
    </form>
  );
};

export interface WhatsappListOverlayProps extends WhatsappListProps {}

export const WhatsappListOverlay: React.FC<WhatsappListOverlayProps> = ({
  config,
  onClose,
  onSubmit,
}) => (
  <div className={css.overlay}>
    <OutsideClick onClickOutside={onClose}>
      <WhatsappList config={config} onClose={onClose} onSubmit={onSubmit} />
    </OutsideClick>
  </div>
);
