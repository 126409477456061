import { useQuery } from '@apollo/react-hooks';
import { Platform } from '@globals';
import gql from 'graphql-tag';
import Maybe from 'graphql/tsutils/Maybe';
import { CONVERSATION_SUBSCRIBER_FRAGMENT } from '../common/queries';
import {
  ConversationQuery,
  ConversationQueryVariables,
} from './@types/ConversationQuery';

interface useLiveChatConversationProps {
  botId: string;
  conversationId: Maybe<string>;
  platform: Maybe<Platform>;
}

export const CONVERSATION_QUERY = gql`
  query ConversationQuery(
    $botId: String!
    $conversationId: String!
    $platform: Platform!
  ) {
    livechatConversation(
      botId: $botId
      conversationId: $conversationId
      platform: $platform
    ) {
      id
      seen
      unseen_messages_count
      outside_livechat_window
      outside_24h_window
      last_action_date
      updated
      folder
      platform
      last_seen
      subscriber {
        ...ConversationSubscriberFragment
      }
    }
  }
  ${CONVERSATION_SUBSCRIBER_FRAGMENT}
`;

export const useLiveChatConversation = ({
  botId,
  conversationId,
  platform,
}: useLiveChatConversationProps) => {
  const { data, loading, error } = useQuery<
    ConversationQuery,
    ConversationQueryVariables
  >(CONVERSATION_QUERY, {
    variables: {
      botId,
      conversationId: conversationId!,
      platform: platform!,
    },
    skip: !conversationId || !platform,
  });

  return { data: data?.livechatConversation, loading, error };
};
