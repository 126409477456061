import React from 'react';
import { getMediaMessagePlaceholders } from '../../helpers/getMediaMessagePlaceholders';
import { CommonAttachment } from './CommonAttachment';
import { AttachmentProps } from './types';
import { AudioPlayer } from './components/AudioPlayer';
import { usePrepareAudioData } from './components/AudioPlayer/hooks/usePrepareAudioData';

export const AudioAttachment: React.FC<AttachmentProps> = ({
  loading,
  ...props
}) => {
  const placeholder = getMediaMessagePlaceholders().audio;
  const { url } = props.attachment;
  const { src, ready } = usePrepareAudioData(url);
  return (
    <CommonAttachment
      {...props}
      placeholder={placeholder}
      loading={(!ready && !!url) || loading}
    >
      {() => (src ? <AudioPlayer url={src} /> : null)}
    </CommonAttachment>
  );
};
