import React, { useEffect, useMemo, useRef } from 'react';
import { HUBSPOT_OAUTH_PARAMS } from './consts';
import { useSearchParams } from '@utils/UrlUtils/useSearchParams';
import { useHistory } from 'react-router-dom';
import { BotTabs, getTabLink } from '@utils/Routing';
import { log } from 'cf-common/src/logger';
import { useConnectHubSpot } from '@utils/Data/HubSpot/useConnectHubSpot';

export const HubSpotConnectPage: React.FC = () => {
  const errorSendedRef = useRef(false);
  const { searchParams } = useSearchParams();

  const { botId, code } = useMemo(() => {
    const state = searchParams.get(HUBSPOT_OAUTH_PARAMS.STATE);
    const code = searchParams.get(HUBSPOT_OAUTH_PARAMS.CODE);

    let botId: string | undefined;

    if (state) {
      try {
        ({ botId } = JSON.parse(state));
      } catch (error) {
        if (!errorSendedRef.current) {
          errorSendedRef.current = true;
          log.error({
            error,
            msg: 'invalid state data in hubspot connect oauth url',
          });
        }
      }
    }

    return { botId, code };
  }, [searchParams]);

  const history = useHistory();

  const [connectHubSpot, { called }] = useConnectHubSpot({
    onCompleted() {
      history.push(getTabLink(BotTabs.configure, botId));
    },
  });

  useEffect(() => {
    if (called) return;
    if (botId && code)
      connectHubSpot({
        variables: {
          botId,
          code,
        },
      });
    else history.push(getTabLink(BotTabs.home, botId));
  }, [connectHubSpot, botId, code, history, called]);

  return null;
};
