import React from 'react';
import { useSafeTranslation } from '@utils/useSafeTranslation';

import {
  FbPageType,
  InstagramAccountType,
} from '@utils/Data/Instagram/BotAccount';
import { useFbPermissions } from '@utils/FacebookPages/useFbPermissions';

import { AvatarSize, AvatarWithPlatform } from '@ui/Avatar';
import { Button } from '@ui/Button';
import { Flex } from '@ui/Flex';
import { Spacer } from '@ui/Spacer';

import accountPlaceholder from './account-placeholder.svg';

import * as css from './ConnectedInstagramContent.css';
import { Platform } from '@globals';
import { sendEvent } from '@utils/Analytics';
import {
  PlatformConnectedButtonsWrapper,
  PlatformConnectedHeaderWrapper,
  PlatformLink,
} from '../../../PlatformConnection';
import { ConnectedInstagramAssociatedWith } from './ConnectedInstagramAssociatedWith';
import { PlatformUpdatePermission } from '../../../PlatformConnection/PlatformUpdatePermissionButton/PlatformUpdatePermissionButton';
import { useDeviceMedia } from '@utils/DOM/useDeviceMedia';
import { PlatformConnectedWrapper } from '../../../PlatformConnection/PlatformConnected/PlatformConnectedWrapper';

export interface ConnectedInstagramWrapperProps {
  fbPage: FbPageType;
  instagramAccount: InstagramAccountType;
}

export const ConnectedInstagramWrapper: React.FC<ConnectedInstagramWrapperProps> =
  ({ fbPage, instagramAccount, children }) => {
    const { isSmallScreenSize } = useDeviceMedia();
    const { picture: instagramPicture, handle: instagramHandle } =
      instagramAccount;

    return (
      <PlatformConnectedWrapper data-testid="instagram-connected">
        <PlatformConnectedHeaderWrapper reversed={isSmallScreenSize}>
          <AvatarWithPlatform
            className={css.avatar}
            src={instagramPicture || accountPlaceholder}
            size={AvatarSize.big}
            platform={Platform.instagram}
          />
          <Spacer horizontalFactor={4} />
          <Flex
            flexDirection="column"
            alignItems="flex-start"
            className={css.userNameContainer}
          >
            <PlatformLink
              href={`https://www.instagram.com/${instagramHandle}/`}
              className={css.userName}
              onClick={() => {
                sendEvent({
                  category: 'grow tools',
                  action: 'go to instagram account click',
                  label: 'instagram',
                });
              }}
            >
              {instagramHandle}
            </PlatformLink>
            <Spacer factor={1} />
            <ConnectedInstagramAssociatedWith
              {...fbPage}
              onClick={() => {
                sendEvent({
                  category: 'grow tools',
                  action: 'associated facebook page click',
                  label: 'instagram',
                });
              }}
            />
          </Flex>
        </PlatformConnectedHeaderWrapper>
        <PlatformConnectedButtonsWrapper>
          {children}
        </PlatformConnectedButtonsWrapper>
      </PlatformConnectedWrapper>
    );
  };

interface ConnectedInstagramContentProps
  extends ConnectedInstagramWrapperProps {
  onDisconnect?(): void;
  onUpdatePermissions?(): void;
  onActivate?(): void;
}

export const ConnectedInstagramContent: React.FC<ConnectedInstagramContentProps> =
  ({
    fbPage,
    instagramAccount,
    onDisconnect,
    onUpdatePermissions,
    onActivate,
  }) => {
    const { isSmallScreenSize } = useDeviceMedia();
    const {
      loading: permissionsLoading,
      permissions: { hasAllPermissions, hasInstagramMessagingPermissions },
    } = useFbPermissions();
    const isInsufficientPermissions =
      !permissionsLoading &&
      (!hasAllPermissions || !hasInstagramMessagingPermissions);

    const { t } = useSafeTranslation();

    return (
      <ConnectedInstagramWrapper
        fbPage={fbPage}
        instagramAccount={instagramAccount}
      >
        {onUpdatePermissions && (
          <PlatformUpdatePermission
            insufficient={isInsufficientPermissions}
            onClick={onUpdatePermissions}
            tooltipContent={t('common.Instagram.updatePermissionsTooltip')}
            data-testid="instagram-connected__update-permissions-button"
          />
        )}
        {onActivate && (
          <>
            <Spacer
              factor={isSmallScreenSize ? 2 : 0}
              horizontalFactor={isSmallScreenSize ? 0 : 4}
            />
            <Button
              intent="primary"
              onClick={onActivate}
              style={{ width: '100%' }}
              data-testid="instagram-connected__activate-button"
            >
              {t('common.Instagram.activateButton')}
            </Button>
          </>
        )}
        {onDisconnect && (
          <>
            <Spacer
              factor={isSmallScreenSize ? 2 : 0}
              horizontalFactor={isSmallScreenSize ? 0 : 4}
            />
            <Button
              intent="secondary"
              data-testid="instagram-connected__disconnect-button"
              style={{ width: '100%' }}
              onClick={() => {
                onDisconnect?.();
              }}
            >
              {t(
                'modernComponents.FacebookPagesSetup.connectedPage.disconnectButtonTitle',
              )}
            </Button>
          </>
        )}
      </ConnectedInstagramWrapper>
    );
  };
