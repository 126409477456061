import React, { useCallback, useEffect, useState } from 'react';
import OutsideClick from 'react-outsideclick';
import { Icon } from '@ui/Icon';
import { Position, Tooltip2 } from '@ui/Tooltip2';
import { useJustSentLivechatMessagesActions } from '@utils/Data/LiveChat/Messages/hooks';
import { MessageTypeKey } from './types';

const FORCE_TOOLTIP_TIMEOUT = 5000;

export interface MessageWarningWrapperProps {
  messageId: string;
  type?: MessageTypeKey;
  errorContent?: React.ReactNode;
  forceTooltip?: boolean;
  placement?: Position;
}

export const MessageWarningWrapper: React.FC<MessageWarningWrapperProps> = ({
  messageId,
  type,
  errorContent,
  forceTooltip,
  placement: customPlacement,
  children,
}) => {
  const { removeJustSentLivechatMessageId } =
    useJustSentLivechatMessagesActions();
  const [forceTooltipDisabled, setForceTooltipDisabled] = useState(false);
  const placement: Position =
    customPlacement || (type === 'incoming' ? 'right' : 'left');

  const hideTooltip = useCallback(() => {
    removeJustSentLivechatMessageId(messageId);
    setForceTooltipDisabled(true);
  }, [messageId, removeJustSentLivechatMessageId]);

  useEffect(() => {
    let timeoutId: ReturnType<typeof setTimeout> | null = null;

    if (forceTooltip) {
      timeoutId = setTimeout(hideTooltip, FORCE_TOOLTIP_TIMEOUT);
    }

    return () => {
      if (timeoutId) clearTimeout(timeoutId);
    };
  }, [forceTooltip, hideTooltip]);

  return (
    <Tooltip2
      hoverable
      boundariesElement="viewport"
      placement={placement}
      show={forceTooltip && !forceTooltipDisabled}
      content={errorContent}
    >
      {(ref, bind) => (
        <OutsideClick
          style={{ alignSelf: 'center' }}
          onClickOutside={hideTooltip}
        >
          <div ref={ref} {...bind}>
            {children}
          </div>
        </OutsideClick>
      )}
    </Tooltip2>
  );
};

export interface MessageWarningProps extends MessageWarningWrapperProps {}

export const MessageWarning: React.FC<MessageWarningProps> = (props) => (
  <MessageWarningWrapper {...props}>
    <Icon
      icon="circleWarning"
      color="red"
      data-testid="livechat_message_row_error-icon"
    />
  </MessageWarningWrapper>
);
