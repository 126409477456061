import React from 'react';
import * as css from './ImageAttachment.css';
import { LoadingPlaceholder } from '@ui/LoadingPlaceholder';
import { Type } from '@ui/Type';
import { MessageAttachmentProps } from '../../MessageInputAttachment';
import { AttachmentBase } from '../AttachmentBase';
import { truncateWithEllipses } from 'cf-common/src/utils/String/truncateWithEllipses';
import { MAX_DISPLAY_FILE_NAME_LENGTH } from '../consts';

export const ImageAttachment: React.FC<MessageAttachmentProps> = ({
  attachment,
  onRemove,
}) => {
  if (!attachment.name) {
    return null;
  }

  return (
    <AttachmentBase
      onRemove={onRemove}
      tooltipContent={
        attachment.name.length > MAX_DISPLAY_FILE_NAME_LENGTH
          ? attachment.name
          : undefined
      }
    >
      {attachment.file ? (
        <img
          src={attachment.file}
          alt={attachment.name}
          className={css.preview}
        />
      ) : (
        <LoadingPlaceholder width={32} height={32} />
      )}
      <Type data-testid="livechat_message-attachment_name">
        {truncateWithEllipses(attachment.name, MAX_DISPLAY_FILE_NAME_LENGTH)}
      </Type>
    </AttachmentBase>
  );
};
