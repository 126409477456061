import { useQuery } from 'react-apollo';
import {
  MessagesQuery,
  MessagesQueryVariables,
} from '../components/Messages/@types/MessagesQuery';
import { MESSAGES_QUERY } from '../components/Messages/queries';
import { FetchMessageParams } from './useFetchMessage';
import { useToaster } from '@ui/Toaster';

export const useMessagesQuery = (
  params: FetchMessageParams,
  needReload: boolean = false,
) => {
  const { addToaster } = useToaster();
  const { data, ...queryState } = useQuery<
    MessagesQuery,
    MessagesQueryVariables
  >(MESSAGES_QUERY, {
    variables: params,
    fetchPolicy: needReload ? 'network-only' : undefined,
    skip: params.skip,
    onError: () => {
      addToaster({
        type: 'error',
        content: window.i18next.t(
          'useFetchMessage-string-1319-an-error-occurred-while-fetching-messages',
        ),
        closeButton: true,
        timeout: 2000,
      });
    },
  });

  return {
    items: data?.livechatMessages.items,
    cursors: data?.livechatMessages.cursors,
    ...queryState,
  };
};
