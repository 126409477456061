import React from 'react';
import { propEq } from 'ramda';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';
import { SEND_TO_MESSENGER_TEXTS } from '../sendToMessengerTexts';
import * as css from './AbandonedCartPageMessagePreview.css';
import { Flex } from '../../../../../modern-ui/Flex';
import { ReactComponent as FbIcon } from './images/fbIcon.svg';
import { MessagePreviewUserDataQuery } from './@types/MessagePreviewUserDataQuery';
import { Spacer } from '../../../../../modern-ui/Spacer';
import { ReactComponent as CloseIcon } from '../../../../../modern-ui/_deprecated/Icon/icons/ic_close_2.svg';

export interface AbandonedCartPageMessagePreviewData {
  title?: string;
  description?: string;
  button_text?: string;
}

interface AbandonedCartPageMessagePreviewProps {
  previewData: AbandonedCartPageMessagePreviewData | undefined;
}

const MESSAGE_PREVIEW_USER_DATA_QUERY = gql`
  query MessagePreviewUserDataQuery {
    me {
      id
      name
      picture
    }
  }
`;

export const AbandonedCartPageMessagePreview: React.FC<AbandonedCartPageMessagePreviewProps> = ({
  previewData,
}) => {
  const { data, loading } = useQuery<MessagePreviewUserDataQuery>(
    MESSAGE_PREVIEW_USER_DATA_QUERY,
  );

  return (
    <div className={css.background}>
      <div className={css.previewTitle}>
        {window.i18next.t(
          'AbandonedCartPageMessagePreview-JSXText-1346-preview',
        )}
      </div>
      <Spacer factor={6} />
      <div className={css.previewBox}>
        {!loading && previewData && data && (
          <div className={css.preview}>
            <CloseIcon className={css.close} />
            <div className={css.title}>{previewData.title}</div>
            <div className={css.description}>{previewData.description}</div>

            <div className={css.buttonBox}>
              <Flex alignItems="center" className={css.button}>
                <FbIcon className={css.fbIcon} />
                {
                  (
                    SEND_TO_MESSENGER_TEXTS().find(
                      propEq('id', previewData.button_text),
                    ) || {}
                  ).title
                }
              </Flex>
              <Flex alignItems="center" className={css.login}>
                <div
                  className={css.avatar}
                  style={{
                    backgroundImage: `url("${data.me.picture}")`,
                  }}
                />
                <div>{data.me.name}</div>
                <div className={css.notYou}>
                  {window.i18next.t(
                    'AbandonedCartPageMessagePreview-JSXText-1583-not-you',
                  )}
                </div>
              </Flex>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
