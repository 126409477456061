import React from 'react';
import { getMediaMessagePlaceholders } from '../../helpers/getMediaMessagePlaceholders';
import { MediaMessageOpenPlaceholder } from '../MediaMessagePlaceholder/MediaMessagePlaceholder';
import { CommonAttachment } from './CommonAttachment';
import { AttachmentProps } from './types';

export const DocumentAttachment: React.FC<AttachmentProps> = ({
  attachment,
  type,
  className,
  ...props
}) => {
  const placeholder = getMediaMessagePlaceholders().document;

  return (
    <CommonAttachment
      {...props}
      placeholder={placeholder}
      attachment={attachment}
      type={type}
    >
      {(url) => (
        <div className={className}>
          <MediaMessageOpenPlaceholder
            url={url}
            type={type}
            {...placeholder}
            text={attachment.filename || placeholder.text}
            filename={attachment.filename || undefined}
          />
        </div>
      )}
    </CommonAttachment>
  );
};
