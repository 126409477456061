import React, { useState } from 'react';
import { Flex } from '@ui/Flex';
import { UserAttribute } from '@components/UiUserAttributeEditor/UserAttributeTypes';
import { UiUserAttributeEditor } from '@components/UiUserAttributeEditor';
import { Platform } from '@globals';
import { useLiveChatSubscriber } from '../../hooks/useLiveChatSubscriber';
import { useAttributeSuggests } from './hooks/useAttributesSuggests';
import { useAttributesOptimisticSaver } from './hooks/useAttributesOptimisticSaver';

const EMPTY_ARRAY: any[] = [];

interface UserAttributesProps {
  id: string;
  botId: string;
  platform: Platform;
  readOnly: boolean;
  isSmallScreenSize: boolean;
}

export const UserAttributes: React.FC<UserAttributesProps> = ({
  id,
  botId,
  platform,
  readOnly,
  isSmallScreenSize,
}) => {
  const { subscriber, loading, error } = useLiveChatSubscriber({
    botId,
    conversationId: id,
    platform,
  });
  const dataHasBeenLoaded = !loading && !!subscriber;
  const dataHasError = !loading && error;

  const { attributes } = subscriber ?? {};
  const userAttributes: UserAttribute[] = attributes ?? [];

  const [prevId, setPrevId] = useState<string>();
  const [editorAttributes, setEditorAttributes] = useState(userAttributes);
  const [savedAttributes, setSavedAttributes] = useState(userAttributes);

  const suggestNames = useAttributeSuggests(botId, readOnly);
  const handleSave = useAttributesOptimisticSaver({
    botId,
    subscriber,
    platform,
    savedAttributes,
    setSavedAttributes,
  });

  if (prevId !== id && dataHasBeenLoaded) {
    setPrevId(id);
    setEditorAttributes(userAttributes);
    setSavedAttributes(userAttributes);
  }

  if (dataHasError) {
    return (
      <Flex
        flexDirection="column"
        fullHeight
        alignItems="center"
        justifyContent="center"
        style={{
          color: 'var(--grey)',
          paddingLeft: '1em',
          paddingRight: '1em',
        }}
      >
        {window.i18next.t(
          'UserAttributes-JSXText-2487-could-not-load-attributes-for-this-user',
        )}
      </Flex>
    );
  }

  if (!subscriber) {
    return null;
  }

  return (
    <UiUserAttributeEditor
      withSearch
      leftSideAddButton
      readOnly={readOnly}
      attributes={editorAttributes}
      onChange={setEditorAttributes}
      attributesSuggestsName={suggestNames}
      attributesSuggestsValues={EMPTY_ARRAY}
      onSaveRequest={handleSave}
      isSmallScreenSize={isSmallScreenSize}
      hideFloatButton
      scrollBoxStyle={{
        overflowY: 'visible',
      }}
    />
  );
};
