import React from 'react';
import cn from 'classnames';
import * as css from './GrowSubPageTitle.css';
import { ButtonUnstyled } from '../../../../modern-ui/Button';
import { Type } from '../../../../modern-ui/Type';

interface Breadcrumb {
  title: string;
  onClick?: () => void;
}

interface PageTitleProps extends React.HTMLProps<HTMLDivElement> {
  breadcrumbs?: Breadcrumb[];
}

export const GrowSubPageTitle: React.FC<PageTitleProps> = ({
  breadcrumbs,
  children,
  className,
  ...props
}) => (
  <div {...props} className={cn(css.PageTitle, className)}>
    {breadcrumbs &&
      breadcrumbs.map(({ title, onClick }) => (
        <React.Fragment key={title}>
          <ButtonUnstyled onClick={onClick}>
            <Type size="24px" color="grey" weight="light">
              {title}
            </Type>
          </ButtonUnstyled>
          <div className={css.dot} />
        </React.Fragment>
      ))}
    <Type size="24px" color="black" weight="light">
      {children}
    </Type>
  </div>
);
