import { Flex } from '@ui/Flex';
import { Icon } from '@ui/Icon';
import i18next from 'i18next';
import React from 'react';
import { Folder, FromType } from '@globals';
import {
  DialogsQuery_livechatConversations_items as Dialog,
  DialogsQuery_livechatConversations_items_subscriber as Subscriber,
} from '../../common/@types/DialogsQuery';
import { LivechatAdminsQuery_bot_livechatAdmins as LivechatAdmin } from '../../hooks/@types/LivechatAdminsQuery';
import { DateFormat, DateUtils, Granularity } from '@utils/DateTime';
import { stripTags } from '@utils/stripTags';
import { Spacer } from '@ui/Spacer';

export const getAssigneeForSubscriber = (
  subscriber: Subscriber,
  admins: LivechatAdmin[],
) => {
  const assigneeAttribute = subscriber.attributes.find(
    (a) => a.name === 'assignee',
  );
  if (assigneeAttribute) {
    return admins.find(({ name }) => name === assigneeAttribute.values[0]);
  }
  return undefined;
};

export const getLastActiveDateFormatted = (date: number) => {
  if (!DateUtils.isSame(date, Granularity.year)) {
    return DateFormat.MMMYYYY(date);
  }
  if (!DateUtils.isSame(date, Granularity.week)) {
    return DateFormat.MMMDD(date);
  }
  if (!DateUtils.isSame(date, Granularity.day)) {
    return DateFormat.dddd(date);
  }
  if (!DateUtils.isSame(date, Granularity.minute)) {
    return DateFormat.LT(date);
  }
  return i18next.t('Utils-string-7851-now');
};

export const getDialogLastMessageText = (dialog: Dialog): React.ReactNode => {
  const messageFromUser =
    dialog.last_message?.from.type &&
    dialog.last_message?.from.type === FromType.user;
  const prefix = messageFromUser ? '' : i18next.t('Utils-string-8561-you');

  let content: React.ReactNode;
  const {
    attachment,
    buttons,
    gallery,
    quick_replies,
    sticker_id,
    text,
    receipt,
    send_to,
    whatsapp_list: whatsappList,
    whatsapp_list_reply: whatsappListReply,
    whatsapp_template: whatsappTemplate,
    whatsapp_location: whatsappLocation,
  } = dialog.last_message!.message;

  if (gallery) {
    if (dialog.folder === Folder.inbox) {
      return i18next.t('Utils-string--188-live-chat-started');
    }
    return (
      <Flex>
        {prefix}
        <Icon icon="attachment" color="greyDark" />
      </Flex>
    );
  }

  if (attachment) {
    content = i18next.t('Utils-string-1135-sent-an-attachment');
  }
  if (sticker_id) {
    content = i18next.t('Utils-string--153-sent-an-sticker');
  }
  if (receipt) {
    content = i18next.t('shopify.orderReceiptLifeChat.dialogText');
  }
  if (buttons?.length || text || quick_replies?.length) {
    content = stripTags((text || '').replace('<br/>', ' '));
  }

  if (send_to?.title) {
    content = i18next.t('Utils-string--153-sent-to-block', {
      blockId: send_to.title,
    });
  }

  if (whatsappListReply) {
    content = whatsappListReply.row_title;
  }

  if (whatsappList) {
    content = whatsappList.header_text || whatsappList.body;
  }

  if (whatsappTemplate) {
    content =
      whatsappTemplate.header_text ||
      whatsappTemplate.body ||
      whatsappTemplate.name;
  }

  if (whatsappLocation) {
    content = (
      <Flex inline alignItems="center">
        <Spacer factor={0} horizontalFactor={1} />
        <Icon icon="location" size="16px" color="baseSemilight" />
        {whatsappLocation.name ||
          i18next.t(
            'modernComponents.FlowBuilder.plugins.WhatsappLocationPlugin.location',
          )}
      </Flex>
    );
  }

  return (
    <>
      {prefix}
      {content}
    </>
  );
};
