import React, { HTMLProps, useCallback } from 'react';
import { HEXColors } from '@ui/_common/colors';
import { MessageContentShape } from '../MessageContentShape';
import { MessageImageShape } from '../MessageContentShape/MessageImageShape';
import * as s from './MediaMessage.css';
import { Attachment, WhatsappAttachment } from './types';
import {
  useAttachment,
  useInitializeAttachmentDownload,
  usePollAttachment,
} from './hooks/useAttachment';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { MediaMessagePlaceholder } from './components/MediaMessagePlaceholder';
import { showSomethingWentWrongToaster } from '@services/MessageService';
import { getMediaMessagePlaceholders } from './helpers/getMediaMessagePlaceholders';
import { AudioAttachment } from './components/Attachments/AudioAttachment';
import { VideoAttachment } from './components/Attachments/VideoAttachment';
import { DocumentAttachment } from './components/Attachments/DocumentAttachment';
import { MediaMessageExpired } from './components/MediaMessageExpired';
import { MediaMessageLoader } from './components/MediaMessageLoader';
import { ImageAttachment } from './components/Attachments/ImageAttachment';
import { MediaMessageCaption } from './components/MediaMessageCaption';
import { useWhatsappEnabled } from '@utils/Whatsapp';
import { sendEvent } from '@utils/Analytics';
import { sendMessageToWebView } from '@utils/sendMessageToWebView';
import { useIsLiveChatWebView } from '@utils/useIsLiveChatWebView';
import {
  MessageFooter,
  MessageFooterProps,
} from '../../MessageTimestamp/MessageTimestamp';
import { FileAttachmentType } from '@utils/UploadService/types';

export const KNOWN_MESSAGE_ATTACHMENT_TYPES = (isWhatsappEnabled: boolean) =>
  isWhatsappEnabled
    ? ['image', 'video', 'audio', 'file']
    : ['image', 'video', 'audio'];

interface Props<T> extends React.HTMLProps<HTMLDivElement> {
  type: 'incoming' | 'outgoing';
  leading?: boolean;
  trailing?: boolean;
  message: {
    sticker_id: string | null;
  };
  messageId?: string;
  attachment: T;
}

export const WhatsappMediaMessage: React.FC<Props<WhatsappAttachment>> = ({
  attachment,
  type,
  messageId,
}) => {
  const { attachment_id } = attachment;
  const {
    initializeDownload,
    loading,
    expired: expiredAtInit,
  } = useInitializeAttachmentDownload();
  const {
    startPolling,
    isPolling,
    expired: expiredAtPolling,
  } = usePollAttachment(messageId, attachment.attachment_id, attachment.type);

  const onClick = useCallback(() => {
    sendEvent({
      category: 'live chat',
      action: 'load attachment click',
      propertyBag: { attachmentId: attachment_id },
    });

    initializeDownload(attachment_id)
      .then(startPolling)
      .catch(showSomethingWentWrongToaster);
  }, [attachment_id, initializeDownload, startPolling]);

  const View = {
    audio: AudioAttachment,
    video: VideoAttachment,
    file: DocumentAttachment,
    image: ImageAttachment,
  }[attachment.type];

  if (!View) {
    return null;
  }

  return (
    <View
      loading={loading || Boolean(isPolling)}
      isExpired={attachment.expired || expiredAtInit || expiredAtPolling}
      attachment={attachment}
      onClick={onClick}
      type={type}
    />
  );
};

export interface MessengerMediaMessageProps
  extends Omit<Props<Attachment>, 'color'>,
    MessageFooterProps {
  date: number;
}

export const MessengerMediaMessage: React.FC<MessengerMediaMessageProps> = ({
  type,
  leading = true,
  trailing = true,
  message,
  attachment,
  date,
  status,
  error,
  id,
  ...domAttrs
}) => {
  const { t } = useSafeTranslation();
  const { isLiveChatWebView } = useIsLiveChatWebView();
  const { url, loading, loadAttachment, expired } = useAttachment(
    attachment,
    id,
  );
  const placeholder = getMediaMessagePlaceholders()[attachment.type];
  const commonProps = {
    attachmentType: attachment.type,
    type,
    leading,
    trailing,
    ...placeholder,
  };

  if (expired) {
    return (
      <MessageContentShape type={type} leading={leading} trailing={trailing}>
        <MessageFooter error={error} date={date} type={type} status={status}>
          <MediaMessageExpired {...commonProps} />
        </MessageFooter>
      </MessageContentShape>
    );
  }

  if (loading) {
    return (
      <MessageContentShape type={type} leading={leading} trailing={trailing}>
        <MessageFooter error={error} date={date} type={type} status={status}>
          <MediaMessageLoader {...commonProps} />
        </MessageFooter>
      </MessageContentShape>
    );
  }

  if (attachment.type === 'video' || attachment.type === 'audio') {
    return (
      <div className={s.wrapper}>
        <MessageContentShape
          {...commonProps}
          className={s.image}
          // eslint-disable-next-line no-underscore-dangle
          style={{ backgroundColor: HEXColors._e4e4e4, fontSize: '90%' }}
          id={id}
          {...domAttrs}
        >
          <MessageFooter error={error} date={date} type={type} status={status}>
            <div style={{ padding: '8px 12px' }}>
              <i>
                {url && (
                  <a
                    href={url}
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={(event) => {
                      if (isLiveChatWebView) {
                        event.preventDefault();
                        sendMessageToWebView({
                          type: 'openURL',
                          payload: { url },
                        });
                      }
                    }}
                  >
                    {attachment.type === 'audio'
                      ? t('MediaMessage-string-6361-audio')
                      : t('MediaMessage-string-8265-video')}
                    {t('MediaMessage-JSXText-2061-attachment')}
                  </a>
                )}
              </i>
            </div>
          </MessageFooter>
        </MessageContentShape>
      </div>
    );
  }

  if (attachment.type === 'image' && Boolean(message.sticker_id)) {
    return (
      <div className={s.wrapper}>
        <MessageFooter error={error} date={date} type={type} status={status}>
          <div
            className={s.image}
            style={{
              backgroundImage: `url(${url})`,
              width: 72,
              height: 72,
            }}
            {...domAttrs}
          />
        </MessageFooter>
      </div>
    );
  }

  if (attachment.type === 'image' && !url) {
    return (
      <MessageContentShape type={type} leading={leading} trailing={trailing}>
        <MessageFooter error={error} date={date} type={type} status={status}>
          <MediaMessagePlaceholder {...commonProps} onClick={loadAttachment} />
        </MessageFooter>
      </MessageContentShape>
    );
  }

  if (attachment.type === 'image') {
    return (
      <div className={s.wrapper}>
        <MessageContentShape type={type} leading={leading} trailing={trailing}>
          <MessageFooter
            error={error}
            date={date}
            type={type}
            status={status}
            asOverlay
          >
            <MessageImageShape
              className={s.image}
              url={url}
              {...(domAttrs as HTMLProps<HTMLImageElement>)}
              {...commonProps}
            />
          </MessageFooter>
        </MessageContentShape>
      </div>
    );
  }

  return (
    <div className={s.wrapper}>
      <MessageContentShape
        {...commonProps}
        className={s.image}
        style={{
          backgroundImage: `url(${url})`,
          width: 300,
          height: 150,
        }}
        {...domAttrs}
      />
    </div>
  );
};

export interface MediaMessageProps
  extends Omit<Props<any>, 'color'>,
    MessageFooterProps {
  date: number;
}

export const MediaMessage: React.FC<MediaMessageProps> = ({
  type,
  leading,
  trailing,
  date,
  status,
  error,
  ...props
}) => {
  const { isWhatsappEnabled } = useWhatsappEnabled();
  const imageOrVideo = [
    FileAttachmentType.image,
    FileAttachmentType.video,
  ].includes(props.attachment.type);
  const asOverlay =
    imageOrVideo && props.attachment.url && !props.attachment.caption;
  const isAudioRenderedByDefaultHTMLTag =
    props.attachment.type === FileAttachmentType.audio && props.attachment.url;

  return isWhatsappEnabled ? (
    <MessageContentShape
      type={type}
      leading={leading}
      trailing={trailing}
      className={s.whatsappMediaMessage}
      colored={props.attachment.type === FileAttachmentType.file}
    >
      <MessageFooter
        error={error}
        date={date}
        type={type}
        asOverlay={asOverlay}
        status={status}
        isAudio={isAudioRenderedByDefaultHTMLTag}
      >
        {/* @ts-expect-error Можно будет удалить когда MessageContentShape будет вынесен для фб/инсты так же как для вотсапа выше */}
        <WhatsappMediaMessage
          type={type}
          attachment={props.attachment}
          messageId={props.id}
        />
        {props.attachment?.caption && (
          <MediaMessageCaption caption={props.attachment.caption} />
        )}
      </MessageFooter>
    </MessageContentShape>
  ) : (
    <MessengerMediaMessage
      error={error}
      type={type}
      leading={leading}
      trailing={trailing}
      date={date}
      status={status}
      {...props}
    />
  );
};
