import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { BehaviorSubject } from 'rxjs';
import { CONVERSATION_ID } from './consts';

export enum LiveChatMobilePage {
  chatList = 'chatList',
  chat = 'chat',
  attributes = 'attributes',
}

const subject = new BehaviorSubject<null | LiveChatMobilePage>(null);

export const useLiveChatMobilePageNavigation = (): [
  activePage: LiveChatMobilePage,
  setActivePage: (page: LiveChatMobilePage) => void,
] => {
  const [activePage, setActivePage] = useState<LiveChatMobilePage>(
    subject.value ?? LiveChatMobilePage.chatList,
  );
  const { search } = useLocation();

  const query = new URLSearchParams(search);
  const conversationId = query.get(CONVERSATION_ID);

  useEffect(() => {
    const subscription = subject.subscribe({
      next: (value) => {
        if (value) setActivePage(value);
      },
    });
    return () => {
      subscription.unsubscribe();
    };
  }, []);

  useEffect(() => {
    if (subject.value) return;
    if (!conversationId) setActivePage(LiveChatMobilePage.chatList);
    else setActivePage(LiveChatMobilePage.chat);
  }, [conversationId]);

  const handleSetActivePage = (page: LiveChatMobilePage) => {
    subject.next(page);
  };

  return [activePage, handleSetActivePage];
};
