import React, { useMemo } from 'react';
import { Platform } from '@globals';
import { useCurrentBotId } from '@utils/Routing';
import * as css from './Reply.css';
import { Type } from '@ui/Type';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { Spacer } from '@ui/Spacer';
import { ButtonUnstyled } from '@ui/Button';
import { TextEllipsis } from '@ui/TextEllipsis';
import { getMediaMessagePlaceholders } from '../MediaMessage/helpers/getMediaMessagePlaceholders';
import { Flex } from '@ui/Flex';
import { Icon } from '@ui/Icon';
import { useAnimation } from './hooks';
import { useBackToMessages } from '../../BackToMessagesContext';
import { useConversationId } from '../../../../hooks/useConversationId';
import { useMessagesQuery } from '../../../../hooks/useMessagesQuery';

interface ReplyProps {
  messageId: string;
  repliedMessageId: string;
}

export const Reply: React.FC<ReplyProps> = ({
  messageId,
  repliedMessageId,
}) => {
  const conversationId = useConversationId();
  const botId = useCurrentBotId()!;
  const { t } = useSafeTranslation();
  const { runAnimation } = useAnimation();
  const { stackPush } = useBackToMessages();

  const { items, loading } = useMessagesQuery({
    botId,
    conversationId,
    platform: Platform.whatsapp,
  });

  const repliedMessage = useMemo(
    () =>
      repliedMessageId
        ? items?.find(({ mid }) => mid === repliedMessageId)
        : undefined,
    [items, repliedMessageId],
  );
  const cachedWhatsappTemplate = repliedMessage?.message?.whatsapp_template;

  const iconName = useMemo(() => {
    if (!cachedWhatsappTemplate) {
      return 'template';
    }
    const placeholders = getMediaMessagePlaceholders();
    if (cachedWhatsappTemplate.header_document) {
      return placeholders.document.iconName;
    }
    if (cachedWhatsappTemplate.header_image) {
      return placeholders.image.iconName;
    }
    if (cachedWhatsappTemplate.header_video) {
      return placeholders.video.iconName;
    }
    if (cachedWhatsappTemplate.header_text) {
      return 'template';
    }
    return undefined;
  }, [cachedWhatsappTemplate]);

  if (loading) {
    return null;
  }

  const text =
    cachedWhatsappTemplate?.header_text ||
    cachedWhatsappTemplate?.body ||
    t('pages.LiveChat.Message.whatsappTemplate');

  const repliedMessageColor = cachedWhatsappTemplate
    ? 'accent1Normal'
    : 'baseTertiary';

  return (
    <>
      <ButtonUnstyled
        data-testid="livechat__reply-button"
        disabled={!cachedWhatsappTemplate}
        className={css.box}
        onClick={() => {
          runAnimation(repliedMessageId);
          stackPush(messageId);
        }}
      >
        <Flex className={css.subBox}>
          <div className={css.content}>
            <Type size="12px" color="accent1Normal" weight="bold">
              {t('pages.LiveChat.Message.reply.you')}
            </Type>

            <Spacer factor={1} />

            <Flex alignItems="center">
              {iconName && (
                <>
                  <Icon
                    icon={iconName}
                    color={repliedMessageColor}
                    size="16px"
                  />
                  <Spacer factor={0} horizontalFactor={1} />
                </>
              )}
              <Type
                size="12px"
                color={repliedMessageColor}
                className={css.title}
              >
                <TextEllipsis>{text}</TextEllipsis>
              </Type>
            </Flex>
          </div>
          {cachedWhatsappTemplate?.header_image && (
            <>
              <Spacer factor={0} horizontalFactor={1} />
              <img
                className={css.image}
                src={cachedWhatsappTemplate.header_image}
                alt=""
              />
            </>
          )}
        </Flex>
      </ButtonUnstyled>
      <Spacer factor={2} />
    </>
  );
};
