import React from 'react';
import { Tooltip2 } from '@ui/Tooltip2';
import { Flex } from '@ui/Flex';
import { Icon } from '@ui/Icon';
import { Anchor } from '@ui/Links';
import * as css from './FacebookAdCampaignsTable.css';
import { Type } from '../../../../modern-ui/Type';
import {
  FacebookAdCampaignsListQuery_bot_facebookAdCampaignsCtm_ads as FacebookAd,
  FacebookAdCampaignsListQuery_bot_facebookAdCampaignsCtm_ads_buttons as FacebookAdButton,
} from '../hooks/@types/FacebookAdCampaignsListQuery';
import { FacebookAdCustomerActionType } from '../../../../../@types/globalTypes';
import { extractMutableButtons } from './FacebookAdUtils';
import { ButtonUnstyled } from '../../../../modern-ui/Button';
import { globalHistory } from '../../../../utils/Routing';
import { getSettingsPageUrl } from './helpers';

enum FacebookAdCampaignsListNoteColor {
  red = 'red',
  greyDark = 'greyDark',
}

enum NoteTypes {
  FAQSelected,
}

interface Note {
  text: string;
  type?: NoteTypes;
  color?: FacebookAdCampaignsListNoteColor;
  component?: React.ReactNode;
}

export const SUPPORTED_FACEBOOK_AD_CUSTOMER_ACTION_TYPES = [
  FacebookAdCustomerActionType.buttons,
  FacebookAdCustomerActionType.quick_replies,
];

export const getToOtherBotNote = ({
  id,
  connected_bot,
}: Pick<FacebookAd, 'id' | 'connected_bot'>): Note => {
  const { id: botId, title } = connected_bot || {};
  return {
    text: `${window.i18next.t(
      'FacebookAdCampaignsListNote-Template--780-the-ad-that-youre-trying-to-connect-is-already-connected-to',
    )}${title}".`,
    component: (
      <>
        {window.i18next.t(
          'FacebookAdCampaignsListNote-JSXText-5139-the-ad-that-youre-trying-to-connect-is-already-connected-to',
        )}
        <ButtonUnstyled
          className={css.toOtherBotButton}
          onClick={(e) => {
            e.stopPropagation();
            globalHistory.push(`/bot/${botId}/home/ctm-ads-settings/${id}`);
          }}
        >
          {title}
        </ButtonUnstyled>
        .
      </>
    ),
  };
};

export const getFacebookAdCampaignsListButtonNote = (
  button: FacebookAdButton | undefined,
  isAutomateEnabled: boolean,
): Note | null => {
  const hasTitleError = !button?.title;
  const hasBlockError = !button?.blocks?.filter(({ removed }) => !removed)
    .length;

  if (hasTitleError && hasBlockError) {
    return {
      text: `${window.i18next.t(
        'FacebookAdCampaignsListNote-Template--203-a-quick-reply-or-button-and-a',
      )}${
        isAutomateEnabled
          ? window.i18next.t('FacebookAdCampaignsListNote-string-9383-block')
          : window.i18next.t('FacebookAdCampaignsListNote-string-3146-flow')
      }${window.i18next.t(
        'FacebookAdCampaignsListNote-Template--200-have-not-been-configured-so-your-ad-wont-run-properly-configure-a-quick-reply-or-button',
      )}`,
      color: FacebookAdCampaignsListNoteColor.red,
    };
  }
  if (hasTitleError) {
    return {
      text: window.i18next.t(
        'FacebookAdCampaignsListNote-string-2020-a-quick-reply-or-button-has-not-been-configured-so-your-ad-wont-run-properly-configure-a-quick-reply-or-button',
      ),
      color: FacebookAdCampaignsListNoteColor.red,
    };
  }
  if (hasBlockError) {
    return {
      text: `${window.i18next.t(
        'FacebookAdCampaignsListNote-Template--431-set-up-redirect-to',
      )}${
        isAutomateEnabled
          ? window.i18next.t('FacebookAdCampaignsListNote-string-9383-block')
          : window.i18next.t('FacebookAdCampaignsListNote-string-3146-flow')
      }${window.i18next.t(
        'FacebookAdCampaignsListNote-Template-4531-to-populate-stats',
      )}`,
    };
  }
  return null;
};

export const getFacebookAdCampaignsListAdNote = (
  ad: Omit<FacebookAd, 'insights'>,
  botId: string,
  skipBlocksCheck: boolean,
  isAutomateEnabled: boolean,
): Note | null => {
  const [headButton] = extractMutableButtons(ad);
  const customerActionType =
    ad.customer_action_type || FacebookAdCustomerActionType.none;

  if (!ad.connected_bot) {
    return {
      text: window.i18next.t(
        'FacebookAdCampaignsListNote-string-3667-this-ad-is-already-connected-to-a-different-page-change-a-page-in-ads-manager',
      ),
    };
  }

  if (ad.connected_bot && ad.connected_bot.id !== botId) {
    return {
      ...getToOtherBotNote(ad),
    };
  }

  if (!ad.has_greeting) {
    return {
      text: `${window.i18next.t(
        'FacebookAdCampaignsListNote-Template-2059-greeting-has-not-been-configured-so-users-wont-receive-the',
      )}${
        isAutomateEnabled
          ? window.i18next.t('FacebookAdCampaignsListNote-string-9383-block')
          : window.i18next.t('FacebookAdCampaignsListNote-string-3146-flow')
      }${window.i18next.t(
        'FacebookAdCampaignsListNote-Template--229-configure-greeting-in-ads-manager',
      )}`,
      color: FacebookAdCampaignsListNoteColor.red,
    };
  }

  if (
    customerActionType === FacebookAdCustomerActionType.ice_breakers &&
    !skipBlocksCheck
  ) {
    return {
      type: NoteTypes.FAQSelected,
      text: `${window.i18next.t(
        'FacebookAdCampaignsListNote-Template--358-you-selected-faq-as-the-customer-action-change-it-to-buttons-or-quick-replies-to-redirect-to',
      )}${
        isAutomateEnabled
          ? window.i18next.t('FacebookAdCampaignsListNote-string-9383-block')
          : window.i18next.t('FacebookAdCampaignsListNote-string-3146-flow')
      }`,
    };
  }

  if (
    !SUPPORTED_FACEBOOK_AD_CUSTOMER_ACTION_TYPES.includes(customerActionType) &&
    !skipBlocksCheck
  ) {
    return {
      text: `${window.i18next.t(
        'FacebookAdCampaignsListNote-Template-1154-choose-buttons-or-quick-reply-in-ads-manager-setup-to-redirect-to-a',
      )}${
        isAutomateEnabled
          ? window.i18next.t('FacebookAdCampaignsListNote-string-9383-block')
          : window.i18next.t('FacebookAdCampaignsListNote-string-3146-flow')
      }.`,
    };
  }

  return skipBlocksCheck
    ? null
    : getFacebookAdCampaignsListButtonNote(headButton, isAutomateEnabled);
};

interface InfoTableRowTailProps {
  tooltipLabel: string;
  href?: string;
}

const InfoTableRowTail: React.FC<InfoTableRowTailProps> = ({
  tooltipLabel,
  href,
}) => (
  <>
    <td className={css.emptyCell}>-</td>
    <td className={css.emptyCell}>-</td>
    <td>
      <Flex justifyContent="flex-end">
        <div style={{ width: '100%', textAlign: 'center' }}>-</div>
        <Tooltip2
          type="small"
          placement="top-end"
          hoverable
          content={
            <>
              <Type size="12px" color="white">
                {tooltipLabel}
              </Type>
              {href && (
                <>
                  <br />
                  <br />
                  <Anchor intent="tooltip" href={href}>
                    {window.i18next.t(
                      'FacebookAdCampaignsListNote-JSXText-7894-edit-settings',
                    )}
                  </Anchor>
                  <Type color="white" size="12px">
                    {' '}
                    {window.i18next.t(
                      'FacebookAdCampaignsListNote-JSXText-1845-↗',
                    )}
                  </Type>
                </>
              )}
            </>
          }
        >
          {(ref, bind) => (
            <div ref={ref} {...bind}>
              <Icon icon="info" color="blue" />
            </div>
          )}
        </Tooltip2>
      </Flex>
    </td>
  </>
);

export const renderFacebookAdCampaignsListButtonNote = (
  button: FacebookAdButton | undefined,
  isAutomateEnabled: boolean,
) => {
  const note = getFacebookAdCampaignsListButtonNote(button, isAutomateEnabled);

  if (!note) {
    return null;
  }

  return <InfoTableRowTail tooltipLabel={note.text} />;
};

export const renderFacebookAdCampaignsListAdNote = (
  ad: FacebookAd,
  botId: string,
  isAutomateEnabled: boolean,
) => {
  const note = getFacebookAdCampaignsListAdNote(
    ad,
    botId,
    false,
    isAutomateEnabled,
  );

  if (!note) {
    return null;
  }

  return (
    <InfoTableRowTail
      tooltipLabel={note.text}
      href={getSettingsPageUrl(botId, ad.id)}
    />
  );
};
