import { Platform } from '@globals';
import { useCurrentBotId } from '@utils/Routing';
import { useMutation, useQuery } from 'react-apollo';
import {
  CONVERSATION_NOTES_QUERY,
  CREATE_CONVERSATION_NOTE_MUTATION,
  DELETE_CONVERSATION_NOTE_MUTATION,
  UPDATE_CONVERSATION_NOTE_MUTATION,
} from './queries';
import {
  CreateConversationNoteMutation,
  CreateConversationNoteMutationVariables,
} from './@types/CreateConversationNoteMutation';
import { useCallback, useMemo, useState } from 'react';
import { createNoteData } from './helpers/createEmptyNote';
import { useAdminId } from '@utils/Admin/useAdminId';
import { sortWith, prop, descend } from 'ramda';
import {
  UpdateConversationNoteMutation,
  UpdateConversationNoteMutationVariables,
} from './@types/UpdateConversationNoteMutation';
import {
  DeleteConversationNoteMutation,
  DeleteConversationNoteMutationVariables,
} from './@types/DeleteConversationNoteMutation';
import {
  ConversationNotesQuery,
  ConversationNotesQuery_livechatConversationNotes,
  ConversationNotesQueryVariables,
} from './@types/ConversationNotesQuery';
import { SHOW_NOTES_LIMIT } from './consts';
import { isNoteNotEmpty } from './helpers/isNoteEmpty';
import { createNewNoteId } from './helpers/createNewNoteId';
import { isNewNoteId } from './helpers/isNewNoteId';
import { createOptimisticNoteId } from './helpers/createOptimisticNoteId';
import { notesCacheUpdater } from './helpers/notesCacheUpdater';
import { useLiveChatNotesSubscription } from './useLiveChatNotesSubscription';

const notesSort = sortWith<ConversationNotesQuery_livechatConversationNotes>([
  descend(prop('createdAt')),
]);

export const useLiveChatNotes = (
  conversationId: string,
  platform: Platform,
  showAll: boolean,
) => {
  const botId = useCurrentBotId()!;
  const { id: adminId } = useAdminId();
  const [newNote, setNewNote] =
    useState<ConversationNotesQuery_livechatConversationNotes | null>(null);

  const commonVariables = useMemo(
    () => ({
      conversationId,
      botId,
      platform,
    }),
    [botId, conversationId, platform],
  );

  const { data, loading } = useQuery<
    ConversationNotesQuery,
    ConversationNotesQueryVariables
  >(CONVERSATION_NOTES_QUERY, {
    variables: commonVariables,
  });

  useLiveChatNotesSubscription(conversationId, platform);

  const rawNotes = data?.livechatConversationNotes;
  const notes = useMemo<
    ConversationNotesQuery_livechatConversationNotes[]
  >(() => {
    const items = notesSort(rawNotes || []).slice(
      0,
      showAll ? undefined : SHOW_NOTES_LIMIT - (newNote ? 1 : 0),
    );
    if (newNote) {
      items.unshift(newNote);
    }
    return items;
  }, [newNote, rawNotes, showAll]);

  const [createConversationNoteMutation] = useMutation<
    CreateConversationNoteMutation,
    CreateConversationNoteMutationVariables
  >(CREATE_CONVERSATION_NOTE_MUTATION);

  const createConversationNote = useCallback(
    (text: string) => {
      const optimisticId = createOptimisticNoteId();
      notesCacheUpdater((items) => {
        items.unshift({
          ...createNoteData(adminId, optimisticId, text),
          createdAt: Date.now(),
        });
        return items;
      }, commonVariables);

      return createConversationNoteMutation({
        variables: {
          ...commonVariables,
          text,
        },
      });
    },
    [adminId, commonVariables, createConversationNoteMutation],
  );

  const [updateConversationNoteMutation] = useMutation<
    UpdateConversationNoteMutation,
    UpdateConversationNoteMutationVariables
  >(UPDATE_CONVERSATION_NOTE_MUTATION);

  const updateConversationNote = useCallback(
    (noteId: string, text: string) => {
      if (isNewNoteId(noteId)) {
        createConversationNote(text);
        setNewNote(null);
        return;
      }
      updateConversationNoteMutation({
        variables: {
          ...commonVariables,
          noteId,
          text,
        },
      });
    },
    [commonVariables, createConversationNote, updateConversationNoteMutation],
  );

  const changeConversationNote = useCallback(
    (noteId: string, text: string) => {
      if (!isNewNoteId(noteId) || !newNote) {
        return;
      }
      setNewNote({
        ...newNote,
        text,
      });
    },
    [newNote],
  );

  const [deleteConversationNoteMutation] = useMutation<
    DeleteConversationNoteMutation,
    DeleteConversationNoteMutationVariables
  >(DELETE_CONVERSATION_NOTE_MUTATION);

  const deleteConversationNote = useCallback(
    (noteId: string) => {
      if (isNewNoteId(noteId)) {
        setNewNote(null);
        return;
      }
      notesCacheUpdater(
        (items) => items.filter(({ id }) => id !== noteId),
        commonVariables,
      );
      deleteConversationNoteMutation({
        fetchPolicy: 'no-cache',
        variables: {
          ...commonVariables,
          noteId,
        },
      });
    },
    [commonVariables, deleteConversationNoteMutation],
  );

  const addConversationNote = useCallback(() => {
    if (isNoteNotEmpty(newNote)) {
      createConversationNote(newNote.text);
    }
    setNewNote({
      ...createNoteData(adminId, createNewNoteId()),
      createdAt: Date.now(),
    });
  }, [adminId, createConversationNote, newNote]);

  const totalNotesQty = (rawNotes?.length || 0) + (newNote ? 1 : 0);

  return {
    loading,
    totalNotesQty,
    needShowAll: totalNotesQty > SHOW_NOTES_LIMIT,
    notes,
    addConversationNote,
    changeConversationNote,
    updateConversationNote,
    deleteConversationNote,
  };
};
