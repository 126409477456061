import cn from 'classnames';
import React from 'react';
import { Avatar as AvatarImage } from '@ui/Avatar';
import * as css from './Avatar.css';

export const Avatar: React.FC<{
  src?: string | null;
  size?: number;
  style?: React.CSSProperties;
  className?: string;
}> = ({ src, size = 40, style = {}, className }) => {
  if (size) {
    Object.assign(style, { width: size, height: size });
  }

  return (
    <AvatarImage
      src={src ?? undefined}
      className={cn(css.avatar, className)}
      style={{ ...style, width: `${size}px`, height: `${size}px` }}
    />
  );
};
