import { useSetupEntryPoint } from '@components/FlowBuilder/utils/useSetupEntryPoint';
import { FlowSelectorModalContainer } from '@components/FlowSelectorDialog/containers/FlowSelectorModalContainer';
import { PluginType } from '@components/Plugins/common/PluginTypes';
import { Button } from '@ui/Button';
import { sendEvent } from '@utils/Analytics';
import React, { useCallback } from 'react';
import { DeprecateMessage } from '@components/DeprecateMessage/DeprecateMessage';

export const DeprecateAbandonedCartMessage: React.FC = () => {
  const {
    showFlowSelector,
    isAddFlowInProgress,
    onSetUp,
    onCloseFlowSelector,
    onSelectFlowForSetUp,
  } = useSetupEntryPoint({
    pluginType: PluginType.popup_entry_point,
    forceActivateOnSetUp: false,
  });

  const onSelectFlowForSetUpWrapper = useCallback(
    (flowId: string) => {
      onSelectFlowForSetUp(flowId);
      sendEvent({
        category: 'configure tab',
        action: 'set up in flow',
        label: PluginType.popup_entry_point,
      });
    },
    [onSelectFlowForSetUp],
  );

  const onCreateNewFlowWrapper = useCallback(
    (flowId: string) => {
      onSelectFlowForSetUp(flowId);
      sendEvent({
        category: 'configure tab',
        action: 'set up in new flow',
        label: PluginType.popup_entry_point,
      });
    },
    [onSelectFlowForSetUp],
  );

  return (
    <>
      <DeprecateMessage
        text={window.i18next.t(
          'DeprecateAbandonedCartMessage-string--787-from-july-1-2021-youll-only-find-this-feature-in-flows',
        )}
        button={
          <Button onClick={onSetUp}>
            {window.i18next.t(
              'DeprecateAbandonedCartMessage-JSXText--131-set-up-in-flows',
            )}
          </Button>
        }
      />
      {showFlowSelector && (
        <FlowSelectorModalContainer
          forceLoading={isAddFlowInProgress}
          onSelectExisting={onSelectFlowForSetUpWrapper}
          onCreateNewFlow={onCreateNewFlowWrapper}
          onClose={onCloseFlowSelector}
        />
      )}
    </>
  );
};
