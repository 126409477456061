import React, { useCallback } from 'react';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { useMutation } from '@apollo/react-hooks';

import { log } from 'cf-common/src/logger';
import {
  Messages,
  ServiceMessageType,
  toaster,
} from '@services/MessageService';
import { Modal } from '@services/.';

import { getRequestIdFromApolloError } from '@utils/GQL/utils';
import { USER_DATA_FOR_INTERCOM_QUERY } from '@utils/Intercom';
import { CONNECTED_FACEBOOK_AD_ACCOUNT_QUERY } from '@utils/MarketingApi';

import { RemoveEntityDialog } from '@components/RemoveEntityDialog';

import { UN_BIND_BOT_MUTATION } from './queries';
import {
  UnBindBotMutation,
  UnBindBotMutationVariables,
} from './@types/UnBindBotMutation';

import { BOT_FEATURES_QUERY, BOT_LIVELINESS_QUERY } from '@utils/Data/Bot';
import { BOT_STATUS_DATA_QUERY } from '@utils/Bot';
import { useDeviceMedia } from '@utils/DOM/useDeviceMedia';
import { CURRENT_BOT_QUERY } from '@utils/Data/Bot/CurrentBot/query';
import { CloseHandler } from '@services/ModalService/types';

export const useUnbindBot = (botId: string) => {
  const [unbindBotMutation, { loading }] = useMutation<
    UnBindBotMutation,
    UnBindBotMutationVariables
  >(UN_BIND_BOT_MUTATION, {
    variables: {
      botId,
    },
    refetchQueries: [
      {
        query: CONNECTED_FACEBOOK_AD_ACCOUNT_QUERY,
        variables: { botId },
      },
      { query: USER_DATA_FOR_INTERCOM_QUERY },
      { query: BOT_FEATURES_QUERY, variables: { botId } },
      { query: BOT_STATUS_DATA_QUERY, variables: { botId } },
      { query: BOT_LIVELINESS_QUERY, variables: { botId } },
      { query: CURRENT_BOT_QUERY, variables: { botId } },
    ],
    onError: (error) => {
      log.error({
        error,
        msg: 'Unbind page error',
        data: {
          label: 'fb_page',
          requestId: getRequestIdFromApolloError(error),
        },
      });
      toaster.show({
        type: ServiceMessageType.error,
        payload: {
          message: Messages.somethingWentWrong,
        },
      });
    },
  });
  return {
    unbindBot: () => unbindBotMutation(),
    unbindBotLoading: loading,
  };
};

const DisconnectPageDialog = ({
  botId,
  onDisconnecting,
  onDisconnected,
  close,
}: {
  botId: string;
  onDisconnected?(): void;
  onDisconnecting?(): void;
  close: CloseHandler;
}) => {
  const { t } = useSafeTranslation();
  const { isSmallScreenSize } = useDeviceMedia();
  const { unbindBot } = useUnbindBot(botId);

  const handleDisconnectPage = () => {
    onDisconnecting?.();
    unbindBot().then(() => {
      onDisconnected?.();
    });
    close();
  };

  return (
    <RemoveEntityDialog
      mobileAdaptive={isSmallScreenSize}
      onSubmit={handleDisconnectPage}
      onDismiss={close}
      renderHeading={() =>
        t('modernComponents.FacebookPagesSetup.disconnectPage.title')
      }
      renderActionText={() =>
        t('modernComponents.FacebookPagesSetup.disconnectPage.action')
      }
      renderNoteText={() =>
        t('modernComponents.FacebookPagesSetup.disconnectPage.description')
      }
    />
  );
};

interface UseShowDisconnectPageDialogParams {
  botId: string;
  onDisconnected?(): void;
  onDisconnecting?(): void;
}

export const useShowDisconnectPageDialog = ({
  botId,
  onDisconnecting,
  onDisconnected,
}: UseShowDisconnectPageDialogParams) => {
  const showDisconnectPageDialog = useCallback(() => {
    Modal.show(
      ({ close }) => (
        <DisconnectPageDialog
          botId={botId}
          onDisconnecting={onDisconnecting}
          onDisconnected={onDisconnected}
          close={close}
        />
      ),
      { id: 'disconnectPageDialog', mobileAdaptive: true },
    );
  }, [onDisconnected, onDisconnecting, botId]);

  return { showDisconnectPageDialog };
};
