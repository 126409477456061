import React from 'react';
import { useSafeTranslation } from '@utils/useSafeTranslation';

import { Button } from '@ui/Button';
import { DefaultDialog } from '@ui/Dialog';
import { Flex } from '@ui/Flex';
import { Spacer } from '@ui/Spacer';
import { Type } from '@ui/Type';

import * as css from './DisconnectModal.css';

interface DisconnectModalProps {
  onClose: () => void;
  onDisconnect: () => void;
}

export const DisconnectModal: React.FC<DisconnectModalProps> = ({
  onClose,
  onDisconnect,
}) => {
  const { t } = useSafeTranslation();
  return (
    <DefaultDialog
      header={t('common.Instagram.disconnectModalHeader')}
      dialogStyle={{ width: 463 }}
      onDismiss={onClose}
      footer={() => (
        <Flex justifyContent="flex-end">
          <Button
            intent="secondary"
            onClick={onClose}
            data-testid="instagram-disconnect-dialog__cancel-button"
          >
            {t('common.Instagram.disconnectModalCancelButton')}
          </Button>
          <Spacer horizontalFactor={4} />
          <Button
            intent="red"
            onClick={onDisconnect}
            data-testid="instagram-disconnect-dialog__disconnect-button"
          >
            {t('common.Instagram.disconnectModalDisconnectButton')}
          </Button>
        </Flex>
      )}
    >
      <Flex className={css.description}>
        <Type size="15px">
          {t('common.Instagram.disconnectModalDescription')}
        </Type>
      </Flex>
    </DefaultDialog>
  );
};
