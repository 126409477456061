import React, { useRef, useEffect } from 'react';

interface ScrollState {
  scrollOffset: number;
  topEdgeProximity: number | null;
  bottomEdgeProximity: number | null;
}

export const useScrollState = (
  ref: React.MutableRefObject<HTMLDivElement | null>,
  onScrollStateChange: (scrollState: ScrollState) => void,
) => {
  const scrollData = useRef<ScrollState>({
    scrollOffset: 0,
    topEdgeProximity: null,
    bottomEdgeProximity: null,
  });

  useEffect(() => {
    const node = ref.current;
    function handleScroll(event: Event) {
      const scrollNode = event.currentTarget as HTMLDivElement;
      scrollData.current = {
        scrollOffset: scrollNode.scrollTop,
        topEdgeProximity: scrollNode.scrollTop,
        bottomEdgeProximity:
          scrollNode.scrollHeight -
          (scrollNode.scrollTop + scrollNode.offsetHeight),
      };

      onScrollStateChange(scrollData.current);
    }
    if (node) {
      node.addEventListener('scroll', handleScroll, { passive: true });

      return () => {
        node.removeEventListener('scroll', handleScroll);
      };
    }
    return undefined;
  }, [onScrollStateChange, ref]);

  return scrollData;
};
