import React from 'react';
import { FadeInCallout } from '@ui/Callout';
import * as css from './CopilotPromoBanner.css';
import { useSafeTranslation } from '@utils/useSafeTranslation';

interface CopilotPromoBannerProps {
  className?: string;
  onHide?: VoidFunction;
}

export const CopilotPromoBanner: React.FC<CopilotPromoBannerProps> = ({
  className,
  onHide,
}) => {
  const { t } = useSafeTranslation();
  return (
    <FadeInCallout
      className={className}
      text={t('Copilot.promoText')}
      type="purple"
      icon="ai"
      closeButtonClassName={css.closeButton}
      onClose={onHide}
    />
  );
};
