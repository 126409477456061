import gql from 'graphql-tag';
import { ATTACHMENT_FRAGMENT } from '../components/Messages/queries';

export const CONVERSATION_SUBSCRIBER_FRAGMENT = gql`
  fragment ConversationSubscriberFragment on Subscriber {
    id
    name
    avatarUrl
    chatfuelUserId
    attributes {
      id
      name
      values
      type
    }
  }
`;

export const CONVERSATION_FRAGMENT = gql`
  fragment ConversationFragment on LivechatConversationV3 {
    id
    seen
    unseen_messages_count
    outside_livechat_window
    outside_24h_window
    last_action_date
    updated
    folder
    platform
    last_seen
    subscriber {
      ...ConversationSubscriberFragment
    }
    last_message {
      id
      mid
      from {
        type
        admin_id
      }
      date
      seen
      conversationId
      status
      message {
        text
        buttons
        attachment {
          ...attachmentFragment
        }
        quick_replies
        gallery {
          type
        }
        sticker_id
        receipt {
          order_number
        }
        send_to {
          title
        }
        whatsapp_list {
          header_text
          body
        }
        whatsapp_list_reply {
          row_title
        }
        whatsapp_template {
          name
          body
          footer
          header_text
          header_document {
            name
            link
          }
          header_image
          header_video
          buttons {
            type
            text
            url
          }
        }
        whatsapp_location {
          longitude
          latitude
          name
          address
        }
        whatsapp_context {
          forwarded
          frequently_forwarded
          originalMessageType
          msgId
        }
      }
      error {
        message
        code
        error_subcode
      }
    }
  }
  ${CONVERSATION_SUBSCRIBER_FRAGMENT}
  ${ATTACHMENT_FRAGMENT}
`;

export const DIALOG_FRAGMENT = gql`
  fragment dialogFragment on LivechatConversationV3 {
    id
    seen
    unseen_messages_count
    folder
    platform
    last_action_date
    outside_livechat_window
    outside_24h_window
    updated
    last_seen
    subscriber {
      id
      name
      avatarUrl
      chatfuelUserId
      attributes {
        id
        type
        name
        values
      }
    }
    last_message {
      id
      date
      status
      seen
      from {
        type
        admin_id
      }
      message {
        text
        buttons
        attachment {
          id
          filename
          type
        }
        quick_replies
        gallery {
          type
        }
        sticker_id
        receipt {
          order_number
        }
        send_to {
          title
        }
        whatsapp_list {
          header_text
          body
        }
        whatsapp_list_reply {
          row_title
        }
        whatsapp_template {
          name
          body
          header_text
          header_document {
            name
            link
          }
          header_image
          header_video
        }
        whatsapp_location {
          longitude
          latitude
          name
          address
        }
      }
    }
  }
`;

export const DIALOGS_QUERY = gql`
  query DialogsQuery(
    $botId: String!
    $segmentation: SegmentationInput!
    $folder: Folder!
    $from: String
    $to: String
    $platform: Platform
  ) {
    livechatConversations(
      botId: $botId
      segmentation: $segmentation
      folder: $folder
      from: $from
      to: $to
      platform: $platform
    ) {
      cursors {
        before
        after
      }
      items {
        ...dialogFragment
      }
    }
  }
  ${DIALOG_FRAGMENT}
`;

export const DIALOGS_COUNT_QUERY = gql`
  query DialogsCountQuery(
    $botId: String!
    $segmentation: SegmentationInput!
    $folder: Folder!
    $from: String
    $to: String
    $platform: Platform
  ) {
    livechatConversationsCount(
      botId: $botId
      segmentation: $segmentation
      folder: $folder
      from: $from
      to: $to
      platform: $platform
    ) {
      count
    }
  }
`;

export const NEW_DIALOGS_QUERY = gql`
  query NewDialogsQuery(
    $botId: String!
    $segmentation: SegmentationInput!
    $folder: Folder!
    $from: String!
    $platform: Platform
  ) {
    newLivechatConversations(
      botId: $botId
      segmentation: $segmentation
      folder: $folder
      from: $from
      platform: $platform
    ) {
      cursors {
        before
        after
      }
      items {
        ...dialogFragment
      }
    }
  }
  ${DIALOG_FRAGMENT}
`;

export const LIVECHAT_SUBSCRIBER_QUERY = gql`
  query LivechatSubscriberQuery(
    $botId: String!
    $conversationId: String!
    $platform: Platform!
  ) {
    livechatConversation(
      botId: $botId
      conversationId: $conversationId
      platform: $platform
    ) {
      id
      subscriber {
        ...ConversationSubscriberFragment
      }
    }
  }
  ${CONVERSATION_SUBSCRIBER_FRAGMENT}
`;

export const MARK_MESSAGES_AS_SEEN_MUTATION = gql`
  mutation MarkMessagesAsSeenMutation(
    $botId: String!
    $conversationId: String!
    $platform: Platform!
    $messageIds: [String!]
  ) {
    markMessagesAsSeen(
      botId: $botId
      conversationId: $conversationId
      platform: $platform
      messageIds: $messageIds
    )
  }
`;
