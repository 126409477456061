import React from 'react';
import { ServerStorageItemKeys } from '../../../../utils/ServerStorage';
import { useShowNTimes } from '../../../../utils/ShowNTimes';
import {
  GrowItemTopBannerCommonProps,
  GrowItemTopBannerInner,
} from './GrowItemTopBannerInner';

interface GrowItemTopBannerProps extends GrowItemTopBannerCommonProps {
  serverStorageKey: ServerStorageItemKeys;
  showLimit?: number;
}

export const GrowItemTopBanner: React.FC<GrowItemTopBannerProps> = ({
  serverStorageKey,
  showLimit = 1,
  ...props
}) => {
  const { loading, show, setCount } = useShowNTimes(
    serverStorageKey,
    showLimit,
  );

  if (loading || !show) {
    return null;
  }

  return (
    <GrowItemTopBannerInner
      onCloseClick={() => setCount(showLimit)}
      {...props}
    />
  );
};
