import Maybe from 'graphql/tsutils/Maybe';

export enum MessagePosition {
  leading,
  middle,
  trailing,
  single,
}

export enum MessageSourceType {
  incoming,
  outgoing,
}

export interface MessageViewProps {
  attachment: Maybe<{ url: Maybe<string> }>;
}
