import { BotTabs, getTabLink, useLocationParams } from '@utils/Routing';
import { Messages, toaster } from '@services/MessageService';
import gql from 'graphql-tag';
import { removeGlobalLoader } from 'cf-common/src/utils/removeGlobalLoader';
import { Level, log } from 'cf-common/src/logger';
import React, { useEffect } from 'react';
import { useMutation } from 'react-apollo';
import { useHistory } from 'react-router-dom';
import {
  ConfirmIntegrationCalendlyOAuthMutation,
  ConfirmIntegrationCalendlyOAuthMutationVariables,
} from './@types/ConfirmIntegrationCalendlyOAuthMutation';

export const CONFIRM_INTEGRATION_CALENDLY_OAUTH_MUTATION = gql`
  mutation ConfirmIntegrationCalendlyOAuthMutation(
    $botId: String!
    $code: String!
  ) {
    confirmIntegrationCalendlyOAuth(botId: $botId, code: $code)
  }
`;

export const IntegrationCalendlyConnectPage: React.FC = () => {
  const history = useHistory();
  const [
    confirmIntegrationCalendlyOAuth,
    { data, error: mutationError, loading, called },
  ] = useMutation<
    ConfirmIntegrationCalendlyOAuthMutation,
    ConfirmIntegrationCalendlyOAuthMutationVariables
  >(CONFIRM_INTEGRATION_CALENDLY_OAUTH_MUTATION);
  const { error: integrationError, code, state } = useLocationParams();
  const { botId, flowId, blockId } = JSON.parse(state);

  useEffect(() => {
    if (!code || !botId) {
      return;
    }

    confirmIntegrationCalendlyOAuth({
      variables: {
        botId,
        code,
      },
    });
  }, [confirmIntegrationCalendlyOAuth, botId, code]);

  useEffect(() => {
    if (called && !loading) {
      removeGlobalLoader();
    }
  }, [called, loading]);

  useEffect(() => {
    if (integrationError || mutationError) {
      log({
        msg: 'Calendly integration error',
        level: Level.error,
        data: {
          error: mutationError?.message || integrationError,
        },
      });

      toaster.error({
        payload: {
          message: Messages.somethingWentWrong,
        },
      });
    }

    if (
      integrationError ||
      mutationError ||
      (botId && data?.confirmIntegrationCalendlyOAuth)
    ) {
      history.push(getTabLink(BotTabs.flows, botId, { flowId, blockId }));
    }
  }, [mutationError, integrationError, botId, data, flowId, blockId, history]);

  return null;
};
