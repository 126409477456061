import gql from 'graphql-tag';

export const SEND_TO_BLOCK_MUTATION = gql`
  mutation SendToBlockMutation(
    $botId: String!
    $conversationId: String!
    $blockId: String!
    $platform: Platform!
  ) {
    sendToBlock(
      botId: $botId
      conversationId: $conversationId
      blockId: $blockId
      platform: $platform
    ) {
      id
      date
      mid
      outpacingId
    }
  }
`;
