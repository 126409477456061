import cn from 'classnames';
import React, { useEffect, useState } from 'react';
import { useDeviceMedia } from '@utils/DOM/useDeviceMedia';
import { LivechatFilter } from '../FilterableSearch';
import { CollapseButton } from '@components/FlowBuilder/EditorPanel/components/editors/components/CollapseButton';
import { LSKey, useLocalStorage } from '@utils/LocalStorage';
import { ChatListView, ChatListViewProps } from './ChatListView';
import { AttributesFilterView } from './AttributesFilterView';
import * as css from './ChatList.css';

export interface ChatListProps
  extends Omit<ChatListViewProps, 'onChooseFilter'> {}

export const ChatList: React.FC<ChatListProps> = ({
  conversationId,
  children,
  ...props
}) => {
  const { isSmallScreenSize } = useDeviceMedia();
  const [filterTypeView, setFilterTypeView] = useState<LivechatFilter | null>(
    null,
  );
  const [showPanel, setShowPanel] = useLocalStorage(
    LSKey.isLiveChatLeftPanelShow,
    true,
  );

  useEffect(() => {
    if (!conversationId && !showPanel) {
      setShowPanel(true);
    }
  }, [conversationId, setShowPanel, showPanel]);

  const isAttributeView = filterTypeView === LivechatFilter.attribute;

  return (
    <div
      className={cn(
        isSmallScreenSize ? css.root_mobile : css.root,
        !showPanel && css.collapse,
        conversationId && !isAttributeView && css.buttonPlace,
        isAttributeView && css.root_wide,
      )}
    >
      {isAttributeView ? (
        <AttributesFilterView onClose={() => setFilterTypeView(null)} />
      ) : showPanel || isSmallScreenSize ? (
        <ChatListView
          conversationId={conversationId}
          onChooseFilter={setFilterTypeView}
          {...props}
        />
      ) : null}

      {!isSmallScreenSize && !isAttributeView && conversationId && (
        <CollapseButton
          onClick={() => {
            setShowPanel?.(!showPanel);
          }}
          active={!!showPanel}
        />
      )}
    </div>
  );
};
