import React, { memo, useMemo } from 'react';
import { IS_DEBUG } from 'cf-common/src/environment';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { useMutation, useQuery } from 'react-apollo';
import memoize from 'memoize-one';
import { clone } from 'ramda';

import { toaster } from '@services/MessageService';
import { Flex } from '@ui/Flex';
import { Icon } from '@ui/Icon';
import { ServiceMessageType } from '@ui/ServiceMessage2';
import { Spacer } from '@ui/Spacer';
import { useToaster } from '@ui/Toaster';
import { Type } from '@ui/Type';
import { sendEvent } from '@utils/Analytics';
import { BotTabs, getTabLink, useCurrentBotId } from '@utils/Routing';
import { useInstagramAccount } from '@utils/Data/Instagram/BotAccount';
import { usePageConnected } from '@utils/FacebookPages/usePageConnected';
import { Value } from '@utils/DynamicListDataSource/DynamicListDataSource';
import {
  createDataSource,
  DynamicListSubscription,
} from '@utils/DynamicListDataSource';
import {
  DynamicListView,
  DynamicListViewProps,
} from '@components/DynamicListView';
import {
  ConnectPageField,
  ConnectPageOrigin,
  useConnectPage,
} from '@components/ConnectPageDialog';
import {
  ConnectInstagramRestorationQueryField,
  ConnectInstagramVariant,
  useConnectInstagramDialog,
} from '@components/dialogs/ConnectInstagramDialog';
import {
  Folder,
  Platform,
  SegmentationInput,
  UserAttributeType,
} from '@globals';

import {
  CONVERSATION_FRAGMENT,
  DIALOGS_QUERY,
  NEW_DIALOGS_QUERY,
} from '../../common/queries';
import {
  ConversationFragment_subscriber,
  ConversationFragment_subscriber_attributes,
} from '../../common/@types/ConversationFragment';
import { CONVERSATION_QUERY } from '../../hooks/useLiveChatConversation';
import {
  ConversationQuery,
  ConversationQueryVariables,
} from '../../hooks/@types/ConversationQuery';
import { useLiveChatSegmentation } from '../../hooks/useLiveChatSegmentation';
import { AvailableSizeDetector } from '../AvailableSizeDetector';
import { DialogItem, DialogItemPlaceholder } from '../DialogItem';
import { UPDATE_USER_ATTRIBUTES } from '../UserAttributes/queries';
import {
  UpdateUserAttributes,
  UpdateUserAttributesVariables,
} from '../UserAttributes/@types/UpdateUserAttributes';
import {
  DialogsQuery,
  DialogsQuery_livechatConversations_items as LivechatConversation,
  DialogsQueryVariables,
} from '../../common/@types/DialogsQuery';
import { FacebookNotConnected, InstagramNotConnected } from './Placeholders';
import { useDeviceMedia } from '@utils/DOM/useDeviceMedia';
import { useWhatsappConnected } from '@utils/Data/Whatsapp';
import { WhatsappNotConnected } from './Placeholders/NotConnected/NotConnected';
import { useWhatsappEnabled } from '@utils/Whatsapp';
import { useHistory } from 'react-router-dom';
import { DeepLinksMode } from '@pages/DeepLinks/types';
import { useLiveChatDialogsQtyState } from '../../hooks/useLiveChatDialogsQtyState';
import { useIsLiveChatWebView } from '@utils/useIsLiveChatWebView';
import { log } from 'cf-common/src/logger';
import { useSubscribeToDialogsUpdates } from './hocks/useSubscribeToDialogsUpdates';

const DIALOG_HEIGHT = 56;
const MOBILE_DIALOG_HEIGHT = 64;

interface DialogListItemProps {
  index: number;
  style: React.CSSProperties;
  data: {
    items: LivechatConversation[];
    getConversationHref: (conversationId: string, platform: Platform) => string;
    activeItemId?: string | null;
    segmentation: SegmentationInput;
    folder: Folder;
    platform: Platform;
    onClick(): void;
    isSmallScreenSize: boolean;
  };
}

const DialogListItem = memo(({ index, style, data }: DialogListItemProps) => {
  const { t } = useSafeTranslation();
  const botId = useCurrentBotId() ?? '';
  const { addToaster } = useToaster();

  const [updateUserAttributes] = useMutation<
    UpdateUserAttributes,
    UpdateUserAttributesVariables
  >(UPDATE_USER_ATTRIBUTES, {
    onCompleted({ updateUserAttributes }) {
      if (updateUserAttributes) {
        if (updateUserAttributes.update?.length) {
          addToaster({
            type: 'info',
            content: (
              <div style={{ minWidth: '300px', whiteSpace: 'nowrap' }}>
                {t('Dialogs-JSXText-9416-conversation-assigned-to')}{' '}
                {updateUserAttributes.update?.[0]?.value}.
              </div>
            ),
            timeout: 3000,
            closeButton: true,
          });
        } else if (updateUserAttributes.delete?.length) {
          addToaster({
            type: 'info',
            content: (
              <div style={{ minWidth: '300px', whiteSpace: 'nowrap' }}>
                {t('Dialogs-JSXText-1820-conversation-unassigned')}
              </div>
            ),
            timeout: 3000,
            closeButton: true,
          });
        }
      }
    },
    onError() {
      addToaster({
        type: 'error',
        content: (
          <div style={{ minWidth: '300px', whiteSpace: 'nowrap' }}>
            {t(
              'Dialogs-JSXText-8890-something-went-wrong-please-try-again-later',
            )}
          </div>
        ),
        timeout: 3000,
        closeButton: true,
      });
    },
  });

  const {
    items,
    getConversationHref,
    activeItemId,
    segmentation,
    folder,
    onClick,
    isSmallScreenSize,
  } = data;

  if (index >= items.length) {
    return (
      <div style={style} key={index}>
        <DialogItemPlaceholder />
      </div>
    );
  }

  const dialog = items[index];
  const { platform, id: conversationId } = dialog;

  return (
    <div style={style} key={conversationId}>
      <DialogItem
        onClick={onClick}
        isSmallScreenSize={isSmallScreenSize}
        dialog={dialog}
        to={getConversationHref(conversationId, platform || Platform.facebook)}
        isSelected={activeItemId === conversationId}
        onAdminAssign={(admin) => {
          sendEvent({
            category: 'live chat',
            action: 'assign to a teammate',
            propertyBag: {
              adminId: admin?.id ?? 'unassigned',
              conversationId,
            },
          });

          const update = admin?.name
            ? { update: [{ name: 'assignee', value: admin.name }] }
            : { delete: ['assignee'] };

          updateUserAttributes({
            variables: {
              botId,
              userId: dialog.subscriber.attributes.find(
                (v) => v.name === 'user id',
              )?.values[0]!,
              update,
            },
            update(cache, { errors }) {
              if (errors) {
                return;
              }

              let result;
              try {
                result = cache.readQuery<
                  ConversationQuery,
                  ConversationQueryVariables
                >({
                  query: CONVERSATION_QUERY,
                  variables: {
                    botId,
                    conversationId,
                    platform: platform ?? data.platform,
                  },
                });
              } catch (error) {
                log.error({
                  msg: 'adminAssign read conversation query failed',
                  data: { error },
                });
              }

              const attributeId = `${conversationId}_assignee_custom`;
              const updateAttribute = (
                subscriber: ConversationFragment_subscriber,
                attribute?: ConversationFragment_subscriber_attributes,
              ) => {
                /* eslint-disable no-param-reassign */
                if (update.update?.length) {
                  if (attribute) {
                    attribute.values = [admin?.name!];
                  } else {
                    subscriber.attributes.push({
                      __typename: 'UserAttribute',
                      id: attributeId,
                      name: 'assignee',
                      type: UserAttributeType.custom,
                      values: [admin?.name!],
                    });
                  }
                } else if (update.delete?.length) {
                  subscriber.attributes = subscriber.attributes.filter(
                    (v) => v.id !== attributeId,
                  );
                }
                /* eslint-enable no-param-reassign */
              };

              if (result?.livechatConversation) {
                const newData = result.livechatConversation;
                const attribute = newData.subscriber.attributes.find(
                  (v) => v.id === attributeId,
                );
                updateAttribute(newData.subscriber, attribute);

                try {
                  const data = clone(result.livechatConversation);

                  cache.writeFragment({
                    id: `LivechatConversationV3:${result.livechatConversation.id}`,
                    fragment: CONVERSATION_FRAGMENT,
                    fragmentName: 'ConversationFragment',
                    data,
                  });
                } catch (error) {
                  log.error({
                    msg: 'adminAssign write conversation fragment failed',
                    data: { error },
                  });
                }
              } else {
                const dialogsQueryOptions = {
                  query: DIALOGS_QUERY,
                  variables: { botId, folder, segmentation, platform },
                };
                let result;
                try {
                  result = cache.readQuery<DialogsQuery, DialogsQueryVariables>(
                    dialogsQueryOptions,
                  );
                } catch (error) {
                  log.error({
                    msg: 'adminAssign read dialogs query failed',
                    data: { error },
                  });
                  result = null;
                }
                if (result) {
                  const newData = clone(result);
                  const subscriber = newData.livechatConversations.items.find(
                    (v) => v.id === conversationId,
                  )?.subscriber;
                  if (subscriber) {
                    const attribute = subscriber?.attributes.find(
                      (v) => v.id === attributeId,
                    );
                    updateAttribute(subscriber, attribute);
                    try {
                      cache.writeQuery({
                        ...dialogsQueryOptions,
                        data: newData,
                      });
                    } catch (error) {
                      log.error({
                        msg: 'adminAssign write dialogs query failed',
                        data: { error },
                      });
                    }
                  }
                }
              }
            },
          });
        }}
      />
    </div>
  );
});

const createDialogItemDataMemoized = memoize(
  (
    items,
    getConversationHref,
    activeItemId,
    segmentation,
    folder,
    platform,
    onClick,
    isSmallScreenSize,
  ) => ({
    items,
    getConversationHref,
    activeItemId,
    segmentation,
    folder,
    platform,
    onClick,
    isSmallScreenSize,
  }),
);

interface DialogsProps {
  botId: string;
  activeItemId?: string | null;
  getConversationHref: (conversationId: string, platform: Platform) => string;
  folder: Folder;
  platform: Platform | null;
  itemClick(): void;
}

export const Dialogs: React.FC<DialogsProps> = ({
  botId,
  activeItemId,
  getConversationHref,
  folder,
  platform,
  itemClick,
}) => {
  const { t } = useSafeTranslation();
  const { isSmallScreenSize } = useDeviceMedia();
  const { isLiveChatWebView } = useIsLiveChatWebView();
  const history = useHistory();
  const segmentation = useLiveChatSegmentation(botId);
  const { setDialogsQty } = useLiveChatDialogsQtyState();

  // update dialogs cache
  useQuery<DialogsQuery, DialogsQueryVariables>(DIALOGS_QUERY, {
    variables: { botId, segmentation, folder, platform },
    fetchPolicy: 'network-only',
  });

  const dataSource = useMemo(
    () =>
      createDataSource<DialogsQuery>({
        botId,
        query: DIALOGS_QUERY,
        variables: { botId, segmentation, folder, platform },
        resultsKey: 'livechatConversations',
        /** indicate that the server response is in reverse-chronological order */
        expectedResponseSorting: 'reverse-chronological',
        updatesMergeStrategy: {
          strategy: 'merge',
          sortedByKey: 'last_action_date',
        },
        onError: () => {
          if (IS_DEBUG)
            toaster.show({
              type: ServiceMessageType.error,
              payload: {
                message: t(
                  'Dialogs-string-1574-an-error-occurred-while-fetching-conversations',
                ),
              },
            });
        },
        pollingOptions: {
          query: NEW_DIALOGS_QUERY,
          getVariables: (currentResult, timestamp) => ({
            botId,
            segmentation,
            folder,
            from:
              currentResult.livechatConversations.cursors.after ||
              String(timestamp),
            platform,
          }),
          resultsKey: 'newLivechatConversations',
        },
      }),
    [botId, segmentation, folder, platform, t],
  );

  useSubscribeToDialogsUpdates(dataSource.queryObservable);

  const { isConnected, loading: connectPageLoading } = usePageConnected(botId);
  const { instagramConnected, instagramDataIsLoading } =
    useInstagramAccount(botId);
  const { isWhatsappEnabled } = useWhatsappEnabled();
  const {
    isConnected: isWhatsappConnected,
    loading: whatsappConnectedLoading,
  } = useWhatsappConnected(botId);
  const { connectInstagram } = useConnectInstagramDialog(
    ConnectInstagramVariant.full,
    {
      [ConnectInstagramRestorationQueryField.name]:
        ConnectPageOrigin.liveChatIg,
    },
  );
  const { connectPage } = useConnectPage({
    botId,
    onPageConnected({ pageId, close, urlParams }) {
      close?.();
      if (
        pageId &&
        urlParams?.[ConnectPageField.origin] === ConnectPageOrigin.liveChatIg
      ) {
        connectInstagram();
      }
    },
  });
  const handleConnect = () => {
    if (platform === Platform.instagram) {
      if (isConnected) {
        connectInstagram();
      } else {
        connectPage({
          urlParams: {
            [ConnectPageField.origin]: ConnectPageOrigin.liveChatIg,
          },
        });
      }
    } else {
      if (isWhatsappEnabled) {
        history.push(
          getTabLink(BotTabs.home, botId, {
            dlMode: DeepLinksMode.connectWhatsapp,
          }),
        );
        return;
      }
      connectPage({
        urlParams: {
          [ConnectPageField.origin]: ConnectPageOrigin.liveChatFb,
        },
      });
    }
  };

  if (
    connectPageLoading ||
    instagramDataIsLoading ||
    whatsappConnectedLoading
  ) {
    return null;
  }

  const isPlatformNotConnected =
    !isConnected ||
    (!instagramConnected && platform === Platform.instagram) ||
    (!isWhatsappConnected && platform === Platform.whatsapp);

  if (isPlatformNotConnected) {
    const Component = {
      [Platform.facebook]: FacebookNotConnected,
      [Platform.instagram]: InstagramNotConnected,
      [Platform.whatsapp]: WhatsappNotConnected,
    }[platform || (isWhatsappEnabled ? Platform.whatsapp : Platform.facebook)];

    return isLiveChatWebView ? null : (
      <Component
        onConnect={handleConnect}
        isSmallScreenSize={isSmallScreenSize}
      />
    );
  }

  return (
    <DynamicListSubscription source={dataSource}>
      {(value) => {
        const {
          fetchOlderItems,
          queryResult: { data, error, loading },
        } = value as Value<DialogsQuery>;
        if (error) {
          throw new Error(error.message);
        }
        if (loading) {
          return (
            // eslint-disable-next-line @typescript-eslint/no-use-before-define
            <LiveChatDialogList
              isItemLoaded={() => true}
              loadMoreItems={() => Promise.resolve()}
              itemCount={isSmallScreenSize ? 4 : 10}
            >
              {DialogItemPlaceholder}
            </LiveChatDialogList>
          );
        }
        if (!data || !('livechatConversations' in data)) {
          return null;
        }
        const { items: dialogs, cursors } = data.livechatConversations;

        setDialogsQty(dialogs.length);

        const dialogsSize = dialogs.length;
        if (!dialogsSize) {
          return (
            <Flex
              flexDirection="column"
              fullHeight
              alignItems="center"
              justifyContent="center"
              style={{
                maxWidth: '80%',
                marginLeft: 'auto',
                marginRight: 'auto',
              }}
            >
              <>
                <Icon icon="dialog" />
                <Spacer factor={4} />
                <Type size="18px" weight="medium">
                  {t('Dialogs-JSXText--119-no-conversations')}
                </Type>
                <Spacer factor={2} />
                <Type size="15px_DEPRECATED">
                  {t(
                    'Dialogs-JSXText--155-your-conversations-will-appear-here',
                  )}
                </Type>
              </>
            </Flex>
          );
        }

        const itemData = createDialogItemDataMemoized(
          dialogs,
          getConversationHref,
          activeItemId,
          segmentation,
          folder,
          platform,
          itemClick,
          isSmallScreenSize,
        );

        return (
          // eslint-disable-next-line @typescript-eslint/no-use-before-define
          <LiveChatDialogList
            isItemLoaded={(index) => index < dialogsSize}
            loadMoreItems={
              cursors.before ? fetchOlderItems : () => Promise.resolve()
            }
            itemCount={cursors.before ? dialogsSize + 50 : dialogsSize}
            itemData={itemData}
          >
            {DialogListItem}
          </LiveChatDialogList>
        );
      }}
    </DynamicListSubscription>
  );
};

interface LiveChatDialogListProps
  extends Pick<
    DynamicListViewProps,
    'isItemLoaded' | 'itemCount' | 'itemData' | 'loadMoreItems' | 'children'
  > {}

const LiveChatDialogList: React.FC<LiveChatDialogListProps> = ({
  isItemLoaded,
  loadMoreItems,
  itemCount,
  itemData,
  children,
}) => {
  const { isSmallScreenSize } = useDeviceMedia();
  return (
    <AvailableSizeDetector monitorWidth={false} monitorHeight>
      {({ size }) => (
        <DynamicListView
          height={size.height as number}
          width="100%"
          itemSize={isSmallScreenSize ? MOBILE_DIALOG_HEIGHT : DIALOG_HEIGHT}
          isItemLoaded={isItemLoaded}
          itemCount={itemCount}
          itemData={itemData}
          loadMoreItems={loadMoreItems}
          threshold={20}
        >
          {children}
        </DynamicListView>
      )}
    </AvailableSizeDetector>
  );
};
