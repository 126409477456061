import React, { useCallback } from 'react';
import { Folder, Platform } from '@globals';
import { Flex, FlexItem } from '@ui/Flex';
import { Spacer } from '@ui/Spacer';
import { sendEvent } from '@utils/Analytics';
import { useDeviceMedia } from '@utils/DOM/useDeviceMedia';
import { addUrlSearchParams } from '@utils/Routing/addUrlSearchParams';
import cn from 'classnames';
import { useHistory, useLocation } from 'react-router-dom';
import { Dialogs } from '../Dialogs/Dialogs';
import { DialogSettingsMenu } from '../DialogSettingsMenu';
import { LivechatFilter, LivechatFilterableSearch } from '../FilterableSearch';
import { FolderSelector } from '../FolderSelector';
import { InstagramPermissionsBanner } from '../InstagramPermissionsBanner/InstagramPermissionsBanner';
import { NotificationsBanner } from '../NotificationsBanner';
import {
  extendedPlatformFromPlatform,
  PlatformSelector,
} from '../PlatformSelector';
import { SendFlowBanner } from '../SendFlowBanner';
import * as css from './ChatListView.css';
import { useWhatsappEnabled } from '@utils/Whatsapp';
import { useIsLiveChatWebView } from '@utils/useIsLiveChatWebView';
import { useFilterableSearch } from '@components/FilterableSearchField';

export interface ChatListViewProps {
  folder: Folder;
  instagramConnected: boolean;
  hasInstagramPermissions: boolean;
  dialogListPlatform: Platform | null;
  conversationId: string | null;
  botId: string;
  itemClick(): void;
  onChooseFilter(type: LivechatFilter): void;
}

export const ChatListView: React.FC<ChatListViewProps> = ({
  folder,
  instagramConnected,
  hasInstagramPermissions,
  dialogListPlatform,
  conversationId,
  botId,
  itemClick,
  onChooseFilter,
}) => {
  const location = useLocation();
  const history = useHistory();
  const { isSmallScreenSize } = useDeviceMedia();
  const { isWhatsappEnabled } = useWhatsappEnabled();
  const { isLiveChatWebView } = useIsLiveChatWebView();
  const { parameters, setFilter } = useFilterableSearch();

  const selectTab = (folder: Folder) => {
    sendEvent({
      category: 'live chat',
      action: 'tab click',
      label: 'tab',
      propertyBag: {
        tab: folder,
      },
    });
    history.push(
      `${location.pathname}?${addUrlSearchParams(
        location.search,
        'folder',
        folder,
      )}`,
    );
  };

  const { pathname, search } = location;

  const getConversationHref = useCallback(
    (id: string, dialogPlatform: string) =>
      `${pathname}?${addUrlSearchParams(
        addUrlSearchParams(search, 'conversationId', id),
        'platform',
        dialogPlatform,
      )}`,
    [pathname, search],
  );

  const banner = (() => {
    if (isLiveChatWebView) return null;
    return instagramConnected && !hasInstagramPermissions ? (
      <InstagramPermissionsBanner />
    ) : (
      <>
        <NotificationsBanner />
        <SendFlowBanner />
      </>
    );
  })();

  return (
    <Flex flexDirection="column" fullHeight>
      {banner}
      <div
        className={cn(
          css.topSection,
          isSmallScreenSize && css.topSection_mobile,
        )}
      >
        {!isWhatsappEnabled && (
          <PlatformSelector
            initialValue={extendedPlatformFromPlatform(dialogListPlatform)}
          />
        )}
        <Spacer factor={2} />
        <FolderSelector
          value={folder}
          onChange={selectTab}
          platform={dialogListPlatform}
          isSmallScreenSize={isSmallScreenSize}
        />
        <Spacer factor={3} />
        <LivechatFilterableSearch
          onChooseFilter={(type) => {
            onChooseFilter(type);
            setFilter({ type, parameters, value: null });
          }}
        />
        <DialogSettingsMenu isSmallScreenSize={isSmallScreenSize} />
      </div>
      <FlexItem growHeight>
        <Dialogs
          key={folder}
          activeItemId={conversationId}
          botId={botId}
          folder={folder}
          getConversationHref={getConversationHref}
          platform={dialogListPlatform}
          itemClick={itemClick}
        />
      </FlexItem>
    </Flex>
  );
};
