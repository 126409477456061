import React from 'react';
import cn from 'classnames';
import { Button, ButtonProps } from '@ui/Button';
import { Icon } from '@ui/Icon';
import { Spacer } from '@ui/Spacer';
import { Flex } from '@ui/Flex';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { Tooltip2, Tooltip2Props } from '@ui/Tooltip2';
import { useDeviceMedia } from '@utils/DOM/useDeviceMedia';
import { PlatformErrorLabel } from '../PlatformErrorLabel/PlatformErrorLabel';
import * as css from './PlatformUpdatePermissionButton.css';

interface TooltipProps extends Omit<Tooltip2Props, 'children'> {}

const Tooltip: React.FC<TooltipProps> = ({ content, children }) => (
  <Tooltip2 hoverable content={content}>
    {(ref, bind) => (
      <div className={css.fullWidth} ref={ref} {...bind}>
        {children}
      </div>
    )}
  </Tooltip2>
);

interface PlatformUpdatePermissionButtonProps
  extends Omit<ButtonProps, 'intent'> {}

const PlatformUpdateInsufficientPermissionButton: React.FC<PlatformUpdatePermissionButtonProps> =
  (props) => {
    const { t } = useSafeTranslation();

    return (
      <Button
        intent="red"
        className={cn(css.fullWidth, props.className)}
        {...props}
      >
        {t(
          'modernComponents.FacebookPagesSetup.connectedPage.updateLostPermissionsButton',
        )}
      </Button>
    );
  };

const PlatformUpdatePermissionButton: React.FC<PlatformUpdatePermissionButtonProps> =
  (props) => {
    const { t } = useSafeTranslation();

    return (
      <Button
        intent="secondary"
        icon={<Icon icon="sync" color="black" size="24px" />}
        className={cn(css.fullWidth, props.className)}
        textClassName={css.text}
        labelTextClassName={css.labelText}
        {...props}
      >
        {t(
          'modernComponents.FacebookPagesSetup.connectedPage.updatePermissionsButton',
        )}
      </Button>
    );
  };

interface PlatformUpdatePermissionProps
  extends PlatformUpdatePermissionButtonProps {
  insufficient: boolean;
  tooltipContent: string;
}

export const PlatformUpdatePermission: React.FC<PlatformUpdatePermissionProps> =
  ({ insufficient, tooltipContent, ...props }) => {
    const { t } = useSafeTranslation();
    const { isSmallScreenSize } = useDeviceMedia();

    return insufficient ? (
      <Flex
        flexDirection={isSmallScreenSize ? 'column' : 'row'}
        className={css.fullWidth}
      >
        <PlatformErrorLabel>
          {t(
            'modernComponents.FacebookPagesSetup.connectedPage.permissionLostText',
          )}
        </PlatformErrorLabel>
        <Spacer factor={isSmallScreenSize ? 5 : 0} horizontalFactor={4} />
        <Tooltip content={tooltipContent}>
          <PlatformUpdateInsufficientPermissionButton {...props} />
        </Tooltip>
      </Flex>
    ) : (
      <Tooltip content={tooltipContent}>
        <PlatformUpdatePermissionButton {...props} />
      </Tooltip>
    );
  };
