import nanoid from 'nanoid';
import { useCallback } from 'react';
import { mergeDeepLeft, clone } from 'ramda';
import { FromType, Platform, WhatsappStatusEnum } from '@globals';
import { log } from 'cf-common/src/logger';
import { LOCAL_ID_POSTFIX } from '../Messages/constants';
import { usePersonaSetting } from '../../hooks/usePersonaSetting';
import client from '../../../../common/services/ApolloService';
import {
  MessagesQuery,
  MessagesQuery_livechatMessages_items as Message,
  MessagesQueryVariables,
} from '../Messages/@types/MessagesQuery';
import { MESSAGES_QUERY } from '../Messages/queries';

interface WriteMessageToCacheProps
  extends Omit<MessagesQueryVariables, 'from' | 'to'> {
  message?: Message;
}

export const writeMessageToCache = ({
  botId,
  conversationId,
  platform,
  message,
}: WriteMessageToCacheProps) => {
  if (!message) {
    return;
  }

  let data;
  try {
    data = clone(
      client.readQuery<MessagesQuery, MessagesQueryVariables>({
        query: MESSAGES_QUERY,
        variables: { botId, conversationId, platform },
      }),
    );
  } catch (error) {
    log.error({
      msg: 'writeMessageToCache read messages query failed',
      data: { error },
    });
  }

  if (!data?.livechatMessages) {
    return;
  }

  data.livechatMessages.items.push(message);

  try {
    client.writeQuery({
      data,
      query: MESSAGES_QUERY,
      variables: { botId, conversationId, platform },
    });
  } catch (error) {
    log.error({
      msg: 'writeMessageToCache write messages query failed',
      data: { error },
    });
  }
};

type DeepPartial<T> = T extends object
  ? {
      [P in keyof T]?: DeepPartial<T[P]>;
    }
  : T;

export interface UseLocalLiveChatMessageV3 {
  botId: string;
  platform: Platform;
  conversationId: string;
}

export const useLocalLiveChatMessageV3 = ({
  botId,
  platform,
  conversationId,
}: UseLocalLiveChatMessageV3) => {
  const { usePersona, adminId, loading, error } = usePersonaSetting(botId);
  const locallyGeneratedId = `${nanoid()}-${LOCAL_ID_POSTFIX}`;
  const sendByAdmin = platform === Platform.facebook && usePersona;

  const getNewMessage = useCallback(
    (message: DeepPartial<Message>): Message => {
      const basicMessage: Message = {
        id: locallyGeneratedId,
        conversationId,
        message: {
          text: null,
          attachment: null,
          gallery: null,
          buttons: null,
          sticker_id: null,
          reactions: null,
          receipt: null,
          send_to: null,
          whatsapp_list_reply: null,
          whatsapp_list: null,
          whatsapp_template: null,
          whatsapp_location: null,
          whatsapp_context: null,
          __typename: 'Message',
        },
        mid: null,
        outpacingId: locallyGeneratedId,
        seen: true,
        status: WhatsappStatusEnum.pending,
        from: {
          type: sendByAdmin ? FromType.admin : FromType.page,
          admin_id: sendByAdmin ? adminId! : null,
          persona: null,
          __typename: 'From',
        },
        date: String(Date.now()),
        error: null,
        __typename: 'LivechatMessageV3',
      };

      // @ts-expect-error
      return mergeDeepLeft(message, basicMessage);
    },
    [adminId, conversationId, locallyGeneratedId, sendByAdmin],
  );

  const writeNewMessage = useCallback(
    (message: DeepPartial<Message>) => {
      writeMessageToCache({
        botId,
        conversationId,
        platform,
        message: getNewMessage(message),
      });
    },
    [botId, conversationId, getNewMessage, platform],
  );

  return {
    loading,
    error,
    getNewMessage,
    writeNewMessage,
    locallyGeneratedId,
  };
};
