import React from 'react';
import { Flex } from '@ui/Flex';
import { ReactComponent as ZzzImage } from './images/zzz.svg';

export const ZzzPlaceholder: React.FC = () => (
  <Flex
    flexDirection="column"
    alignItems="center"
    justifyContent="center"
    style={{ backgroundColor: 'var(--white)' }}
    fullHeight
  >
    <ZzzImage />
  </Flex>
);
