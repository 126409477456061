import React from 'react';
import { Flex } from '@ui/Flex';
import { Icon, IconProps } from '@ui/Icon';
import * as css from './MediaMessageIcon.css';
import { ColorKey } from '@ui/_common/colors';

export interface MediaMessageIconProps {
  iconSize: IconProps['size'];
  size: string;
  icon: IconProps['icon'];
  color?: ColorKey;
}

export const MediaMessageIcon: React.FC<MediaMessageIconProps> = ({
  iconSize,
  size,
  icon,
  color = 'white',
}) => (
  <Flex
    alignItems="center"
    justifyContent="center"
    className={css.icon}
    style={{ width: size, height: size }}
  >
    <Icon icon={icon} size={iconSize} color={color} />
  </Flex>
);
