const justSentLivechatMessagesHolder = {
  messageIds: [] as string[],
};

const Query = {
  justSentLivechatMessageIds: () => {
    return justSentLivechatMessagesHolder.messageIds;
  },
};

const Mutation = {
  justSentLivechatMessageAdd: (
    _: any,
    { messageId }: { messageId: string },
  ) => {
    const { messageIds: prevMessageIds } = justSentLivechatMessagesHolder;

    const nextMessageIds = [...prevMessageIds, messageId];

    justSentLivechatMessagesHolder.messageIds = nextMessageIds;
    return nextMessageIds;
  },
  justSentLivechatMessageRemove: (
    _: any,
    { messageId }: { messageId: string },
  ) => {
    const { messageIds: prevMessageIds } = justSentLivechatMessagesHolder;

    const nextMessageIds = prevMessageIds.filter((id) => id !== messageId);

    justSentLivechatMessagesHolder.messageIds = nextMessageIds;
    return nextMessageIds;
  },
};

export const livechatResolvers = {
  Query,
  Mutation,
};
