import React from 'react';
import { MessageAttachmentProps } from '../../MessageInputAttachment';
import { Type } from '@ui/Type';
import { AttachmentBase } from '../AttachmentBase';
import { truncateWithEllipses } from 'cf-common/src/utils/String/truncateWithEllipses';
import { init, last } from 'ramda';
import { Icon } from '@ui/Icon';
import { MAX_DISPLAY_FILE_NAME_LENGTH } from '../consts';

interface FileAttachmentProps extends MessageAttachmentProps {}

const DOT = '.';

export const FileAttachment: React.FC<FileAttachmentProps> = ({
  onRemove,
  attachment,
}) => {
  const nameArr = attachment.name.split(DOT);
  const extension = last(nameArr);
  const name = init(nameArr).join(DOT);

  return (
    <AttachmentBase
      onRemove={onRemove}
      tooltipContent={
        attachment.name.length > MAX_DISPLAY_FILE_NAME_LENGTH
          ? attachment.name
          : undefined
      }
    >
      <Icon icon="note" size="16px" color="baseTertiary" />
      <Type data-testid="livechat_message-attachment_name">
        {truncateWithEllipses(name, MAX_DISPLAY_FILE_NAME_LENGTH)}.{extension}
      </Type>
    </AttachmentBase>
  );
};
