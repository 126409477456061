import React from 'react';
import cn from 'classnames';
import { Flex } from '@ui/Flex';
import { Icon } from '@ui/Icon';
import { Type } from '@ui/Type';
import { useDeviceMedia } from '@utils/DOM/useDeviceMedia';
import * as css from './PlatformHeader.css';

export interface PlatformHeaderProps extends TestLocator {
  icon: string;
  title: string;
}

export const PlatformHeader: React.FC<PlatformHeaderProps> = ({
  icon,
  title,
  children,
  ...props
}) => {
  const { isSmallScreenSize } = useDeviceMedia();

  return (
    <Flex
      justifyContent="space-between"
      alignItems={isSmallScreenSize ? 'flex-start' : 'center'}
      data-testid={props['data-testid']}
      className={cn({ [css.fullWidth]: children })}
    >
      <Flex className={css.iconWrapper}>
        <Icon icon={icon} size="32px" className={css.icon} />
        <Type size="24px" weight="semibold">
          {title}
        </Type>
      </Flex>
      {children}
    </Flex>
  );
};
