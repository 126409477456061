import { useCurrentBotId } from '@utils/Routing';
import { useSubscriptionWithAuth } from '@utils/Data/hocks/useSubscriptionWithAuth';

import { DIALOGS_FOLDER_SUBSCRIPTION } from '../../../common/subscriptions';
import { ObservableQuery } from 'apollo-client';
import { DialogsQuery } from '../../../common/@types/DialogsQuery';
import {
  DialogsFolderSubscription,
  DialogsFolderSubscriptionVariables,
} from '../../../common/@types/DialogsFolderSubscription';
import { moveConversationToFolderInCache } from '../../../utils/moveConversationToFolderInCache';
import { DialogQueryConfig } from '../../../utils/moveConversationToFolderInCacheClosure';

export const useSubscribeDialogsFolderUpdates = (
  observableQuery: ObservableQuery<DialogsQuery>,
) => {
  const botId = useCurrentBotId();

  useSubscriptionWithAuth<
    DialogsFolderSubscription,
    DialogsFolderSubscriptionVariables
  >(DIALOGS_FOLDER_SUBSCRIPTION, {
    variables: {
      botId: botId || '',
    },
    skip: !botId,
    onSubscriptionData(data) {
      const { id: folderId, folder: folderName } = data.subscriptionData.data?.livechatDialogsFolder || { id: null, folder: null };
      if (!folderId || !folderName) {
        return;
      }

      const { variables } = observableQuery;
      moveConversationToFolderInCache(
        folderId,
        folderName,
        variables as DialogQueryConfig,
        true,
      );
    }
  });
};
