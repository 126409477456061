import { ATTR_SIGN } from '@ui/TextWithAttributesEditor';
import { HEXColors } from '@ui/_common/colors';

const boldRegExp = new RegExp('\\*(.*?)\\*', 'gs');
const italicRegExp = new RegExp('_(.*?)_', 'gs');
const strikeThroughRegExp = new RegExp('~(.*?)~', 'gs');
const monospaceRegExp = new RegExp('```(.*?)```', 'gs');
const attributeRegExp = new RegExp(
  `${ATTR_SIGN.start}(.*?)${ATTR_SIGN.end}`,
  'gs',
);

export const previewFormat = (text: string) =>
  filterXSS(text)
    .replace(boldRegExp, '<b>$1</b>')
    .replace(italicRegExp, '<i>$1</i>')
    .replace(strikeThroughRegExp, '<s>$1</s>')
    .replace(monospaceRegExp, '<code>$1</code>')
    .replace(
      attributeRegExp,
      `<span style="color: ${HEXColors.blue}">$&</span>`,
    );
