import React from 'react';
import { TextMessage } from '../TextMessage';
import { TextMessageProps } from '../TextMessage/TextMessage';
import { MessagesQuery_livechatMessages_items_message_whatsapp_list } from '../../@types/MessagesQuery';
import { Type } from '@ui/Type';
import { Spacer } from '@ui/Spacer';
import {
  MessageFooter,
  MessageFooterProps,
} from '../../MessageTimestamp/MessageTimestamp';
import * as css from './WhatsappListMessage.css';

interface WhatsappListMessageProps
  extends Omit<TextMessageProps, 'color'>,
    MessageFooterProps {
  config: MessagesQuery_livechatMessages_items_message_whatsapp_list;
}

export const WhatsappListMessage: React.FC<WhatsappListMessageProps> = ({
  date,
  type,
  status,
  error,
  config: { header_text: headerText, button, body, footer },
  ...messageProps
}) => {
  const textColor = type === 'outgoing' ? 'white' : 'cobalt';

  return (
    <TextMessage
      {...messageProps}
      type={type}
      buttons={button ? [button] : undefined}
      buttonsIcons={['listView']}
    >
      <MessageFooter error={error} date={date} type={type} status={status}>
        <div className={css.wrapper}>
          <Type color={textColor} weight="bold" as="div">
            {headerText}
          </Type>
          <Spacer factor={2} />
          <Type color={textColor} as="div">
            {body}
          </Type>
          <Spacer factor={2} />
          <Type color={textColor} as="div" weight="semibold" size="12px">
            {footer}
          </Type>
        </div>
      </MessageFooter>
    </TextMessage>
  );
};
