import React from 'react';
import ng from 'angular';
import { ApolloProvider } from '@apollo/react-common';
import { react2angular } from 'react2angular';
import { Router } from 'react-router-dom';
import { AbandonedCartSetup } from './AbandonedCartSetup';
import client from '../../../../common/services/ApolloService';
import { globalHistory } from '../../../../utils/Routing';

const AbandonedCartSetupWithApolloProvider: React.FC = () => (
  <ApolloProvider client={client}>
    <Router history={globalHistory}>
      <AbandonedCartSetup />
    </Router>
  </ApolloProvider>
);

export const ngAbandonedCartSetup = ng
  .module('app.pages.growPage.abandonedCartSetup', [])
  .component(
    'abandonedCartSetup',
    react2angular(AbandonedCartSetupWithApolloProvider, [], []),
  ).name;
