import React from 'react';
import { Placeholder } from '../../helpers/getMediaMessagePlaceholders';
import { Flex } from '@ui/Flex';
import { Icon } from '@ui/Icon';
import { Type } from '@ui/Type';
import * as css from './MediaMessageExpired.css';

interface MediaMessageExpiredProps extends Placeholder {}

export const MediaMessageExpired: React.FC<MediaMessageExpiredProps> = ({
  text,
  expired,
  iconName,
}) => {
  return (
    <Flex className={css.wrapper} flexDirection="column">
      <Flex gapFactor={1} alignItems="center">
        <Icon icon={iconName} size="16px" />
        <Type size="12px" color="baseSub">
          {text}
        </Type>
      </Flex>
      <Type color="baseTertiary" size="12px" as="div" className={css.subText}>
        {expired}
      </Type>
    </Flex>
  );
};
