import React, { useCallback, useEffect, useState } from 'react';
import memoize from 'lodash-es/memoize';
import gql from 'graphql-tag';
import { useMutation } from '@apollo/react-hooks';
import { log } from 'cf-common/src/logger';
import { Focus } from 'react-powerplug';
import { clone, curry, propOr, prop } from 'ramda';
import { extractGQLErrorData, removeTypename } from '@utils/GQL/utils';
import { useAutomateEnabled } from '@utils/Data/Admin/Automate';
import * as css from './ClickToMessengerAdsSettingsPageStep3.css';
import {
  SegmentedGroupTrack,
  SegmentedGroupTrackColorWay,
} from '../../../../../modern-ui/SegmentedGroup/SegmentedGroupTrack';
import {
  Button,
  ButtonColorWay,
  ButtonIntent,
  ButtonSize,
} from '../../../../../modern-ui/_deprecated/Button';
import { Spacer } from '../../../../../modern-ui/Spacer';
import { Type } from '../../../../../modern-ui/Type';
import { EmojiPicker } from '../../../../../modern-ui/EmojiPicker';
import { insertText } from '../../../../../utils/documentExecCommand';
import { Flex } from '../../../../../modern-ui/Flex';
import { SymbolsLimitBubble } from '../../../../../modern-ui/SymbolsLimitBubble';
import { Input } from '../../../../../modern-ui/Input';
import { Anchor } from '../../../../../modern-ui/Links';
import { ReactComponent as AddIcon } from './images/add.svg';
import {
  FacebookAdsByCampaignQuery_bot_facebookAdCampaign_ads as Ad,
  FacebookAdsByCampaignQuery_bot_facebookAdCampaign_ads_buttons,
} from './@types/FacebookAdsByCampaignQuery';
import {
  AdCampaignType,
  FacebookAdCustomerActionType,
  FacebookAdMediaType,
  Platform,
} from '../../../../../../@types/globalTypes';
import { BlocksSelector } from '../../../../AiSetupPage/BlocksSelector';
import { BlockWithPermissions } from '../../../../AiSetupPage';
import { BlocksSelectorData } from '../../../../../modern-components/BlocksSelector2';
import { FACEBOOK_AD_FRAGMENT } from '../../hooks/useFacebookAdCampaignsListQuery';
import {
  UpdateFacebookAdMutation,
  UpdateFacebookAdMutationVariables,
} from './@types/UpdateFacebookAdMutation';
import { Messages, toaster } from '../../../../../services/MessageService';
import { ServiceMessageType } from '../../../../../modern-ui/ServiceMessage2';
import { extractMutableButtons } from '../../components/FacebookAdUtils';
import { Autofocus } from '../../../../../modern-ui/Autofocus';
import { ReactComponent as InfoIcon } from '../../../../../modern-ui/_deprecated/Icon/icons/error-info.svg';
import { sendEvent } from '../../../../../utils/Analytics';
import { HoverDisclosure } from '../../../../../modern-ui/HoverDisclosure';
import { SimpleCombobox } from '@ui/SimpleCombobox';
import { ButtonUnstyled } from '../../../../../modern-ui/Button';
import { ReactComponent as Dots } from '../../../../../modern-ui/_deprecated/Icon/icons/3dot.svg';
import { Modal } from '../../../../../modern-ui/Modal';
import { RemoveEntityDialog } from '../../../../../modern-components/RemoveEntityDialog';
import { SUPPORTED_FACEBOOK_AD_CUSTOMER_ACTION_TYPES } from '../../components/FacebookAdCampaignsListNote';
import { useGoToEditTab } from '../../../../../utils/Routing/useGoToEditTab';

interface ClickToMessengerAdsSettingsPageStep3Props {
  ad: Ad;
  botId: string;
  onRequestClose: () => void;
  campaignType: AdCampaignType;
}

type FacebookAdCustomerActionTabIdType =
  | FacebookAdCustomerActionType.quick_replies
  | FacebookAdCustomerActionType.buttons;

interface FacebookAdCustomerActionsTab {
  id: FacebookAdCustomerActionTabIdType;
  title: string;
  maxButtonTitleLength: number;
  maxButtonsQty: number;
}

const FACEBOOK_AD_CUSTOMER_ACTIONS_TABS: () => Record<
  FacebookAdCustomerActionTabIdType,
  FacebookAdCustomerActionsTab
> = memoize(() => ({
  [FacebookAdCustomerActionType.buttons]: {
    id: FacebookAdCustomerActionType.buttons,
    title: window.i18next.t(
      'ClickToMessengerAdsSettingsPageStep3-string-2001-button',
    ),
    maxButtonTitleLength: 20,
    maxButtonsQty: 3,
  },
  [FacebookAdCustomerActionType.quick_replies]: {
    id: FacebookAdCustomerActionType.quick_replies,
    title: window.i18next.t(
      'ClickToMessengerAdsSettingsPageStep3-string-3921-quick-reply',
    ),
    maxButtonTitleLength: 80,
    maxButtonsQty: 11,
  },
}));

const getFieldId = curry(
  (buttonIndex: number, fieldType: 'input' | 'blockSelector') =>
    `${fieldType}_${buttonIndex}`,
);

const addEmptyButtonIfNeed = (ad: Ad) => {
  const updatedAd = clone(ad);
  const buttons = extractMutableButtons(updatedAd);
  if (buttons.length === 0) {
    buttons.push({
      __typename: 'CardButton',
      title: '',
      blocks: [],
    });
  }
  return updatedAd;
};

const FACEBOOK_AD_CUSTOMER_ACTIONS_TABS_ORDER: () => FacebookAdCustomerActionsTab[] =
  () => Object.values(FACEBOOK_AD_CUSTOMER_ACTIONS_TABS());

const UPDATE_FACEBOOK_AD_MUTATION = gql`
  mutation UpdateFacebookAdMutation($botId: String!, $ad: FacebookAdInput!) {
    updateFacebookAd(botId: $botId, ad: $ad) {
      ...facebookAdFragment
    }
  }
  ${FACEBOOK_AD_FRAGMENT}
`;

export const ClickToMessengerAdsSettingsPageStep3: React.FC<ClickToMessengerAdsSettingsPageStep3Props> =
  ({ ad, botId, onRequestClose, campaignType }) => {
    const goGoToEditTab = useGoToEditTab();
    const [currentAd, setCurrentAd] = useState<Ad>(ad);
    const [lastAddedButtonIndex, setLastAddedButtonIndex] = useState<
      number | undefined
    >();
    const [completedButtonTitleIndex, setCompletedButtonTitleIndex] = useState<
      number | undefined
    >();
    const [needConfirmRemoveButton, setNeedConfirmRemoveButton] = useState<
      number | undefined
    >();
    const [touchedFields, setTouchedFields] = useState<string[]>([]);

    const { isAutomateEnabled } = useAutomateEnabled();

    const [updateFacebookAd] = useMutation<
      UpdateFacebookAdMutation,
      UpdateFacebookAdMutationVariables
    >(UPDATE_FACEBOOK_AD_MUTATION, {
      onError: (error) => {
        const { status, message: apiMessage } =
          extractGQLErrorData(error) || {};
        log.warn({ error, msg: 'Update add error' });

        toaster.show({
          type: ServiceMessageType.error,
          payload: {
            message:
              [422, 400].includes(status as number) &&
              typeof apiMessage === 'string'
                ? apiMessage
                : Messages.somethingWentWrong,
          },
        });
      },
    });
    /**
     *  reset flag (for focus on new field)
     */
    useEffect(() => {
      if (lastAddedButtonIndex !== undefined) {
        setLastAddedButtonIndex(undefined);
      }
    }, [lastAddedButtonIndex]);

    /**
     *  reset flag (for focus on new field)
     */
    useEffect(() => {
      if (completedButtonTitleIndex !== undefined) {
        setCompletedButtonTitleIndex(undefined);
      }
    }, [completedButtonTitleIndex]);

    useEffect(() => {
      setTouchedFields(
        extractMutableButtons(ad).reduce((acc, _, i) => {
          const getFieldIdForIndex = getFieldId(i);
          acc.push(getFieldIdForIndex('input'));
          acc.push(getFieldIdForIndex('blockSelector'));
          return acc;
        }, [] as string[]),
      );
      setCurrentAd(addEmptyButtonIfNeed(ad));
    }, [ad]);

    useEffect(() => {
      if (
        !SUPPORTED_FACEBOOK_AD_CUSTOMER_ACTION_TYPES.includes(
          currentAd?.customer_action_type,
        )
      ) {
        const updatedAd = {
          ...currentAd,
          customer_action_type: FacebookAdCustomerActionType.quick_replies,
        };
        setCurrentAd(addEmptyButtonIfNeed(updatedAd));
      }
    }, [currentAd]);

    const setAdItemButton = useCallback(
      (
        button: FacebookAdsByCampaignQuery_bot_facebookAdCampaign_ads_buttons,
        index?: number,
      ) => {
        const updatedAd = clone<Ad>(currentAd);
        const buttons = extractMutableButtons(updatedAd);
        buttons[index !== undefined ? index : buttons.length] = button;
        setCurrentAd(updatedAd);
      },
      [currentAd],
    );

    const saveFacebookAd = useCallback(() => {
      const { connected_bot, has_greeting, ...clearAd } =
        removeTypename(currentAd);
      updateFacebookAd({
        variables: {
          botId,
          ad: clearAd,
        },
        optimisticResponse: {
          updateFacebookAd: currentAd,
        },
      });
    }, [botId, currentAd, updateFacebookAd]);

    if (
      !SUPPORTED_FACEBOOK_AD_CUSTOMER_ACTION_TYPES.includes(
        currentAd?.customer_action_type,
      )
    ) {
      return null;
    }

    const buttons = extractMutableButtons(currentAd);

    const currentAdTab =
      currentAd.customer_action_type &&
      FACEBOOK_AD_CUSTOMER_ACTIONS_TABS()[
        currentAd.customer_action_type as FacebookAdCustomerActionTabIdType
      ];

    const isTextAndVideoType =
      currentAd.media_type === FacebookAdMediaType.video;

    return (
      <>
        <div className={css.box}>
          <Spacer factor={4} />
          <Flex alignItems="center">
            <SegmentedGroupTrack
              role="tablist"
              colorWay={SegmentedGroupTrackColorWay.grey}
              style={{ minWidth: 232 }}
            >
              {FACEBOOK_AD_CUSTOMER_ACTIONS_TABS_ORDER().map(
                ({ id, title }) => (
                  <Button
                    key={id}
                    role="tab"
                    intent={ButtonIntent.toggle}
                    colorWay={ButtonColorWay.toggleWhiteHover}
                    size={ButtonSize.s}
                    aria-selected={currentAd.customer_action_type === id}
                    disabled={
                      isTextAndVideoType &&
                      id === FacebookAdCustomerActionType.buttons
                    }
                    onClick={() => {
                      const updatedAd = {
                        ...clone(currentAd),
                        customer_action_type: id,
                      };
                      setCurrentAd(addEmptyButtonIfNeed(updatedAd));
                      setTouchedFields([]);
                      sendEvent({
                        category: 'ads manager',
                        action: 'configure action switch to',
                        label:
                          id === FacebookAdCustomerActionType.buttons
                            ? 'buttons'
                            : 'quick reply',
                      });
                    }}
                  >
                    {title}
                  </Button>
                ),
              )}
            </SegmentedGroupTrack>
            {isTextAndVideoType && (
              <div className={css.tabNote}>
                <InfoIcon className={css.infoIcon} />
                <Type size="15px_DEPRECATED">
                  {window.i18next.t(
                    'ClickToMessengerAdsSettingsPageStep3-JSXText--914-you-can-only-use-a-quick-reply-here-because-your-new-ad-has-a-text-and-video-opt-in-message-greeting',
                  )}
                </Type>
              </div>
            )}
          </Flex>
          <Spacer factor={4} />
          <BlocksSelectorData botId={botId}>
            {({ blocksGroups, flowsGroups }) => (
              <>
                {buttons.map((button, buttonIndex) => {
                  const firstBlock = button.blocks?.[0];
                  const getFieldIdByType = getFieldId(buttonIndex);

                  return (
                    <HoverDisclosure
                      // eslint-disable-next-line react/no-array-index-key
                      key={buttonIndex}
                      render={({ bind, isVisible }) => (
                        <div {...bind}>
                          <Flex>
                            <Type as="p" size="15px_DEPRECATED">
                              {currentAdTab.title} {buttonIndex + 1}
                            </Type>
                            {isVisible && buttons.length > 1 && (
                              <SimpleCombobox
                                itemToString={propOr<string>('', 'title')}
                                menuboxStyle={{ width: 'auto' }}
                                onSelect={() => {
                                  setNeedConfirmRemoveButton(buttonIndex);
                                }}
                                renderInput={({ getToggleButtonProps }) => (
                                  <ButtonUnstyled
                                    {...getToggleButtonProps({
                                      type: 'button',
                                    })}
                                    className={css.dotsButton}
                                  >
                                    <Dots />
                                  </ButtonUnstyled>
                                )}
                                items={[
                                  {
                                    id: 'delete',
                                    title: `${window.i18next.t(
                                      'ClickToMessengerAdsSettingsPageStep3-Template--107-delete',
                                    )}${currentAdTab.title.toLowerCase()}`,
                                  },
                                ]}
                              />
                            )}
                          </Flex>
                          <Spacer factor={2} />
                          <Autofocus
                            shouldFocus={buttonIndex === lastAddedButtonIndex}
                            render={({ bind: { ref: autofocusRef } }) => (
                              <Focus>
                                {({ bind, focused }) => (
                                  <Input
                                    error={
                                      touchedFields.includes(
                                        getFieldIdByType('input'),
                                      ) && !button.title
                                    }
                                    render={({ getInputProps }) => (
                                      <EmojiPicker
                                        onSelect={(emoji, el) => {
                                          insertText(emoji.native, el);
                                        }}
                                      >
                                        {({
                                          bind: { ref, ...emojiPickerBind },
                                        }) => (
                                          <Flex alignItems="center">
                                            <input
                                              {...getInputProps({
                                                onChange: (
                                                  e: React.FormEvent<HTMLInputElement>,
                                                ) => {
                                                  setAdItemButton(
                                                    {
                                                      ...button,
                                                      title:
                                                        e.currentTarget.value,
                                                    },
                                                    buttonIndex,
                                                  );
                                                },
                                                maxLength:
                                                  currentAdTab.maxButtonTitleLength,
                                                placeholder: `${window.i18next.t(
                                                  'ClickToMessengerAdsSettingsPageStep3-Template--162-enter-the-text-for-your',
                                                )}${currentAdTab.title.toLowerCase()}`,
                                                value: button.title || '',
                                                ref: (el) => {
                                                  // eslint-disable-next-line no-param-reassign
                                                  ref.current = el;
                                                  autofocusRef(el);
                                                },
                                                onFocus: () => {
                                                  bind.onFocus();
                                                  emojiPickerBind.onFocus();
                                                },
                                                onBlur: ({
                                                  currentTarget: { value },
                                                }: React.FocusEvent<HTMLInputElement>) => {
                                                  bind.onBlur();
                                                  emojiPickerBind.onBlur();
                                                  setTouchedFields([
                                                    ...touchedFields,
                                                    getFieldIdByType('input'),
                                                  ]);
                                                  if (value) {
                                                    sendEvent({
                                                      category: 'ads manager',
                                                      action: `${currentAdTab.title.toLowerCase()} filled out`,
                                                      propertyBag: {
                                                        value,
                                                      },
                                                    });
                                                  }
                                                },
                                                onKeyDown: ({ key }) => {
                                                  if (key === 'Enter') {
                                                    setCompletedButtonTitleIndex(
                                                      buttonIndex,
                                                    );
                                                  }
                                                },
                                                className: css.input,
                                              })}
                                            />
                                            {focused ? (
                                              <SymbolsLimitBubble
                                                value={
                                                  currentAdTab.maxButtonTitleLength -
                                                  (button.title?.length || 0)
                                                }
                                              />
                                            ) : null}
                                          </Flex>
                                        )}
                                      </EmojiPicker>
                                    )}
                                  />
                                )}
                              </Focus>
                            )}
                          />

                          <Spacer factor={4} />
                          <Type as="p" size="15px_DEPRECATED">
                            {window.i18next.t(
                              'ClickToMessengerAdsSettingsPageStep3-JSXText--127-select-the',
                            )}
                            {isAutomateEnabled
                              ? window.i18next.t(
                                  'ClickToMessengerAdsSettingsPageStep3-string-9383-block',
                                )
                              : window.i18next.t(
                                  'ClickToMessengerAdsSettingsPageStep3-string-3146-flow',
                                )}{' '}
                            {window.i18next.t(
                              'ClickToMessengerAdsSettingsPageStep3-JSXText--330-where-the-user-will-be-directed-by-click',
                            )}
                          </Type>
                          <Spacer factor={2} />
                          <Flex alignItems="center">
                            <div className={css.blockSelector}>
                              <BlocksSelector
                                platform={Platform.facebook}
                                placeholder={`${window.i18next.t(
                                  'ClickToMessengerAdsSettingsPageStep3-Template--125-choose-a',
                                )}${
                                  isAutomateEnabled
                                    ? window.i18next.t(
                                        'ClickToMessengerAdsSettingsPageStep3-string-9383-block',
                                      )
                                    : window.i18next.t(
                                        'ClickToMessengerAdsSettingsPageStep3-string-3146-flow',
                                      )
                                }${window.i18next.t(
                                  'ClickToMessengerAdsSettingsPageStep3-Template--127-to-redirect',
                                )}`}
                                autofocus={
                                  completedButtonTitleIndex === buttonIndex
                                }
                                currentBotId={botId}
                                blocksGroups={
                                  blocksGroups as BlockWithPermissions[]
                                }
                                flowsGroups={flowsGroups}
                                blocksSelected={
                                  button.blocks?.filter(
                                    ({ removed }) => !removed,
                                  ) || []
                                }
                                error={
                                  touchedFields.includes(
                                    getFieldIdByType('blockSelector'),
                                  ) && !button.blocks?.length
                                }
                                onBlur={() => {
                                  setTouchedFields([
                                    ...touchedFields,
                                    getFieldIdByType('blockSelector'),
                                  ]);
                                }}
                                onChange={(blocksSelected) => {
                                  const blocks = blocksSelected.map(
                                    (block) => ({
                                      ...block,
                                      flow_id: null,
                                    }),
                                  );

                                  sendEvent({
                                    category: 'ads manager',
                                    action: 'blocks selected',
                                    propertyBag: {
                                      blocks: blocksSelected.map(prop('title')),
                                    },
                                  });

                                  setAdItemButton(
                                    {
                                      ...button,
                                      blocks,
                                    },
                                    buttonIndex,
                                  );
                                }}
                                onGoToBlock={(block) => {
                                  saveFacebookAd();
                                  if (block) {
                                    sendEvent({
                                      category: 'ads manager',
                                      action:
                                        'double click to block in selector',
                                      label: block.id,
                                    });
                                  }
                                }}
                              />
                            </div>
                            {buttonIndex === 0 && (
                              <div className={css.tabNote}>
                                <InfoIcon className={css.infoIcon} />
                                <Type size="15px_DEPRECATED">
                                  {window.i18next.t(
                                    'ClickToMessengerAdsSettingsPageStep3-JSXText-1522-connecting-multiple-ads-to-the-same',
                                  )}{' '}
                                  {isAutomateEnabled
                                    ? window.i18next.t(
                                        'ClickToMessengerAdsSettingsPageStep3-string-9383-block',
                                      )
                                    : window.i18next.t(
                                        'ClickToMessengerAdsSettingsPageStep3-string-3146-flow',
                                      )}
                                  {window.i18next.t(
                                    'ClickToMessengerAdsSettingsPageStep3-JSXText-8729-will-affect-stats-to-get-accurate-numbers-make-sure-each-ad-is-connected-to-a-different',
                                  )}{' '}
                                  {isAutomateEnabled
                                    ? window.i18next.t(
                                        'ClickToMessengerAdsSettingsPageStep3-string-9383-block',
                                      )
                                    : window.i18next.t(
                                        'ClickToMessengerAdsSettingsPageStep3-string-3146-flow',
                                      )}
                                  .
                                </Type>
                              </div>
                            )}
                          </Flex>
                          <Spacer factor={4} />
                          <Anchor
                            intent="external"
                            hideArrow
                            disabled={!firstBlock}
                            onClick={() => {
                              if (firstBlock) {
                                saveFacebookAd();
                                sendEvent({
                                  category: 'ads manager',
                                  action: 'edit message in block',
                                });
                                goGoToEditTab(firstBlock.id);
                              }
                            }}
                          >
                            {window.i18next.t(
                              'ClickToMessengerAdsSettingsPageStep3-JSXText-5765-edit-message-in',
                            )}
                            {isAutomateEnabled
                              ? window.i18next.t(
                                  'ClickToMessengerAdsSettingsPageStep3-string-9383-block',
                                )
                              : window.i18next.t(
                                  'ClickToMessengerAdsSettingsPageStep3-string-3146-flow',
                                )}
                          </Anchor>
                          <Spacer factor={4} />
                        </div>
                      )}
                    />
                  );
                })}
              </>
            )}
          </BlocksSelectorData>

          <Flex
            alignItems="center"
            justifyContent="space-between"
            style={{ width: 936 }}
          >
            <div>
              {buttons.length < currentAdTab.maxButtonsQty && (
                <ButtonUnstyled
                  onClick={() => {
                    setLastAddedButtonIndex(buttons.length);
                    setAdItemButton({
                      __typename: 'CardButton',
                      title: '',
                      blocks: [],
                    });
                    sendEvent({
                      category: 'ads manager',
                      action: `add ${currentAdTab.title.toLowerCase()}`,
                    });
                  }}
                >
                  <AddIcon />
                  {window.i18next.t(
                    'ClickToMessengerAdsSettingsPageStep3-JSXText-2865-add',
                  )}
                </ButtonUnstyled>
              )}
            </div>
            <Flex alignItems="center">
              <Button
                intent={ButtonIntent.primary}
                colorWay={ButtonColorWay.blue}
                onClick={() => {
                  saveFacebookAd();
                  const { buttons, quick_replies } = removeTypename(currentAd);
                  sendEvent({
                    category: 'ads manager',
                    action: 'save settings',
                    label:
                      campaignType === AdCampaignType.click_to_messenger
                        ? 'click-to-messenger campaign'
                        : 'sponsored messages campaign',
                    propertyBag: {
                      buttons,
                      quick_replies,
                    },
                  });
                  onRequestClose();
                }}
              >
                {window.i18next.t(
                  'ClickToMessengerAdsSettingsPageStep3-JSXText-1052-save',
                )}
              </Button>
            </Flex>
          </Flex>
        </div>
        {needConfirmRemoveButton !== undefined && (
          <Modal
            onDismiss={() => {
              setNeedConfirmRemoveButton(undefined);
            }}
          >
            <RemoveEntityDialog
              renderHeading={() => (
                <span>
                  {window.i18next.t(
                    'ClickToMessengerAdsSettingsPageStep3-JSXText-4700-are-you-sure-you-want-to',
                  )}
                  {currentAdTab.title.toLowerCase()}
                  <br />
                  {window.i18next.t(
                    'ClickToMessengerAdsSettingsPageStep3-JSXText-1385-delete',
                  )}
                </span>
              )}
              renderNoteText={() =>
                `${window.i18next.t(
                  'ClickToMessengerAdsSettingsPageStep3-Template--223-this-action-cannot-be-undone-all-stats-from-this',
                )}${currentAdTab.title.toLowerCase()}${window.i18next.t(
                  'ClickToMessengerAdsSettingsPageStep3-Template--100-will-be-deleted',
                )}`
              }
              renderActionText={() =>
                window.i18next.t(
                  'ClickToMessengerAdsSettingsPageStep3-string-2043-delete',
                )
              }
              onSubmit={() => {
                const updatedAd = clone<Ad>(currentAd);
                const buttons = extractMutableButtons(updatedAd);
                buttons.splice(needConfirmRemoveButton, 1);
                setCurrentAd(updatedAd);
                setNeedConfirmRemoveButton(undefined);
              }}
              onDismiss={() => {
                setNeedConfirmRemoveButton(undefined);
              }}
            />
          </Modal>
        )}
      </>
    );
  };
