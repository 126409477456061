import React, { useContext, useRef } from 'react';
import * as css from './BackToMessagesContext.css';

export interface BackToMessagesContextParams {
  scrolled: number;
  stackPush: (messageId: string) => void;
  stackPop: () => string | null;
  giveNextMessageToScroll: () => string | null;
}

export const BackToMessagesContext =
  React.createContext<BackToMessagesContextParams>({
    scrolled: 0,
    stackPop: () => null,
    stackPush: () => null,
    giveNextMessageToScroll: () => null,
  });

export const useBackToMessages = () => {
  return useContext(BackToMessagesContext);
};

export interface BackToMessagesWrapperProps
  extends Pick<BackToMessagesContextParams, 'scrolled'> {}

export const BackToMessagesWrapper: React.FC<BackToMessagesWrapperProps> = ({
  children,
  scrolled,
}) => {
  const refStack = useRef<string[]>([]);

  const stackPush = (messageId: string) => {
    if (messageId === refStack.current[0]) {
      return;
    }

    refStack.current = [messageId, ...refStack.current];
  };

  const stackPop = () => {
    const [head, ...rest] = refStack.current;
    refStack.current = rest;
    return head;
  };

  const giveNextMessageToScroll = (): string | null => {
    let messageId = stackPop();

    while (messageId) {
      const messageElement = document.querySelector(
        `[data-mid='${messageId}']`,
      );

      if ((messageElement?.getBoundingClientRect().y || 0) < 0) {
        messageId = stackPop();
      } else {
        return messageId;
      }
    }

    return null;
  };

  return (
    <BackToMessagesContext.Provider
      value={{ scrolled, stackPush, stackPop, giveNextMessageToScroll }}
    >
      <div className={css.wrapper}>{children}</div>
    </BackToMessagesContext.Provider>
  );
};

export const BOTTOM_ID = 'back-to-messages-bottom';
export const BackToMessagesBottom: React.FC = ({ children }) => (
  <div id={BOTTOM_ID}>{children}</div>
);
