import { clone } from 'ramda';
import { DataProxy } from 'apollo-cache'; // eslint-disable-line import/no-extraneous-dependencies
import { FetchResult } from 'apollo-link';
import { Folder, SegmentationInput, Platform } from '@globals';
import { DIALOGS_QUERY, DIALOGS_COUNT_QUERY } from '../common/queries';
import { MoveConversation } from '../hooks/@types/MoveConversation';
import {
  DialogsQuery,
  DialogsQueryVariables,
  DialogsQuery_livechatConversations_items as DialogItem,
} from '../common/@types/DialogsQuery';
import {
  DialogsCountQuery,
  DialogsCountQueryVariables,
} from '../common/@types/DialogsCountQuery';
import { log } from 'cf-common/src/logger';
import { DocumentNode } from 'graphql';
import client from '@common/services/ApolloService';

interface MoveableConfig {
  source: Folder;
  target: Folder;
}

export interface DialogQueryConfig {
  botId: string;
  segmentation: SegmentationInput;
  platform: Platform | null;
}

interface DialogsQueryOptions {
  query: DocumentNode;
  variables: DialogsQueryVariables;
}

type DialogsQueryConfigurationReturnType = [
  {
    dialogsQueryData: DialogsQuery | null;
    dialogsCountQueryData: DialogsCountQuery | null;
  },
  {
    dialogsQueryOptions: DialogsQueryOptions;
    dialogsCountQueryOptions: DialogsQueryOptions;
  },
];

export const moveConversationToFolderInCacheClosure = (
  { source, target }: MoveableConfig,
  { botId, segmentation, platform }: DialogQueryConfig,
  canReloadTargetFolderIfNeed: boolean = false,
) => {
  const getDialogsQueryOptions = (folder: Folder) => ({
    query: DIALOGS_QUERY,
    variables: { botId, segmentation, folder, platform },
  });

  const getDialogsCountQueryOptions = (folder: Folder) => ({
    query: DIALOGS_COUNT_QUERY,
    variables: { botId, folder, segmentation, platform },
  });

  return function moveConversationUpdateCallback(
    cache: DataProxy,
    { data, errors }: FetchResult<MoveConversation>,
  ) {
    if (errors) {
      return;
    }

    const getDialogsQueryConfiguration = (
      folder: Folder,
    ): DialogsQueryConfigurationReturnType => {
      const dialogsQueryOptions = getDialogsQueryOptions(folder);
      let dialogsQueryData;
      try {
        dialogsQueryData = clone(
          cache.readQuery<DialogsQuery, DialogsQueryVariables>(
            dialogsQueryOptions,
          ),
        );
      } catch {
        dialogsQueryData = null;
      }
      const dialogsCountQueryOptions = getDialogsCountQueryOptions(folder);
      let dialogsCountQueryData;
      try {
        dialogsCountQueryData = clone(
          cache.readQuery<DialogsCountQuery, DialogsCountQueryVariables>(
            dialogsCountQueryOptions,
          ),
        );
      } catch {
        dialogsCountQueryData = null;
      }
      return [
        { dialogsQueryData, dialogsCountQueryData },
        { dialogsQueryOptions, dialogsCountQueryOptions },
      ];
    };

    /**
     * Initially, check 'closed' instead of 'all' since there is no need to mutate 'all' conversations count or items,
     * but if 'closed' returns null, for example, in case if the livechatConversations by the current key haven't been already loaded,
     * then load 'all' to find itemToPlaceToTarget
     */
    const [
      {
        dialogsQueryData: sourceFolderDialogsData,
        dialogsCountQueryData: sourceFolderDialogsCountData,
      },
      {
        dialogsQueryOptions: sourceFolderDialogsQueryOptions,
        dialogsCountQueryOptions: sourceFolderDialogsCountQueryOptions,
      },
    ] = getDialogsQueryConfiguration(
      source === Folder.all ? Folder.closed : source,
    );

    let itemToPlaceToTarget: DialogItem | undefined;
    if (sourceFolderDialogsData) {
      itemToPlaceToTarget =
        sourceFolderDialogsData.livechatConversations.items.find(
          (c) => c.id === data?.moveConversation.id,
        );

      if (itemToPlaceToTarget) {
        sourceFolderDialogsData.livechatConversations.items =
          sourceFolderDialogsData.livechatConversations.items.filter(
            (c) => c.id !== itemToPlaceToTarget!.id,
          );

        try {
          cache.writeQuery({
            ...sourceFolderDialogsQueryOptions,
            data: sourceFolderDialogsData,
          });
        } catch (error) {
          log.error({
            msg: 'moveConversationToFolderInCache dialogs count write query failed',
            data: { error },
          });
        }
      }

      if (sourceFolderDialogsCountData && itemToPlaceToTarget) {
        const { count } =
          sourceFolderDialogsCountData.livechatConversationsCount;
        sourceFolderDialogsCountData.livechatConversationsCount.count =
          count - (count > 0 ? 1 : 0);
        try {
          cache.writeQuery({
            ...sourceFolderDialogsCountQueryOptions,
            data: sourceFolderDialogsCountData,
          });
        } catch (error) {
          log.error({
            msg: 'moveConversationToFolderInCache source dialogs count write query failed',
            data: { error },
          });
        }
      }
    } else {
      const [{ dialogsQueryData: sourceFolderDialogsData }] =
        getDialogsQueryConfiguration(Folder.all);
      if (sourceFolderDialogsData) {
        itemToPlaceToTarget =
          sourceFolderDialogsData.livechatConversations.items.find(
            (c) => c.id === data?.moveConversation.id,
          );
      }
    }

    const [
      {
        dialogsQueryData: targetFolderDialogsData,
        dialogsCountQueryData: targetFolderDialogsCountData,
      },
      {
        dialogsQueryOptions: targetFolderDialogsQueryOptions,
        dialogsCountQueryOptions: targetFolderDialogsCountQueryOptions,
      },
    ] = getDialogsQueryConfiguration(target);

    const isInCurrentFolder = !!targetFolderDialogsData?.livechatConversations.items.find(d => d.id === data?.moveConversation.id);
    if (target === Folder.all || isInCurrentFolder) return;

    if (targetFolderDialogsData && itemToPlaceToTarget) {
      itemToPlaceToTarget.folder = target;
      const itemId = itemToPlaceToTarget.id;
      if (
        targetFolderDialogsData.livechatConversations.items.some(
          ({ id }) => id === itemId,
        )
      ) {
        return;
      }

      targetFolderDialogsData.livechatConversations.items.unshift(
        itemToPlaceToTarget,
      );

      try {
        cache.writeQuery({
          ...targetFolderDialogsQueryOptions,
          data: targetFolderDialogsData,
        });
      } catch (error) {
        log.error({
          msg: 'moveConversationToFolderInCache target dialogs count write query failed',
          data: { error },
        });
      }
    } else if (canReloadTargetFolderIfNeed) {
      client.query({
        ...targetFolderDialogsQueryOptions,
        fetchPolicy: 'network-only',
      });
    }

    if (targetFolderDialogsCountData && itemToPlaceToTarget) {
      targetFolderDialogsCountData.livechatConversationsCount.count += 1;
      try {
        cache.writeQuery({
          ...targetFolderDialogsCountQueryOptions,
          data: targetFolderDialogsCountData,
        });
      } catch (error) {
        log.error({
          msg: 'moveConversationToFolderInCache target folder dialogs count write query failed',
          data: { targetFolderDialogsCountData },
        });
      }
    } else if (canReloadTargetFolderIfNeed) {
      client.query({
        ...targetFolderDialogsCountQueryOptions,
        fetchPolicy: 'network-only',
      });
    }
  };
};
