import React from 'react';
import { LoadingPlaceholder } from '@ui/LoadingPlaceholder';
import { Spacer } from '@ui/Spacer';
import { Type } from '@ui/Type';
import { useCurrentBotId } from '@utils/Routing';
import { useWhatsappEnabled } from '@utils/Whatsapp';
import { useDeviceMedia } from '@utils/DOM/useDeviceMedia';
import { useJustSentLivechatMessages } from '@utils/Data/LiveChat/Messages/hooks';
import {
  BusinessVerificationStatus,
  Platform,
  WhatsappOriginalMessageType,
} from '@globals';
import { useLiveChatMessagesUploadingContext } from '../../hooks/LiveChatMessagesUploadingContext/LiveChatMessagesUploadingContext';
import { Avatar, InstagramAvatar } from '../Avatar';
import { MessagesQuery_livechatMessages_items as Message } from './@types/MessagesQuery';
import {
  AdminsById,
  getAdminAvatarUrl,
  getMessageSourceType,
  getMessageType,
  isSameSender,
  isStickerMessage,
} from './helpers';
import { InstagramMessage } from './InstagramMessage';
import { MessagePosition } from './InstagramMessage/types';
import { GalleryMessage } from './Message/GalleryMessage';
import { MediaMessage } from './Message/MediaMessage';
import { KNOWN_MESSAGE_ATTACHMENT_TYPES } from './Message/MediaMessage/MediaMessage';
import { MessageError, MessageRow, MessageTypeKey } from './Message/MessageRow';
import { Persona } from './Message/Persona';
import { ProductGalleryMessage } from './Message/ProductGalleryMessage';
import { ReceiptMessage } from './Message/ReceiptMessage';
import { TextMessage } from './Message/TextMessage';
import { TimeTitle } from './Message/TimeTitle';
import { sizes } from '../../common/sizes';
import { UploadingAttachmentPlaceholder } from './UploadingAttachmentPlaceholder';
import { SentBlockMessage } from './Message/SentBlockMessage';
import { MessageRejectionContent } from './getMessageRejectionContent';
import css from './MessagesList.css';
import { useWhatsappConnectionState } from '../../../BotPage/HomeTab/components/WhatsappAccount/hooks/useWhatsappConnectionState';
import { WhatsappListMessage } from './Message/WhatsappListMessage';
import { WhatsappListReplyMessage } from './Message/WhatsappListReplyMessage';
import { MessageFooter } from './MessageTimestamp/MessageTimestamp';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { DateFormat, DateUtils } from '@utils/DateTime';
import { LOCAL_ID_POSTFIX } from './constants';
import { WhatsappTemplate } from './Message/WhatsappTemplate';
import { WhatsappLocationMessage } from './Message/WhatsappLocationMessage/WhatsappLocationMessage';
import { useBillingPaymentMethodsLink } from '@utils/Data/Whatsapp';
import { Reply } from './Message/Reply';
import { BackToMessages } from './BackToMessages';
import { BackToMessagesBottom } from './BackToMessagesContext';

interface GetMessagePositionParams {
  isSingle: boolean;
  isLeading: boolean;
  isTrailing: boolean;
}

const getMessagePosition = ({
  isSingle,
  isLeading,
  isTrailing,
}: GetMessagePositionParams) =>
  isSingle
    ? MessagePosition.single
    : isLeading
    ? MessagePosition.leading
    : isTrailing
    ? MessagePosition.trailing
    : MessagePosition.middle;

interface MessagesListProps {
  items: Message[];
  /**
   * see ./README.md
   */
  user?: { name: string; avatarUrl: string | null };
  page: { picture: string | null; title: string };
  adminsById: AdminsById;
  platform: Platform;
  conversationId: string;
  isBusinessVerified?: boolean;
  billingPaymentLink?: string;
  noAccent?: boolean;
}

export const MessagesList: React.FC<MessagesListProps> = ({
  items,
  user = {},
  page,
  adminsById,
  platform,
  conversationId,
  isBusinessVerified,
  billingPaymentLink,
  noAccent,
}) => {
  const { t } = useSafeTranslation();
  const { isWhatsappEnabled } = useWhatsappEnabled();
  const { isSmallScreenSize } = useDeviceMedia();
  const botId = useCurrentBotId();
  const { sendingMessages } = useLiveChatMessagesUploadingContext();
  const justSentMessageIds = useJustSentLivechatMessages();

  const getMessageSize = (type: MessageTypeKey) =>
    isSmallScreenSize ? sizes.mobile : sizes[type];

  return (
    <>
      {items.map((item, index) => {
        const type = getMessageType(item);
        const sourceType = getMessageSourceType(item);
        const itemTimestamp = Number(item.date);
        const sender = item.from;
        const prevMessage = index > 0 ? items[index - 1] : null;
        const nextMessage = index < items.length - 1 ? items[index + 1] : null;
        const isPrevDate = nextMessage
          ? !DateUtils.isSameDay(Number(nextMessage.date), itemTimestamp)
          : false;
        const isNextDate = prevMessage
          ? !DateUtils.isSameDay(Number(prevMessage.date), itemTimestamp)
          : true;
        const isLeading =
          !prevMessage ||
          isNextDate ||
          isStickerMessage(platform, items, index - 1) ||
          !isSameSender(prevMessage.from, sender);
        const isTrailing =
          !nextMessage || isPrevDate || !isSameSender(nextMessage.from, sender);
        const isTrailingBorderRadius =
          isTrailing || isStickerMessage(platform, items, index + 1);
        const messagePosition = getMessagePosition({
          isSingle: isLeading && isTrailing,
          isLeading,
          isTrailing: !!isTrailingBorderRadius,
        });

        const isOptimisticItem = item.id.includes(LOCAL_ID_POSTFIX);
        const { message, mid } = item;
        const messageLiked = (message.reactions ?? []).length > 0;

        const messageError: MessageError | undefined = item.error
          ? {
              messageId: item.id,
              content: (
                <MessageRejectionContent
                  error={item.error}
                  isBusinessVerified={isBusinessVerified}
                  billingPaymentLink={billingPaymentLink}
                />
              ),
              forceTooltip: justSentMessageIds.includes(item.id),
            }
          : undefined;

        return (
          <div key={item.id} data-mid={mid}>
            {isNextDate ? (
              <TimeTitle>{DateFormat.DDMMMMYYYY(itemTimestamp)}</TimeTitle>
            ) : null}
            {isLeading && sender.persona?.name ? (
              <MessageRow
                type={type}
                preceding={!messageLiked}
                padStart={getMessageSize('incoming').rowPadding}
                padEnd={getMessageSize('outgoing').rowPadding}
                isSmallScreenSize={isSmallScreenSize}
              >
                <Persona persona={sender.persona} pageTitle={page.title} />
              </MessageRow>
            ) : null}
            {message.send_to ? (
              <SentBlockMessage {...message.send_to} />
            ) : message.gallery ? (
              <MessageRow
                type={type}
                padStart={getMessageSize('incoming').rowPadding}
                padEnd={0}
                isSmallScreenSize={isSmallScreenSize}
                error={
                  platform === Platform.whatsapp ? undefined : messageError
                }
              >
                {message.gallery.type === 'products' ? (
                  <ProductGalleryMessage
                    error={messageError}
                    type={type}
                    gallery={message.gallery}
                    date={Number(item.date)}
                    status={item.status}
                  />
                ) : (
                  <GalleryMessage
                    error={messageError}
                    date={Number(item.date)}
                    type={type}
                    gallery={message.gallery}
                    status={item.status}
                  />
                )}
              </MessageRow>
            ) : (
              <MessageRow
                type={type}
                isSmallScreenSize={isSmallScreenSize}
                padStart={
                  type === 'incoming' && isTrailing
                    ? 0
                    : getMessageSize('incoming').rowPadding
                }
                padEnd={
                  type === 'outgoing' && isTrailing
                    ? 0
                    : getMessageSize('outgoing').rowPadding
                }
                preceding={!(isTrailing || messageLiked)}
                error={
                  platform === Platform.whatsapp ? undefined : messageError
                }
                renderMedia={
                  isTrailing
                    ? () =>
                        platform === Platform.instagram ? (
                          type === 'incoming' ? (
                            <Avatar
                              src={user.avatarUrl}
                              size={getMessageSize('incoming').avatarSize}
                              style={{
                                marginLeft:
                                  getMessageSize('incoming').avatarMargin,
                                marginRight:
                                  getMessageSize('incoming').avatarMargin,
                              }}
                            />
                          ) : (
                            <>
                              <Spacer horizontalFactor={2} />
                              <InstagramAvatar
                                botId={botId}
                                isSmallScreenSize={isSmallScreenSize}
                              />
                              <Spacer
                                horizontalFactor={isSmallScreenSize ? 2 : 6}
                              />
                            </>
                          )
                        ) : (
                          <Avatar
                            className={css.avatar}
                            src={
                              type === 'incoming'
                                ? user.avatarUrl
                                : sender.type === 'page'
                                ? page.picture
                                : sender.persona
                                ? sender.persona.picture
                                : getAdminAvatarUrl(sender, adminsById)
                            }
                            size={getMessageSize(type).avatarSize}
                            style={{
                              marginLeft: getMessageSize(type).avatarMargin,
                              marginRight: getMessageSize(type).avatarMargin,
                            }}
                          />
                        )
                    : undefined
                }
              >
                {platform === Platform.instagram ? (
                  <InstagramMessage
                    date={Number(item.date)}
                    type={type}
                    sourceType={sourceType}
                    position={messagePosition}
                    message={message}
                    messageId={mid || ''}
                    optimistic={isOptimisticItem}
                    botId={botId || ''}
                    conversationId={conversationId}
                    withAvatar={isTrailing}
                    status={item.status}
                    error={messageError}
                  />
                ) : message.whatsapp_list ? (
                  <WhatsappListMessage
                    type={type}
                    leading={isLeading}
                    trailing={isTrailing}
                    buttons={message.buttons}
                    style={isOptimisticItem ? { opacity: 0.6 } : undefined}
                    config={message.whatsapp_list}
                    date={Number(item.date)}
                    status={item.status}
                    error={messageError}
                  />
                ) : message.whatsapp_location ? (
                  <WhatsappLocationMessage
                    leading={isLeading}
                    trailing={isTrailing}
                    config={message.whatsapp_location}
                    error={messageError}
                    type={type}
                    date={Number(item.date)}
                    status={item.status}
                  />
                ) : message.whatsapp_list_reply ? (
                  <WhatsappListReplyMessage
                    type={type}
                    leading={isLeading}
                    trailing={isTrailing}
                    buttons={message.buttons}
                    style={isOptimisticItem ? { opacity: 0.6 } : undefined}
                    config={message.whatsapp_list_reply}
                    date={Number(item.date)}
                    status={item.status}
                    error={messageError}
                  />
                ) : message.whatsapp_template ? (
                  <WhatsappTemplate
                    type={type}
                    noAccent={noAccent}
                    leading={isLeading}
                    trailing={isTrailing}
                    buttons={message.buttons}
                    style={isOptimisticItem ? { opacity: 0.6 } : undefined}
                    config={message.whatsapp_template}
                    date={Number(item.date)}
                    status={item.status}
                    error={messageError}
                  />
                ) : message.receipt ? (
                  <ReceiptMessage
                    receipt={message.receipt}
                    date={Number(item.date)}
                    type={type}
                    status={item.status}
                    error={messageError}
                  />
                ) : message.attachment &&
                  KNOWN_MESSAGE_ATTACHMENT_TYPES(isWhatsappEnabled).includes(
                    message.attachment.type,
                  ) ? (
                  <MediaMessage
                    id={item.id}
                    type={type}
                    leading={isLeading}
                    trailing={isTrailing}
                    message={message}
                    attachment={message.attachment}
                    date={Number(item.date)}
                    status={item.status}
                    error={messageError}
                  />
                ) : (
                  <TextMessage
                    type={type}
                    noAccent={noAccent}
                    leading={isLeading}
                    trailing={isTrailing}
                    buttons={message.buttons}
                    style={isOptimisticItem ? { opacity: 0.6 } : undefined}
                  >
                    <MessageFooter
                      date={Number(item.date)}
                      type={type}
                      status={item.status}
                      error={messageError}
                      noAccent={noAccent}
                    >
                      <div className={css.textWrapper}>
                        {message.whatsapp_context?.msgId &&
                          message.whatsapp_context.originalMessageType ===
                            WhatsappOriginalMessageType.template && (
                            <Reply
                              messageId={item.mid!}
                              repliedMessageId={message.whatsapp_context?.msgId}
                            />
                          )}
                        {message.text?.length ? (
                          <span
                            data-testid="livechat__text-message"
                            dangerouslySetInnerHTML={{
                              __html: filterXSS(message.text),
                            }}
                          />
                        ) : (
                          <Type color="grey" size="15px_DEPRECATED">
                            {t(
                              'MessagesList-JSXText--209-this-content-type-is-not-yet-supported',
                            )}
                          </Type>
                        )}
                      </div>
                    </MessageFooter>
                  </TextMessage>
                )}
              </MessageRow>
            )}
          </div>
        );
      })}
      <BackToMessagesBottom>
        <Spacer factor={4} />
      </BackToMessagesBottom>
      {sendingMessages.length > 0 && (
        <>
          {sendingMessages.map((message, index) => {
            const position = getMessagePosition({
              isSingle: sendingMessages.length === 1,
              isLeading: index === 0,
              isTrailing: index === sendingMessages.length - 1,
            });

            const { avatarSize, avatarMargin } = getMessageSize('outgoing');

            return (
              <MessageRow
                key={message.id}
                type="outgoing"
                isSmallScreenSize={isSmallScreenSize}
                renderMedia={() => (
                  <LoadingPlaceholder
                    width={avatarSize}
                    height={avatarSize}
                    style={{
                      marginLeft: avatarMargin,
                      marginRight: avatarMargin,
                      borderRadius: '50%',
                    }}
                  />
                )}
              >
                <UploadingAttachmentPlaceholder
                  message={message}
                  position={position}
                  conversationId={conversationId}
                />
              </MessageRow>
            );
          })}
          <Spacer factor={4} />
        </>
      )}

      <BackToMessages />
    </>
  );
};

export const WhatsppMessagesList: React.FC<MessagesListProps> = (props) => {
  const botId = useCurrentBotId()!;
  const { currentAccount } = useWhatsappConnectionState();
  const { link } = useBillingPaymentMethodsLink(botId);

  return (
    <MessagesList
      {...props}
      billingPaymentLink={link}
      isBusinessVerified={
        currentAccount?.business_verification_status ===
        BusinessVerificationStatus.verified
      }
    />
  );
};
