import {
  DialogQueryConfig,
  moveConversationToFolderInCacheClosure,
} from './moveConversationToFolderInCacheClosure';
import { Folder } from '@globals';
import client from '@common/services/ApolloService';

export const moveConversationToFolderInCache = (
  id: string,
  folder: Folder | null,
  variables: DialogQueryConfig,
  canReloadTargetFolderIfNeed: boolean = false,
) => {
  moveConversationToFolderInCacheClosure(
    {
      source: folder === Folder.inbox ? Folder.closed : Folder.inbox,
      target: folder || Folder.inbox,
    },
    variables as DialogQueryConfig,
    canReloadTargetFolderIfNeed,
  )(client, {
    data: {
      moveConversation: {
        __typename: 'LivechatConversationV3',
        id,
        folder,
      },
    },
  });
};
