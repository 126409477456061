import gql from 'graphql-tag';
import { ATTACHMENT_FRAGMENT } from '../../../queries';

export const ATTACHMENT_URL_QUERY = gql`
  query AttachmentUrlQuery(
    $botId: String!
    $attachmentId: String!
    $messageId: String!
  ) {
    attachmentUrl(
      botId: $botId
      attachmentId: $attachmentId
      messageId: $messageId
    ) {
      id
      url
    }
  }
`;

export const INITIALIZE_ATTACHMENT_DOWNLOAD = gql`
  mutation InitializeAttachmentDownloadMutation(
    $botId: String!
    $attachmentId: String!
  ) {
    initializeAttachmentDownload(botId: $botId, attachmentId: $attachmentId)
  }
`;

export const POLL_ATTACHMENT = gql`
  query PollAttachment(
    $botId: String!
    $attachmentId: String!
    $messageId: String!
    $type: String!
  ) {
    getAttachment(
      botId: $botId
      messageId: $messageId
      attachmentId: $attachmentId
      type: $type
    ) {
      status
      attachment {
        ...attachmentFragment
      }
    }
  }

  ${ATTACHMENT_FRAGMENT}
`;
