import React, { useMemo } from 'react';
import { TextMessage } from '../TextMessage';
import { TextMessageProps } from '../TextMessage/TextMessage';
import { MessagesQuery_livechatMessages_items_message_whatsapp_template } from '../../@types/MessagesQuery';
import { Type } from '@ui/Type';
import { Spacer } from '@ui/Spacer';
import {
  MessageFooter,
  MessageFooterProps,
} from '../../MessageTimestamp/MessageTimestamp';
import { WhatsappTemplateButtonType } from '@globals';
import { IconNames } from '@ui/Icon/icons';
import * as css from './WhatsappTemplate.css';
import { Flex } from '@ui/Flex';
import { DocumentAttachment } from '../MediaMessage/components/Attachments/DocumentAttachment';
import { AttachmentProps } from '../MediaMessage/components/Attachments/types';
import { VideoAttachment } from '../MediaMessage/components/Attachments/VideoAttachment';
import { truncateWithEllipses } from 'cf-common/src/utils/String/truncateWithEllipses';
import { previewFormat } from './helpers/previewFormat';
import cn from 'classnames';

interface WhatsappTemplateProps
  extends Omit<TextMessageProps, 'color'>,
    MessageFooterProps {
  config: MessagesQuery_livechatMessages_items_message_whatsapp_template;
  hideFooter?: boolean;
  className?: string;
}

const BUTTON_TYPE_TO_ICON_NAME: Record<
  WhatsappTemplateButtonType,
  IconNames | undefined
> = {
  [WhatsappTemplateButtonType.URL]: 'link',
  [WhatsappTemplateButtonType.PHONE_NUMBER]: 'call',
  [WhatsappTemplateButtonType.MARKETING_OPT_OUT]: 'reply',
  [WhatsappTemplateButtonType.QUICK_REPLY]: undefined,
};

export const WhatsappTemplate: React.FC<WhatsappTemplateProps> = ({
  date,
  type,
  status,
  error,
  config,
  className,
  hideFooter,
  noAccent,
  ...messageProps
}) => {
  const buttonsUrls = useMemo(
    () => config.buttons?.map(({ url }) => url),
    [config.buttons],
  );

  const buttonsIcons = useMemo(
    () =>
      config.buttons?.map(
        ({ type }) => (type && BUTTON_TYPE_TO_ICON_NAME[type]) || null,
      ),
    [config.buttons],
  );

  const attachmentProps = useMemo<AttachmentProps>(() => {
    const url = config.header_document?.link || config.header_video || '';
    const filenameArr = (
      config.header_document?.name ||
      url.split('/').pop() ||
      ''
    ).split('.');
    const ext = filenameArr.pop();
    const name = filenameArr.join('.');

    return {
      loading: false,
      isExpired: false,
      attachment: {
        attachment_id: url,
        type,
        url,
        filename: `${truncateWithEllipses(name, 25)}.${ext}`,
        caption: null,
      },
      onClick: () => {
        window.open(url, '_blank');
      },
      type,
    };
  }, [config.header_document, config.header_video, type]);

  const bodyHtml = useMemo(
    () => config.body && previewFormat(config.body),
    [config.body],
  );

  const headerHtml = useMemo(
    () => config.header_text && previewFormat(config.header_text),
    [config.header_text],
  );

  const textColor = type === 'incoming' || noAccent ? 'baseNormal' : 'white';

  return (
    <TextMessage
      type={type}
      noAccent={noAccent}
      {...messageProps}
      buttons={config.buttons?.map(({ text }) => text)}
      buttonsIcons={buttonsIcons}
      buttonsUrls={buttonsUrls}
      className={className}
    >
      <MessageFooter
        error={error}
        date={date}
        type={type}
        status={status}
        hideFooter={hideFooter}
        noAccent={noAccent}
      >
        <div className={css.box}>
          {config.header_image && (
            <>
              <Flex justifyContent="center">
                <img
                  src={config.header_image}
                  alt=""
                  className={css.image}
                  data-testid="whatsapp-template_message__header-image"
                />
              </Flex>
              <Spacer factor={2} />
            </>
          )}
          {config.header_document && (
            <>
              <DocumentAttachment
                className={cn(css.document, type === 'incoming' && css.gray)}
                {...attachmentProps}
              />
              <Spacer factor={2} />
            </>
          )}
          {config.header_video && (
            <>
              <VideoAttachment className={css.video} {...attachmentProps} />
              <Spacer factor={2} />
            </>
          )}
          {config.header_text && (
            <>
              <Type
                color={textColor}
                as="div"
                weight="bold"
                data-testid="whatsapp-template_message__header-text"
              >
                <span
                  dangerouslySetInnerHTML={
                    headerHtml ? { __html: headerHtml } : undefined
                  }
                />
              </Type>
              <Spacer factor={2} />
            </>
          )}

          {config.body && (
            <Type
              color={textColor}
              as="div"
              data-testid="whatsapp-template_message__body"
            >
              <span
                dangerouslySetInnerHTML={
                  bodyHtml ? { __html: bodyHtml } : undefined
                }
              />
            </Type>
          )}
          {config.footer && (
            <>
              <Spacer factor={2} />
              <Type
                color={textColor}
                as="div"
                size="12px"
                weight="semibold"
                data-testid="whatsapp-template_message__footer"
              >
                {config.footer}
              </Type>
            </>
          )}
        </div>
      </MessageFooter>
    </TextMessage>
  );
};
