/* eslint-disable no-param-reassign */
import { useCallback } from 'react';
import {
  FacebookAdsByCampaignQuery_bot_facebookAdCampaign,
  FacebookAdsByCampaignQuery_bot_facebookAdCampaign_ads as Ad,
} from '../ClickToMessengerAdsSettingsPage/components/@types/FacebookAdsByCampaignQuery';
import { FacebookAdCustomerActionType } from '../../../../../@types/globalTypes';
import { useMultipleBlocksStatsLazy } from '../../../../utils/BlocksUtils';
import { FacebookAdCampaignsListQuery_bot_facebookAdCampaignsCtm } from '../hooks/@types/FacebookAdCampaignsListQuery';
import { MultipleBlocksStatsQuery_bot_blocksStats_stats } from '../../../../utils/@types/MultipleBlocksStatsQuery';

export const extractMutableButtons = (ad: Ad) => {
  if (ad.customer_action_type === FacebookAdCustomerActionType.buttons) {
    ad.buttons = ad.buttons || [];
    return ad.buttons;
  }
  if (ad.customer_action_type === FacebookAdCustomerActionType.quick_replies) {
    if (!ad.quick_replies) {
      ad.quick_replies = {
        __typename: 'QuickReplyPluginConfig',
        buttons: [],
      };
    }
    ad.quick_replies.buttons = ad.quick_replies.buttons || [];
    return ad.quick_replies.buttons;
  }
  return [];
};

const extractAllBlocksIds = (
  campaign: FacebookAdsByCampaignQuery_bot_facebookAdCampaign[],
) =>
  Array.from(
    campaign.reduce((blockIds, { ads }) => {
      if (ads) {
        ads.forEach((ad) => {
          extractMutableButtons(ad).forEach(({ blocks }) => {
            if (!blocks) {
              return;
            }
            blocks.forEach(({ id }) => {
              blockIds.add(id);
            });
          });
        });
      }
      return blockIds;
    }, new Set<string>()),
  );

export type BlocksStatsType = {
  [blockId: string]: MultipleBlocksStatsQuery_bot_blocksStats_stats;
};

export const useMultipleBlocksStatsForCampaigns = (botId: string) => {
  const [callback, { data, ...state }] = useMultipleBlocksStatsLazy();
  const blocksStats = data?.bot.blocksStats.reduce(
    (blocksStats, { id, stats }) => Object.assign(blocksStats, { [id]: stats }),
    {} as BlocksStatsType,
  );

  return {
    executeMultipleBlocksStatsForCampaigns: useCallback(
      (
        campaigns: FacebookAdCampaignsListQuery_bot_facebookAdCampaignsCtm[],
      ) => {
        if (!botId) {
          return;
        }
        const blocksIds = extractAllBlocksIds(campaigns);
        if (!blocksIds.length) {
          return;
        }
        callback({ variables: { blocksIds, botId } });
      },
      [botId, callback],
    ),
    blocksStats,
    ...state,
  };
};
