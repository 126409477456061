import gql from 'graphql-tag';
import {
  paraphraseForBot,
  paraphraseForBotVariables,
} from './@types/paraphraseForBot';
import { useMutation } from '@apollo/react-hooks';

const COPILOT_MUTATION = gql`
  mutation paraphraseForBot(
    $botId: ID!
    $mode: ParaphraseMode!
    $text: String!
  ) {
    paraphraseForBot(botId: $botId, mode: $mode, text: $text) {
      text
    }
  }
`;

export const useCopilot = () => {
  return useMutation<paraphraseForBot, paraphraseForBotVariables>(
    COPILOT_MUTATION,
  );
};
