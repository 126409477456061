import React from 'react';
import * as s from './Persona.css';

interface Persona {
  name: string;
  picture: string;
}

interface Props {
  persona: Persona;
  pageTitle?: string;
}

export const Persona: React.SFC<Props> = ({ persona, pageTitle }) => {
  const content = pageTitle
    ? `${persona.name}${window.i18next.t(
        'Persona-Template-1013-from',
      )}${pageTitle}`
    : persona.name;
  return (
    <div className={s.root} data-testid="livechat_message_persona_divider">
      {content}
    </div>
  );
};
