const rowPadding = 64;
const avatarSize = 32;
const avatarMargin = (rowPadding - avatarSize) / 2;
const rowPaddingOutgoing = avatarSize + avatarMargin * 2;

const mobileAvatarSize = 24;
const mobileAvatarMargin = 8;
const mobileRowPadding = mobileAvatarMargin * 2 + mobileAvatarSize;

export const sizes: {
  [key in 'incoming' | 'outgoing' | 'mobile']: {
    avatarSize: number;
    avatarMargin: number;
    rowPadding: number;
  };
} = {
  incoming: {
    avatarSize,
    avatarMargin,
    rowPadding,
  },
  outgoing: {
    avatarSize,
    avatarMargin,
    rowPadding: rowPaddingOutgoing,
  },
  mobile: {
    avatarSize: mobileAvatarSize,
    avatarMargin: mobileAvatarMargin,
    rowPadding: mobileRowPadding,
  },
};
