import React, { useEffect } from 'react';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';
import cn from 'classnames';
import { useHistory, useLocation } from 'react-router-dom';
import { Flex } from '@ui/Flex';
import { Button } from '@ui/Button';
import { Spacer } from '@ui/Spacer';
import { isBotProOrTrial } from '@utils/Pro';
import { Type } from '@ui/Type';
import { Anchor } from '@ui/Links';
import { useBotFeatures } from '@utils/Data/Bot';
import { getBillingPageUrl, useCurrentBotId } from '@utils/Routing';
import { sendEvent } from '@utils/Analytics';
import { log } from 'cf-common/src/logger';
import { SectionBox } from '../../../../modern-components/SectionBox/SectionBox';
import { GrowItemTitle } from '../../common/GrowItem/GrowItemTitle';
import { ShopInfoQuery, ShopInfoQueryVariables } from './@types/ShopInfoQuery';
import { ReactComponent as NotificationIcon } from '../../../../modern-ui/_deprecated/Icon/icons/notification.svg';
import { AbandonedCartSetupHeader } from './AbandonedCartSetupHeader';
import { AbandonedCartManualLink } from '../AbandonedCartManualLink';
import * as css from './AbandonedCartSetup.css';
import {
  ServerStorageItemKeys,
  useServerStorageForBot,
} from '../../../../utils/ServerStorage';
import { ReactComponent as ProIcon } from '../../../../modern-ui/_deprecated/Icon/icons/Pro.svg';
import { DeprecateAbandonedCartMessage } from './DeprecateAbandonedCartMessage';
import { OptInSetup } from './OptInSetup';
import { CartRemindersStat } from './CartRemindersStat';

export const BOT_TAB_ID = 'bot-tab-id';

const SHOP_INFO_QUERY = gql`
  query ShopInfoQuery($botId: String!) {
    bot(id: $botId) {
      id
      connectedShop {
        id
        myshopify_domain
      }
      abandonedCart {
        sequence_id
      }
      pro {
        status
        manual
      }
      abandonedCartStat {
        reminders {
          id
          block_id
          enabled
          clicked
          purchased
          sent
        }
      }
    }
  }
`;

export const CART_REMINDERS_SCROLL_LOCATOR = 'cr-scroll-locator';

export const AbandonedCartSetup: React.FC = () => {
  const botId = useCurrentBotId();
  const history = useHistory();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const scrollToParams = queryParams.get('scroll-to');
  const { botFeatures, botFeaturesLoading } = useBotFeatures(botId ?? '');
  const [closedHeader, setClosedHeader, { loading: headerLoading }] =
    useServerStorageForBot<boolean>(
      ServerStorageItemKeys.abandonedCartSetupHeader,
      botId,
    );

  const {
    data: shopInfoData,
    loading: shopInfoDataLoading,
    error: shopInfoDataError,
  } = useQuery<ShopInfoQuery, ShopInfoQueryVariables>(SHOP_INFO_QUERY, {
    variables: { botId: botId || '' },
    skip: !botId,
    onError: (error) => {
      log.warn({ error, msg: 'Error loading shopify shop data' });
    },
  });

  useEffect(() => {
    if (scrollToParams?.includes(CART_REMINDERS_SCROLL_LOCATOR)) {
      document.getElementById(CART_REMINDERS_SCROLL_LOCATOR)?.scrollIntoView();
      const botTabDiv = document.getElementById(BOT_TAB_ID);
      if (botTabDiv) {
        botTabDiv.scrollTop -= 16;
      }
      history.push({
        ...location,
        search: '',
      });
    }
  }, [scrollToParams, history, location]);

  if (!shopInfoData?.bot.abandonedCartStat?.reminders?.length) {
    return null;
  }

  if (
    !botId ||
    shopInfoDataLoading ||
    !shopInfoData ||
    headerLoading ||
    !shopInfoData.bot.connectedShop?.id
  ) {
    return <div id={CART_REMINDERS_SCROLL_LOCATOR} />;
  }

  if (shopInfoDataError) {
    return (
      <div id={CART_REMINDERS_SCROLL_LOCATOR}>
        {window.i18next.t('AbandonedCartSetup-JSXText--199-loading-error')}
      </div>
    );
  }

  const {
    bot: { connectedShop },
  } = shopInfoData;

  const isProOrTrial = isBotProOrTrial(shopInfoData.bot);

  return (
    <SectionBox id={CART_REMINDERS_SCROLL_LOCATOR}>
      <AbandonedCartSetupHeader
        closedHeader={closedHeader}
        setClosedHeader={setClosedHeader}
      />
      <GrowItemTitle>
        <Flex justifyContent="space-between" alignItems="center">
          <div>
            <span>
              {window.i18next.t(
                'AbandonedCartSetup-JSXText--154-cart-reminders',
              )}
            </span>
            {!isProOrTrial && <ProIcon className={css.pro} />}
          </div>
          <AbandonedCartManualLink />
        </Flex>
        <DeprecateAbandonedCartMessage />
      </GrowItemTitle>
      <Spacer factor={2} />
      <Type as="div" color="greyDark" size="15px_DEPRECATED">
        {window.i18next.t(
          'AbandonedCartSetup-JSXText-8504-learn-how-to-set-up-abandoned-cart-reminders',
        )}
        <br />
        {window.i18next.t(
          'AbandonedCartSetup-JSXText--193-to-recover-up-to-7-x-more-revenue',
        )}
      </Type>

      <Spacer factor={6} />

      <Flex justifyContent="space-between" alignItems="flex-end">
        <div>
          <Type weight="medium" size="15px_DEPRECATED">
            {window.i18next.t(
              'AbandonedCartSetup-JSXText-8229-your-shopify-store',
            )}
          </Type>
          <div>
            {connectedShop
              ? connectedShop.myshopify_domain
              : 'chatfuel.myshopify.com'}
          </div>
        </div>
        <Flex
          flexDirection="column"
          alignItems="flex-end"
          justifyContent="space-between"
        >
          <Type as="div" size="12px" color="greyDark">
            {window.i18next.t(
              'AbandonedCartSetup-JSXText--127-to-disconnect-your-store-go',
            )}
          </Type>
          <div>
            <Type size="12px" color="greyDark">
              {window.i18next.t('AbandonedCartSetup-JSXText-1311-to')}
            </Type>
            <Anchor
              hideArrow
              target="_blank"
              intent="external"
              href={`https://${connectedShop?.myshopify_domain}/admin/apps`}
              onClick={() => {
                sendEvent({
                  category: 'cart reminders',
                  action: 'click installed apps',
                });
              }}
            >
              {window.i18next.t(
                'AbandonedCartSetup-JSXText-7148-installed-apps',
              )}
            </Anchor>
            <Type size="12px" color="greyDark">
              {window.i18next.t('AbandonedCartSetup-JSXText-1981-in-shopify')}
            </Type>
          </div>
        </Flex>
      </Flex>
      <Spacer factor={6} />

      <div className={cn({ [css.lock]: !isProOrTrial })}>
        <OptInSetup />
        <Spacer factor={7} />
        <CartRemindersStat />
      </div>

      {!isProOrTrial && (
        <Flex
          alignItems="center"
          justifyContent="space-between"
          className={css.proBlock}
        >
          <Flex alignItems="flex-start">
            <NotificationIcon className={css.notificationIcon} />
            <Type color="greyDark" size="15px_DEPRECATED">
              {window.i18next.t(
                'AbandonedCartSetup-JSXText--155-upgrade-to-pro-to-continue-using',
              )}
              <br />
              {window.i18next.t(
                'AbandonedCartSetup-JSXText-3351-feature-all-your-data-is-saved',
              )}
            </Type>
          </Flex>
          <Button
            intent="primary"
            onClick={() => {
              sendEvent({
                category: 'cart reminders',
                action: 'click upgrade to pro',
              });
              history.push(
                getBillingPageUrl(
                  botId,
                  !!botFeatures?.dialogs_pricing_enabled,
                ),
              );
            }}
            disabled={botFeaturesLoading}
          >
            {window.i18next.t('AbandonedCartSetup-JSXText-1227-upgrade-to-pro')}
          </Button>
        </Flex>
      )}
    </SectionBox>
  );
};
