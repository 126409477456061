import React, { useCallback, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import cn from 'classnames';
import { BotPageRouteParams } from '@utils/Routing';
import * as css from './ClickToMessengerAdsSettingsPage.css';
import { GrowSubPage, GrowSubPageTitle } from '../../common/GrowSubPage';
import { Spacer } from '../../../../modern-ui/Spacer';
import { Type } from '../../../../modern-ui/Type';
import { Box } from '../../../../modern-ui/Box';
import { Stepper } from '../../../../modern-ui/Stepper';
import {
  ClickToMessengerAdsSettingsPageStep1,
  ClickToMessengerAdsSettingsPageStep2,
} from './components';
import { Flex } from '../../../../modern-ui/Flex';
import {
  Button,
  ButtonColorWay,
  ButtonIntent,
} from '../../../../modern-ui/_deprecated/Button';
import { ClickToMessengerAdsSettingsPageStep3 } from './components/ClickToMessengerAdsSettingsPageStep3';
import { FacebookAdsByCampaignQuery_bot_facebookAdCampaign_ads } from './components/@types/FacebookAdsByCampaignQuery';
import { ReactComponent as InfoIcon } from '../../../../modern-ui/_deprecated/Icon/icons/error-info.svg';
import { sendEvent } from '../../../../utils/Analytics';
import { AdCampaignType } from '../../../../../@types/globalTypes';
import { AdManagerTutorialLinks } from '../components/AdManagerTutorialLinks';

enum SettingsStep {
  step1 = 0,
  step2 = 1,
  step3 = 2,
}

const EMPTY_CAMPAIGNS_LIST_ERROR =
  'You don’t have any campaigns. Create one in Ads Manager.';

export const ClickToMessengerAdsSettingsPage: React.FC = () => {
  const { botId = '', pageTabId: initialAdId } =
    useParams<BotPageRouteParams>();
  const history = useHistory();
  const goToGrow = useCallback(() => {
    history.push({
      pathname: `/bot/${botId}/grow`,
      state: {
        scrollToAdsManager: true,
      },
    });
  }, [botId, history]);
  const setAdIdInLocation = useCallback(
    (adId: string = '') => {
      history.push(`/bot/${botId}/home/ctm-ads-settings/${adId}`);
    },
    [botId, history],
  );

  const [currentAd, setCurrentAd] =
    useState<FacebookAdsByCampaignQuery_bot_facebookAdCampaign_ads | null>(
      null,
    );

  const [isCampaignsListEmpty, setIsCampaignsListEmpty] =
    useState<boolean>(false);

  const [currentCampaignType, setCurrentCampaignType] =
    useState<AdCampaignType>(AdCampaignType.click_to_messenger);

  const [currentStep, setCurrentStep] = useState<SettingsStep>(
    SettingsStep.step1,
  );

  useEffect(() => {
    if (initialAdId) {
      setCurrentStep(SettingsStep.step3);
    }
  }, [initialAdId]);

  return (
    <GrowSubPage>
      <GrowSubPageTitle
        breadcrumbs={[
          {
            title: window.i18next.t(
              'ClickToMessengerAdsSettingsPage-string-3147-growth-tools',
            ),
            onClick: goToGrow,
          },
        ]}
      >
        {window.i18next.t(
          'ClickToMessengerAdsSettingsPage-JSXText-3416-facebook-ads',
        )}
      </GrowSubPageTitle>
      <div style={{ width: 520 }}>
        <Type color="greyDark" size="15px_DEPRECATED">
          {window.i18next.t(
            'ClickToMessengerAdsSettingsPage-JSXText--148-start-conversations-at-scale-leveraging-facebook-targeting-to-find-customers-relevant-to-your-business',
          )}
        </Type>
      </div>
      <Spacer factor={2} />
      <AdManagerTutorialLinks
        onClick={(linkVideo) => {
          sendEvent({
            category: 'ads manager',
            action: 'watch the video',
            label: 'from ads manager settings',
            propertyBag: {
              linkVideo,
            },
          });
        }}
      />
      <Spacer factor={6} />
      <Box borderRadiusFactor={2}>
        <Stepper
          steps={[
            {
              active: currentStep === SettingsStep.step1,
              done: currentStep > SettingsStep.step1,
              title: window.i18next.t(
                'ClickToMessengerAdsSettingsPage-string-1588-begin-setup-in-ads-manager',
              ),
              content: currentStep === SettingsStep.step1 && (
                <ClickToMessengerAdsSettingsPageStep1
                  currentCampaignType={currentCampaignType}
                  onFacebookAdCampaignTypeSelect={(type) => {
                    setCurrentCampaignType(type);
                  }}
                />
              ),
            },
            {
              active: currentStep === SettingsStep.step2,
              done: currentStep > SettingsStep.step2,
              title: (
                <Flex alignItems="center">
                  {window.i18next.t(
                    'ClickToMessengerAdsSettingsPage-JSXText--122-connect-your-bot-to-your-ad',
                  )}
                  {currentStep === SettingsStep.step1 && (
                    <Button
                      intent={ButtonIntent.primary}
                      colorWay={ButtonColorWay.blue}
                      tall
                      className={css.setUpButton}
                      onClick={() => {
                        setIsCampaignsListEmpty(false);
                        setCurrentStep(SettingsStep.step2);
                        sendEvent({
                          category: 'ads manager',
                          action: 'set up connect bot',
                          label:
                            currentCampaignType ===
                            AdCampaignType.click_to_messenger
                              ? 'click-to-messenger campaign'
                              : 'sponsored messages campaign',
                          propertyBag: {
                            error: isCampaignsListEmpty
                              ? EMPTY_CAMPAIGNS_LIST_ERROR
                              : undefined,
                          },
                        });
                      }}
                    >
                      {window.i18next.t(
                        'ClickToMessengerAdsSettingsPage-JSXText-1747-set-up',
                      )}
                    </Button>
                  )}
                  {isCampaignsListEmpty && (
                    <div className={css.stepNote}>
                      <InfoIcon className={cn(css.infoIcon, css.red)} />
                      <Type color="red" size="15px_DEPRECATED">
                        {EMPTY_CAMPAIGNS_LIST_ERROR}
                      </Type>
                    </div>
                  )}
                </Flex>
              ),
              content: currentStep > SettingsStep.step1 && (
                <ClickToMessengerAdsSettingsPageStep2
                  botId={botId}
                  initialAdId={initialAdId}
                  onAdSelect={(ad) => {
                    if (currentAd && !ad) {
                      setCurrentStep(SettingsStep.step2);
                      setAdIdInLocation(undefined);
                    }
                    setCurrentAd(ad);
                  }}
                  campaignType={currentCampaignType}
                  onCampaignsListEmpty={() => {
                    setIsCampaignsListEmpty(true);
                    setCurrentStep(SettingsStep.step1);
                  }}
                />
              ),
            },
            {
              active: currentStep === SettingsStep.step3,
              title: (
                <Flex alignItems="center">
                  {window.i18next.t(
                    'ClickToMessengerAdsSettingsPage-JSXText-1158-configure-customer-actions',
                  )}
                  {currentStep < SettingsStep.step3 && (
                    <Button
                      intent={ButtonIntent.primary}
                      colorWay={ButtonColorWay.blue}
                      tall
                      className={css.setUpButton}
                      disabled={
                        !currentAd || currentAd?.connected_bot?.id !== botId
                      }
                      onClick={() => {
                        setCurrentStep(SettingsStep.step3);
                        setAdIdInLocation(currentAd?.id);
                        sendEvent({
                          category: 'ads manager',
                          action: 'set up customer actions',
                          label:
                            currentCampaignType ===
                            AdCampaignType.click_to_messenger
                              ? 'click-to-messenger campaign'
                              : 'sponsored messages campaign',
                        });
                      }}
                    >
                      {window.i18next.t(
                        'ClickToMessengerAdsSettingsPage-JSXText-1747-set-up',
                      )}
                    </Button>
                  )}
                </Flex>
              ),
              content: currentStep === SettingsStep.step3 && currentAd && (
                <ClickToMessengerAdsSettingsPageStep3
                  botId={botId}
                  ad={currentAd}
                  onRequestClose={goToGrow}
                  campaignType={currentCampaignType}
                />
              ),
            },
          ]}
        />
      </Box>
    </GrowSubPage>
  );
};
