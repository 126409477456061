import nanoid from 'nanoid';
import { livechatConversationNoteFragment } from '../@types/livechatConversationNoteFragment';

export const createNoteData = (
  adminId = '',
  noteId: string = '',
  text: string = '',
) =>
  ({
    __typename: 'LivechatConversationNote',
    id: noteId || nanoid(),
    adminId,
    text,
    updatedAt: Date.now(),
  } as livechatConversationNoteFragment);
