import React from 'react';
import { sendEvent } from '@utils/Analytics';
import { FilterableSearchField } from '@components/FilterableSearchField';
import { useCurrentBotId } from '@utils/Routing';
import { LivechatFilter } from './types';
import { AssigneeFilterableInput } from './components/AssineeFilter';
import { useLiveChatAdmins } from '../../hooks/useLiveChatAdmins';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { useWhatsappEnabled } from '@utils/Whatsapp';
import { AttributesFilterableInput } from './components/AttributesFilter/AttributesFilterableInput';

export interface LivechatFilterableSearchProps {
  onChooseFilter?: (type: LivechatFilter) => void;
}

export const LivechatFilterableSearch: React.FC<LivechatFilterableSearchProps> =
  ({ onChooseFilter }) => {
    const botId = useCurrentBotId();
    const { t } = useSafeTranslation();
    const { admins, loading: adminsLoading } = useLiveChatAdmins(botId!);
    const { isWhatsappEnabled } = useWhatsappEnabled();

    return (
      <FilterableSearchField<LivechatFilter>
        filterMenuTitle={t('FilterableSearch-string--512-search-by')}
        searchPlaceholderText={t(
          isWhatsappEnabled
            ? 'FilterableSearch-string-2105-search-by-user-name-and-phone'
            : 'FilterableSearch-string-2105-search-by-user-name',
        )}
        disableSearchInput={adminsLoading}
        onPrepareFilter={(preparedFilter) => {
          sendEvent({
            category: 'live chat',
            action: 'prepare filter',
            label: preparedFilter as unknown as string,
          });
        }}
        onSearchClick={() => {
          sendEvent({
            category: 'live chat',
            action: 'click search',
          });
        }}
      >
        {[
          {
            id: LivechatFilter.assignee,
            title: t('FilterableSearch-string--305-assignee'),
            icon: 'assign',
            type: 'item',
            activationString: t('FilterableSearch-string-1418-assignee'),
            renderFilterableInput: (preparedFilter, config) => (
              <AssigneeFilterableInput
                livechatAdmins={admins}
                preparedFilter={preparedFilter}
                {...config}
              />
            ),
          },
          {
            id: LivechatFilter.attribute,
            title: t('pages.LiveChat.FilterableSearch.attribute.menuTitle'),
            icon: 'attribute',
            type: 'item',
            activationString: t(
              'pages.LiveChat.FilterableSearch.attribute.activationString',
            ),
            onFilterActivated: () => onChooseFilter?.(LivechatFilter.attribute),
            renderFilterableInput: (preparedFilter, config) => (
              <AttributesFilterableInput
                onItemClick={() => onChooseFilter?.(LivechatFilter.attribute)}
                preparedFilter={preparedFilter}
                {...config}
              />
            ),
          },
        ]}
      </FilterableSearchField>
    );
  };
