import React, { CSSProperties, useCallback, useEffect, useState } from 'react';
import { propOr } from 'ramda';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { LSKey, useLocalStorage } from '@utils/LocalStorage/useLocalStorage';
import { Flex } from '@ui/Flex';
import { SimpleCombobox } from '@ui/SimpleCombobox';
import { ConnectedFacebookAdAccountQuery_bot_connectedFacebookAdAccount as FacebookAdAccount } from '@utils/MarketingApi/@types/ConnectedFacebookAdAccountQuery';
import { Type } from '@ui/Type';
import { usePageConnected } from '@utils/FacebookPages/usePageConnected';
import {
  useFacebookAdAccounts,
  useConnectedFacebookAdAccount,
} from '@utils/MarketingApi';
import { Loader } from '@ui/Loader';
import { sendEvent } from '@utils/Analytics';
import { Input } from '@ui/Input';
import { Button, ButtonUnstyled } from '@ui/Button';
import { Icon } from '@ui/Icon';
import { BlocksSelectorData } from '@components/BlocksSelector2';
import { useFbPermissions } from '@utils/FacebookPages/useFbPermissions';
import { Spacer } from '@ui/Spacer';
import { FacebookAdCampaignCategory } from '@globals';
import { useFacebookAdCampaignsListQuery } from '../hooks/useFacebookAdCampaignsListQuery';
import { FacebookAdCampaignsTable } from './FacebookAdCampaignsTable';
import * as css from './FacebookAdAccountSelector.css';

interface FacebookAdAccountSelectorProps {
  botId: string;
  goToGrow: () => void;
  wrapperStyles?: CSSProperties;
  limit?: number;
}

const SHOW_TYPE_ITEMS = () => [
  {
    id: '0',
    value: FacebookAdCampaignCategory.all,
    title: window.i18next.t(
      'FacebookAdAccountSelector-string-6264-all-campaigns',
    ),
  },
  {
    id: '1',
    value: FacebookAdCampaignCategory.active,
    title: window.i18next.t(
      'FacebookAdAccountSelector-string-4839-active-campaigns',
    ),
  },
  {
    id: '2',
    value: FacebookAdCampaignCategory.inactive,
    title: window.i18next.t(
      'FacebookAdAccountSelector-string--340-inactive-campaigns',
    ),
  },
];

const NAMESPACE = 'pages.GrowPage.ClickToMessenger.selector';

// prettier-ignore
export const FacebookAdAccountSelectableTable: React.FC<FacebookAdAccountSelectorProps> = ({
  botId,
  limit,
  wrapperStyles,
  goToGrow,
}) => {
  const { t } = useSafeTranslation();
  const {
    facebookAdAccounts,
    loading: facebookAdAccountsLoading,
    refetch: refetchAdAccounts,
  } = useFacebookAdAccounts({
    fetchPolicy: 'cache-and-network',
  });
  const history = useHistory();
  const {
    bindFacebookAdAccount,
    connectedFacebookAdAccount,
    loading: connectedFacebookAdAccountLoading,
  } = useConnectedFacebookAdAccount(botId);
  const { permissions } = useFbPermissions();
	const matchesGrow = useRouteMatch('/bot/:botId/grow/ctm-all-campaigns');
  const matchesHome = useRouteMatch('/bot/:botId/home/ctm-all-campaigns');
  const isAllCampaignsPage = Boolean(matchesGrow || matchesHome);

    const { pageId, loading: loadingPageConnected } = usePageConnected(botId);

    const [selectedAdAccount, setSelectedAdAccount] =
      useState<FacebookAdAccount | null>(null);

    useEffect(() => {
      if (
        facebookAdAccounts.length &&
        !connectedFacebookAdAccount &&
        pageId &&
        !connectedFacebookAdAccountLoading
      ) {
        const [defaultFacebookAdAccount] = facebookAdAccounts;
        setSelectedAdAccount(defaultFacebookAdAccount);
        bindFacebookAdAccount(defaultFacebookAdAccount.id);
      }
    }, [
      facebookAdAccounts,
      connectedFacebookAdAccount,
      bindFacebookAdAccount,
      pageId,
      connectedFacebookAdAccountLoading,
    ]);

    useEffect(() => {
      if (connectedFacebookAdAccount) {
        setSelectedAdAccount(connectedFacebookAdAccount);
      }
    }, [connectedFacebookAdAccount]);

  const [campaignCategory, setCampaignCategory] = useLocalStorage(
    LSKey.fbAdsShowCampaignCategory,
    FacebookAdCampaignCategory.active,
  );

    useEffect(() => {
      if (isAllCampaignsPage) {
        setCampaignCategory(FacebookAdCampaignCategory.all);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const {
      data,
      loading: campaignsLoading,
      error,
      refetch: refetchAdCampaigns,
    } = useFacebookAdCampaignsListQuery({
      botId,
      limit,
      category: campaignCategory,
    });

    const onShowTypeChange = useCallback(
      (showType) => {
        setCampaignCategory(showType.value);
      },
      [setCampaignCategory],
    );

    const isCampaignsListEmpty = data?.bot.facebookAdCampaignsCtm?.length === 0;
    const isAdAccountsListEmpty = facebookAdAccounts.length === 0;
    const showEmptyAccountView = !pageId || isAdAccountsListEmpty;
    const showButtons =
      pageId &&
      permissions &&
      !isCampaignsListEmpty &&
      !campaignsLoading &&
      !isAdAccountsListEmpty &&
      !facebookAdAccountsLoading;

    const loading =
      facebookAdAccountsLoading ||
      connectedFacebookAdAccountLoading ||
      loadingPageConnected;

    return (
      <>
        <Flex
          className={css.box}
          alignItems="center"
          justifyContent={
            !showEmptyAccountView || facebookAdAccountsLoading
              ? 'space-between'
              : 'flex-end'
          }
          style={{ height: 36 }}
        >
          {loading ? (
            <Flex
              justifyContent="center"
              alignItems="center"
              style={{ width: 200, marginRight: 24, height: 36 }}
            >
              <Loader />
            </Flex>
          ) : !showEmptyAccountView ? (
            <div className={css.selector}>
              <SimpleCombobox
                className={css.dropdown}
                selectedItem={selectedAdAccount}
                itemToString={propOr('', 'name')}
                items={facebookAdAccounts}
                onChange={(account) => {
                  setSelectedAdAccount(account);
                  bindFacebookAdAccount(account!.id);
                  if (account) {
                    sendEvent({
                      category: 'ads manager',
                      action: 'change account confirm',
                      propertyBag: {
                        accountId: account.id,
                        accountName: account.name,
                      },
                    });
                  }
                }}
                renderInput={({ getToggleButtonProps, getInputProps }) => {
                  const { onClick } = getToggleButtonProps();

                  return (
                    <Input
                      {...getInputProps()}
                      onClick={onClick}
                      renderIconEnd={() => (
                        <ButtonUnstyled onClick={onClick}>
                          <Icon icon="triangle" size="24px" />
                        </ButtonUnstyled>
                      )}
                    />
                  );
                }}
              />
            </div>
          ) : null}
          <div className={css.statusWrapper}>
            <Type size="15px_DEPRECATED">{t(`${NAMESPACE}.show`)}</Type>
            <div className={css.statusCombobox}>
              <SimpleCombobox
                menuboxStyle={{ width: 175, right: 0 }}
                scrollboxStyle={{ overflowX: 'hidden' }}
                items={SHOW_TYPE_ITEMS()}
                itemToString={(item) => item?.title || ''}
                selectedItem={SHOW_TYPE_ITEMS().find(
                  ({ value }) => value === campaignCategory,
                )}
                onChange={onShowTypeChange}
                renderInput={({ getToggleButtonProps, selectedItem }) => {
                  const { onClick } = getToggleButtonProps();

                  return (
                    <ButtonUnstyled onClick={onClick}>
                      <Type color="blue" size="15px_DEPRECATED">
                        {selectedItem.title.toLowerCase()}
                      </Type>
                    </ButtonUnstyled>
                  );
                }}
              />
            </div>
          </div>
        </Flex>
        <BlocksSelectorData botId={botId}>
          {({ flowsGroups }) => {
            const flowIds = flowsGroups
              .flatMap(({ flows }) => flows)
              .filter(Boolean)
              .map((flow) => flow?.id) as string[];

            return (
              <FacebookAdCampaignsTable
                data={data}
                loading={campaignsLoading}
                error={error}
                refetchAdCampaigns={refetchAdCampaigns}
                refetchAdAccounts={refetchAdAccounts}
                wrapperStyles={wrapperStyles}
                flowIds={flowIds}
                showEmptyAccountView={showEmptyAccountView}
              />
            );
          }}
        </BlocksSelectorData>
        {showButtons && (
          <>
            <Flex
              alignItems="center"
              justifyContent="flex-end"
              style={{ paddingBottom: isAllCampaignsPage ? 24 : 0 }}
              className={css.buttons}
            >
              <Button
                intent="primary"
                icon={<Icon icon="plus" color="white" />}
                onClick={() => {
                  history.push(`/bot/${botId}/home/ctm-ads-settings/`);
                  sendEvent({
                    category: 'ads manager',
                    action: 'connect new ads',
                  });
                }}
              >
                {t(`${NAMESPACE}.connectNewAds`)}
              </Button>
              <Spacer horizontalFactor={4} />
              {isAllCampaignsPage ? (
                <Button intent="secondary" onClick={goToGrow}>
                  {t(`${NAMESPACE}.backToGrowth`)}
                </Button>
              ) : (
                <Button
                  intent="secondary"
                  onClick={() => {
                    sendEvent({
                      category: 'ads manager',
                      action: 'show all ad campaigns',
                    });
                    history.push(`/bot/${botId}/home/ctm-all-campaigns`);
                  }}
                >
                  {t(`${NAMESPACE}.showAllCampaigns`)}
                </Button>
              )}
              {isAllCampaignsPage && <Spacer horizontalFactor={5} />}
            </Flex>
          </>
        )}
      </>
    );
  };
