import React from 'react';
import { Flex } from '@ui/Flex';
import { FlexProps } from '@ui/Flex/Flex';
import { useDeviceMedia } from '@utils/DOM/useDeviceMedia';
import { getComponentWithRefAndName } from '@utils/withRef';

interface PlatformConnectedWrapperProps extends FlexProps, TestLocator {}

export const PlatformConnectedWrapper = getComponentWithRefAndName<
  HTMLDivElement,
  PlatformConnectedWrapperProps
>('PlatformConnectedWrapper', ({ children, ...props }, ref) => {
  const { isSmallScreenSize } = useDeviceMedia();

  return (
    <Flex
      ref={ref}
      alignItems="center"
      justifyContent="space-between"
      flexDirection={isSmallScreenSize ? 'column' : 'row'}
      {...props}
    >
      {children}
    </Flex>
  );
});
