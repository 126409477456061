import { Platform } from '@globals';
import { FileAttachmentType } from '@utils/UploadService/types';

export interface MessageAttachment {
  type: FileAttachmentType;
  name: string;
  file?: string;
  blob?: Blob;
  active?: boolean;
}

export enum SendingMessageStatus {
  queue = 'queue',
  uploading = 'uploading',
  error = 'error',
}

export interface SendingMessage {
  id: string;
  conversationId: string;
  platform: Platform;
  status?: SendingMessageStatus;
  attachment?: MessageAttachment;
  text?: string;
  outpacingId?: string;
}
