import React from 'react';
import { useMutation, useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import { Link, useHistory } from 'react-router-dom';
import { formatPriceEx } from '@utils/Format/priceFormatter';
import * as css from './AbandonedCartSetup.css';
import { Type } from '../../../../modern-ui/Type';
import { Loader } from '../../../../modern-ui/Loader';
import { Button } from '../../../../modern-ui/Button';
import { sendEvent } from '../../../../utils/Analytics';
import {
  CartRemindersStatQuery,
  CartRemindersStatQueryVariables,
} from './@types/CartRemindersStatQuery';
import {
  CreateTriggerMessage,
  CreateTriggerMessageVariables,
} from './@types/CreateTriggerMessage';
import { useCurrentBotId } from '../../../../utils/Routing';
import { ReactComponent as InfoIcon } from '../../../../modern-ui/_deprecated/Icon/icons/error-info.svg';
import { ReactComponent as PlusIcon } from '../../../../modern-ui/_deprecated/Icon/icons/Plus_small.svg';
import { useIsBotProOrTrial } from '../../../../utils/Pro';

const CART_REMINDERS_STAT_QUERY = gql`
  query CartRemindersStatQuery($botId: String!) {
    bot(id: $botId) {
      id
      abandonedCartStat {
        reminders {
          id
          block_id
          enabled
          clicked
          purchased
          sent
        }
        revenue {
          amount
          currency
        }
      }
    }
  }
`;

const TRIGGERED_MESSAGE_TYPE = 'abandoned_cart_update';

const MAX_REMINDERS_IN_STATS = 5;

const CREATE_TRIGGER_MESSAGE = gql`
  mutation CreateTriggerMessage(
    $botId: String!
    $triggerType: String
    $reminderNumber: Int
  ) {
    createCartReminderBlock(
      botId: $botId
      triggerType: $triggerType
      reminderNumber: $reminderNumber
    ) {
      id
      block_id
    }
  }
`;

export const CartRemindersStat: React.FC = () => {
  const botId = useCurrentBotId() || '';
  const history = useHistory();

  const { loading: isBotProOrTrialLoading, proOrTrialBot } = useIsBotProOrTrial(
    botId,
  );

  const {
    data: cartRemindersStats,
    loading: cartRemindersStatsLoading,
  } = useQuery<CartRemindersStatQuery, CartRemindersStatQueryVariables>(
    CART_REMINDERS_STAT_QUERY,
    {
      variables: {
        botId: botId || '',
      },
      skip: !botId,
      fetchPolicy: 'network-only',
    },
  );

  const [createTrigger, { loading: createTriggerLoading }] = useMutation<
    CreateTriggerMessage,
    CreateTriggerMessageVariables
  >(CREATE_TRIGGER_MESSAGE, {
    onCompleted: (data) => {
      if (data.createCartReminderBlock?.id) {
        history.push(
          `/bot/${botId}/broadcast/${data.createCartReminderBlock.block_id}/${data.createCartReminderBlock.id}/sequence`,
        );
      }
    },
  });

  const blocks = cartRemindersStats?.bot.abandonedCartStat?.reminders || [];
  const revenue = cartRemindersStats?.bot.abandonedCartStat?.revenue;

  if (
    !botId ||
    isBotProOrTrialLoading ||
    (!proOrTrialBot && blocks.length === 0)
  ) {
    return null;
  }

  return (
    <table className={css.table}>
      <thead>
        <tr>
          <th align="left">
            <Type size="12px" color="greyDark">
              {window.i18next.t(
                'CartRemindersStat-JSXText--527-reminder-stats',
              )}
            </Type>
          </th>
          <th className={css.dataCol}>
            <Type size="12px" color="greyDark">
              {window.i18next.t('CartRemindersStat-JSXText-1709-status')}
            </Type>
          </th>
          <th className={css.dataCol}>
            <Type size="12px" color="greyDark">
              {window.i18next.t('CartRemindersStat-JSXText-1056-sent')}
            </Type>
          </th>
          <th className={css.dataCol}>
            <Type size="12px" color="greyDark">
              {window.i18next.t('CartRemindersStat-JSXText--172-click-rate')}
            </Type>
          </th>
          <th className={css.lastHeaderCol}>
            <Type size="12px" color="greyDark">
              {window.i18next.t('CartRemindersStat-JSXText-1624-orders')}
            </Type>
          </th>
        </tr>
      </thead>
      <tbody>
        {blocks.map(
          (block, i) =>
            block && (
              <tr key={block.id} className={css.tableRow}>
                <td>
                  <Link
                    className={css.reminderTitle}
                    to={`/bot/${botId}/broadcast/${block.block_id}/${block.id}/sequence`}
                  >
                    <Type size="15px_DEPRECATED">
                      {window.i18next.t(
                        'CartRemindersStat-JSXText--397-message',
                      )}
                      {i + 1}
                    </Type>
                  </Link>
                </td>
                <td className={css.dataCol}>
                  <Type size="15px_DEPRECATED">
                    {block.enabled
                      ? window.i18next.t('CartRemindersStat-string-2368-live')
                      : window.i18next.t(
                          'CartRemindersStat-string--191-paused',
                        )}
                  </Type>
                </td>
                <td className={css.dataCol}>
                  <Type size="15px_DEPRECATED">
                    {(block.sent || '0').toLocaleString('en-EN')}
                  </Type>
                </td>
                <td className={css.dataCol}>
                  <Type size="15px_DEPRECATED">
                    {block.sent
                      ? `${Math.round(
                          ((block.clicked || 0) / block.sent) * 100,
                        )}%`
                      : '—'}
                  </Type>
                </td>
                <td className={css.lastCol}>
                  <Type size="15px_DEPRECATED">
                    {(block.purchased || '0').toLocaleString('en-EN')}
                  </Type>
                </td>
              </tr>
            ),
        )}
      </tbody>
      <tfoot>
        <tr>
          {proOrTrialBot && blocks.length === 0 && !cartRemindersStatsLoading && (
            <td colSpan={3}>
              <Type size="15px_DEPRECATED">
                <InfoIcon className={css.infoIcon} />
                {window.i18next.t(
                  'CartRemindersStat-JSXText-1206-no-reminders-currently-set',
                )}
              </Type>
            </td>
          )}
          {proOrTrialBot && (
            <td colSpan={2} align={blocks.length === 0 ? 'right' : 'left'}>
              {createTriggerLoading ? (
                <Loader />
              ) : blocks.length < MAX_REMINDERS_IN_STATS ? (
                <Button
                  intent="text"
                  icon={<PlusIcon />}
                  onClick={() => {
                    sendEvent({
                      category: 'cart reminders',
                      action: 'create reminder',
                      propertyBag: {
                        reminderNumber: blocks.length,
                      },
                    });
                    createTrigger({
                      variables: {
                        botId,
                        triggerType: TRIGGERED_MESSAGE_TYPE,
                        reminderNumber: blocks.length,
                      },
                    });
                  }}
                >
                  {window.i18next.t(
                    'CartRemindersStat-JSXText-1496-create-reminder',
                  )}
                </Button>
              ) : (
                <Button
                  intent="text"
                  onClick={() => {
                    sendEvent({
                      category: 'cart reminders',
                      action: 'go to view all reminders',
                    });
                    history.push(`/bot/${botId}/broadcast`);
                  }}
                >
                  {window.i18next.t(
                    'CartRemindersStat-JSXText--165-see-all-reminders',
                  )}
                </Button>
              )}
            </td>
          )}
          {blocks.length !== 0 && revenue && (
            <td colSpan={proOrTrialBot ? 3 : 5} align="right">
              <Type color="greyDark" size="15px_DEPRECATED">
                {window.i18next.t('CartRemindersStat-JSXText--392-revenue')}
              </Type>
              <span style={{ marginRight: '4px' }} />
              <Type as="span" size="18px" weight="medium">
                {revenue.amount && revenue.currency
                  ? formatPriceEx(revenue.amount, revenue.currency)
                  : '$0'}
              </Type>
            </td>
          )}
        </tr>
      </tfoot>
    </table>
  );
};
