import React, { useEffect } from 'react';
import gql from 'graphql-tag';
import { useHistory } from 'react-router-dom';
import { removeGlobalLoader } from 'cf-common/src/utils/removeGlobalLoader';
import { useMutation } from '@apollo/react-hooks';
import { InitAuthProps } from '@utils/AuthFlow';
import { sendEvent } from '@utils/Analytics';
import { PluginType } from '@components/Plugins/common/PluginTypes';
import { StartIntegrationZapierOAuthState } from '@utils/Integrations/Zapier/startIntegrationZapierOAuth';
import { BotTabs, getTabLink } from '@utils/Routing';
import { Oops } from '../Oops';
import {
  ConfirmIntegrationZapierOauthMutation,
  ConfirmIntegrationZapierOauthMutationVariables,
} from './@types/ConfirmIntegrationZapierOauthMutation';

const CONFIRM_INTEGRATION_ZAPIER_OAUTH_MUTATION = gql`
  mutation ConfirmIntegrationZapierOauthMutation(
    $botId: String!
    $accessToken: String!
  ) {
    confirmIntegrationZapierOauth(botId: $botId, accessToken: $accessToken)
  }
`;

const clearHashRegExp = /^#/;

export const IntegrationZapierConnectPage: React.FC<InitAuthProps> = ({
  location: { hash, search },
}) => {
  const [
    confirmIntegrationZapierOauth,
    { data, error, loading, called },
  ] = useMutation<
    ConfirmIntegrationZapierOauthMutation,
    ConfirmIntegrationZapierOauthMutationVariables
  >(CONFIRM_INTEGRATION_ZAPIER_OAUTH_MUTATION);
  const history = useHistory();

  // We get auth data from url params
  const urlParams = new URLSearchParams(search); // some data in real search param
  const urlHashParams = new URLSearchParams(hash.replace(clearHashRegExp, '')); // some data in after hash search param - wtf?!
  const accessToken = urlHashParams.get('access_token');
  const authError = urlParams.get('error');
  const { botId, flowId, blockId } = JSON.parse(
    urlHashParams.get('state') || urlParams.get('state') || '{}',
  ) as StartIntegrationZapierOAuthState;

  useEffect(() => {
    if (accessToken) {
      confirmIntegrationZapierOauth({
        variables: {
          botId,
          accessToken,
        },
      });
    }
  }, [confirmIntegrationZapierOauth, accessToken, botId]);

  useEffect(() => {
    if (authError) {
      history.push(getTabLink(BotTabs.flows, botId, { flowId, blockId }));
    }
  }, [authError, flowId, blockId, botId, history]);

  useEffect(() => {
    if (called && !loading) {
      removeGlobalLoader();
    }
  }, [called, loading]);

  useEffect(() => {
    if (!error && botId && data?.confirmIntegrationZapierOauth) {
      sendEvent({
        category: PluginType.external_integration_entry_point,
        action: 'zapier connect success',
        propertyBag: {
          botId,
          flowId,
          blockId,
        },
      });
      history.push(getTabLink(BotTabs.flows, botId, { flowId, blockId }));
    }
  }, [error, botId, flowId, blockId, data, history]);

  if (error) {
    return (
      <Oops
        reason="Error on confirm Integration Zapier connect"
        errorText={error.message}
      />
    );
  }

  return null;
};
