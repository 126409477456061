import React from 'react';
import { HomeTabInternalPage } from '../HomeTabInternalPage';
import { DisableIfPageNotConnected } from '../../BotPage/HomeTab/components/DisableIfPageNotConnected';
import { RoleConsumer } from '@utils/Roles';
import { Permission } from '../../../common/services/RoleService';
import { ClickToMessengerSetup } from '.';

export const FacebookMessengerAdsPage = () => (
  <HomeTabInternalPage>
    <DisableIfPageNotConnected>
      <RoleConsumer domain="grow" can={Permission.EDIT}>
        <ClickToMessengerSetup />
      </RoleConsumer>
    </DisableIfPageNotConnected>
  </HomeTabInternalPage>
);
