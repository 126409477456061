import React from 'react';
import cx from 'classnames';
import {
  MessageContentShape,
  MessageExtraShape,
  Divider,
} from '../MessageContentShape';
import { MessageButtons } from '../MessageButtons';
import * as s from './GalleryMessage.css';
import {
  MessageFooter,
  MessageFooterProps,
} from '../../MessageTimestamp/MessageTimestamp';
import { Spacer } from '@ui/Spacer';

const galleryImageDefaults = {
  width: 300,
  height: 150,
  heightSquare: 230,
};

interface GalleryItem {
  title: string | null;
  description: string | null;
  buttons: string[] | null;
  image_url: string | null;
  url: string | null;
}

interface GalleryMessageProps extends MessageFooterProps {
  type: 'incoming' | 'outgoing';
  gallery: {
    items: GalleryItem[];
    type: string | null;
  };
  testing?: boolean;
  onButtonClick?(galleryIndex: number, buttonIndex: number, text: string): void;
}

export const GalleryMessage: React.FC<GalleryMessageProps> = ({
  type,
  date,
  status,
  gallery,
  testing,
  error,
  onButtonClick,
}) => {
  return (
    <div className={cx(s.root, { [s.outgoing]: type === 'outgoing' })}>
      {gallery.items.map((item, index) => {
        const hasButtons = Boolean(item.buttons && item.buttons.length);
        return (
          // eslint-disable-next-line react/no-array-index-key
          <div key={index}>
            <MessageContentShape
              type={type}
              leading
              trailing={false}
              capped
              className={s.image}
              style={{
                backgroundColor: 'var(--greyLight20)',
                backgroundImage: `url(${item.image_url})`,
                width: galleryImageDefaults.width,
                height:
                  gallery.type === 'square'
                    ? galleryImageDefaults.heightSquare
                    : galleryImageDefaults.height,
              }}
            />
            <MessageExtraShape
              type={type}
              testing={testing}
              style={{ maxWidth: galleryImageDefaults.width }}
            >
              <MessageFooter
                error={error}
                date={date}
                type={type}
                status={status}
              >
                <div className={s.extra}>
                  <div>
                    <strong>{item.title}</strong>
                  </div>

                  {item.description ? <div>{item.description}</div> : null}
                  <Spacer factor={2} />
                </div>
              </MessageFooter>
              {hasButtons ? (
                <React.Fragment>
                  <Divider />
                  <MessageButtons
                    testing={testing}
                    buttons={item.buttons?.map((text) => ({ text }))!}
                    onButtonClick={(buttonIndex, text) =>
                      onButtonClick?.(index, buttonIndex, text)
                    }
                  />
                </React.Fragment>
              ) : null}
            </MessageExtraShape>
          </div>
        );
      })}
    </div>
  );
};
