import React from 'react';
import { Placeholder } from '../../helpers/getMediaMessagePlaceholders';
import { MediaMessageExpired } from '../MediaMessageExpired';
import { MediaMessageLoader } from '../MediaMessageLoader';
import { MediaMessagePlaceholder } from '../MediaMessagePlaceholder';
import { AttachmentProps } from './types';

export interface CommonAttachmentProps
  extends Omit<AttachmentProps, 'placeholder'> {
  placeholder: Placeholder;
  children: (id: string) => React.ReactNode;
  type?: 'incoming' | 'outgoing';
}

export const CommonAttachment: React.FC<CommonAttachmentProps> = ({
  attachment,
  onClick,
  isExpired,
  loading,
  placeholder,
  children,
  type,
}) => {
  if (isExpired) {
    return <MediaMessageExpired {...placeholder} />;
  }

  if (loading) {
    return <MediaMessageLoader type={type} {...placeholder} />;
  }

  if (!attachment.url) {
    return (
      <MediaMessagePlaceholder onClick={onClick} type={type} {...placeholder} />
    );
  }

  if (attachment.url) {
    return <>{children(attachment.url)}</>;
  }

  return null;
};
