import React, { useCallback } from 'react';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { useHistory } from 'react-router-dom';
import { useCurrentBotId } from '@utils/Routing';
import { BotTabCentered } from '@components/BotTab';
import { Button } from '@ui/Button';
import { Icon } from '@ui/Icon';
import { Spacer } from '@ui/Spacer';
import { Type } from '@ui/Type';
import * as css from './HomeTabInternalPage.css';

export const HomeTabInternalPage: React.FC = ({ children }) => {
  const { t } = useSafeTranslation();
  const botId = useCurrentBotId()!;
  const history = useHistory();

  const onBackClick = useCallback(() => {
    history.push(`/bot/${botId}/home`);
  }, [botId, history]);

  return (
    <BotTabCentered className={css.legacyPageWidth}>
      <Button
        style={{ inlineSize: 'fit-content' }}
        icon={<Icon icon="arrowBack" size="14px" />}
        intent="secondary"
        onClick={onBackClick}
      >
        <Type size="15px">{t('pages.LegacyPage.back')}</Type>
      </Button>
      <Spacer horizontalFactor={6} />
      {children}
    </BotTabCentered>
  );
};
