import { useHistory, useLocation } from 'react-router-dom';
import { SimpleCombobox } from '@ui/SimpleCombobox';
import { Button } from '@ui/Button';
import { Icon } from '@ui/Icon';
import { MenuItem } from '@ui/Menu';
import { Type } from '@ui/Type';
import { sendEvent } from '@utils/Analytics';
import { useWhatsappEnabled } from '@utils/Whatsapp';
import React, { useCallback, useMemo } from 'react';
import { ExtendedPlatform } from './types';
import * as css from './PlatformSelector.css';
import i18next from 'i18next';
import { Spacer } from '@ui/Spacer';

interface DialogSourceType {
  id: ExtendedPlatform;
  title: string;
}

const PLATFORM_BY_DIALOG_SOURCE = (): Record<
  ExtendedPlatform,
  DialogSourceType
> => ({
  [ExtendedPlatform.all]: {
    id: ExtendedPlatform.all,
    title: i18next.t('PlatformSelector-string--131-all-channels'),
  },
  [ExtendedPlatform.facebook]: {
    id: ExtendedPlatform.facebook,
    title: i18next.t('PlatformSelector-string-5678-messenger'),
  },
  [ExtendedPlatform.instagram]: {
    id: ExtendedPlatform.instagram,
    title: i18next.t('PlatformSelector-string--182-instagram-direct'),
  },
  [ExtendedPlatform.whatsapp]: {
    id: ExtendedPlatform.whatsapp,
    title: i18next.t('PlatformSelector-string--182-whatsapp'),
  },
});

export interface PlatformSelectorProps {
  initialValue: ExtendedPlatform;
}

export const PlatformSelector: React.FC<PlatformSelectorProps> = ({
  initialValue,
}) => {
  const location = useLocation();
  const history = useHistory();
  const { isWhatsappEnabled } = useWhatsappEnabled();

  const onChange = useCallback(
    (platform: ExtendedPlatform) => {
      sendEvent({
        category: 'live chat',
        action: 'platform select',
        propertyBag: {
          platform,
        },
      });

      const params = new URLSearchParams(location.search);

      if (platform !== ExtendedPlatform.all) {
        params.set('platform', platform);
        params.set('dialogListPlatform', platform);
        params.delete('conversationId');
      } else {
        params.delete('dialogListPlatform');
      }

      history.push(`${location.pathname}?${params}`);
    },
    [history, location],
  );

  const dialogSources: Record<string, DialogSourceType> = useMemo(
    PLATFORM_BY_DIALOG_SOURCE,
    [],
  );

  const availableSources: DialogSourceType[] = useMemo(
    () =>
      isWhatsappEnabled
        ? [dialogSources[ExtendedPlatform.whatsapp]]
        : Object.values(dialogSources).filter(
            ({ id }) => id !== ExtendedPlatform.whatsapp,
          ),
    [isWhatsappEnabled, dialogSources],
  );

  if (isWhatsappEnabled) {
    return (
      <div>
        <Spacer factor={1} />
        <Type size="18px" weight="medium">
          {dialogSources[ExtendedPlatform.whatsapp].title}
        </Type>
      </div>
    );
  }

  return (
    <SimpleCombobox<{ id: ExtendedPlatform }>
      downshiftContainerClassName={css.combobox}
      position="bottom-start"
      onChange={(item) => {
        if (item?.id && item.id !== initialValue) {
          onChange(item.id);
        }
      }}
      renderInput={({ ref, getToggleButtonProps }) => {
        return (
          <Button
            {...getToggleButtonProps()}
            className={css.button}
            ref={ref}
            intent="text"
            iconRight={<Icon icon="triangle" />}
            data-testid="livechat_platform-selector-button"
          >
            <Type size="18px" weight="medium">
              {dialogSources[initialValue].title}
            </Type>
          </Button>
        );
      }}
      items={availableSources}
      renderItem={({ getItemProps, item, index, highlightedIndex }) => {
        const active = index === highlightedIndex;
        return (
          <MenuItem
            {...getItemProps({ item })}
            key={item.id}
            active={active}
            data-testid={`livechat_platform-selector_${item.id}-button`}
            title={dialogSources[item.id].title}
          />
        );
      }}
      unsaveSelectedOption
    />
  );
};
