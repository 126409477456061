import React from 'react';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { Flex } from '@ui/Flex';
import { Spacer } from '@ui/Spacer';
import { Type } from '@ui/Type';
import { ReactComponent as ConversationImage } from '../assets/Conversation.svg';

export const NoConversationsPlaceholder: React.FC = () => {
  const { t } = useSafeTranslation();
  return (
    <Flex
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      style={{ backgroundColor: 'var(--white)' }}
      fullHeight
    >
      <ConversationImage />
      <Spacer factor={4} />
      <Type size="24px" weight="medium" align="center">
        {t('pages.LiveChat.Disabled.noConversationsYet')}
      </Type>
    </Flex>
  );
};
