import React from 'react';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { Button } from '@ui/Button';
import { Type } from '@ui/Type';
import { Icon } from '@ui/Icon';
import { Tooltip2 } from '@ui/Tooltip2';
import { Modal } from '@services/index';
import { FlowSelectorDialog } from '@components/FlowSelectorDialog/FlowSelectorDialog';
import { useFlowGroups } from '@components/FlowSelectorDialog/hooks';
import { DialogLocalizationContextProvider } from '@components/FlowSelectorDialog/LocalizationContext';
import { useCurrentBotId } from '@utils/Routing';
import { useToaster } from '@ui/Toaster';
import { sendEvent } from '@utils/Analytics';
import { useSendBlock } from './useSendBlock';
import { Folder, Platform } from '@globals';

interface SendFlowButtonProps {
  conversationId: string;
  platform: Platform;
  dialogListPlatform: Platform | null;
  conversationFolder: Folder;
  disabled: boolean;
}

export const SendFlowButton: React.FC<SendFlowButtonProps> = ({
  platform,
  conversationId,
  dialogListPlatform,
  conversationFolder,
  disabled,
}) => {
  const botId = useCurrentBotId()!;
  const { flowGroups, loading: flowGroupsLoading } = useFlowGroups(botId);
  const { addToaster } = useToaster();
  const { t } = useSafeTranslation();
  const { sendBlock, disabled: sendBlockDisabled } = useSendBlock({
    botId,
    conversationId,
    conversationFolder,
    platform,
    dialogListPlatform,
    onConversationMoved: (folder) => {
      addToaster({
        type: 'info',
        content: (
          <div style={{ minWidth: '300px', whiteSpace: 'nowrap' }}>
            {t('Dialogs-JSXText--382-conversation-moved-to')}{' '}
            {folder.charAt(0).toUpperCase() + folder.slice(1)}.
          </div>
        ),
        timeout: 3000,
        closeButton: true,
      });
    },
  });

  const buttonDisabled = disabled || sendBlockDisabled;

  return (
    <Tooltip2
      content={
        <Type size="12px" color="white">
          {t('pages.LiveChat.SendFlow.tooltip')}
        </Type>
      }
      placement="top"
      type="small"
    >
      {(ref, bind) => (
        <Button
          {...bind}
          ref={ref}
          intent="text"
          size="s"
          disabled={!!buttonDisabled}
          icon={<Icon icon="tabs_flows" size="16px" />}
          data-testid="livechat_message-input_send-flow-button"
          onClick={() => {
            sendEvent({
              category: 'live chat',
              action: 'open choose flow dialog',
              propertyBag: {
                botId,
                conversationId,
              },
            });
            Modal.show(
              ({ close }) => (
                <DialogLocalizationContextProvider
                  header="pages.LiveChat.SendFlow.modal.header"
                  selectFlowButtonTitle={`pages.LiveChat.SendFlow.modal.selectButton${
                    conversationFolder === Folder.closed ? 'InClosed' : 'InOpen'
                  }`}
                >
                  <FlowSelectorDialog
                    flowsGroups={flowGroups}
                    loading={flowGroupsLoading}
                    onAcceptSelectFlow={(flowId, { title }) => {
                      sendEvent({
                        category: 'live chat',
                        action: 'send flow',
                        propertyBag: {
                          botId,
                          conversationId,
                          flowId,
                        },
                      });
                      sendBlock({ flowId, title });
                      close();
                    }}
                    showCreateButton={false}
                    onClose={close}
                    platform={platform}
                  />
                </DialogLocalizationContextProvider>
              ),
              {
                mobileAdaptive: true,
              },
            );
          }}
        />
      )}
    </Tooltip2>
  );
};
