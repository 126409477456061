import React from 'react';
import { BotTabs } from '@utils/Routing';
import { LiveChatPageContent } from './LiveChatPageContent';
import { MiniOnbordingTab } from '@components/MiniOnboarding/MiniOnbordingTab';
import { useResetMetaForMobile } from '@utils/css/useResetMetaForMobile';
import { LiveChatDialogsQtyStateContextProvider } from './hooks/useLiveChatDialogsQtyState';

interface LiveChatPageProps {}

export const LiveChatPage: React.FC<LiveChatPageProps> = () => {
  useResetMetaForMobile();
  return (
    <MiniOnbordingTab page={BotTabs.liveChat}>
      <LiveChatDialogsQtyStateContextProvider>
        <LiveChatPageContent />
      </LiveChatDialogsQtyStateContextProvider>
    </MiniOnbordingTab>
  );
};
