import React from 'react';
import { Type } from '@ui/Type';
import { Anchor } from '@ui/Links';
import { sendEvent } from '@utils/Analytics';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import {
  ConnectPageField,
  ConnectPageOrigin,
  useConnectPage,
} from '@components/ConnectPageDialog';
import { PlatformNotConnected } from '../../../PlatformConnection';

const Tooltip = () => {
  const { t } = useSafeTranslation();

  return (
    <Type size="12px" color="white" as="div">
      {t('modernComponents.FacebookPagesSetup.common.troubleshootingTooltip')}
      <br />
      <br />
      <Anchor
        intent="tooltip"
        size="small"
        target="_blank"
        href={t('common.helpDocLinks.problems_with_facebook_page_connection')}
        onClick={() => {
          sendEvent({
            category: 'connect facebook page',
            action: 'trouble shooting click',
          });
        }}
      >
        {t('common.Troubleshooting')}
      </Anchor>
    </Type>
  );
};

interface FacebookPageDisconnectedProps {
  botId: string;
  refetchInstagramAccount(): void;
}

export const FacebookPageDisconnected: React.FC<FacebookPageDisconnectedProps> =
  ({ botId, refetchInstagramAccount }) => {
    const { t } = useSafeTranslation();

    const { connectPage } = useConnectPage({
      botId,
      urlParams: {
        [ConnectPageField.origin]: ConnectPageOrigin.facebookConnect,
      },
      onPageConnected() {
        refetchInstagramAccount();
      },
    });

    return (
      <PlatformNotConnected
        icon="facebook"
        title={t('modernComponents.FacebookPage.title')}
        tooltipContent={<Tooltip />}
        description={t('modernComponents.FacebookPage.connectPage.description')}
        buttonTextId="facebook-page__connect-button"
        onClick={() => {
          sendEvent({
            category: 'connect facebook page',
            action: 'click connect page',
          });
          connectPage();
        }}
      />
    );
  };
