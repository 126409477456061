import React from 'react';
import * as css from './FacebookAdCampaignsTable.css';
import { LoadingPlaceholder } from '../../../../modern-ui/LoadingPlaceholder/LoadingPlaceholder';
import { Spacer } from '../../../../modern-ui/Spacer';

const ITEMS_QTY = 5;
const ITEMS = Array(ITEMS_QTY)
  .fill(0)
  .map((_, i) => i);

export const FacebookAdCampaignsListLoader: React.FC = () => (
  <>
    {ITEMS.map((key) => (
      <tbody key={key} className={css.loader}>
        <tr>
          <td className={css.spacer} />
          <td>
            <LoadingPlaceholder />
            <Spacer factor={2} />
            <LoadingPlaceholder />
          </td>
          <td />
          <td>
            <LoadingPlaceholder />
          </td>
          <td>
            <LoadingPlaceholder />
          </td>
          <td>
            <LoadingPlaceholder />
          </td>
          <td className={css.rightBorder}>
            <LoadingPlaceholder />
          </td>
          <td>
            <LoadingPlaceholder />
          </td>
          <td>
            <LoadingPlaceholder />
          </td>
          <td>
            <LoadingPlaceholder />
          </td>
          <td className={css.spacer} />
        </tr>
      </tbody>
    ))}
  </>
);
