import { useQuery } from 'react-apollo';
import gql from 'graphql-tag';
import {
  PersonaSettingQuery,
  PersonaSettingQueryVariables,
} from './@types/PersonaSettingQuery';

const PERSONA_SETTING_QUERY = gql`
  query PersonaSettingQuery($botId: String!) {
    me {
      id
      usePersona(botId: $botId)
    }
  }
`;

export const usePersonaSetting = (botId: string) => {
  const { data, loading, error } = useQuery<
    PersonaSettingQuery,
    PersonaSettingQueryVariables
  >(PERSONA_SETTING_QUERY, { variables: { botId } });

  return {
    usePersona: data?.me.usePersona,
    adminId: data?.me.id,
    loading,
    error,
  };
};
