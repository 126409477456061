import React from 'react';
import { Icon } from '@ui/Icon';
import { Tooltip2 } from '@ui/Tooltip2';
import { PlatformHeader, PlatformHeaderProps } from './PlatformHeader';

export interface PlatformNotConnectedHeaderProps extends PlatformHeaderProps {
  tooltipContent?: React.ReactNode;
}

export const PlatformNotConnectedHeader: React.FC<PlatformNotConnectedHeaderProps> =
  ({ tooltipContent, ...props }) => {
    return (
      <PlatformHeader {...props}>
        {tooltipContent && (
          <Tooltip2
            type="small"
            placement="right"
            content={tooltipContent}
            hoverable
          >
            {(ref, bind) => (
              <div ref={ref} {...bind}>
                <Icon icon="help" size="24px" color="black" />
              </div>
            )}
          </Tooltip2>
        )}
      </PlatformHeader>
    );
  };
