import React, { useEffect } from 'react';
import { Flex } from '../../../../modern-ui/Flex';
import bagIcon from './images/shopping-bags.png';
import { ReactComponent as GrowIcon } from './images/grow.svg';
import { ReactComponent as PointerIcon } from './images/pointer.svg';
import * as css from './AbandonedCartBanner.css';
import { sendEventOnceByCategoryAndAction } from '../../../../utils/Analytics';
import { BotsListBannerProps } from '../../../BotListPage/components/BotsListBannerBox';
import { BotsListBanner } from '../../../BotListPage/components/BotsListBanner';
import { Type } from '../../../../modern-ui/Type';
import { Color } from '../../../../modern-ui/_common/colors';

export const AbandonedCartBanner: React.FC<BotsListBannerProps> = ({
  onRequestClose,
}) => {
  useEffect(() => {
    sendEventOnceByCategoryAndAction({
      category: 'abandoned cart',
      action: 'banner show',
    });
  }, []);

  return (
    <BotsListBanner
      color={Color.blue}
      title={window.i18next.t(
        'AbandonedCartBanner-string--135-boost-sales-for-your-ecommerce-store',
      )}
      subtitle={
        <>
          {window.i18next.t(
            'AbandonedCartBanner-JSXText-8949-send-abandoned-cart-reminders-in-messenger-to-users',
          )}
          <br />
          {window.i18next.t(
            'AbandonedCartBanner-JSXText--755-with-unfinished-orders',
          )}
        </>
      }
      icon={<img src={bagIcon} alt="" width={70} height={70} />}
      actionBlock={
        <>
          <Flex>
            <Type color="white" size="15px_DEPRECATED">
              {window.i18next.t(
                'AbandonedCartBanner-JSXText--164-find-new-features',
              )}
              <br />
              {window.i18next.t(
                'AbandonedCartBanner-JSXText-1639-in-the-grow-tab',
              )}
            </Type>
            <div className={css.growLink}>
              <GrowIcon />
              <div>
                {window.i18next.t('AbandonedCartBanner-JSXText-2228-grow')}
              </div>
              <PointerIcon className={css.growLinkPointer} />
            </div>
          </Flex>
        </>
      }
      onRequestClose={onRequestClose}
    />
  );
};
