import React, { useEffect, useState } from 'react';
import gql from 'graphql-tag';
import { useLazyQuery } from '@apollo/react-hooks';
import { propOr } from 'ramda';
import { useAutomateEnabled } from '@utils/Data/Admin/Automate';
import * as css from './ClickToMessengerAdsSettingsPageStep2.css';
import { Spacer } from '../../../../../modern-ui/Spacer';
import { CenteredLoader } from '../../../../../modern-ui/Loader';
import { Type } from '../../../../../modern-ui/Type';
import {
  filterItemsToShowWithoutCurrentItem,
  SimpleCombobox,
} from '@ui/SimpleCombobox';
import { Input } from '../../../../../modern-ui/Input';

import {
  FacebookAdsByCampaignQuery,
  FacebookAdsByCampaignQuery_bot_facebookAdCampaign_ads as Ad,
  FacebookAdsByCampaignQueryVariables,
} from './@types/FacebookAdsByCampaignQuery';
import { Autofocus } from '../../../../../modern-ui/Autofocus';
import {
  FacebookAdQuery,
  FacebookAdQueryVariables,
} from './@types/FacebookAdQuery';
import { FACEBOOK_AD_FRAGMENT } from '../../hooks/useFacebookAdCampaignsListQuery';
import {
  AdCampaignType,
  FacebookAdCustomerActionType,
} from '../../../../../../@types/globalTypes';
import { useFacebookAdCampaignsBasicListQuery } from '../../hooks/useFacebookAdCampaignsBasicListQuery';
import { FacebookAdCampaignsBasicQuery_bot_facebookAdCampaigns as Campaign } from '../../hooks/@types/FacebookAdCampaignsBasicQuery';
import { sendEvent } from '../../../../../utils/Analytics';
import { Flex } from '../../../../../modern-ui/Flex';
import { ReactComponent as InfoIcon } from '../../../../../modern-ui/_deprecated/Icon/icons/error-info.svg';
import { getFacebookAdCampaignsListAdNote } from '../../components/FacebookAdCampaignsListNote';
import { useConnectedFacebookAdAccount } from '../../../../../utils/MarketingApi';

interface ClickToMessengerAdsSettingsPageStep2Props {
  botId: string;
  initialAdId: string | undefined;
  campaignType: AdCampaignType;
  onAdSelect: (ad: Ad | null) => void;
  onCampaignsListEmpty: () => void;
}

const itemToString = propOr('', 'name');

const FACEBOOK_ADS_IN_CAMPAIGN_QUERY = gql`
  query FacebookAdsByCampaignQuery($campaignId: String!, $botId: String!) {
    bot(id: $botId) {
      id
      facebookAdCampaign(id: $campaignId) {
        id
        ads {
          ...facebookAdFragment
        }
      }
    }
  }
  ${FACEBOOK_AD_FRAGMENT}
`;

const FACEBOOK_AD_QUERY = gql`
  query FacebookAdQuery($adId: String!, $botId: String!) {
    bot(id: $botId) {
      id
      facebookAdDetailed(id: $adId) {
        ...facebookAdFragment
      }
    }
  }
  ${FACEBOOK_AD_FRAGMENT}
`;

export const ClickToMessengerAdsSettingsPageStep2: React.FC<ClickToMessengerAdsSettingsPageStep2Props> =
  ({ botId, initialAdId, campaignType, onAdSelect, onCampaignsListEmpty }) => {
    const { connectedFacebookAdAccount } = useConnectedFacebookAdAccount(botId);
    const { data, loading: loadingCampaigns } =
      useFacebookAdCampaignsBasicListQuery({
        variables: {
          botId,
          accountId: connectedFacebookAdAccount?.id || '',
        },
        fetchPolicy: 'network-only',
        skip: !connectedFacebookAdAccount,
      });
    const [currentCampaign, setCurrentCampaign] = useState<Campaign | null>(
      null,
    );
    const [currentAd, setCurrentAd] = useState<Ad | null>(null);
    const [
      queryAds,
      { data: adsData, loading: loadingAdsQuery, called: calledAdsQuery },
    ] = useLazyQuery<
      FacebookAdsByCampaignQuery,
      FacebookAdsByCampaignQueryVariables
    >(FACEBOOK_ADS_IN_CAMPAIGN_QUERY, { fetchPolicy: 'cache-and-network' });

    const [queryInitialAd, { data: adInitialData, loading: loadingInitialAd }] =
      useLazyQuery<FacebookAdQuery, FacebookAdQueryVariables>(
        FACEBOOK_AD_QUERY,
      );

    const facebookAdCampaigns = data?.bot?.facebookAdCampaigns || null;
    const ads = adsData?.bot.facebookAdCampaign?.ads || [];
    const adInitial = adInitialData?.bot?.facebookAdDetailed;

    const { isAutomateEnabled } = useAutomateEnabled();

    /**
     *  set state after campaigns loading
     */
    useEffect(() => {
      if (loadingCampaigns) {
        return;
      }

      if (facebookAdCampaigns?.length === 0) {
        onCampaignsListEmpty();
      }

      if (facebookAdCampaigns?.length === 1) {
        setCurrentCampaign(facebookAdCampaigns?.[0]);
      }
    }, [loadingCampaigns, facebookAdCampaigns, onCampaignsListEmpty]);

    /**
     *  query ads after campaign select
     */
    useEffect(() => {
      if (currentCampaign) {
        queryAds({
          variables: {
            botId,
            campaignId: currentCampaign.id,
          },
        });
      }
    }, [queryAds, currentCampaign, botId]);

    /**
     *  set state after ads loading
     */
    useEffect(() => {
      if (!loadingAdsQuery && ads?.length === 1) {
        setCurrentAd(ads?.[0]);
      }
    }, [loadingAdsQuery, ads]);

    /**
     *  query ad after select
     */
    useEffect(() => {
      if (initialAdId && botId) {
        queryInitialAd({ variables: { botId, adId: initialAdId } });
      }
    }, [initialAdId, queryInitialAd, botId]);

    /**
     *  select ad on initial ad
     */
    useEffect(() => {
      if (adInitial && facebookAdCampaigns) {
        setCurrentAd(adInitial);
        setCurrentCampaign(
          facebookAdCampaigns.find(({ id }) => id === adInitial.campaign_id) ||
            null,
        );
      }
    }, [adInitial, facebookAdCampaigns]);

    /**
     *  fire select ad event
     */
    useEffect(() => {
      onAdSelect(currentAd);
    }, [currentAd, onAdSelect]);

    if (
      initialAdId &&
      (loadingInitialAd ||
        loadingCampaigns ||
        loadingAdsQuery ||
        !calledAdsQuery)
    ) {
      return <CenteredLoader style={{ height: 156, width: 476 }} />;
    }

    const alowedAds = ads.filter((ad) =>
      [
        FacebookAdCustomerActionType.buttons,
        FacebookAdCustomerActionType.quick_replies,
        FacebookAdCustomerActionType.ice_breakers,
      ].includes(ad.customer_action_type),
    );

    const note =
      currentAd &&
      getFacebookAdCampaignsListAdNote(
        currentAd,
        botId,
        true,
        isAutomateEnabled,
      );

    return (
      <div className={css.box}>
        <Spacer factor={4} />
        <Type size="15px_DEPRECATED">
          {window.i18next.t(
            'ClickToMessengerAdsSettingsPageStep2-JSXText--204-select-click-to-messenger-or-sponsored-messages-campaign',
          )}
        </Type>
        <Spacer factor={1} />
        {loadingCampaigns ? (
          <CenteredLoader style={{ height: 36 }} />
        ) : (
          <>
            <SimpleCombobox
              itemToString={itemToString}
              filter={filterItemsToShowWithoutCurrentItem}
              selectedItem={currentCampaign as any}
              onSelect={(item) => {
                const campaign = item as unknown as Campaign;
                setCurrentCampaign(campaign);
                setCurrentAd(null);
                onAdSelect(null);
                if (campaign) {
                  sendEvent({
                    category: 'ads manager',
                    action: 'choose campaign',
                    label:
                      campaignType === AdCampaignType.click_to_messenger
                        ? 'click-to-messenger campaign'
                        : 'sponsored messages campaign',
                    propertyBag: {
                      campaignId: campaign.id,
                      campaignName: campaign.name,
                    },
                  });
                }
              }}
              renderInput={({ getInputProps, openMenu, clearSelection }) => (
                <Autofocus
                  shouldFocus={
                    (facebookAdCampaigns?.length || 0) > 1 && !initialAdId
                  }
                  render={({ bind }) => (
                    <Input
                      {...getInputProps({
                        placeholder: window.i18next.t(
                          'ClickToMessengerAdsSettingsPageStep2-string--884-choose-campaign',
                        ),
                        onFocus: openMenu,
                        onChange: () => {
                          clearSelection();
                        },
                        ...bind,
                      })}
                    />
                  )}
                />
              )}
              items={(data?.bot.facebookAdCampaigns as any[]) || []}
            />
            <Spacer factor={4} />
            {loadingAdsQuery ? (
              <CenteredLoader style={{ height: 62 }} />
            ) : (
              <>
                <Type size="15px_DEPRECATED">
                  {window.i18next.t(
                    'ClickToMessengerAdsSettingsPageStep2-JSXText-2879-select-ad',
                  )}
                </Type>
                <Spacer factor={1} />
                <Flex alignItems="center">
                  <div className={css.box}>
                    <SimpleCombobox
                      filter={filterItemsToShowWithoutCurrentItem}
                      itemToString={itemToString}
                      selectedItem={currentAd as any}
                      onSelect={(item) => {
                        const ad = item as unknown as Ad;
                        setCurrentAd(ad);
                        onAdSelect(null);
                        if (ad) {
                          sendEvent({
                            category: 'ads manager',
                            action: 'choose ad',
                            label: campaignType,
                            propertyBag: {
                              adId: ad.id,
                              adName: ad.name,
                            },
                          });
                        }
                      }}
                      renderInput={({
                        getInputProps,
                        openMenu,
                        clearSelection,
                      }) => {
                        const moreOneAlowedAds = (alowedAds?.length || 0) > 1;
                        return (
                          <Autofocus
                            shouldFocus={moreOneAlowedAds && !initialAdId}
                            render={({ bind }) => (
                              <Input
                                containerClassName={css.input}
                                {...getInputProps({
                                  placeholder: window.i18next.t(
                                    'ClickToMessengerAdsSettingsPageStep2-string--125-choose-ad',
                                  ),
                                  onFocus: openMenu,
                                  onChange: () => {
                                    clearSelection();
                                  },
                                  disabled: !moreOneAlowedAds,
                                  ...bind,
                                })}
                              />
                            )}
                          />
                        );
                      }}
                      items={alowedAds as any}
                    />
                  </div>
                  {note && (
                    <div className={css.tabNote}>
                      <InfoIcon className={css.infoIcon} />
                      <Type color={note.color} size="15px_DEPRECATED">
                        {note.component || note.text}
                      </Type>
                    </div>
                  )}
                </Flex>
              </>
            )}
          </>
        )}
      </div>
    );
  };
