import React, { useCallback, useEffect } from 'react';

/**
 * This hook is responsible for
 *
 *  * Making the list scrolled all the way down on initial render
 *    (because newest items are at the bottom)
 *
 *  * Scrolling to the end when itemCount increases
 *    (this means that a new item has been added),
 *    but only when you haven't scrolled too far up.
 */

const EDGE_PROXIMITY_THRESHOLD = 100; // px

export const useReverseScrollView = (loading: boolean) => {
  const scrollRef = React.useRef<HTMLDivElement | null>(null);
  const containerRef = React.useRef<HTMLDivElement | null>(null);
  const bottomScrollOffset = React.useRef(0);

  const onResize = useCallback(() => {
    if (!scrollRef.current) {
      return;
    }

    const bottomEdgeOffset =
      EDGE_PROXIMITY_THRESHOLD + scrollRef.current.clientHeight;

    if (
      scrollRef.current?.scrollTop < EDGE_PROXIMITY_THRESHOLD ||
      bottomScrollOffset.current < bottomEdgeOffset
    ) {
      scrollRef.current.scrollTop =
        scrollRef.current.scrollHeight - bottomScrollOffset.current;
    }
  }, []);

  const onScroll = useCallback(() => {
    if (!scrollRef.current) {
      return;
    }

    const bottomEdgeOffset =
      EDGE_PROXIMITY_THRESHOLD + scrollRef.current.clientHeight;
    const currentBottomScrollOffset =
      scrollRef.current.scrollHeight - scrollRef.current.scrollTop;

    if (currentBottomScrollOffset > bottomEdgeOffset) {
      bottomScrollOffset.current = currentBottomScrollOffset;
    } else {
      bottomScrollOffset.current = 0;
    }
  }, []);

  const resizeObserver = React.useRef(new window.ResizeObserver(onResize));

  useEffect(() => {
    const resizeObserverCopy = resizeObserver.current;
    const containerRefCopy = containerRef.current;
    const scrollRefCopy = scrollRef.current;

    if (scrollRefCopy) {
      resizeObserverCopy.observe(containerRefCopy);
      scrollRefCopy.addEventListener('scroll', onScroll);
    }

    return () => {
      if (scrollRefCopy) {
        resizeObserverCopy.unobserve(containerRefCopy);
        scrollRefCopy.removeEventListener('scroll', onScroll);
      }
    };
  }, [onScroll]);

  useEffect(() => {
    if (loading) {
      return;
    }
    bottomScrollOffset.current = 0;
    if (!scrollRef.current) {
      return;
    }
    scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
  }, [loading]);

  return { scrollRef, containerRef };
};
