import { Spacer } from '@ui/Spacer';
import { useDeviceMedia } from '@utils/DOM/useDeviceMedia';
import React from 'react';
import {
  PlatformNotConnectedBody,
  PlatformNotConnectedBodyProps,
} from './PlatformNotConnectedBody';
import {
  PlatformNotConnectedHeader,
  PlatformNotConnectedHeaderProps,
} from './PlatformNotConnectedHeader';

interface PlatformNotConnectedProps
  extends PlatformNotConnectedHeaderProps,
    PlatformNotConnectedBodyProps {}

export const PlatformSeparator = () => {
  const { isSmallScreenSize } = useDeviceMedia();

  return <Spacer factor={isSmallScreenSize ? 4 : 6} />;
};

export const PlatformNotConnected: React.FC<PlatformNotConnectedProps> = (
  props,
) => {
  return (
    <>
      <PlatformNotConnectedHeader {...props} />
      <PlatformSeparator />
      <PlatformNotConnectedBody {...props} />
    </>
  );
};
