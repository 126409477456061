import React from 'react';
import { TutorialLinks } from '../../../modern-ui/Links';
import BookEmoji from './images/book.png';
import { sendEvent } from '../../../utils/Analytics';

export const AbandonedCartManualLink: React.FC = () => {
  return (
    <TutorialLinks
      variant="noaccent"
      renderIcon={() => (
        <img
          src={BookEmoji}
          alt="book-emoji"
          width="18"
          height="20"
          style={{ marginRight: '4px' }}
        />
      )}
      linkItems={[
        {
          text: window.i18next.t(
            'AbandonedCartManualLink-string-8126-read-manual',
          ),
          target: '_blank',
          href: 'https://chatfuel.com/files/Cart_Reminders_via_Messenger.pdf',
          onClick: () => {
            sendEvent({
              category: 'cart reminders',
              action: 'read manual',
            });
          },
        },
      ]}
    />
  );
};
