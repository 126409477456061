import { Spacer } from '@ui/Spacer';
import { Type } from '@ui/Type';
import React from 'react';

export const TimeTitle: React.FC = ({ children }) => (
  <>
    <div style={{ textAlign: 'center' }}>
      <Type color="grey" size="12px">
        {children}
      </Type>
    </div>
    <Spacer factor={2} />
  </>
);
