import gql from 'graphql-tag';
import { LIVECHAT_CONVERSATION_NOTE_FRAGMENT } from './queries';

export const LIVECHAT_NOTE_CREATED = gql`
  subscription LivechatNoteCreated($botId: String!, $conversationId: String!) {
    livechatNoteCreated(botId: $botId, conversationId: $conversationId) {
      ...livechatConversationNoteFragment
      createdAt
    }
  }
  ${LIVECHAT_CONVERSATION_NOTE_FRAGMENT}
`;

export const LIVECHAT_NOTE_UPDATED = gql`
  subscription LivechatNoteUpdated($botId: String!, $conversationId: String!) {
    livechatNoteUpdated(botId: $botId, conversationId: $conversationId) {
      ...livechatConversationNoteFragment
      createdAt
    }
  }
  ${LIVECHAT_CONVERSATION_NOTE_FRAGMENT}
`;

export const LIVECHAT_NOTE_DELETED = gql`
  subscription LivechatNoteDeleted($botId: String!, $conversationId: String!) {
    livechatNoteDeleted(botId: $botId, conversationId: $conversationId) {
      id
    }
  }
`;
