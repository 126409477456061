import { WhatsappStatusEnum } from '@globals';
import { ColorKey } from '@ui/_common/colors';

export enum DeliveredStatusThemeType {
  messagesList,
  dialogsList,
}

export type DeliveredStatusTheme = Record<WhatsappStatusEnum, ColorKey>;

export const MESSAGES_LIST_THEME: DeliveredStatusTheme = {
  [WhatsappStatusEnum.pending]: 'accent1Dark',
  [WhatsappStatusEnum.failed]: 'accent4Semilight',
  [WhatsappStatusEnum.sent]: 'accent1Dark',
  [WhatsappStatusEnum.delivered]: 'accent1Dark',
  [WhatsappStatusEnum.read]: 'accent1Light',
};

export const DIALOGS_LIST_THEME: DeliveredStatusTheme = {
  [WhatsappStatusEnum.pending]: 'baseSemilight',
  [WhatsappStatusEnum.failed]: 'red',
  [WhatsappStatusEnum.sent]: 'baseSemilight',
  [WhatsappStatusEnum.delivered]: 'baseSemilight',
  [WhatsappStatusEnum.read]: 'accent1Dark',
};

export const getColorTheme = (theme: DeliveredStatusThemeType) => {
  return {
    [DeliveredStatusThemeType.dialogsList]: DIALOGS_LIST_THEME,
    [DeliveredStatusThemeType.messagesList]: MESSAGES_LIST_THEME,
  }[theme];
};
