import { useQuery } from '@apollo/react-hooks';
import { Flex } from '@ui/Flex';
import { LoadingPlaceholder } from '@ui/LoadingPlaceholder';
import { Spacer } from '@ui/Spacer';
import { Type } from '@ui/Type';
import { useCurrentBotId } from '@utils/Routing';
import gql from 'graphql-tag';
import Maybe from 'graphql/tsutils/Maybe';
import React, { useState } from 'react';
import { TextMessage } from '../TextMessage';
import { MessagePosition, MessageSourceType } from '../types';
import {
  InstagramStoryQuery,
  InstagramStoryQueryVariables,
} from './@types/InstagramStoryQuery';
import * as css from './StoryMessage.css';
import {
  MessageFooter,
  MessageFooterProps,
} from '../../MessageTimestamp/MessageTimestamp';

interface Message {
  text: Maybe<string>;
  attachment: Maybe<{ type: string; attachment_id: Maybe<string> }>;
}

interface StoryMessageProps extends MessageFooterProps {
  sourceType: MessageSourceType;
  position: MessagePosition;
  message: Message;
  messageId: string;
  positionClassName: string;
}

const INSTAGRAM_STORY_QUERY = gql`
  query InstagramStoryQuery(
    $botId: String!
    $mediaId: String!
    $storyType: String
  ) {
    instagramStory(botId: $botId, mediaId: $mediaId, storyType: $storyType) {
      media_url
    }
  }
`;

export const StoryMessage: React.FC<StoryMessageProps> = ({
  sourceType,
  position,
  message,
  messageId,
  date,
  type,
  status,
  error,
  positionClassName,
}) => {
  const botId = useCurrentBotId();
  const { data, loading } = useQuery<
    InstagramStoryQuery,
    InstagramStoryQueryVariables
  >(INSTAGRAM_STORY_QUERY, {
    variables: {
      botId: botId || '',
      mediaId:
        message.attachment?.type === 'story_reply'
          ? message.attachment?.attachment_id ?? ''
          : messageId,
      storyType: message.attachment?.type,
    },
    skip: !botId || !messageId,
    fetchPolicy: 'network-only',
  });

  const [isVideoStory, setIsVideoStory] = useState(false);

  return (
    <Flex>
      <div className={css.greyLine} />
      <Spacer horizontalFactor={2} />
      <Flex flexDirection="column" alignItems="flex-start">
        {message.attachment?.type === 'story_reply' && (
          <Type
            as="div"
            size="12px"
            data-testid="livechat__story-reply-message"
          >
            {window.i18next.t(
              'StoryMessage-JSXText-9717-replied-to-your-story',
            )}
          </Type>
        )}
        {message.attachment?.type === 'story_mention' && (
          <Type
            as="div"
            size="12px"
            data-testid="livechat__story-mention-message"
          >
            {window.i18next.t(
              'StoryMessage-JSXText-1241-mentioned-you-in-their-story',
            )}
          </Type>
        )}
        {loading && <LoadingPlaceholder width={140} height={250} />}
        {!loading &&
          (data?.instagramStory?.media_url ? (
            <MessageFooter
              error={error}
              date={date}
              type={type}
              status={status}
              asOverlay
            >
              {isVideoStory ? (
                /* eslint-disable-next-line jsx-a11y/media-has-caption */
                <video
                  src={data.instagramStory.media_url}
                  className={css.storyImg}
                />
              ) : (
                <img
                  src={data.instagramStory.media_url}
                  alt="story content"
                  className={css.storyImg}
                  // video file will trigger onError
                  // and we'll change tag to show the content
                  onError={() => setIsVideoStory(true)}
                />
              )}
            </MessageFooter>
          ) : (
            <>
              <Type as="div" size="12px" color="grey">
                {window.i18next.t(
                  'StoryMessage-JSXText-6878-story-not-available',
                )}
              </Type>
              <Spacer factor={2} />
            </>
          ))}
        {message.text && (
          <Flex flexDirection="column">
            <Spacer factor={2} />
            <TextMessage
              error={error}
              status={status}
              date={date}
              type={type}
              position={position}
              text={message.text}
              sourceType={sourceType}
              positionClassName={positionClassName}
            />
          </Flex>
        )}
      </Flex>
    </Flex>
  );
};
