import React, { useCallback, useEffect, useRef } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useAdminFeatures } from '@utils/Data/Admin';
import { useAutomateEnabled } from '@utils/Data/Admin/Automate';
import { sendEvent } from '@utils/Analytics';
import * as css from './ClickToMessengerSetup.css';
import { SectionBox } from '../../../../modern-components/SectionBox/SectionBox';
import { Type } from '../../../../modern-ui/Type';
import { FacebookAdAccountSelectableTable } from '../components';
import { GrowItemTopBanner } from '../../common/GrowItem/GrowItemTopBanner';
import phoneIconUrl from '../images/mobile-phone-with-rightwards-arrow-at-left.png';
import { ServerStorageItemKeys } from '../../../../utils/ServerStorage';
import { useCurrentBotId } from '../../../../utils/Routing';
import { RoleConsumer, RoleProvider } from '../../../../utils/Roles';
import { Permission } from '../../../../common/services/RoleService';

export const ClickToMessengerSetup: React.FC = () => {
  const botId = useCurrentBotId() || '';
  const history = useHistory();
  const { state: locationState } = useLocation();
  const { adminFeatures } = useAdminFeatures();
  const showAdsManager = adminFeatures?.show_ads_manager;
  const { isAutomateEnabled } = useAutomateEnabled();
  const goToGrow = useCallback(() => {
    history.push(`/bot/${botId}/grow`);
    sendEvent({
      category: 'ads manager',
      action: 'back to growth tools',
    });
  }, [botId, history]);

  const ref = useRef<HTMLDivElement | null>(null);

  const scrollToAdsManager = locationState?.scrollToAdsManager;

  useEffect(() => {
    if (scrollToAdsManager && ref.current) {
      ref.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }, [scrollToAdsManager]);

  if (!showAdsManager) {
    return null;
  }

  return (
    <RoleProvider botId={botId}>
      <RoleConsumer domain="grow" can={Permission.VIEW}>
        {({ allowed: allowedView, loading: rolesViewLoading }) => {
          if (!allowedView || rolesViewLoading) {
            return null;
          }
          return (
            <RoleConsumer domain="grow" can={Permission.EDIT}>
              {({ allowed: allowedEdit, loading: rolesEditLoading }) => {
                return (
                  <SectionBox
                    className={css.box}
                    disabled={rolesEditLoading || !allowedEdit}
                    ref={(el) => {
                      ref.current = el;
                    }}
                  >
                    <GrowItemTopBanner
                      serverStorageKey={
                        ServerStorageItemKeys.isHideCTMSetupHeadBanner
                      }
                      icon={
                        <img src={phoneIconUrl} alt="" width={20} height={20} />
                      }
                    >
                      {window.i18next.t(
                        'ClickToMessengerSetup-JSXText--201-here-you-can-see-ad-stats-and',
                      )}{' '}
                      {isAutomateEnabled
                        ? window.i18next.t(
                            'ClickToMessengerSetup-string-9383-block',
                          )
                        : window.i18next.t(
                            'ClickToMessengerSetup-string-3146-flow',
                          )}
                      {window.i18next.t(
                        'ClickToMessengerSetup-JSXText--824-stats-for-your-campaigns',
                      )}
                    </GrowItemTopBanner>
                    <div className={css.facebookMessengerHeader}>
                      <Type size="24px" weight="medium">
                        {window.i18next.t(
                          'ClickToMessengerSetup-JSXText-2018-facebook-messenger-ads',
                        )}
                      </Type>
                    </div>
                    <FacebookAdAccountSelectableTable
                      wrapperStyles={{ margin: '0 -24px' }}
                      botId={botId}
                      limit={5}
                      goToGrow={goToGrow}
                    />
                  </SectionBox>
                );
              }}
            </RoleConsumer>
          );
        }}
      </RoleConsumer>
    </RoleProvider>
  );
};
