import { useMutation } from '@apollo/react-hooks';
import { clone } from 'ramda';
import { useToaster } from '@ui/Toaster';
import { UserAttribute } from '@components/UiUserAttributeEditor/UserAttributeTypes';
import { refetchCommonAttributesQueryObservables } from '@utils/AttributesUtils/AttributesUtils';
import { Platform, UserAttributeType } from '@globals';
import {
  ConversationQuery,
  ConversationQuery_livechatConversation_subscriber,
  ConversationQueryVariables,
} from '../../../hooks/@types/ConversationQuery';
import { CONVERSATION_QUERY } from '../../../hooks/useLiveChatConversation';
import { getAttributesDiff } from '../../../../../components/users/getAttributesDiff';
import { UPDATE_USER_ATTRIBUTES } from '../queries';
import {
  UpdateUserAttributes,
  UpdateUserAttributesVariables,
} from '../@types/UpdateUserAttributes';
import { CONVERSATION_FRAGMENT } from '../../../common/queries';
import { log } from 'cf-common/src/logger';

const subscriberIdToConversationId = (id: string, platform: Platform) => {
  return {
    [Platform.facebook]: id,
    [Platform.instagram]: `ig_${id}`,
    // TODO-whatsapp
    [Platform.whatsapp]: '',
  }[platform];
};

interface AttributesOptimisticSaverProps {
  botId: string;
  subscriber?: ConversationQuery_livechatConversation_subscriber;
  platform: Platform;
  savedAttributes: UserAttribute[];
  setSavedAttributes(nextAttributes: UserAttribute[]): void;
}

export const useAttributesOptimisticSaver = ({
  botId,
  subscriber,
  platform,
  savedAttributes,
  setSavedAttributes,
}: AttributesOptimisticSaverProps) => {
  const { addToaster } = useToaster();

  const [updateAttributes] = useMutation<
    UpdateUserAttributes,
    UpdateUserAttributesVariables
  >(UPDATE_USER_ATTRIBUTES, {
    awaitRefetchQueries: true,
    onCompleted: () => refetchCommonAttributesQueryObservables(botId),
  });

  return (newAttributes: UserAttribute[]) => {
    if (!subscriber?.chatfuelUserId) {
      return;
    }
    const payload = getAttributesDiff(newAttributes, savedAttributes);
    setSavedAttributes(newAttributes);
    updateAttributes({
      variables: {
        botId,
        userId: subscriber.chatfuelUserId,
        update: payload,
      },
      update(cache, { data, errors }) {
        if (errors) {
          return;
        }
        const conversationId = subscriberIdToConversationId(
          subscriber.id,
          platform,
        );
        let result;

        try {
          result = cache.readQuery<
            ConversationQuery,
            ConversationQueryVariables
          >({
            query: CONVERSATION_QUERY,
            variables: { botId, conversationId, platform },
          });
        } catch (error) {
          log.error({
            msg: 'updateAttributes read conversation query failed',
            data: { error },
          });
        }

        if (result?.livechatConversation) {
          const newData = result.livechatConversation;
          data?.updateUserAttributes?.update?.forEach((attr) => {
            if (attr?.value) {
              const id = `${subscriber.id}_${attr.name}_custom`;
              const existedAttribute = subscriber.attributes.find(
                (v) => v.id === id,
              );
              if (existedAttribute) {
                existedAttribute.values = [attr.value];
              } else {
                subscriber.attributes.push({
                  __typename: 'UserAttribute',
                  id,
                  name: attr.name!,
                  type: UserAttributeType.custom,
                  values: [attr.value],
                });
              }
            }
          });
          if (data?.updateUserAttributes?.delete?.length) {
            newData.subscriber.attributes =
              newData.subscriber.attributes.filter(
                (attr) =>
                  !data.updateUserAttributes?.delete?.some(
                    (deletedAttrName) =>
                      attr.id === `${subscriber.id}_${deletedAttrName}_custom`,
                  ),
              );
          }

          try {
            const data = clone(result.livechatConversation);
            cache.writeFragment({
              id: `LivechatConversationV3:${result.livechatConversation.id}`,
              fragment: CONVERSATION_FRAGMENT,
              fragmentName: 'ConversationFragment',
              data,
            });
          } catch (error) {
            log.error({
              msg: 'updateAttributes write conversation fragment failed',
              data: { error },
            });
          }
        }
      },
    }).catch((e) => {
      addToaster({
        type: 'error',
        content: `${window.i18next.t(
          'useAttributesOptimisticSaver-Template--852-attributes',
        )}${e.message}`,
        timeout: 5000,

        closeButton: true,
      });
    });
  };
};
