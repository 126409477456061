import React from 'react';
import { Query, Mutation } from '@apollo/react-components';
import gql from 'graphql-tag';
import Downshift from 'downshift';
import { path, propOr, find, propEq, pipe } from 'ramda';
import cn from 'classnames';
import { ReactComponent as DropdownIcon } from '@ui/_deprecated/Icon/icons/ic_dropdown_arr.svg';
import { Button, ButtonIntent, ButtonSize } from '@ui/_deprecated/Button';
import ScrollBox from '@ui/ScrollBox/ScrollBox';
import { Divider } from '@ui/Menu/Divider';
import { Menubox, MenuItem } from '@ui/Menu';
import { ReactComponent as BirdIcon } from '@ui/_deprecated/Icon/icons/bird.svg';
import { Avatar } from '../Avatar';
import client from '../../../../common/services/ApolloService';
import {
  PersonaToggleDataQuery,
  PersonaToggleDataQueryVariables,
} from './@types/PersonaToggleDataQuery';
import * as css from './PersonaApiToggle.css';
import {
  usePersonaToggleMutation,
  usePersonaToggleMutationVariables,
} from './@types/usePersonaToggleMutation';
import { sizes } from '../../common/sizes';
import { HEXColors } from '@ui/_common/colors';

interface IOneBlockSelectorProps {
  botId: string;
  disabled?: boolean;
  isSmallScreenSize?: boolean;
}

type MenuItemType = {
  index: number;
  title: string;
  picture: string;
  isPersona: boolean;
};

const PERSONA_TOGGLE_DATA_QUERY = gql`
  query PersonaToggleDataQuery($botId: String!) {
    bot(id: $botId) {
      id
      title
      status {
        page_info {
          id
          picture
        }
      }
    }
    me {
      id
      name
      picture
      usePersona(botId: $botId)
    }
  }
`;

const USE_PERSONA_TOGGLE_MUTATION = gql`
  mutation usePersonaToggleMutation($botId: String!, $usePersona: Boolean!) {
    usePersonaToggle(botId: $botId, usePersona: $usePersona) {
      id
      usePersona(botId: $botId)
    }
  }
`;

const getSelectedItem = (
  data: PersonaToggleDataQuery,
  menuItems: MenuItemType[],
) =>
  pipe(path(['me', 'usePersona']), propEq('isPersona') as any, find)(data)(
    menuItems,
  ) as MenuItemType;

export const PersonaApiToggle: React.FC<IOneBlockSelectorProps> = React.memo(
  ({ botId, disabled, isSmallScreenSize }) => (
    <Query<PersonaToggleDataQuery, PersonaToggleDataQueryVariables>
      query={PERSONA_TOGGLE_DATA_QUERY}
      variables={{ botId }}
      client={client}
    >
      {({ data }) => {
        const menuItems: MenuItemType[] = [
          {
            index: 0,
            title: path(['bot', 'title'], data) as string,
            picture: path(
              ['bot', 'status', 'page_info', 'picture'],
              data,
            ) as string,
            isPersona: false,
          },
          {
            index: 1,
            title: path(['me', 'name'], data) as string,
            picture: path(['me', 'picture'], data) as string,
            isPersona: true,
          },
        ];
        const selectedItem = data && getSelectedItem(data, menuItems);

        return data ? (
          <Mutation<usePersonaToggleMutation, usePersonaToggleMutationVariables>
            mutation={USE_PERSONA_TOGGLE_MUTATION}
            client={client}
          >
            {(usePersonaToggleMutation) => (
              <Downshift
                initialSelectedItem={selectedItem}
                itemToString={propOr(undefined, 'title') as any}
                onChange={(item) =>
                  usePersonaToggleMutation({
                    variables: {
                      botId,
                      usePersona: item!.isPersona,
                    },
                    optimisticResponse: {
                      usePersonaToggle: {
                        __typename: 'Admin',
                        id: data.me.id,
                        usePersona: item!.isPersona,
                      },
                    },
                  })
                }
              >
                {({
                  isOpen,
                  getToggleButtonProps,
                  getItemProps,
                  highlightedIndex,
                }) => (
                  <div className={css.box}>
                    <Button
                      {...getToggleButtonProps()}
                      className={css.button}
                      iconClassName={cn(
                        css.buttonAvatar,
                        isSmallScreenSize && css.buttonAvatar_mobile,
                      )}
                      rightIconClassName={css.buttonMenuToggle}
                      intent={ButtonIntent.default}
                      size={ButtonSize.s}
                      disabled={disabled}
                      renderIcon={() => (
                        <Avatar
                          src={selectedItem && selectedItem.picture}
                          size={
                            isSmallScreenSize
                              ? sizes.mobile.avatarSize
                              : sizes.incoming.avatarSize
                          }
                        />
                      )}
                      renderIconRight={() => (
                        <DropdownIcon className={css.arrow} />
                      )}
                    />
                    {isOpen ? (
                      <Menubox className={css.menu}>
                        <ScrollBox>
                          <MenuItem
                            title={window.i18next.t(
                              'PersonaApiToggle-string--144-select-persona-for-your-reply',
                            )}
                            className={css.menuItem}
                            // eslint-disable-next-line no-underscore-dangle
                            style={{ backgroundColor: HEXColors._f4f4f4 }}
                          />
                          {menuItems.map((item) => (
                            <React.Fragment key={item.index}>
                              <Divider className={css.divider} />
                              <MenuItem
                                {...getItemProps({ item })}
                                title={item.title}
                                active={highlightedIndex === item.index}
                                leftElement={
                                  <Avatar src={item.picture} size={28} />
                                }
                                className={css.menuItem}
                                rightElement={
                                  selectedItem === item && (
                                    <BirdIcon className={css.birdIcon} />
                                  )
                                }
                              />
                            </React.Fragment>
                          ))}
                        </ScrollBox>
                      </Menubox>
                    ) : null}
                  </div>
                )}
              </Downshift>
            )}
          </Mutation>
        ) : null;
      }}
    </Query>
  ),
);
