import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { BotPageRouteParams } from '@utils/Routing';
import { Flex } from '../../../../modern-ui/Flex';
import { AbandonedCartPageMessageTab } from './tabs/AbandonedCartPageMessageTab';
import {
  AbandonedCartPageMessagePreview,
  AbandonedCartPageMessagePreviewData,
} from './peviews/AbandonedCartPageMessagePreview';
import { TabsIds } from '../AbandonedCartConst';
import * as css from './AbandonedCartPage.css';
import { Type } from '../../../../modern-ui/Type';
import { Spacer } from '../../../../modern-ui/Spacer';
import { AbandonedCartManualLink } from '../AbandonedCartManualLink';

export const AbandonedCartPage: React.FC = () => {
  const { botId, pageTabId } = useParams<BotPageRouteParams>();

  const [previewData, setPreviewData] = useState<
    AbandonedCartPageMessagePreviewData | undefined
  >();

  if (!botId) {
    return null;
  }

  return (
    <div className={css.page}>
      <div className={css.pageContent}>
        <Type weight="medium" size="24px">
          {window.i18next.t('AbandonedCartPage-JSXText--808-opt-in-settings')}
        </Type>
        <Spacer factor={2} />
        <Type as="div" color="greyDark" size="15px_DEPRECATED">
          {window.i18next.t(
            'AbandonedCartPage-JSXText-7619-learn-how-to-set-up-abandoned-cart-reminders-to-recover-up-to-7-x-more-revenue',
          )}
        </Type>
        <Spacer factor={2} />
        <AbandonedCartManualLink />
        <Spacer factor={6} />
        <Flex className={css.card}>
          <div className={css.leftSide}>
            {pageTabId === TabsIds.message && (
              <AbandonedCartPageMessageTab
                onChangePreviewData={setPreviewData}
              />
            )}
          </div>
          <div className={css.rightSide}>
            {pageTabId === TabsIds.message && (
              <AbandonedCartPageMessagePreview previewData={previewData} />
            )}
          </div>
        </Flex>
      </div>
    </div>
  );
};
