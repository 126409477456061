import React from 'react';
import { Anchor } from '@ui/Links';
import { FontSizeKey, FontWeightKey, Type } from '@ui/Type';

interface PlatformLinkProps {
  href: string;
  children: string;
  size?: FontSizeKey;
  weight?: FontWeightKey;
  onClick?: () => void;
  className?: string;
}

export const PlatformLink: React.FC<PlatformLinkProps> = ({
  href,
  size,
  weight,
  className,
  onClick,
  children,
}) => (
  <Anchor
    hideArrow
    intent="internal"
    target="_blank"
    rel="noopener noreferrer"
    href={href}
    className={className}
    onClick={onClick}
  >
    <Type size={size || '24px'} weight={weight || 'semibold'}>
      {children}
    </Type>
  </Anchor>
);
