import React, { useMemo, useState } from 'react';
import * as css from './LiveChatNotes.css';
import { Flex } from '@ui/Flex';
import { Type } from '@ui/Type';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { IconButton } from '@ui/IconButton';
import { Platform } from '@globals';
import { useLiveChatNotes } from './hooks/useLiveChatNotes';
import { LiveChatNoteItem } from './components/LiveChatNoteItem';
import { Spacer } from '@ui/Spacer';
import { useCurrentBot } from '@utils/Data/Bot';
import { useAdminId } from '@utils/Admin/useAdminId';
import { LSKey, useLocalStorage } from '@utils/LocalStorage';
import { ButtonUnstyled } from '@ui/Button';
import { useRolePermission } from '@utils/Roles';
import { Permission } from '@common/services/RoleService';
import { RightPanelDivider } from '../RightPanelDivider';
import { sendEvent } from '@utils/Analytics';

interface LiveChatNotesProps {
  conversationId: string;
  platform: Platform;
}

export const LiveChatNotes: React.FC<LiveChatNotesProps> = ({
  conversationId,
  platform,
}) => {
  const { t } = useSafeTranslation();
  const [isNotesExpand, setIsNotesExpand] = useLocalStorage(
    LSKey.isLiveChatNotesExpand,
    true,
  );
  const { allowed: editAllowed } = useRolePermission({
    domain: 'inbox',
    can: Permission.EDIT,
  });
  const { id: currentAdminId } = useAdminId();
  const [isShowAll, setIsShowAll] = useState<boolean>(false);
  const {
    loading,
    totalNotesQty,
    needShowAll,
    notes,
    addConversationNote,
    changeConversationNote,
    updateConversationNote,
    deleteConversationNote,
  } = useLiveChatNotes(conversationId, platform, isShowAll);
  const { bot } = useCurrentBot();
  const adminToId = useMemo(
    () =>
      Object.fromEntries(bot?.admins.map(({ id, name }) => [id, name]) || []),
    [bot],
  );

  if (loading || (!editAllowed && totalNotesQty === 0)) {
    return null;
  }

  return (
    <>
      {' '}
      <div className={css.box}>
        <Flex justifyContent="space-between" alignItems="center">
          <Type weight="bold">
            {t('pages.LiveChat.rightPanel.notes.title')}
            {totalNotesQty > 0 ? <> ・ {totalNotesQty}</> : null}
          </Type>
          <Flex gapFactor={2} alignItems="center">
            {editAllowed && (
              <IconButton
                data-testid="livechat_notes_add-button"
                icon="plus"
                color="accent1Normal"
                onMouseDown={(event) => {
                  event.preventDefault();
                  event.stopPropagation();
                }}
                onClick={() => {
                  setIsNotesExpand(true);
                  addConversationNote();
                  sendEvent({
                    category: 'live chat',
                    action: 'add note click',
                    label: 'notes',
                  });
                }}
              />
            )}
            {notes.length > 0 && (
              <>
                <div className={css.divider} />
                <IconButton
                  data-testid="livechat_notes_expand-button"
                  color="accent1Normal"
                  icon={isNotesExpand ? 'chevronUp' : 'chevronDown'}
                  onClick={() => {
                    setIsNotesExpand((prev) => !prev);
                    sendEvent({
                      category: 'live chat',
                      action: 'expand click',
                      label: 'notes',
                      propertyBag: {
                        isNotesExpand,
                      },
                    });
                  }}
                />
              </>
            )}
          </Flex>
        </Flex>
        {isNotesExpand && notes.length > 0 && (
          <>
            <Spacer factor={3} />
            <div>
              {notes.map((note) => {
                const { id, text, adminId, updatedAt } = note;
                return (
                  <LiveChatNoteItem
                    key={id}
                    editable={adminId === currentAdminId && editAllowed}
                    defaultText={text}
                    lastEdit={updatedAt}
                    adminName={
                      adminToId[adminId] ||
                      t('pages.LiveChat.rightPanel.notes.deleted')
                    }
                    onDone={(value) => {
                      updateConversationNote(id, value);
                    }}
                    onChange={(value) => {
                      changeConversationNote(id, value);
                    }}
                    onDeleteRequest={() => {
                      deleteConversationNote(id);
                    }}
                  />
                );
              })}
            </div>
            {needShowAll && (
              <>
                <Spacer factor={4} />
                <ButtonUnstyled
                  data-testid="livechat_notes_show-all-button"
                  className={css.showAll}
                  onMouseDown={(event) => {
                    event.preventDefault();
                    event.stopPropagation();
                  }}
                  onClick={() => {
                    setIsShowAll((prev) => !prev);
                    sendEvent({
                      category: 'live chat',
                      action: isShowAll ? 'show less click' : 'show all click',
                      label: 'notes',
                    });
                  }}
                >
                  <Type color="accent1Normal">
                    {t(
                      isShowAll
                        ? 'pages.LiveChat.rightPanel.notes.showLess'
                        : 'pages.LiveChat.rightPanel.notes.showAll',
                    )}
                  </Type>
                </ButtonUnstyled>
              </>
            )}
          </>
        )}
      </div>
      <RightPanelDivider />
    </>
  );
};
