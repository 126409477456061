import React from 'react';
import { Anchor } from '@ui/Links';
import { Avatar } from '@ui/Avatar';
import { Type } from '@ui/Type';
import { Flex } from '@ui/Flex';
import { Spacer } from '@ui/Spacer';
import * as css from './ConnectedInstagramAssociatedWith.css';
import { useSafeTranslation } from '@utils/useSafeTranslation';

interface ConnectedInstagramAssociatedWithProps {
  title: string;
  picture: string;
  pageId: string;
  onClick: () => void;
}

export const ConnectedInstagramAssociatedWith: React.FC<ConnectedInstagramAssociatedWithProps> =
  ({ picture, title, pageId, onClick }) => {
    const { t } = useSafeTranslation();

    return (
      <Flex alignItems="center" className={css.associatedWithContainer}>
        <Type size="12px">
          {t('pages.GrowPage.InstagramAccount.associatedWithText')}
        </Type>
        <Spacer horizontalFactor={1} factor={0} />
        <Avatar className={css.avatar} src={picture} customSize={15} />
        <Spacer horizontalFactor={1} factor={0} />
        <Anchor
          hideArrow
          intent="internal"
          href={`https://www.facebook.com/${pageId}`}
          target="_blank"
          rel="noopener noreferrer"
          className={css.pageLink}
          onClick={onClick}
        >
          <Type size="12px">{title}</Type>
        </Anchor>
      </Flex>
    );
  };
