import React, { useState } from 'react';
import useVisibilityChange from 'use-visibility-change';
import { EmptyView } from '@ui/EmptyView/EmptyView';
import { sendEvent } from '../../../../utils/Analytics';

interface FacebookAdCampaignsListEmptyProps {
  refetchRequest: () => void;
  title: string;
  message: string;
  refreshButtonLabel: string;
}

const MIN_TIME_TO_CREATE_CAMPAIGN_TIMEOUT = 10 * 1000;

export const FacebookAdCampaignsListEmpty: React.FC<FacebookAdCampaignsListEmptyProps> = ({
  refetchRequest,
  title,
  message,
  refreshButtonLabel,
}) => {
  const [isCampaignCreating, setIsCampaignCreating] = useState<boolean>(false);

  useVisibilityChange({
    onShow: ({ lastSeenDate }) => {
      if (!isCampaignCreating) {
        return;
      }
      setIsCampaignCreating(false);
      const campaignAccountTime = Date.now() - lastSeenDate;
      if (campaignAccountTime > MIN_TIME_TO_CREATE_CAMPAIGN_TIMEOUT) {
        refetchRequest();
      }
    },
  });

  return (
    <EmptyView
      title={title}
      message={message}
      refreshButtonLabel={refreshButtonLabel}
      linkHref="https://facebook.com/adsmanager"
      linkLabel="facebook.com/adsmanager"
      onLinkClick={() => setIsCampaignCreating(true)}
      onRefreshClick={() => {
        refetchRequest();
        sendEvent({
          category: 'ads manager',
          action: refreshButtonLabel,
        });
      }}
      onTutorialLinkClick={() => {
        sendEvent({
          category: 'ads manager',
          action: 'watch the video',
          label: 'from ads manager dashboard',
          propertyBag: {
            linkVideo: 'ctm',
          },
        });
      }}
    />
  );
};
