import React from 'react';
import { Button, ButtonProps } from '@ui/Button';

export interface PlatformButton extends ButtonProps {}

export const PlatformButton: React.FC<PlatformButton> = ({
  children,
  ...props
}) => (
  <Button intent="primary" {...props}>
    {children}
  </Button>
);
