import React, { useCallback, useEffect, useState } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { useHistory } from 'react-router-dom';
import gql from 'graphql-tag';
import { getAdminFeatures } from '@utils/Data/Admin';
import { sendEventOnceByCategoryAndAction } from '../../../../utils/Analytics';
import { BotsListBannerProps } from '../../../BotListPage/components/BotsListBannerBox';
import { BotsListBanner } from '../../../BotListPage/components/BotsListBanner';
import phoneIconUrl from '../images/mobile-phone-with-rightwards-arrow-at-left.png';
import { Color } from '../../../../modern-ui/_common/colors';
import { Type } from '../../../../modern-ui/Type';
import {
  ClickToMessengerBotsListBannerQuery,
  ClickToMessengerBotsListBannerQuery_adsManagerBotsListBannerData_bots,
} from './@types/ClickToMessengerBotsListBannerQuery';
import { AdsManagerBotsListBannerType } from '../../../../../@types/globalTypes';
import {
  Button,
  ButtonColorWay,
  ButtonIntent,
} from '../../../../modern-ui/_deprecated/Button';
import { ButtonUnstyled } from '../../../../modern-ui/Button';
import { ReactComponent as DropdownIcon } from '../../../../modern-ui/_deprecated/Icon/icons/ic_dropdown_arr.svg';
import { defaultItemToString, SimpleCombobox } from '@ui/SimpleCombobox';
import { MenuItem } from '../../../../modern-ui/Menu';
import { Avatar } from '../../../../modern-ui/Avatar';
import { Flex } from '../../../../modern-ui/Flex';
import * as css from './ClickToMessengerBotsListBanner.css';
import { Spacer } from '../../../../modern-ui/Spacer';
import {
  ServerStorageItemKeys,
  serverStorageItemGet,
  serverStorageItemSet,
} from '../../../../utils/ServerStorage';

const CLICK_TO_MESSENGER_BOTS_LIST_BANNER_BOT_FRAGMENT = gql`
  fragment clickToMessengerBotsListBannerBotFragment on Bot {
    id
    title
    status {
      page_info {
        id
        picture
      }
    }
  }
`;

const CLICK_TO_MESSENGER_BOTS_LIST_BANNER_QUERY = gql`
  query ClickToMessengerBotsListBannerQuery {
    adsManagerBotsListBannerData {
      bots {
        ...clickToMessengerBotsListBannerBotFragment
      }
      type
    }
    bots {
      ...clickToMessengerBotsListBannerBotFragment
    }
  }
  ${CLICK_TO_MESSENGER_BOTS_LIST_BANNER_BOT_FRAGMENT}
`;

export const customCanBeShown = async () => {
  const CLOSED_INTERVAL = 7 * 24 * 60 * 60 * 1000;
  const FINAL_SHOW_TIMESTAMP = 1591488000 * 1000; // 03.06.2020
  const now = Date.now();
  if (FINAL_SHOW_TIMESTAMP < now) {
    return true;
  }
  const [adminFeatures, closeTimestamp] = await Promise.all([
    getAdminFeatures(),
    serverStorageItemGet(
      ServerStorageItemKeys.clickToMessengerBotsListBannerCloseTimestamp,
    ),
  ]);
  if (!adminFeatures.show_ads_manager) {
    return false;
  }
  if (!closeTimestamp) {
    return true;
  }
  if (closeTimestamp + CLOSED_INTERVAL < Date.now()) {
    serverStorageItemSet(
      ServerStorageItemKeys.clickToMessengerBotsListBannerCloseTimestamp,
      0,
    );
    return true;
  }
  return false;
};

export const ClickToMessengerBotsListBanner: React.FC<BotsListBannerProps> = ({
  onRequestClose,
}) => {
  const { data } = useQuery<ClickToMessengerBotsListBannerQuery>(
    CLICK_TO_MESSENGER_BOTS_LIST_BANNER_QUERY,
  );
  const [currentBot, setCurrentBot] = useState<
    | ClickToMessengerBotsListBannerQuery_adsManagerBotsListBannerData_bots
    | undefined
  >();
  const history = useHistory();

  const { bots: targetBots, type } = data?.adsManagerBotsListBannerData || {};
  const { bots } = data || {};

  useEffect(() => {
    sendEventOnceByCategoryAndAction({
      category: 'ads manager',
      action: 'banner show',
    });
  }, []);

  useEffect(() => {
    if (targetBots?.length && !currentBot) {
      setCurrentBot(targetBots[0]);
    }
  }, [targetBots, setCurrentBot, currentBot]);

  const closeHandler = useCallback(() => {
    serverStorageItemSet(
      ServerStorageItemKeys.clickToMessengerBotsListBannerCloseTimestamp,
      Date.now(),
    );
    onRequestClose();
  }, [onRequestClose]);

  if (!bots?.length) {
    return null;
  }

  return (
    <BotsListBanner
      color={Color.yellowLight}
      title={
        <Type size="18px">
          {type === AdsManagerBotsListBannerType.with_ad_subscribers &&
            window.i18next.t(
              'ClickToMessengerBotsListBanner-string-1082-set-up-click-to-messenger-and-sponsored-messages-ads-easily-with-our-new-feature',
            )}
          {type === AdsManagerBotsListBannerType.with_comments &&
            window.i18next.t(
              'ClickToMessengerBotsListBanner-string-1791-acquiring-users-from-facebook-comments-now-try-native-feature-for-facebook-ads-too',
            )}
          {type === AdsManagerBotsListBannerType.common &&
            window.i18next.t(
              'ClickToMessengerBotsListBanner-string--105-grow-your-subscriber-list-using-our-new-native-facebook-ads-feature',
            )}
        </Type>
      }
      subtitle={
        <Type size="15px_DEPRECATED">
          {window.i18next.t(
            'ClickToMessengerBotsListBanner-JSXText--161-start-conversations-at-scale-leveraging-facebook',
          )}
          <br />
          {window.i18next.t(
            'ClickToMessengerBotsListBanner-JSXText-6378-targeting-to-find-customers-relevant-to-your-business',
          )}
        </Type>
      }
      icon={<img src={phoneIconUrl} alt="" width={70} height={70} />}
      actionBlock={
        <Flex flexDirection="column" alignItems="flex-end">
          {(bots?.length === 1 ||
            type === AdsManagerBotsListBannerType.with_ad_subscribers) && (
            <Button
              intent={ButtonIntent.primary}
              colorWay={ButtonColorWay.white}
              onClick={() => {
                history.push(`/bot/${currentBot?.id}/grow`);
              }}
            >
              {window.i18next.t(
                'ClickToMessengerBotsListBanner-JSXText-4199-set-up-in',
              )}
              {currentBot?.title}
              {window.i18next.t(
                'ClickToMessengerBotsListBanner-JSXText-1010-bot',
              )}
            </Button>
          )}
          {bots?.length > 1 && (
            <SimpleCombobox<ClickToMessengerBotsListBannerQuery_adsManagerBotsListBannerData_bots>
              items={bots}
              menuboxStyle={{ minWidth: 180, right: 0 }}
              onChange={(bot) => {
                if (bot) {
                  history.push(`/bot/${bot.id}/grow`);
                }
              }}
              renderInput={({ getToggleButtonProps }) =>
                bots?.length === 1 ||
                type === AdsManagerBotsListBannerType.with_ad_subscribers ? (
                  <>
                    <Spacer factor={2} />
                    <ButtonUnstyled {...getToggleButtonProps()}>
                      <Flex alignItems="center" className={css.selectorLink}>
                        {window.i18next.t(
                          'ClickToMessengerBotsListBanner-JSXText--297-сhoose-another-bot',
                        )}
                        <DropdownIcon className={css.selectorLinkIcon} />
                      </Flex>
                    </ButtonUnstyled>
                  </>
                ) : (
                  <Button
                    intent={ButtonIntent.primary}
                    colorWay={ButtonColorWay.white}
                    {...getToggleButtonProps()}
                    renderIconRight={() => <DropdownIcon />}
                  >
                    {window.i18next.t(
                      'ClickToMessengerBotsListBanner-JSXText-1662-choose-bot-to-setup',
                    )}
                  </Button>
                )
              }
              renderItem={({ getItemProps, item, index, highlightedIndex }) => (
                <MenuItem
                  key={item.id}
                  {...getItemProps({ item })}
                  leftElement={
                    <Avatar
                      src={item.status?.page_info?.picture ?? undefined}
                      style={{ verticalAlign: -10 }}
                    />
                  }
                  active={index === highlightedIndex}
                  title={defaultItemToString(item)}
                />
              )}
            />
          )}
        </Flex>
      }
      onRequestClose={closeHandler}
    />
  );
};
