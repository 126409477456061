import React from 'react';
import { Folder, Platform } from '@globals';
import { useToaster } from '@ui/Toaster';
import {
  getUrlSearchParams,
  getUrlSearchParamsEnumSafe,
} from '@utils/Routing/getUrlSearchParams';
import { useCurrentBotId } from '@utils/Routing';
import { sendEvent } from '@utils/Analytics';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { useLocation } from 'react-router-dom';
import { CONVERSATION_ID, FOLDER_ITEMS } from './consts';
import { useLiveChatSegmentation } from './hooks/useLiveChatSegmentation';
import { useMoveConversation } from './hooks/useMoveConversation';
import { moveConversationToFolderInCacheClosure } from './utils/moveConversationToFolderInCacheClosure';

interface useLiveChatMoveConversationProps {
  conversationId?: string;
  platform?: Platform | null;
}

export const useLiveChatMoveConversation = ({
  conversationId: initConversationId,
  platform: initplatform,
}: useLiveChatMoveConversationProps) => {
  const { t } = useSafeTranslation();
  const botId = useCurrentBotId();

  const { search } = useLocation();

  const platform =
    initplatform ??
    getUrlSearchParamsEnumSafe(search, 'platform', Platform) ??
    Platform.facebook;
  const dialogsPlatform = getUrlSearchParamsEnumSafe(
    search,
    'dialogListPlatform',
    Platform,
  );

  const conversationId =
    initConversationId ?? getUrlSearchParams(search, CONVERSATION_ID);

  const segmentation = useLiveChatSegmentation(botId!);

  const { addToaster } = useToaster();
  const { moveConversation } = useMoveConversation({
    onCompleted(data) {
      const folderTitle = FOLDER_ITEMS.find(
        (v) => v.id === data.moveConversation.folder,
      )?.title;
      const folder = folderTitle ? t(folderTitle) : '';
      addToaster({
        type: 'info',
        content: (
          <div style={{ minWidth: '300px', whiteSpace: 'nowrap' }}>
            {t('Dialogs-JSXText--382-conversation-moved-to')}{' '}
            {folder.charAt(0).toUpperCase() + folder.slice(1)}.
          </div>
        ),
        timeout: 3000,
        closeButton: true,
      });
    },
    onError() {
      addToaster({
        type: 'error',
        content: (
          <div style={{ minWidth: '300px', whiteSpace: 'nowrap' }}>
            {t(
              'Dialogs-JSXText-8890-something-went-wrong-please-try-again-later',
            )}
          </div>
        ),
        timeout: 3000,
        closeButton: true,
      });
    },
  });

  const handleMoveDialogToClose = () => {
    if (!conversationId || !botId) return;
    sendEvent({
      category: 'live chat',
      action: 'conversation done',
      propertyBag: {
        conversationId,
      },
    });
    moveConversation({
      variables: {
        botId,
        conversationId,
        folder: Folder.closed,
        platform,
      },
      optimisticResponse: {
        moveConversation: {
          __typename: 'LivechatConversationV3',
          id: conversationId,
          folder: Folder.closed,
        },
      },
      update: moveConversationToFolderInCacheClosure(
        { source: Folder.inbox, target: Folder.closed },
        { botId, segmentation, platform: dialogsPlatform },
      ),
    });
  };

  const handleMoveDialogToOpen = () => {
    if (!conversationId || !botId) return;
    moveConversation({
      variables: {
        botId,
        conversationId,
        folder: Folder.inbox,
        platform,
      },
      optimisticResponse: {
        moveConversation: {
          __typename: 'LivechatConversationV3',
          id: conversationId,
          folder: Folder.inbox,
        },
      },
      update: moveConversationToFolderInCacheClosure(
        { source: Folder.closed, target: Folder.inbox },
        { botId, segmentation, platform: dialogsPlatform },
      ),
    });
  };

  return {
    moveConversationToOpen: handleMoveDialogToOpen,
    moveConversationToClose: handleMoveDialogToClose,
  };
};
