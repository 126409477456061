import React from 'react';
import { propOr } from 'ramda';
import * as css from './ClickToMessengerAdsSettingsPageStep1.css';
import { Spacer } from '../../../../../modern-ui/Spacer';
import { Type } from '../../../../../modern-ui/Type';
import { Flex } from '../../../../../modern-ui/Flex';
import step2aImg from './images/step2a.png';
import step2bImg from './images/step2b.png';
import step2cImg from './images/step2c.png';
import { ReactComponent as InfoIcon } from '../../../../../modern-ui/_deprecated/Icon/icons/error-info.svg';
import {
  Button,
  ButtonIntent,
} from '../../../../../modern-ui/_deprecated/Button';
import { ReactComponent as DropdownIcon } from '../../../../../modern-ui/_deprecated/Icon/icons/ic_dropdown_arr.svg';
import { Item, SimpleCombobox } from '@ui/SimpleCombobox';
import { AdCampaignType } from '../../../../../../@types/globalTypes';
import { sendEvent } from '../../../../../utils/Analytics';
import { Anchor } from '../../../../../modern-ui/Links';

const CAMPAIGN_TYPE_SELECTOR_ITEMS: () => Item[] = () => [
  {
    id: AdCampaignType.click_to_messenger,
    title: window.i18next.t(
      'ClickToMessengerAdsSettingsPageStep1-string--983-click-to-messenger-campaign',
    ),
  },
  {
    id: AdCampaignType.sponsored_message,
    title: window.i18next.t(
      'ClickToMessengerAdsSettingsPageStep1-string-7486-sponsored-messages-campaign',
    ),
  },
];

interface ClickToMessengerAdsSettingsPageStep1Props {
  currentCampaignType: AdCampaignType;
  onFacebookAdCampaignTypeSelect: (type: AdCampaignType) => void;
}

export const ClickToMessengerAdsSettingsPageStep1: React.FC<ClickToMessengerAdsSettingsPageStep1Props> =
  ({ onFacebookAdCampaignTypeSelect, currentCampaignType }) => (
    <>
      <Spacer factor={2} />
      <div style={{ width: 280 }}>
        <SimpleCombobox
          items={CAMPAIGN_TYPE_SELECTOR_ITEMS()}
          initialSelectedItem={CAMPAIGN_TYPE_SELECTOR_ITEMS()[0]}
          itemToString={propOr<string>('', 'title')}
          onChange={(item) => {
            const type = item?.id as AdCampaignType;
            onFacebookAdCampaignTypeSelect(
              type || AdCampaignType.click_to_messenger,
            );
            sendEvent({
              category: 'ads manager',
              action: 'begin setup',
              label:
                type === AdCampaignType.click_to_messenger
                  ? 'click-to-messenger campaign'
                  : 'sponsored messages campaign',
            });
          }}
          renderInput={({ getToggleButtonProps, selectedItem }) => (
            <Button
              intent={ButtonIntent.primary}
              {...getToggleButtonProps()}
              renderIconRight={() => <DropdownIcon />}
            >
              {selectedItem.title}
            </Button>
          )}
        />
      </div>
      <Spacer factor={6} />
      {currentCampaignType === AdCampaignType.click_to_messenger && (
        <>
          <Type weight="medium" size="15px_DEPRECATED">
            {window.i18next.t(
              'ClickToMessengerAdsSettingsPageStep1-JSXText-2544-1-create-a-click-to-messenger-campaign-in-ads-manager',
            )}
          </Type>
          <Spacer factor={1} />
          <Type color="greyDark" size="15px_DEPRECATED">
            {window.i18next.t(
              'ClickToMessengerAdsSettingsPageStep1-JSXText-2667-go-to',
            )}
          </Type>
          <Anchor
            hideArrow
            intent="external"
            href="https://facebook.com/adsmanager"
            target="_blank"
            className={css.link}
            onClick={() => {
              sendEvent({
                category: 'ads manager',
                action: 'go to ads manager',
              });
            }}
          >
            facebook.com/adsmanager
          </Anchor>
          <Type color="greyDark" size="15px_DEPRECATED">
            {window.i18next.t(
              'ClickToMessengerAdsSettingsPageStep1-JSXText-1724-choose-messages-as-the-consideration-then-set-up-your-ad-as-desired',
            )}
          </Type>
          <Spacer factor={7} />
          <Type weight="medium" size="15px_DEPRECATED">
            {window.i18next.t(
              'ClickToMessengerAdsSettingsPageStep1-JSXText-3300-2-set-up-a-opt-in-message-greeting-to-act-as-your-opt-in-message',
            )}
          </Type>
          <Flex alignItems="baseline" justifyContent="space-between">
            <div className={css.stepColl}>
              <img className={css.stepImage} src={step2aImg} alt="" />
              <Spacer factor={2} />
              <Type as="p" color="greyDark" size="15px_DEPRECATED">
                {window.i18next.t(
                  'ClickToMessengerAdsSettingsPageStep1-JSXText-4016-click-create-chat-to-write-an-engaging-message-to-get-users-to-opt-in',
                )}
              </Type>
            </div>
            <div className={css.stepColl}>
              <img className={css.stepImage} src={step2bImg} alt="" />
              <Spacer factor={2} />
              <Type as="p" color="greyDark" size="15px_DEPRECATED">
                {window.i18next.t(
                  'ClickToMessengerAdsSettingsPageStep1-JSXText--896-choose-text-only-or-text-and-image',
                )}
              </Type>
            </div>
          </Flex>
        </>
      )}
      {currentCampaignType === AdCampaignType.sponsored_message && (
        <>
          <Type weight="medium" size="15px_DEPRECATED">
            {window.i18next.t(
              'ClickToMessengerAdsSettingsPageStep1-JSXText-1344-1-create-a-sponsored-messages-campaign-in-ads-manager',
            )}
          </Type>
          <Spacer factor={1} />
          <Type color="greyDark" size="15px_DEPRECATED">
            {window.i18next.t(
              'ClickToMessengerAdsSettingsPageStep1-JSXText-2667-go-to',
            )}
          </Type>
          <Anchor
            hideArrow
            intent="external"
            href="https://facebook.com/adsmanager"
            target="_blank"
            className={css.link}
          >
            facebook.com/adsmanager
          </Anchor>
          <Type color="greyDark" size="15px_DEPRECATED">
            {window.i18next.t(
              'ClickToMessengerAdsSettingsPageStep1-JSXText-1724-choose-messages-as-the-consideration-then-set-up-your-ad-as-desired',
            )}
          </Type>
          <Spacer factor={7} />
          <Type weight="medium" size="15px_DEPRECATED">
            {window.i18next.t(
              'ClickToMessengerAdsSettingsPageStep1-JSXText-3300-2-set-up-a-opt-in-message-greeting-to-act-as-your-opt-in-message',
            )}
          </Type>
          <Spacer factor={4} />
          <div style={{ width: 506 }}>
            <img className={css.stepImage} src={step2cImg} alt="" />
            <Spacer factor={2} />
            <Type as="p" color="greyDark" size="15px_DEPRECATED">
              {window.i18next.t(
                'ClickToMessengerAdsSettingsPageStep1-JSXText--896-choose-text-only-or-text-and-image',
              )}
            </Type>
          </div>
        </>
      )}
      <Spacer factor={5} />
      <div>
        <InfoIcon className={css.infoIcon} />
        <Type size="15px_DEPRECATED">
          {window.i18next.t(
            'ClickToMessengerAdsSettingsPageStep1-JSXText--186-you-only-need-to-edit-the-greeting-in-this-window-customer-actions-can-be-configured-back-in-chatfuel',
          )}
        </Type>
      </div>
      <Spacer factor={6} />
      <Type weight="medium" size="15px_DEPRECATED">
        {window.i18next.t(
          'ClickToMessengerAdsSettingsPageStep1-JSXText-1264-3-when-your-ads-are-all-set-up-click-confirm-and-return-to-chatfuel',
        )}
      </Type>
    </>
  );
