import {
  ConversationNotesQuery,
  ConversationNotesQuery_livechatConversationNotes,
  ConversationNotesQueryVariables,
} from '../@types/ConversationNotesQuery';
import { mapQuery } from '@utils/GQL/mapQuery';
import client from '@common/services/ApolloService';
import { CONVERSATION_NOTES_QUERY } from '../queries';

export const notesCacheUpdater = (
  updater: (
    items: ConversationNotesQuery_livechatConversationNotes[],
  ) => ConversationNotesQuery_livechatConversationNotes[],
  variables: ConversationNotesQueryVariables,
) => {
  mapQuery<ConversationNotesQuery, ConversationNotesQueryVariables>(
    client,
    {
      query: CONVERSATION_NOTES_QUERY,
      variables,
    },
    (notesData) => {
      const notesListUpdated =
        notesData.livechatConversationNotes?.slice() || [];
      return {
        ...notesData,
        livechatConversationNotes: updater(notesListUpdated),
      };
    },
  );
};
