import React from 'react';
import { CenteredLoader } from '@ui/Loader';
import { Spacer } from '@ui/Spacer';

interface PlatformConnectionLoadingProps extends TestLocator {}

export const PlatformConnectionLoading: React.FC<PlatformConnectionLoadingProps> =
  (props) => (
    <>
      <Spacer factor={1} />
      <CenteredLoader data-testid={props['data-testid']} />
      <Spacer factor={1} />
    </>
  );
