import { useEventListener } from 'cf-common/src/hooks/useEventListener';
import { useCallback, useEffect, useRef } from 'react';
import { useMutation } from 'react-apollo';
import { Platform } from '@globals';
import {
  MarkMessagesAsSeenMutation,
  MarkMessagesAsSeenMutationVariables,
} from '../common/@types/MarkMessagesAsSeenMutation';
import { MARK_MESSAGES_AS_SEEN_MUTATION } from '../common/queries';
import { MessagesQuery_livechatMessages_items as Message } from '../components/Messages/@types/MessagesQuery';

interface MarkMessagesAsSeenParams {
  botId?: string;
  conversationId: string;
  platform: Platform;
  messages?: Message[];
  editingAllowed?: boolean;
}

export const useMarkMessagesAsSeen = ({
  botId,
  conversationId,
  platform,
  messages,
  editingAllowed,
}: MarkMessagesAsSeenParams) => {
  const [markMessagesAsSeenMutation] = useMutation<
    MarkMessagesAsSeenMutation,
    MarkMessagesAsSeenMutationVariables
  >(MARK_MESSAGES_AS_SEEN_MUTATION);
  const prevMessagesLengthRef = useRef<number | undefined>();

  const markMessagesAsSeen = useCallback(() => {
    if (
      !editingAllowed ||
      !document.hasFocus() ||
      prevMessagesLengthRef.current === messages?.length
    ) {
      return;
    }
    const unseenMessageMIds =
      messages?.reduce((acc, item) => {
        if (!item.seen && item.mid) {
          acc.push(item.mid);
        }
        return acc;
      }, [] as string[]) ?? [];
    if (!botId || unseenMessageMIds.length === 0) {
      return;
    }
    prevMessagesLengthRef.current = messages?.length;
    markMessagesAsSeenMutation({
      variables: {
        botId,
        conversationId,
        platform,
        messageIds: unseenMessageMIds,
      },
    });
  }, [
    editingAllowed,
    messages,
    botId,
    markMessagesAsSeenMutation,
    conversationId,
    platform,
  ]);

  useEventListener('focusin', markMessagesAsSeen);

  useEffect(() => {
    markMessagesAsSeen();
  }, [markMessagesAsSeen]);
};
