import React from 'react';
import * as css from './AttachmentBase.css';
import { Flex } from '@ui/Flex';
import { HoverDisclosure } from '@ui/HoverDisclosure';
import { IconButton } from '@ui/IconButton';
import { TextEllipsis } from '@ui/TextEllipsis';
import { Tooltip2 } from '@ui/Tooltip2';

interface AttachmentBaseProps {
  onRemove: () => void;
  tooltipContent?: React.ReactNode;
}

export const AttachmentBase: React.FC<AttachmentBaseProps> = ({
  onRemove,
  children,
  tooltipContent,
}) => (
  <HoverDisclosure
    render={({ isVisible, bind: hoverBind }) => (
      <Tooltip2
        placement="top"
        content={<TextEllipsis>{tooltipContent}</TextEllipsis>}
        boundariesElement="viewport"
        disabled={!tooltipContent}
      >
        {(ref, bind) => (
          <div ref={ref} {...bind}>
            <Flex
              {...hoverBind}
              className={css.attachment}
              alignItems="center"
              gapFactor={2}
              data-testid="livechat_message-input_attachment"
            >
              {children}
              {isVisible && (
                <IconButton
                  onClick={onRemove}
                  icon="delete"
                  size="24px"
                  className={css.removeButton}
                  data-testid="livechat_message-delete_attachment"
                />
              )}
            </Flex>
          </div>
        )}
      </Tooltip2>
    )}
  />
);
