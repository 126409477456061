import { ParaphraseMode } from '@globals';
import { NestedMenuNodeDisplayMode, PopperMenu } from '@ui/NestedMenu';
import React from 'react';
import { ButtonUnstyled } from '@ui/Button';
import * as css from './CopilotMenu.css';
import { sendEvent } from '@utils/Analytics';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { CopilotIcon } from './CopilotIcon';

interface CopilotMenuProps {
  onItemSelect: (item: { id: ParaphraseMode; title: string }) => void;
}

export const CopilotMenu: React.FC<CopilotMenuProps> = ({ onItemSelect }) => {
  const { t } = useSafeTranslation();
  const items = [
    {
      title: t('Copilot.modes.spellCheck'),
      id: ParaphraseMode.spellCheck,
    },
    {
      id: 'tone',
      title: t('Copilot.modes.changeTone'),
      displayMode: NestedMenuNodeDisplayMode.nested,
      children: [
        { title: t('Copilot.modes.friendly'), id: ParaphraseMode.friendly },
        { title: t('Copilot.modes.formal'), id: ParaphraseMode.formal },
      ],
    },
  ];
  return (
    <PopperMenu
      items={items}
      onItemSelect={(item) => onItemSelect(item as any)}
      placement="top-start"
    >
      {(ref, { setOpen }) => (
        <ButtonUnstyled
          className={css.copilotBtn}
          ref={ref}
          onClick={() => {
            sendEvent({
              category: 'live chat',
              action: 'open copilot window',
            });
            setOpen(true);
          }}
        >
          <CopilotIcon />
        </ButtonUnstyled>
      )}
    </PopperMenu>
  );
};
