import React from 'react';
import { Flex } from '@ui/Flex';
import { LoadingPlaceholder } from '@ui/LoadingPlaceholder';
import { Spacer } from '@ui/Spacer';

export const SubscriptionLoader: React.FC = () => {
  const userLoader = (
    <Flex alignItems="center" style={{ padding: '12px 0' }}>
      <LoadingPlaceholder
        color="grey"
        style={{
          borderRadius: '50%',
          width: '32px',
          height: '32px',
        }}
      />
      <Spacer horizontalFactor={2} />
      <div>
        <LoadingPlaceholder
          color="grey"
          style={{
            width: '200px',
            height: '20px',
          }}
        />
        <Spacer factor={2} />
        <LoadingPlaceholder
          color="grey"
          style={{
            width: '120px',
            height: '12px',
          }}
        />
      </div>
    </Flex>
  );
  return (
    <>
      {userLoader}
      {userLoader}
    </>
  );
};
