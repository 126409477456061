import {
  useFilterableSearch,
  SearchType,
} from '@components/FilterableSearchField';
import { DEFAULT_SEGMENTATION } from '@utils/Data/LiveChat';
import { useLiveChatAdmins } from './useLiveChatAdmins';
import { LivechatFilter } from '../components/FilterableSearch';
import {
  UserAttributeType,
  ParametersOperator,
  ParameterFilterValueOperator,
  SegmentationInput,
} from '@globals';
import { useMemo } from 'react';

const getNameParameterValue = (
  name:
    | 'first name'
    | 'last name'
    | 'instagram name'
    | 'instagram handle'
    | 'whatsapp phone'
    | 'whatsapp name',
  value: string,
) => ({
  name,
  values: [value],
  operation: ParameterFilterValueOperator.starts_with,
  type: UserAttributeType.system,
});

export const useLiveChatSegmentation = (botId: string) => {
  const { admins } = useLiveChatAdmins(botId);
  const { type, value, parameters } = useFilterableSearch<LivechatFilter>();
  return useMemo(() => {
    const segmentation: SegmentationInput = {
      operation: ParametersOperator.and,
      parameters: [],
    };

    switch (type) {
      case LivechatFilter.attribute: {
        return (parameters as SegmentationInput) || DEFAULT_SEGMENTATION;
      }
      case LivechatFilter.assignee:
        segmentation.parameters!.push({
          name: 'assignee',
          operation: ParameterFilterValueOperator.is,
          type: UserAttributeType.system,
          values: [
            (value && admins.find((a) => a.id === value)?.name) ?? 'not set',
          ],
        });
        break;
      case SearchType.search: {
        if (value) {
          const fullName = value.trim();
          const [firstName, lastName] = fullName.split(' ');

          if (lastName) {
            segmentation.parameters!.push({
              type: UserAttributeType.compound,
              sub_filter: {
                operation: ParametersOperator.or,
                parameters: [
                  {
                    type: UserAttributeType.compound,
                    sub_filter: {
                      operation: ParametersOperator.and,
                      parameters: [
                        getNameParameterValue('first name', firstName),
                        getNameParameterValue('last name', lastName),
                      ],
                    },
                  },
                  {
                    type: UserAttributeType.compound,
                    sub_filter: {
                      operation: ParametersOperator.and,
                      parameters: [
                        getNameParameterValue('first name', lastName),
                        getNameParameterValue('last name', firstName),
                      ],
                    },
                  },
                  {
                    type: UserAttributeType.compound,
                    sub_filter: {
                      operation: ParametersOperator.or,
                      parameters: [
                        getNameParameterValue('instagram name', fullName),
                      ],
                    },
                  },
                  {
                    type: UserAttributeType.compound,
                    sub_filter: {
                      operation: ParametersOperator.or,
                      parameters: [
                        getNameParameterValue('whatsapp name', fullName),
                      ],
                    },
                  },
                  {
                    type: UserAttributeType.compound,
                    sub_filter: {
                      operation: ParametersOperator.or,
                      parameters: [
                        getNameParameterValue('whatsapp phone', fullName),
                      ],
                    },
                  },
                ],
              },
            });
          } else if (firstName) {
            segmentation.parameters!.push({
              type: UserAttributeType.compound,
              sub_filter: {
                operation: ParametersOperator.or,
                parameters: [
                  getNameParameterValue('first name', firstName),
                  getNameParameterValue('last name', firstName),
                  getNameParameterValue('instagram name', fullName),
                  getNameParameterValue('instagram handle', fullName),
                  getNameParameterValue('whatsapp name', fullName),
                  getNameParameterValue('whatsapp phone', fullName),
                ],
              },
            });
          }
        }
        break;
      }
      default:
        break;
    }
    return segmentation;
  }, [admins, parameters, type, value]);
};
