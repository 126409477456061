import React from 'react';
import cn from 'classnames';
import { Flex } from '@ui/Flex';
import { FlexProps } from '@ui/Flex/Flex';
import { getComponentWithRefAndName } from '@utils/withRef';
import * as css from './PlatformConnectedButtonsWrapper.css';

interface PlatformConnectedButtonsWrapperProps extends FlexProps, TestLocator {}

export const PlatformConnectedButtonsWrapper = getComponentWithRefAndName<
  HTMLDivElement,
  PlatformConnectedButtonsWrapperProps
>('PlatformConnectedButtonsWrapper', ({ children, ...props }, ref) => (
  <Flex ref={ref} {...props} className={cn(css.wrapper, props.className)}>
    {children}
  </Flex>
));
