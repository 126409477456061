import React from 'react';
import { Type } from '@ui/Type';
import { Anchor } from '@ui/Links';
import { sendEvent } from '@utils/Analytics';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { PlatformNotConnected } from '../../PlatformConnection';

export const Tooltip = () => {
  const { t } = useSafeTranslation();

  return (
    <Type size="12px" color="white" as="div">
      {t('modernComponents.FacebookPagesSetup.common.troubleshootingTooltip')}
      <br />
      <br />
      <Anchor
        intent="tooltip"
        size="small"
        target="_blank"
        href={t('common.helpDocLinks.problems_with_facebook_page_connection')}
        onClick={() => {
          sendEvent({
            category: 'grow tools',
            action: 'trouble shooting click',
            label: 'instagram',
          });
        }}
      >
        {t('common.Troubleshooting')}
      </Anchor>
    </Type>
  );
};

interface InstagramAccountDisconnectedProps {
  onClick: () => void;
}

export const InstagramAccountDisconnected: React.FC<InstagramAccountDisconnectedProps> =
  ({ onClick }) => {
    const { t } = useSafeTranslation();

    return (
      <PlatformNotConnected
        icon="instagramFilled"
        title={t('common.Instagram.dialogHeader')}
        data-testid="instagram-account-header"
        tooltipContent={<Tooltip />}
        description={t('common.Instagram.permissionsNeededText')}
        onClick={onClick}
        buttonTextId="instagram-disconneceted__connect-button"
        wrapperTestId="instagram-disconnected"
        textTestId="instagram-disconnected__text"
      />
    );
  };
