import { Attachment } from '../types';
import {
  useApolloClient,
  useLazyQuery,
  useMutation,
  useQuery,
} from '@apollo/react-hooks';
import {
  ATTACHMENT_URL_QUERY,
  INITIALIZE_ATTACHMENT_DOWNLOAD,
  POLL_ATTACHMENT,
} from './queries';
import { useCurrentBotId } from '@utils/Routing';
import {
  AttachmentUrlQuery,
  AttachmentUrlQueryVariables,
} from './@types/AttachmentUrlQuery';
import { useCallback, useEffect, useState } from 'react';
import { extractGQLErrorData } from '@utils/GQL/utils';
import { showSomethingWentWrongToaster } from '@services/MessageService';
import { log } from 'cf-common/src/logger';
import { sendEvent } from '@utils/Analytics';
import {
  PollAttachment,
  PollAttachmentVariables,
} from './@types/PollAttachment';
import { WaMediaAttachmentDownloadingStatus } from '@globals';
import Maybe from 'graphql/tsutils/Maybe';

export const useAttachment = (
  { attachment_id: attachmentId, url }: Attachment,
  messageId: Maybe<string>,
) => {
  const botId = useCurrentBotId();
  const [loadAttachmentQuery, { loading, error, data }] = useLazyQuery<
    AttachmentUrlQuery,
    AttachmentUrlQueryVariables
  >(ATTACHMENT_URL_QUERY, {
    onError: (error) => {
      if (extractGQLErrorData(error)?.status !== 404) {
        log.warn({ error, msg: 'Error load attachment url' });
        showSomethingWentWrongToaster();
      }
    },
  });

  const loadAttachment = useCallback(() => {
    sendEvent({
      category: 'live chat',
      action: 'load attachment click',
      propertyBag: {
        attachmentId,
      },
    });
    if (!botId || !attachmentId || !messageId) {
      return;
    }
    loadAttachmentQuery({
      variables: {
        botId,
        attachmentId,
        messageId,
      },
    });
  }, [attachmentId, botId, loadAttachmentQuery, messageId]);

  return {
    loading,
    attachmentId,
    url: url || data?.attachmentUrl.url,
    loadAttachment,
    error,
    expired: extractGQLErrorData(error)?.status === 404,
  };
};

export const useInitializeAttachmentDownload = () => {
  const botId = useCurrentBotId()!;
  const [initialize, queryResult] = useMutation(INITIALIZE_ATTACHMENT_DOWNLOAD);

  const initializeDownload = useCallback(
    (attachmentId) => {
      sendEvent({
        category: 'live chat',
        action: 'initialize download',
      });

      return initialize({
        variables: {
          botId,
          attachmentId,
        },
      }).catch((error) => {
        if (extractGQLErrorData(error)?.status === 404) {
          return;
        }

        throw error;
      });
    },
    [botId, initialize],
  );

  return {
    initializeDownload,
    expired: extractGQLErrorData(queryResult.error)?.status === 404,
    ...queryResult,
  };
};

const POLLING_INTERVAL = 1000;

export const usePollAttachment = (
  messageId: Maybe<string>,
  attachmentId: Maybe<string>,
  type: string,
) => {
  const botId = useCurrentBotId()!;
  const client = useApolloClient();
  const [isPolling, setIsPolling] = useState<boolean | null>(null);

  const queryResult = useQuery<PollAttachment, PollAttachmentVariables>(
    POLL_ATTACHMENT,
    {
      skip: !attachmentId || !isPolling || !messageId,
      variables: {
        botId,
        attachmentId: attachmentId!,
        messageId: messageId!,
        type,
      },
    },
  );

  const { startPolling, stopPolling, data, error } = queryResult;

  const expired =
    data?.getAttachment.status === WaMediaAttachmentDownloadingStatus.EXPIRED;

  useEffect(() => {
    if (error && isPolling) {
      stopPolling();
      setIsPolling(false);
    }
  }, [error, isPolling, stopPolling]);

  useEffect(() => {
    if (!isPolling || !data) {
      return;
    }

    if (
      data?.getAttachment.status ===
      WaMediaAttachmentDownloadingStatus.DOWNLOADING
    ) {
      return;
    }

    if (
      data?.getAttachment.status === WaMediaAttachmentDownloadingStatus.ERROR
    ) {
      showSomethingWentWrongToaster();
    }

    setIsPolling(false);
    stopPolling();
  }, [attachmentId, botId, client, isPolling, data, type, stopPolling]);

  const onStartPolling = useCallback(() => {
    setIsPolling(true);
    startPolling(POLLING_INTERVAL);
  }, [startPolling]);

  return {
    ...queryResult,
    isPolling,
    expired,
    startPolling: onStartPolling,
  };
};
