import React, { useRef } from 'react';
import { Icon } from '@ui/Icon';
import { Type } from '@ui/Type';
import { Spacer } from '@ui/Spacer';
import {
  FilterableBaseInput,
  useFilterableSearch,
  RenderFilterableInputConfig,
} from '@components/FilterableSearchField';
import { ActionButton } from '@components/FilterableSearchField/components/ActionButton';
import { SearchType } from '@components/FilterableSearchField/context';
import { sendEvent } from '@utils/Analytics';
import { LivechatFilter } from '../../types';
import { AssigneesMenu } from '../../../AssigneesMenu';
import { UNASSIGNED_ITEM } from '../../../AssigneesMenu/constants';
import { Avatar } from '../../../Avatar';
import { LivechatAdmin } from '../../../../hooks/useLiveChatAdmins';

import * as css from './AssigneeFilterableInput.css';

export interface AssigneeInputProps {
  onCloseClick: VoidFunction;
}

export const AssigneeInput = React.forwardRef<
  HTMLButtonElement,
  React.PropsWithChildren<AssigneeInputProps>
>(({ onCloseClick, children }, ref) => (
  <div className={css.assigneeInput}>
    {children}
    <Spacer horizontalFactor={2} factor={0} />
    <ActionButton
      icon="close"
      ref={ref}
      onClick={(e) => {
        e.stopPropagation();
        onCloseClick();
      }}
    />
    <Spacer horizontalFactor={2} factor={0} />
  </div>
));

interface AssigneeFilterableInputProps
  extends RenderFilterableInputConfig<LivechatFilter> {
  livechatAdmins: LivechatAdmin[];
  preparedFilter: LivechatFilter;
}

export const AssigneeFilterableInput: React.FC<AssigneeFilterableInputProps> =
  ({
    comboboxStyles,
    livechatAdmins,
    preparedFilter,
    preparedFilterRef,
    searchValue,
    setPreparedFilter,
    setSearchInputValue,
    inputWidth,
  }) => {
    const { value, setFilter, resetFilter } = useFilterableSearch();
    const clearSelectionRef = useRef<HTMLButtonElement>(null);
    const assignee = livechatAdmins.find((a) => a.id === value);

    return (
      <AssigneesMenu
        assigneeId={assignee?.id}
        comboboxProps={{ ...comboboxStyles, initialIsOpen: true }}
        onAssigneeChange={(admin) => {
          sendEvent({
            category: 'live chat',
            action: 'filter by assignee',
            propertyBag: {
              adminId: admin?.id ?? 'unassinged',
            },
          });
          setSearchInputValue('');
          setPreparedFilter(null);
          setFilter({
            type: LivechatFilter.assignee,
            value: admin?.id ?? null,
            parameters: null,
          });
        }}
      >
        {(comboboxProps) => {
          const { openMenu, closeMenu, isOpen } = comboboxProps;

          return (
            <FilterableBaseInput
              width={inputWidth}
              defaultValue={searchValue}
              showPreparedFilterInput={!!preparedFilter}
              preparedFilterRef={preparedFilterRef}
              onPreparedFilterChange={(event: any) => {
                const { value } = event.target;
                if (isOpen) {
                  closeMenu?.();
                }
                setPreparedFilter(null);
                setFilter({ type: SearchType.search, value, parameters: null });
              }}
              onControlClick={() => {
                if (preparedFilter) {
                  if (!isOpen) {
                    openMenu?.();
                  }
                } else {
                  clearSelectionRef.current?.focus();
                }
              }}
              renderFilter={() => (
                <AssigneeInput
                  ref={clearSelectionRef}
                  onCloseClick={() => resetFilter()}
                >
                  {value ? (
                    <Avatar
                      className={css.assigneeAvatar}
                      src={assignee?.picture}
                      size={16}
                    />
                  ) : (
                    <>
                      <Spacer horizontalFactor={1} factor={0} />
                      <Icon icon="assign" />
                    </>
                  )}
                  <Spacer horizontalFactor={1} factor={0} />
                  <Type size="15px_DEPRECATED">
                    {value ? assignee?.name : UNASSIGNED_ITEM.name}
                  </Type>
                </AssigneeInput>
              )}
            />
          );
        }}
      </AssigneesMenu>
    );
  };
