import { ObservableQuery } from 'apollo-client';
import { DialogsQuery } from '../../../common/@types/DialogsQuery';
import { useSubscriptionWithAuth } from '@utils/Data/hocks/useSubscriptionWithAuth';
import { useCurrentBotId } from '@utils/Routing';
import {
  DIALOGS_LAST_MESSAGE_STATUS_SUBSCRIPTION,
  DIALOGS_UNSEEN_MESSAGES_COUNT_SUBSCRIPTION,
} from '../../../common/subscriptions';
import {
  DialogsUnseenMessagesCountSubscription,
  DialogsUnseenMessagesCountSubscriptionVariables,
} from '../../../common/@types/DialogsUnseenMessagesCountSubscription';
import {
  DialogsLastMessageStatusSubscription,
  DialogsLastMessageStatusSubscriptionVariables,
} from '../../../common/@types/DialogsLastMessageStatusSubscription';
import { useSubscribeDialogsAssigneeUpdates } from './useSubscribeDialogsAssigneeUpdates';
import { useSubscribeNewDialogsMessages } from './useSubscribeNewDialogsMessages';
import { useSubscribeDialogsFolderUpdates } from './useSubscribeDialogsFolderUpdates';

export const useSubscribeToDialogsUpdates = (
  observableQuery: ObservableQuery<DialogsQuery>,
) => {
  const botId = useCurrentBotId();

  useSubscribeDialogsAssigneeUpdates(observableQuery);

  useSubscriptionWithAuth<
    DialogsLastMessageStatusSubscription,
    DialogsLastMessageStatusSubscriptionVariables
  >(DIALOGS_LAST_MESSAGE_STATUS_SUBSCRIPTION, {
    variables: {
      botId: botId || '',
    },
    skip: !botId,
  });

  useSubscriptionWithAuth<
    DialogsUnseenMessagesCountSubscription,
    DialogsUnseenMessagesCountSubscriptionVariables
  >(DIALOGS_UNSEEN_MESSAGES_COUNT_SUBSCRIPTION, {
    variables: {
      botId: botId || '',
    },
    skip: !botId,
  });

  useSubscribeDialogsFolderUpdates(observableQuery);
  useSubscribeNewDialogsMessages(observableQuery);
};
