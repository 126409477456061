import { useCallback, useEffect, useRef, useState } from 'react';
import { Mp3Recorder } from '@utils/Audio/Mp3Recorder';

export enum RecorderState {
  inactive = 'inactive',
  recording = 'recording',
  playing = 'playing',
  dataReady = 'dataReady',
}

const RECORD_LIMIT = 15 * 60 * 1000;

export const useMediaRecorder = () => {
  const [recorderState, setRecorderState] = useState<RecorderState>(
    RecorderState.inactive,
  );
  const recorderRef = useRef<Mp3Recorder | null>(null);
  const playerRef = useRef<HTMLAudioElement | null>(null);
  const [startTime, setStartTime] = useState<number | null>(null);
  const [currentTime, setCurrentTime] = useState<number>(0);
  const [audioBlob, setAudioBlob] = useState<Blob | undefined>();

  const startTimeCount = useCallback(() => {
    setStartTime(Date.now());
    setCurrentTime(0);
  }, []);

  const stopTimeCount = useCallback(() => {
    setStartTime(null);
  }, []);

  const record = useCallback(async () => {
    recorderRef.current = new Mp3Recorder();
    const recorder = recorderRef.current;
    await recorder.start();
    setRecorderState(RecorderState.recording);
    startTimeCount();
  }, [startTimeCount]);

  const stopPlay = useCallback(() => {
    playerRef.current?.pause();
    playerRef.current = null;
    setRecorderState(RecorderState.dataReady);
    stopTimeCount();
  }, [stopTimeCount]);

  const play = useCallback(() => {
    if (!audioBlob) {
      return;
    }
    const blobURL = URL.createObjectURL(audioBlob);
    const audio = new Audio(blobURL);
    audio.play();
    setRecorderState(RecorderState.playing);
    audio.addEventListener(
      'ended',
      () => {
        stopPlay();
        URL.revokeObjectURL(blobURL);
      },
      { once: true },
    );
    playerRef.current = audio;
    startTimeCount();
  }, [audioBlob, startTimeCount, stopPlay]);

  const stop = useCallback(() => {
    switch (recorderState) {
      case RecorderState.recording:
        if (!recorderRef.current) {
          return;
        }
        recorderRef.current.stop();
        recorderRef.current.getBlob().then((blob) => {
          setRecorderState(RecorderState.dataReady);
          setAudioBlob(blob);
          recorderRef.current?.close();
          recorderRef.current = null;
        });
        stopTimeCount();
        break;
      case RecorderState.playing:
        stopPlay();
        break;
      default:
    }
  }, [recorderState, stopPlay, stopTimeCount]);

  useEffect(() => {
    if (!startTime) {
      return undefined;
    }
    const interval = window.setInterval(() => {
      setCurrentTime(Date.now() - startTime);
    }, 1000);
    return () => {
      window.clearInterval(interval);
    };
  }, [startTime]);

  useEffect(() => {
    if (
      recorderState === RecorderState.recording &&
      currentTime > RECORD_LIMIT
    ) {
      stop();
    }
  }, [currentTime, recorderState, stop]);

  useEffect(
    () => () => {
      recorderRef.current?.stop();
      recorderRef.current?.close();
    },
    [],
  );

  return {
    record,
    stop,
    play,
    state: recorderState,
    currentTime,
    audioBlob,
  };
};
