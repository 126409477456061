import { Avatar } from '@ui/Avatar';
import { useBotInstagramAccount } from '@utils/Data/Instagram/BotAccount';
import React from 'react';
import { sizes } from '../../common/sizes';

export const InstagramAvatar: React.FC<{
  botId?: string;
  isSmallScreenSize?: boolean;
}> = ({ botId, isSmallScreenSize }) => {
  const { instagramConnectedAccount } = useBotInstagramAccount(botId || '');

  return (
    <Avatar
      src={instagramConnectedAccount.picture}
      size={
        isSmallScreenSize ? sizes.mobile.avatarSize : sizes.incoming.avatarSize
      }
    />
  );
};
