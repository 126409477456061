/* eslint-disable jsx-a11y/label-has-associated-control,jsx-a11y/label-has-for */
import React, { useState, useEffect } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { pathOr } from 'ramda';
import { useAutomateEnabled } from '@utils/Data/Admin/Automate';
import { log } from 'cf-common/src/logger';
import {
  Dialog,
  DialogCloseButton,
  DialogContent,
  DialogHeading,
} from '../../../modern-ui/Dialog';
import { CommentsAutoreplyQuery_bot_commentsAutoreplyRules as CommentsAutoreplyRule } from './@types/CommentsAutoreplyQuery';
import { Flex } from '../../../modern-ui/Flex';
import { Input } from '../../../modern-ui/Input';
import { Box } from '../../../modern-ui/Box';
import { RadioButton } from '../../../modern-ui/Radio';
import { Autofocus } from '../../../modern-ui/Autofocus';
import { Spacer } from '../../../modern-ui/Spacer';
import { BubbleEditor } from '../../../modern-ui/BubbleEditor';
import {
  deserialize,
  TextWithAttributesEditor,
} from '../../../modern-ui/TextWithAttributesEditor';
import { AttributesData } from '../../../utils/AttributesUtils/AttributesData';
import { Button, ButtonIntent } from '../../../modern-ui/_deprecated/Button';
import { CheckBox } from '../../../modern-ui/CheckBox';
import { BlocksSelectorData } from '../../../modern-components/BlocksSelector2';
import { BlocksSelector } from '../../AiSetupPage/BlocksSelector';
import { BlockWithPermissions } from '../../AiSetupPage';
import { Anchor } from '../../../modern-ui/Links';
import { saveCommentsAutoreplyRule } from './commentsAutoreplyMutations';
import { Tooltip2 } from '../../../modern-ui/Tooltip2';
import { Messages, toaster } from '../../../services/MessageService';
import { ServiceMessageType } from '../../../modern-ui/ServiceMessage2';
import { sendEvent } from '../../../utils/Analytics';
import * as css from './CommentsAutoreply.css';
import { Platform } from '@globals';

export interface CommentsAutoreplyDialogProps {
  initCommentsAutoreplyRule: CommentsAutoreplyRule;
  botId: string;
  position: number;
  onRequestClose: () => void;
}

interface CommentsAutoreplyRuleForm extends CommentsAutoreplyRule {
  applyRuleTo: ApplyRuleTo;
  howBotWillReply: HowBotWillReply;
  sendToBlock: boolean;
}

enum ApplyRuleTo {
  all = 'applyToAll',
  specific = 'specific',
}

const APPLY_RULE_TO_ITEMS = () => [
  {
    id: ApplyRuleTo.all,
    title: window.i18next.t(
      'CommentsAutoreplyRuleDialog-string-1086-all-posts',
    ),
    subtitle: window.i18next.t(
      'CommentsAutoreplyRuleDialog-string--143-best-for-bot-subscribers-growth',
    ),
  },
  {
    id: ApplyRuleTo.specific,
    title: window.i18next.t(
      'CommentsAutoreplyRuleDialog-string-1455-a-specific-post',
    ),
    subtitle: window.i18next.t(
      'CommentsAutoreplyRuleDialog-string--402-great-for-sales-posts',
    ),
  },
];

enum HowBotWillReply {
  all = 'allReply',
  matching = 'matching',
}

const HOW_BOT_WILL_REPLY_ITEMS = () => [
  {
    id: HowBotWillReply.all,
    title: window.i18next.t(
      'CommentsAutoreplyRuleDialog-string-1777-to-all-comments',
    ),
  },
  {
    id: HowBotWillReply.matching,
    title: window.i18next.t(
      'CommentsAutoreplyRuleDialog-string-1752-only-to-comments-with-keywords',
    ),
  },
];

const emptyRule: CommentsAutoreplyRule = {
  __typename: 'CommentsAutoreplyRule',
  id: '',
  title: null,
  phrases: null,
  post_url: null,
  response: null,
  blocks: null,
};

const TITLE_FIELD = 'title';
const SEND_TO_BLOCK_FIELD = 'sendToBlock';
const POST_URL_FIELD = 'post_url';
const BLOCKS_FIELD = 'blocks';
const PHRASES_FIELD = 'phrases';
const RESPONSE_FIELD = 'response';

export const CommentsAutoreplyRuleDialog: React.FC<CommentsAutoreplyDialogProps> =
  ({ botId, initCommentsAutoreplyRule, position, onRequestClose }) => {
    const [isInvalidURL, setIsInvalidURL] = useState<boolean>(false);
    const [isNameExists, setIsNameExists] = useState<boolean>(false);
    const [needSetFocusToField, setNeedSetFocusToField] = useState<
      string | undefined
    >();

    const { isAutomateEnabled } = useAutomateEnabled();

    useEffect(() => {
      setNeedSetFocusToField(undefined);
    }, [needSetFocusToField]);

    const formInitialValue: CommentsAutoreplyRuleForm = {
      ...initCommentsAutoreplyRule,
      applyRuleTo: initCommentsAutoreplyRule.post_url
        ? ApplyRuleTo.specific
        : ApplyRuleTo.all,
      howBotWillReply: initCommentsAutoreplyRule.phrases
        ? HowBotWillReply.matching
        : HowBotWillReply.all,
      [SEND_TO_BLOCK_FIELD]: !!initCommentsAutoreplyRule.blocks,
    };

    return (
      <Dialog className={css.CommentsAutoreplyRuleDialog}>
        <DialogContent className={css.contentBox}>
          <DialogHeading>
            {window.i18next.t(
              'CommentsAutoreplyRuleDialog-JSXText-1117-autoreply-rule',
            )}
          </DialogHeading>
          <Formik
            initialValues={formInitialValue}
            validationSchema={Yup.object().shape({
              title: Yup.string().required(),
              post_url: Yup.string()
                .nullable()
                .when('applyRuleTo', {
                  is: (applyRuleTo) => applyRuleTo === ApplyRuleTo.specific,
                  then: Yup.string().url().required(),
                }),
              phrases: Yup.array()
                .nullable()
                .when('howBotWillReply', {
                  is: (howBotWillReply) =>
                    howBotWillReply === HowBotWillReply.matching,
                  then: Yup.array().compact().min(1).required(),
                }),
              response: Yup.string().required(),
              blocks: Yup.array()
                .nullable()
                .when(SEND_TO_BLOCK_FIELD, {
                  is: true,
                  then: Yup.array().compact().min(1).required(),
                }),
            })}
            onSubmit={async (
              { applyRuleTo, howBotWillReply, sendToBlock, ...updatedRule },
              { setSubmitting },
            ) => {
              try {
                const rule = {
                  ...emptyRule,
                  ...updatedRule,
                  phrases:
                    howBotWillReply === HowBotWillReply.matching
                      ? updatedRule.phrases
                      : null,
                  blocks: sendToBlock ? updatedRule.blocks : null,
                  post_url:
                    applyRuleTo === ApplyRuleTo.specific
                      ? updatedRule.post_url
                      : null,
                };

                const trackCommentsUnder =
                  howBotWillReply === HowBotWillReply.matching
                    ? window.i18next.t(
                        'CommentsAutoreplyRuleDialog-string--177-matching-comments',
                      )
                    : window.i18next.t(
                        'CommentsAutoreplyRuleDialog-string-7788-all-comments',
                      );
                const replyToPostComments =
                  applyRuleTo === ApplyRuleTo.specific
                    ? window.i18next.t(
                        'CommentsAutoreplyRuleDialog-string-1247-specific-post',
                      )
                    : window.i18next.t(
                        'CommentsAutoreplyRuleDialog-string--407-any-post',
                      );

                sendEvent({
                  category: 'acquire users from comments',
                  action: 'submit rule',
                  label: `${trackCommentsUnder} / ${replyToPostComments}`,
                  propertyBag: {
                    'track comments under': trackCommentsUnder,
                    'reply to post comments': replyToPostComments,
                    'send user who respond': sendToBlock,
                    'reply if comment contains': rule.phrases,
                  },
                });
                await saveCommentsAutoreplyRule(botId, rule, position);
                onRequestClose();
              } catch (error) {
                const gqlErrors: { [errorName: string]: string } = pathOr(
                  {},
                  ['graphQLErrors', 0, 'extensions', 'exception', 'errors'],
                  error,
                );
                if (gqlErrors.title) {
                  setIsNameExists(true);
                } else if (gqlErrors.post_url) {
                  setIsInvalidURL(true);
                } else if (gqlErrors.title) {
                  toaster.show({
                    type: ServiceMessageType.error,
                    payload: {
                      message: gqlErrors.title,
                    },
                  });
                } else {
                  toaster.show({
                    type: ServiceMessageType.error,
                    payload: {
                      message: Messages.somethingWentWrong,
                    },
                  });
                  log.warn({ error, msg: 'Error save CommentsAutoreplyRule' });
                }
              } finally {
                setSubmitting(false);
              }
            }}
          >
            {({
              handleSubmit,
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              setFieldValue,
              setFieldTouched,
              isSubmitting,
            }) => {
              return (
                <form onSubmit={handleSubmit}>
                  <Flex alignItems="center">
                    <label htmlFor={TITLE_FIELD} className={css.ruleName}>
                      {window.i18next.t(
                        'CommentsAutoreplyRuleDialog-JSXText--206-rule-name',
                      )}
                    </label>
                    <div className={css.ruleNameInputBox}>
                      <Input
                        id={TITLE_FIELD}
                        name={TITLE_FIELD}
                        value={values.title || ''}
                        onChange={(e) => {
                          handleChange(e);
                          setIsNameExists(false);
                        }}
                        onBlur={handleBlur}
                        error={
                          touched.title && (!!errors.title || isNameExists)
                        }
                      />
                    </div>
                  </Flex>
                  {isNameExists && (
                    <div className={css.invalid}>
                      {window.i18next.t(
                        'CommentsAutoreplyRuleDialog-JSXText--881-this-rule-name-already-exists',
                      )}
                    </div>
                  )}
                  <Spacer factor={6} />
                  <Box>
                    <div className={css.boxTitle}>
                      {window.i18next.t(
                        'CommentsAutoreplyRuleDialog-JSXText-4511-1-apply-rule-to',
                      )}
                    </div>
                    <Spacer factor={4} />
                    <Flex justifyContent="space-between">
                      {APPLY_RULE_TO_ITEMS().map(({ id, title, subtitle }) => (
                        <div key={id} className={css.radioLabelWidth}>
                          <RadioButton
                            id={id}
                            name="applyRuleTo"
                            renderLabel={() => (
                              <span className={css.radioLabel}>
                                <span className={css.radioLabelTitle}>
                                  {title}
                                </span>
                                <Spacer factor={1} />
                                <span className={css.radioLabelSubTitle}>
                                  {subtitle}
                                </span>
                              </span>
                            )}
                            value={values.applyRuleTo}
                            onChange={(event) => {
                              handleChange(event);
                              if (id === ApplyRuleTo.specific) {
                                setNeedSetFocusToField(POST_URL_FIELD);
                                setFieldTouched(POST_URL_FIELD, false);
                                setIsInvalidURL(false);
                              }
                            }}
                          />
                        </div>
                      ))}
                    </Flex>
                    {values.applyRuleTo === ApplyRuleTo.specific && (
                      <>
                        <Spacer factor={4} />
                        <Autofocus
                          shouldFocus={needSetFocusToField === POST_URL_FIELD}
                          render={({ bind }) => (
                            <Input
                              name={POST_URL_FIELD}
                              value={values.post_url || ''}
                              onChange={(e) => {
                                handleChange(e);
                                setIsInvalidURL(false);
                              }}
                              onBlur={handleBlur}
                              error={
                                touched.post_url &&
                                (!!errors.post_url || isInvalidURL)
                              }
                              placeholder={window.i18next.t(
                                'CommentsAutoreplyRuleDialog-string--512-click-on-the-timestamp-of-the-post-to-copy-the-link',
                              )}
                              {...bind}
                            />
                          )}
                        />
                        {isInvalidURL && (
                          <div className={css.invalid}>
                            {window.i18next.t(
                              'CommentsAutoreplyRuleDialog-JSXText--168-insert-a-facebook-post-link',
                            )}
                          </div>
                        )}
                      </>
                    )}
                  </Box>
                  <Spacer factor={2} />
                  <Box>
                    <div className={css.boxTitle}>
                      {window.i18next.t(
                        'CommentsAutoreplyRuleDialog-JSXText--581-2-how-bot-will-reply',
                      )}
                    </div>
                    <Spacer factor={4} />
                    <Flex justifyContent="space-between">
                      {HOW_BOT_WILL_REPLY_ITEMS().map(({ id, title }) => (
                        <RadioButton
                          key={id}
                          id={id}
                          label={title}
                          name="howBotWillReply"
                          value={values.howBotWillReply}
                          className={css.radioLabel}
                          onChange={(event) => {
                            handleChange(event);
                            if (id === HowBotWillReply.matching) {
                              setNeedSetFocusToField(PHRASES_FIELD);
                              setFieldTouched(PHRASES_FIELD, false);
                            }
                          }}
                        />
                      ))}
                    </Flex>
                    {values.howBotWillReply === HowBotWillReply.matching && (
                      <>
                        <Spacer factor={4} />
                        <div className={css.comment}>
                          {window.i18next.t(
                            'CommentsAutoreplyRuleDialog-JSXText-3121-reply-if-user-comment-contains-any-of-the-following-keywords',
                          )}
                        </div>
                        <Spacer factor={2} />
                        <div className={css.phrases}>
                          <Input
                            error={!!errors.phrases && touched.phrases}
                            render={() => (
                              <BubbleEditor
                                defaultValue={(values.phrases || []).join('\n')}
                                onStringChange={(phrasesString) => {
                                  setFieldValue(
                                    PHRASES_FIELD,
                                    phrasesString.split('\n'),
                                  );
                                }}
                                autoFocus={
                                  needSetFocusToField === PHRASES_FIELD
                                }
                                showControlsPlaceholder
                                onBlur={() => {
                                  setFieldTouched(PHRASES_FIELD, true);
                                }}
                                showEmojiPicker
                              />
                            )}
                          />
                        </div>
                      </>
                    )}
                    <Spacer factor={6} />
                    <div className={css.comment}>
                      {window.i18next.t(
                        'CommentsAutoreplyRuleDialog-JSXText--291-reply-with-a-private-message',
                      )}
                    </div>
                    <Spacer factor={2} />
                    <AttributesData botId={botId}>
                      {({ attributes }) => (
                        <Input
                          error={!!errors.response && touched.response}
                          render={() => (
                            <TextWithAttributesEditor
                              defaultValue={deserialize(values.response || '')}
                              onStringChange={(value) => {
                                setFieldValue(RESPONSE_FIELD, value);
                              }}
                              onBlur={() => {
                                setFieldTouched(RESPONSE_FIELD, true);
                              }}
                              placeholder={window.i18next.t(
                                'CommentsAutoreplyRuleDialog-string-9725-write-down-something-engaging',
                              )}
                              attributes={attributes}
                              className={css.urlInput}
                            />
                          )}
                        />
                      )}
                    </AttributesData>
                    <Spacer factor={6} />
                    <Flex alignItems="flex-start">
                      <CheckBox
                        id={SEND_TO_BLOCK_FIELD}
                        name={SEND_TO_BLOCK_FIELD}
                        onBlur={handleBlur}
                        onChange={(event) => {
                          handleChange(event);
                          if (event.currentTarget.checked) {
                            setNeedSetFocusToField(BLOCKS_FIELD);
                            setFieldTouched(BLOCKS_FIELD, false);
                          }
                        }}
                        checked={values[SEND_TO_BLOCK_FIELD]}
                      />
                      <label
                        className={css.sendToBlockLabel}
                        htmlFor={SEND_TO_BLOCK_FIELD}
                      >
                        <div>
                          {window.i18next.t(
                            'CommentsAutoreplyRuleDialog-JSXText--193-if-the-user-responds-send-them-to-a',
                          )}{' '}
                          {isAutomateEnabled
                            ? window.i18next.t(
                                'CommentsAutoreplyRuleDialog-string-9383-block',
                              )
                            : window.i18next.t(
                                'CommentsAutoreplyRuleDialog-string-3146-flow',
                              )}
                        </div>
                        {!values[SEND_TO_BLOCK_FIELD] && (
                          <>
                            <Spacer factor={1} />
                            <div className={css.sendToBlockComment}>
                              {window.i18next.t(
                                'CommentsAutoreplyRuleDialog-JSXText--173-if-a-user-responds-to-your-bots-private-message-theyll-become-a-subscriber-choose-a',
                              )}{' '}
                              {isAutomateEnabled
                                ? window.i18next.t(
                                    'CommentsAutoreplyRuleDialog-string-9383-block',
                                  )
                                : window.i18next.t(
                                    'CommentsAutoreplyRuleDialog-string-3146-flow',
                                  )}
                              {window.i18next.t(
                                'CommentsAutoreplyRuleDialog-JSXText--173-to-send-these-subscribers-to-next-so-you-can-keep-them-engaged',
                              )}
                            </div>
                            <Spacer factor={4} />
                          </>
                        )}
                      </label>
                    </Flex>
                    <BlocksSelectorData botId={botId}>
                      {({ blocksGroups, flowsGroups }) =>
                        values[SEND_TO_BLOCK_FIELD] ? (
                          <>
                            <Spacer factor={2} />
                            <div className={css.blockSelector}>
                              <BlocksSelector
                                platform={Platform.facebook}
                                currentBotId={botId}
                                blocksGroups={
                                  blocksGroups as BlockWithPermissions[]
                                }
                                flowsGroups={flowsGroups}
                                blocksSelected={values.blocks || []}
                                error={touched.blocks && !!errors.blocks}
                                onBlur={() => {
                                  setFieldTouched(BLOCKS_FIELD, true);
                                }}
                                onChange={(blocks) => {
                                  setFieldValue(BLOCKS_FIELD, blocks);
                                }}
                                autofocus={
                                  needSetFocusToField === BLOCKS_FIELD &&
                                  !(values.blocks || []).length
                                }
                              />
                            </div>
                            <Spacer factor={4} />
                          </>
                        ) : null
                      }
                    </BlocksSelectorData>
                    <Tooltip2
                      content={`${window.i18next.t(
                        'CommentsAutoreplyRuleDialog-Template-1427-a-user-who-receives-a-message-from-your-bot-in-response-to-their-comment-will-only-become-a-bot-subscriber-once-they-reply-to-that-message-or-tap-a-button-in-the-persistent-menu-only-then-will-you-be-able-to-engage-them-with-a',
                      )}${
                        isAutomateEnabled
                          ? 'block or Sequence'
                          : window.i18next.t(
                              'CommentsAutoreplyRuleDialog-string-3146-flow',
                            )
                      }${window.i18next.t(
                        'CommentsAutoreplyRuleDialog-Template--116-design-your-bots-initial-message-accordingly-so-it-encourages-the-user-to-reply-or-tap-a-menu-button',
                      )}`}
                      positionFixed
                      placement="top-start"
                      hoverable
                      className={css.tooltip}
                    >
                      {(ref, bind) => (
                        <div>
                          <Anchor
                            hideArrow
                            intent="subtitle"
                            ref={ref}
                            {...bind}
                            className={css.tooltipHover}
                          >
                            {window.i18next.t(
                              'CommentsAutoreplyRuleDialog-JSXText-1740-more-on-how-a-user-becomes-a-bot-subscriber',
                            )}
                          </Anchor>
                        </div>
                      )}
                    </Tooltip2>
                  </Box>
                  <Spacer factor={4} />
                  <Flex justifyContent="flex-end">
                    <Button
                      type="submit"
                      intent={ButtonIntent.primary}
                      disabled={isSubmitting || Object.keys(errors).length > 0}
                    >
                      {window.i18next.t(
                        'CommentsAutoreplyRuleDialog-JSXText-6362-done',
                      )}
                    </Button>
                  </Flex>
                </form>
              );
            }}
          </Formik>
        </DialogContent>
        <DialogCloseButton onClick={onRequestClose} />
      </Dialog>
    );
  };
