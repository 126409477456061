import gql from 'graphql-tag';
import { FULL_NATIVE_BOT_FRAGMENT } from '../../../../../common/services/GQLqueries/BotGQService.const';

const FACEBOOK_PAGES_PRICING_FRAGMENT = gql`
  fragment facebookPagesPricingFragment on Bot {
    pricingData {
      currency
      pricing
      currentUsers
      reached_limit
      currentMaxUsers
      currentPrice
      needPrice
    }
  }
`;

export const UN_BIND_BOT_MUTATION = gql`
  mutation UnBindBotMutation($botId: String!) {
    unBindBot(botId: $botId) {
      ...nativeBotFragment
      ...facebookPagesPricingFragment
    }
  }
  ${FULL_NATIVE_BOT_FRAGMENT}
  ${FACEBOOK_PAGES_PRICING_FRAGMENT}
`;
