import React from 'react';
import cn from 'classnames';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { Anchor } from '@ui/Links';
import { Type } from '@ui/Type';
import { sendEvent } from '@utils/Analytics';
import * as css from './WindowWarning.css';

interface WindowWarningProps {
  message: string;
  href: string;
  linkTitle: string;
  className?: string;
}

const WindowWarning: React.FC<WindowWarningProps> = ({
  className,
  message,
  href,
  linkTitle,
}) => (
  <div className={cn(css.out7DaysWindowWarningContainer, className)}>
    <Type
      size="15px"
      color="alloyOrange"
      data-testid="livechat_message-input_window-warning"
    >
      {message}{' '}
      <Anchor
        href={href}
        target="_blank"
        style={{ color: 'var(--alloyOrange)' }}
        onMouseDown={() => {
          sendEvent({
            category: 'live chat',
            action: 'learn more about 7-days window',
            label: 'segments sync',
            propertyBag: {
              reason: 'send segments to ads manager',
            },
          });
        }}
        hideArrow
      >
        {linkTitle}
      </Anchor>
    </Type>
  </div>
);

interface WhatsappWindowWarningProps
  extends Pick<WindowWarningProps, 'className'> {
  is24WindowNotOpen: boolean;
}

export const WhatsappWindowWarning: React.FC<WhatsappWindowWarningProps> = ({
  className,
  is24WindowNotOpen,
}) => {
  const { t } = useSafeTranslation();

  return (
    <WindowWarning
      className={className}
      message={t(
        is24WindowNotOpen
          ? 'pages.LiveChat.MessageInput.whatsappMessageIntervalNotOpen'
          : 'pages.LiveChat.MessageInput.whatsappMessageIntervalClosed',
      )}
      href="https://docs.chatfuel.com/en/articles/6975885-reengage-whatsapp-messages"
      linkTitle={t('common.LearnMore')}
    />
  );
};

interface MessengerWindowWarningProps
  extends Pick<WindowWarningProps, 'className'> {}

export const MessengerWindowWarning: React.FC<MessengerWindowWarningProps> = ({
  className,
}) => {
  const { t } = useSafeTranslation();

  return (
    <WindowWarning
      className={className}
      message={t('pages.LiveChat.MessageInput.messageIntervalClosed')}
      href="https://developers.facebook.com/docs/messenger-platform/send-messages/message-tags"
      linkTitle={t('common.LearnMore')}
    />
  );
};
