import { Platform } from '@globals';
import { ExtendedPlatform } from './types';

export const extendedPlatformFromPlatform = (platform: Platform | null) => {
  if (!platform) {
    return ExtendedPlatform.all;
  }

  return {
    [Platform.facebook]: ExtendedPlatform.facebook,
    [Platform.instagram]: ExtendedPlatform.instagram,
    [Platform.whatsapp]: ExtendedPlatform.whatsapp,
  }[platform];
};
