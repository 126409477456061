import { useCurrentBotId } from '@utils/Routing';
import noop from 'lodash-es/noop';
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { log } from 'cf-common/src/logger';
import { extendMessageWithNulls } from '../../components/SendMessage';
import { SendingMessage, SendingMessageStatus } from './types';
import { RestMessage } from '@pages/LiveChat/components/SendMessage/types';
import { fileUpload } from '@utils/Fetch/fileUpload';
import { writeMessageToCache } from '@pages/LiveChat/components/SendMessage/useLocalLiveChatMessageV3';
import { FileAttachmentType } from '@utils/UploadService/types';
import nanoid from 'nanoid';

interface LiveChatContextProps {
  sendingMessages: SendingMessage[];
  uploadingProgress?: number;
  sendMessages(messages: SendingMessage[]): void;
  retryMessage(id: string): void;
  cancelMessage(id: string): void;
}

const UPLOAD_URL_PART_BY_TYPE: Record<string, string> = {
  [FileAttachmentType.file]: 'document',
  [FileAttachmentType.image]: 'image',
  [FileAttachmentType.audio]: 'audio',
};

export const LiveChatMessagesUploadingContext =
  React.createContext<LiveChatContextProps>({
    sendingMessages: [],
    sendMessages: noop,
    retryMessage: noop,
    cancelMessage: noop,
  });

export const LiveChatMessagesUploadingProvider: React.FC = ({ children }) => {
  const botId = useCurrentBotId();

  const [sendingMessages, setSendingMessages] = useState<SendingMessage[]>([]);
  const sendingQueue = useRef<SendingMessage[]>([]);

  const [uploadingProgress, setUploadingProgress] = useState<number>(0);
  const uploadingAbortFunction = useRef<() => void>(noop);

  const removeMessage = useCallback((id: string) => {
    setSendingMessages((currentMessages) =>
      currentMessages.filter((item) => item.id !== id),
    );
  }, []);

  const removeMessageFromQueue = (id: string) => {
    const currentItemIndex = sendingQueue.current.findIndex(
      (item) => item.id === id,
    );
    if (currentItemIndex >= 0) {
      sendingQueue.current.splice(currentItemIndex, 1);
    }
  };

  const setSendingMessageStatus = useCallback(
    (id: string, status: SendingMessageStatus) => {
      setSendingMessages((currentMessages) =>
        currentMessages.map((item) =>
          item.id === id
            ? {
                ...item,
                status,
              }
            : item,
        ),
      );
    },
    [],
  );

  const postMessage = useCallback(() => {
    const message = sendingQueue.current[0];
    if (!message) {
      return;
    }
    const outpacingId = nanoid();
    setUploadingProgress(0);
    setSendingMessageStatus(message.id, SendingMessageStatus.uploading);
    uploadingAbortFunction.current = fileUpload<RestMessage>({
      url: `/livechat/${botId}/conversations/${message.conversationId}/${
        UPLOAD_URL_PART_BY_TYPE[
          message.attachment?.type || FileAttachmentType.file
        ]
      }?platform=${message.platform}&filename=${
        message.attachment?.name
      }&outpacingId=${outpacingId}`,
      fileUrl: message.attachment?.file ?? '',
      fileBlob: message.attachment?.blob,
      fileFieldName: 'filedata',
      onProgress: (percent) => {
        setUploadingProgress(percent || 0);
      },
      onError: () => {
        removeMessageFromQueue(message.id);
        setSendingMessageStatus(message.id, SendingMessageStatus.error);
      },
      onLoad: (result) => {
        const { id, conversationId, platform } = message;
        writeMessageToCache({
          botId: botId || '',
          conversationId,
          platform,
          message: extendMessageWithNulls(
            result,
            conversationId,
            platform,
            outpacingId,
          ),
        });
        removeMessageFromQueue(message.id);
        removeMessage(id);
      },
    });
  }, [removeMessage, botId, setSendingMessageStatus]);

  const sendMessages = useCallback((messages: SendingMessage[]) => {
    setSendingMessages((currentMessages) => [...currentMessages, ...messages]);
    sendingQueue.current.push(...messages);
  }, []);

  const retryMessage = useCallback(
    (id: string) => {
      const message = sendingMessages.find((item) => item.id === id);
      if (message) {
        sendingQueue.current.push(message);
        setSendingMessageStatus(message.id, SendingMessageStatus.queue);
      }
    },
    [sendingMessages, setSendingMessageStatus],
  );

  const cancelMessage = useCallback(
    (id: string) => {
      if (
        sendingMessages.find((item) => item.id === id)?.status ===
        SendingMessageStatus.uploading
      ) {
        try {
          uploadingAbortFunction.current();
          uploadingAbortFunction.current = noop;
        } catch (error) {
          log.warn({ error });
        }
      }
      const itemNumber = sendingQueue.current.findIndex(
        (item) => item.id === id,
      );
      sendingQueue.current.splice(itemNumber, 1);
      removeMessage(id);
    },
    [removeMessage, sendingMessages],
  );

  useEffect(() => {
    if (
      sendingQueue.current.length > 0 &&
      !sendingMessages.some(
        (item) => item.status === SendingMessageStatus.uploading,
      )
    ) {
      postMessage();
    }
  }, [sendingMessages, postMessage]);

  return (
    <LiveChatMessagesUploadingContext.Provider
      value={{
        sendingMessages,
        uploadingProgress,
        sendMessages,
        retryMessage,
        cancelMessage,
      }}
    >
      {children}
    </LiveChatMessagesUploadingContext.Provider>
  );
};

export const useLiveChatMessagesUploadingContext = () => {
  return useContext<LiveChatContextProps>(LiveChatMessagesUploadingContext);
};
