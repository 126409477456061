import React from 'react';
import { CircleLoader } from '@ui/Loader/CircleLoader';
import { IconProps } from '@ui/Icon';
import { MediaMessageBox } from '../MediaMessageBox/MediaMessageBox';

export interface MediaProps {
  iconName: IconProps['icon'];
  text: string;
}

export interface MediaMessageLoaderProps extends MediaProps {
  big: boolean;
  type?: 'incoming' | 'outgoing';
}

export const MediaMessageLoader: React.FC<MediaMessageLoaderProps> = ({
  iconName,
  text,
  big,
  type,
}) => {
  return (
    <MediaMessageBox big={big} text={text} type={type} iconName={iconName}>
      <CircleLoader color="baseSub" size={big ? 36 : 24} opacity={1} />
    </MediaMessageBox>
  );
};
